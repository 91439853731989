import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { TranslateModule } from "@ngx-translate/core";

import { CreateShareApartmentFormComponent } from "./shareApartmentForm/createShareApartmentForm.component";
import { ShareApartmentConfirmComponent } from "./shareConfirm/shareApartmentConfirm.component";
import { EditShareApartmentFormComponent } from "./shareApartmentForm/editShareApartmentForm.component";

import { CreateShareProfileFormComponent } from "./shareProfileForm/createShareProfileForm.component";
import { EditShareProfileFormComponent } from "./shareProfileForm/editShareProfileForm.component";
import { ShareProfileConfirmComponent } from "./shareConfirm/shareProfileConfirm.component";

import { AddUserComponent } from "./addUser/addUser.component";

import { ConnectionModule } from "../connection/connection.module";
import { TextMaskModule } from "angular2-text-mask";
import { FileUploadModule } from "ng2-file-upload";
import { BsDatepickerModule } from "ngx-bootstrap/datepicker";
import { Ng5SliderModule } from "ng5-slider";
import { AgmCoreModule } from '@agm/core';
import { AboutMeFormComponent } from './aboutmeform/AboutMeForm.component';

const googleMapsCore = AgmCoreModule.forRoot({
  apiKey: 'AIzaSyCZStE1RjCN1UqHvxMo47WYQ7ftwyqtevE'
});

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ConnectionModule,
    RouterModule,
    TranslateModule,
    TextMaskModule,
    FileUploadModule,
    BsDatepickerModule.forRoot(),
    DragDropModule,
    Ng5SliderModule,
    googleMapsCore,
  ],
  declarations: [
    EditShareApartmentFormComponent,
    EditShareProfileFormComponent,
    CreateShareProfileFormComponent,
    CreateShareApartmentFormComponent,
    ShareApartmentConfirmComponent,
    ShareProfileConfirmComponent,
    AddUserComponent,
    AboutMeFormComponent,
  ],
  exports: [
    CreateShareApartmentFormComponent,
    EditShareApartmentFormComponent,
    CreateShareProfileFormComponent,
    EditShareProfileFormComponent,
    ShareApartmentConfirmComponent,
    ShareProfileConfirmComponent,
    AddUserComponent,
    AboutMeFormComponent,
  ]
})
export class ShareFormsModule {}
