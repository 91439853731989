import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "SelectPanel",
  templateUrl: "./SelectPanel.component.html",
  styleUrls: ["./SelectPanel.component.css"]
})
export class SelectPanel {
  @Input() items: any[];            // [{id: xxx, name: xxx}, ...]
  @Input() editable = true;
  @Input() maxItems = undefined;
  @Output() selected = new EventEmitter<any[]>();

  selectedStates: boolean[];
  private _preSelected: any[];      // [id1, id2, ...]

  @Input() set preSelected(selected: any[]) {
    this._preSelected = selected;
    const preSelectedSet = new Set(this.preSelected);
    this.selectedStates = this.items.map(item => {
      let isSelected = false;
      preSelectedSet.forEach((index, preSelected) => {
        if (preSelected.name === item.name && preSelected.id === index.id) {
          isSelected = true;
        }
      });
      return isSelected;
    });
  }

  get preSelected() {
    return this._preSelected;
  }

  constructor() {}

  toggle(index) {
    if (!this.editable) {
      return;
    }
    if (!this.selectedStates[index] && this.maxItems && this.selectedStates.filter(state => state).length >= this.maxItems) {
      return;
    }
    this.selectedStates[index] = !this.selectedStates[index];
    this.selected.emit(this.items.filter((_, i) => this.selectedStates[i]).map(item => item));
  }
}
