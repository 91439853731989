import { Injectable, Inject } from '@angular/core';
import { Subject }    from 'rxjs/Subject';
@Injectable()
export class SetLangService {
  	private setLangNotify = new Subject<any>();
  	setLangNotifyObservable$ = this.setLangNotify.asObservable();

  	constructor(){}

  	public setLangNotifyOther() {
		  this.setLangNotify.next();
  	}

  	private setLangCompletedNotify = new Subject<any>();
  	setLangCompletedNotifyObservable$ = this.setLangCompletedNotify.asObservable();

  	public setLangCompletedNotifyOther() {
		  this.setLangCompletedNotify.next();
  	}
}