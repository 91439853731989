import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { ConnectionModule } from "../connection/connection.module";

import { FooterComponent } from "./footer/footer.component";
import { HeaderComponent } from "./header/header.component";
import { HomeComponent } from "./home/home.component";
import { HostHomeComponent } from "./home/hosthome.component";
import { OnboardingComponent } from "./onboarding/onboarding.component";
import { FeedbackComponent } from './feedback/feedback.component'
import { ShareModule } from "../share/share.module";
import { TranslateModule } from "@ngx-translate/core";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { ModalModule, BsDatepickerModule, BsDropdownModule, TooltipModule } from "ngx-bootstrap";
import { TextMaskModule } from 'angular2-text-mask';
import { ImageCropperModule } from "ng2-img-cropper/index";

import { AvatarModule } from 'ngx-avatar';
import { SwitchComponent } from "./switch/switch.component";
import { IncentiveComponent } from './incentive/incentive.component';
import { ChatbotComponent } from './chatbot/Chatbot.component';
import { MatchDetailsModalComponent } from "./matchDetails/matchDetailsModal.component";
import { Ng5SliderModule } from 'ng5-slider'

export { HomeComponent, HostHomeComponent, OnboardingComponent };

@NgModule({
  imports: [
    ModalModule.forRoot(),
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    ShareModule,
    ConnectionModule,
    FontAwesomeModule,
    TextMaskModule,
    ImageCropperModule,
    AvatarModule,
    BsDatepickerModule.forRoot(),
    BsDropdownModule,
    Ng5SliderModule,
    ReactiveFormsModule,
    TooltipModule.forRoot(),
  ],
  declarations: [HeaderComponent, HomeComponent, HostHomeComponent, FooterComponent, OnboardingComponent, FeedbackComponent, SwitchComponent, IncentiveComponent, ChatbotComponent, MatchDetailsModalComponent],
  exports: [HeaderComponent, HomeComponent, HostHomeComponent, FooterComponent, OnboardingComponent, FeedbackComponent, SwitchComponent, IncentiveComponent, ChatbotComponent, MatchDetailsModalComponent],
})
export class GeneralModule {}
