import { Component } from "@angular/core";
import { Utils } from "../../../utils/utils";

import { GeneralController } from "../../../controllers/controllers.module";

@Component({
  selector: "footer-container",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"]
})
export class FooterComponent {
  constructor(private utils: Utils, private genCtrl: GeneralController) {
    this.utils.executeBrowserOnly(() => {
      this.subscriptions.lang = this.genCtrl
        .getSubscribeLang()
        .subscribe(res => {
          this.lang = this.utils.getCurrentLang();
        });
    });
  }

  subscriptions: any = {};
  lang: string = "fr";
  year: number;

  ngOnInit() {
    this.year = (new Date()).getFullYear();
  }

  ngOnDestroy() {
    this.utils.executeBrowserOnly(() => {
      [this.subscriptions.lang].map(obs =>
        this.utils.unsubscribeObservable(obs)
      );
    });
  }

  goToTop() {
    this.utils.executeBrowserOnly(() => {
      window.scrollTo(0, 0);
    });
  }
}
