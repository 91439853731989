export class ConditionsObject {
    constructor() {
    }

    isOwner: boolean = false;
    isRoommate: boolean = false;
    isBookmarked: boolean = false;
    isBrowser: boolean = false;
    isLogged: boolean = false;
    isOpen: boolean = false;
    isSaved: boolean = false;
    isCopied: boolean = false;
    isUploaded: boolean = false;
    hasError: boolean = false;
    isAnimated: boolean = true;
}
