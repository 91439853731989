import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ApartmentController} from './apartment.controller';
import {ProfileController} from './profile.controller';
import {UserController} from './user.controller';
import {GeneralController} from './general.controller';
import {RentRequestController} from './rent-request.controller';
import {PaymentController} from './payment.controller'
import { CityController } from './city.controller';
import { SystemController } from './system.controller';
import { MatchingController } from './matching.controller';
import { FeedbackController } from './feedback.controller'
import { TemplateController } from './template.controller';

@NgModule({
    imports: [CommonModule],
    providers: [
        ApartmentController,
        ProfileController,
        UserController,
        GeneralController,
        RentRequestController,
        PaymentController,
        CityController,
        SystemController,
        MatchingController,
        FeedbackController,
        TemplateController
    ]
})
export class ControllersModule {
}

export {
    ApartmentController,
    ProfileController,
    UserController,
    GeneralController,
    RentRequestController,
    PaymentController,
    CityController,
    SystemController,
    FeedbackController,
    MatchingController,
    TemplateController
};
