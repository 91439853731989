import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  Renderer2
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { Utils } from "../../../utils/utils";
import { TranslateService } from "@ngx-translate/core";
import * as moment from 'moment';

import {
  UserController,
  GeneralController
} from "../../../controllers/controllers.module";

@Component({
  selector: "sendMessage",
  templateUrl: "./sendMessage.component.html",
  styleUrls: ["./sendMessage.component.css"],
})
export class SendMessageComponent {
  @Input() public object: any;
  @Input() public type: any;
  @Input() public onAfterMessageSent: any;

  @ViewChild("contactform") public contactform: NgForm;
  constructor(
    private utils: Utils,
    private el: ElementRef,
    private renderer: Renderer2,
    private translate: TranslateService,
    private userCtrl: UserController,
    private genCtrl: GeneralController
  ) {
    this.subscriptions.login = this.userCtrl
      .getSubscribeLogin(true)
      .subscribe(async () => {
        this.validateUser();
      });

    this.subscriptions.modal = this.userCtrl
      .getSubscribeLoginModal(true)
      .subscribe(async () => {
        this.validateUser();
        this.onSubmitForm();
      });

    this.subscriptions.logout = this.userCtrl
      .getSubscribeLogout(true)
      .subscribe(async () => {
        this.isLogged = false;
        this.loggedUser = null;
      });
  }

  subscriptions: any = {};
  isLogged: boolean = false;
  loggedUser: any;
  mail: any = {
    name: "",
    phone: "",
    email: "",
    message: "",
    moveInMonth: "",
    budget: "",
    lengthOfStay: "",
    id: "",
  };
  loginModal = null;
  errors: any = {
    hasError: false,
    mail: false,
    text: false,
    invalidMail: false
  };

  properties = {
    mailSent: false,
    isSending: false,
    isBrowser: false
  };
  imageHost: string = this.utils.getEnvironmentValue("amazonS3Url");

  firstAvailableMoveInDate: Date;
  maxMoveOutDate: Date;

  months = [
    { value: "1", label: "January" },
    { value: "2", label: "February" },
    { value: "3", label: "March" },
    { value: "4", label: "April" },
    { value: "5", label: "May" },
    { value: "6", label: "June" },
    { value: "7", label: "July" },
    { value: "8", label: "August" },
    { value: "9", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  budgets_en = [
    { value: '1', label: '$600 - $800' },
    { value: '2', label: '$800 - $1,000' },
    { value: '3', label: '$1,000 - $1,200' },
    { value: '4', label: '$1,200 - $1,400' },
    { value: '5', label: '$1,400 - $1,600' },
    { value: '6', label: '$1,600 - $1,800' },
    { value: '7', label: '$1,800+' }
  ];

  budgets_fr = [
    { value: '1', label: '600$ - 800$' },
    { value: '2', label: '800$ - 1 000$' },
    { value: '3', label: '1 000$ - 1 200$' },
    { value: '4', label: '1 200$ - 1 400$' },
    { value: '5', label: '1 400$ - 1 600$' },
    { value: '6', label: '1 600$ - 1 800$' },
    { value: '7', label: '1 800$+' }
  ];

  stays = [
    { value: "1", label: "1 month" },
    { value: "2", label: "2 months" },
    { value: "3", label: "3 months" },
    { value: "4", label: "4 months" },
    { value: "5", label: "5 months" },
    { value: "6", label: "6 months" },
    { value: "7", label: "7 months" },
    { value: "8", label: "8 months" },
    { value: "9", label: "9 months" },
    { value: "10", label: "10 months" },
    { value: "11", label: "11 months" },
    { value: "12", label: "12 months or more" },
  ];

  ngOnInit() {
    this.utils.executeBrowserOnly(() => {
      this.properties.isBrowser = true;
      this.validateUser();
      this.setDefaultMessage();
      this.updateMoveInMonthCalendar();
      this.updateMinStayList();
    });
  }

  validateUser() {
    this.loggedUser = this.utils.getLoggedUser();
    this.isLogged = this.loggedUser;

    if (this.loggedUser) {
      if (!this.mail.email) {
        this.mail.email = this.loggedUser.email ? this.loggedUser.email : "";
      }
      if (!this.mail.name) {
        this.mail.name = (this.loggedUser.firstName ? this.loggedUser.firstName : "") + " " +
                         (this.loggedUser.lastName ? this.loggedUser.lastName : "");
        this.mail.name = this.mail.name.trim();
      }
    }
  }

  getLang() {
    return this.utils.getCurrentLang();
  }

  setDefaultMessage() {
    this.translate.get("I'm interested in this apartment and I'd like to be contacted.")
      .subscribe((res: string) => {
        this.mail.message = res;
      });
  }

  onSubmitForm() {
    if (this.utils.getLoggedUser()) {
      this.isLogged = true;
    }
    if (this.isLogged) {
      if (this.contactform.valid) {
        this.properties.isSending = true;
        this.sendMail();
      } else {
        for (var i in this.contactform.controls) {
          this.contactform.controls[i].markAsTouched();
        }
        this.properties.isSending = false;
      }
    } else {
      this.onOpenLoginModal();
    }
  }

  onCloseLoginModal() {
    this.genCtrl.hideModal(this.renderer, this.loginModal);
  }

  onOpenLoginModal() {
    let loginModal = this.genCtrl.getModal(
      this.el,
      this.renderer,
      "div.modal-login",
      true
    );
  }

  sendMail() {
    if (!this.loggedUser) {
      return;
    }

    this.mail.id = this.object._id;

    this.userCtrl
      .contactOwner(this.mail)
      .then(async () => {
        if (this.onAfterMessageSent) {
          this.onAfterMessageSent();
        }

        const message = this.utils
          .getTranslator()
          .instant("sendmessage.success");
        this.genCtrl.triggerAlertSuccess(message);

        this.setDefaultMessage();
        this.properties.isSending = false;
      })
      .catch(async (error) => {
        const message = this.utils
          .getTranslator()
          .instant("sendmessage.error");
        this.genCtrl.triggerAlertError(message);

        this.properties.isSending = false;

        console.log(error);
      });
  }

  updateMoveInMonthCalendar() {
    this.firstAvailableMoveInDate = new Date();

    let date = moment().startOf('day');
    for (let i = 0; i < (this.object.wholeApartment ? 1 : this.object.rooms.length); i++) {
        if (this.object.rooms[i].moveOutDate) {
            if (moment(this.object.rooms[i].moveOutDate.toString().split('T')[0]).isAfter(date)) {
                date = moment(this.object.rooms[i].moveOutDate.toString().split('T')[0]);
            }
        }
    }
    this.maxMoveOutDate = date.toDate();
  }

  updateMinStayList() {
    let min = 12;
    for (let i = 0; i < (this.object.wholeApartment ? 1 : this.object.rooms.length); i++) {
      let roomMinStay = parseInt(this.object.rooms[i].minimumStay, 10) - 1;
      if (roomMinStay < min) {
        min = roomMinStay;
      }
    }
    this.stays = this.stays.slice(min > 0 ? min - 1 : 0);
  }

  ngOnDestroy() {
    [
      this.subscriptions.login,
      this.subscriptions.logout,
      this.subscriptions.modal
    ].map((obs) => this.utils.unsubscribeObservable(obs));
  }
}
