import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { Routes, RouterModule } from "@angular/router";

import { ShareFormsModule } from "./shareforms.module";
import { TranslateModule } from "@ngx-translate/core";

import { ShareSpaceComponent } from "./shareSpace/shareSpace.component";
import { ShareToggleComponent } from "./shareToggle/shareToggle.component";
import { ShareProfileComponent } from "./shareProfile/shareProfile.component";
import { ShareApartmentComponent } from "./shareApartment/shareApartment.component";
import { CreateShareApartmentFormComponent } from "./shareApartmentForm/createShareApartmentForm.component";

const routes: Routes = [
  { path: "newapartment", component: ShareSpaceComponent },
  { path: "newroommate", redirectTo: "newapartment" },
  { path: "apartment", component: CreateShareApartmentFormComponent },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ShareFormsModule,
    RouterModule.forChild(routes),
    TranslateModule,
  ],
  declarations: [
    ShareProfileComponent,
    ShareApartmentComponent,
    ShareToggleComponent,
    ShareSpaceComponent
  ],
  exports: [ShareToggleComponent]
})
export class ShareModule {}
