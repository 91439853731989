import { Injectable, PLATFORM_ID, Inject } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import {Headers, Http, RequestOptions} from '@angular/http';
import { Utils } from "../utils/utils";

import { FacebookService, LoginResponse } from "ngx-facebook";
import { environment } from "environments/environment";

import { LoginService } from "../modules/services/loginService";
import { LogoutService } from "../modules/services/logoutService";
import { UpdateUserService } from "../modules/services/updateUserService";

@Injectable()
export class UserController {
  constructor(
    private http: Http,
    private utils: Utils,
    private loginService: LoginService,
    private logoutService: LogoutService,
    private updateUserService: UpdateUserService,
    private fb: FacebookService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.fb.init({
        appId: this.utils.getEnvironmentValue("fbAppId"),
        xfbml: true,
        version: "v2.9"
      });
    }
  }

  prepareHeaders() {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append('Authorization', this.utils.getLoginToken());
    return new RequestOptions({ headers: headers });
  }

  async fbLogin() {
    return this.fb
      .login({
        enable_profile_selector: true, // allow user to pick what profile to login with
        return_scopes: true, // returns the scopes that the user authorized
        scope: "public_profile,user_gender,email" // the scopes we want the user to authorize
      })
      .then((response: LoginResponse) => {
        return this.fb
          .api(
            "/me?fields=email,gender,birthday,first_name,last_name,picture.width(400).height(400)",
            "get",
            {}
          )
          .then((responseME: any) => {
            responseME.provider = "facebook";
            responseME.image = responseME.picture.data.url;
            responseME.authToken = response.authResponse.accessToken;
            responseME.token = response.authResponse.accessToken;
            responseME.app_user_id = response.authResponse.userID;
            return responseME;
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((error: any) => console.log("User canceled login."));
  }

  async signIn() {
    return new Promise(async (resolve, reject) => {
      const user = await this.fbLogin();
      if (user && user.id) {
        try {
          let response = await this.getUserByProvider(user.id, user.provider, user.email, user.authToken);
          if (!response || response.length === 0) {
            try {
              user["language"] = this.utils.getCurrentLang();
              response = await this.postUser(user);
              resolve({ user: response, newUser: true });
            } catch (e) {
              resolve({});
            }
          } else {
            resolve({ user: response });
          }
        } catch (e) {
          reject(e);
        }
      } else {
        resolve({});
      }
    });
  }

  async signInGoogle(credential: string) {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await this.getUserByProvider('', 'GOOGLE', '', credential);
        if (!response || response.length === 0) {
          try {
            response = await this.postUser({
              provider: 'GOOGLE',
              token: credential,
              language: this.utils.getCurrentLang()
            });
            resolve({ user: response, newUser: true });
          } catch (e) {
            resolve({});
          }
        } else {
          resolve({ user: response });
        }
      } catch (e) {
        reject(e);
      }
    });
  }

  async signInEmail(email, password) {
    return new Promise(async (resolve, reject) => {
      let response = await this.getUserByEmail(email, password);
      if (!response || response.length === 0 || response.error) {
        resolve(response);
      } else {
        resolve({ user: response });
      }
    });
  }

  async signOut() {
    return this.http
      .post(`${this.utils.getEnvironmentValue("apiUrl")}users/signout`, {}, this.prepareHeaders())
      .toPromise()
      .then((response) => response.json())
      .catch((err) => {
        return err.json();
      });
  }
  
  async registerEmail(info) {
    return new Promise(async (resolve, reject) => {
      let response = await this.registerUserByEmail(info);
      resolve(response);
    });
  }

  async getUser(id) {
    return this.http
      .get(this.utils.getEnvironmentValue("apiUrl") + "users/" + id, this.prepareHeaders())
      .toPromise()
      .then((response) => response.json())
      .catch((err) => {
        return err.json();
      });
  }

  async getUserProfile(id) {
    return this.http
        .get(this.utils.getEnvironmentValue("apiUrl") + "users/" + id + '/profile', this.prepareHeaders())
        .toPromise()
        .then((response) => response.json())
        .catch((err) => {
          return null;
        });
  }

  async getUserByProvider(id, provider, email, token) {
    return this.http
      .get(
        `${this.utils.getEnvironmentValue(
          "apiUrl"
        )}users?provider=${provider}&app_user_id=${id}&email=${email}&token=${token}`
      )
      .toPromise()
      .then((response) => {
        const res = response.json();
        if (res && res.token && res.user) {
          this.utils.storeLoginToken(res.token);
          return res.user;
        }
      })
  }

  async getUserByEmail(email, password) {
    return this.http
      .post(
        `${this.utils.getEnvironmentValue("apiUrl")}users/emaillogin`, {
          provider: "email",
          email: email,
          password: password
        }
      )
      .toPromise()
      .then((response) => {
        const res = response.json();
        if (res && res.token && res.user) {
          this.utils.storeLoginToken(res.token);
          return res.user;
        }
      })
      .catch((err) => {
        return err.json();
      });
  }

  async getUserByCode(code) {
    return this.http
      .get(`${this.utils.getEnvironmentValue("apiUrl")}users?userCode=${code}`, this.prepareHeaders())
      .toPromise()
      .then((response) => response.json());
  }

  async postUser(user) {
    return this.http
      .post(`${this.utils.getEnvironmentValue("apiUrl")}users`, user)
      .toPromise()
      .then((response) => {
        const res = response.json();
        if (res && res.token && res.user) {
          this.utils.storeLoginToken(res.token);
          return res.user;
        } else {
          return res;
        }
      })
      .catch((err) => {
        return err.json();
      });
  }

  async registerUserByEmail(info) {
    info.provider = "email";
    return this.http
      .post(`${this.utils.getEnvironmentValue("apiUrl")}users/emailregister`, info)
      .toPromise()
      .then((response) => response.json())
      .catch((err) => {
        return err.json();
      });
  }

  async confirmUserEmail(info) {
    return this.http
      .post(`${this.utils.getEnvironmentValue("apiUrl")}users/emailconfirm`, info)
      .toPromise()
      .then((response) => response.json())
      .catch((err) => {
        return err.json();
      });
  }

  async resetPasswordReq(email) {
    return this.http
      .post(`${this.utils.getEnvironmentValue("apiUrl")}users/resetpasswordrequest`, {
        email: email
      })
      .toPromise()
      .then((response) => response.json())
      .catch((err) => {
        return err.json();
      });
  }

  async resetPasswordConfirm(email, token, password) {
    return this.http
      .post(`${this.utils.getEnvironmentValue("apiUrl")}users/resetpassword`, {
        email: email,
        token: token,
        password: password
      })
      .toPromise()
      .then((response) => response.json())
      .catch((err) => {
        return err.json();
      });
  }

  async changePassword(email, currentPassword, newPassword) {
    return this.http
      .post(`${this.utils.getEnvironmentValue("apiUrl")}users/changepassword`, {
        email: email,
        currentPassword: currentPassword,
        newPassword: newPassword
      }, this.prepareHeaders())
      .toPromise()
      .then((response) => response.json())
      .catch((err) => {
        return err.json();
      });
  }
  
  async updateUser(id, user) {
    return this.http
      .put(`${this.utils.getEnvironmentValue("apiUrl")}users/${id}`, user, this.prepareHeaders())
      .toPromise()
      .then((response) => response.json());
  }

  async verifyUser(id) {
    return this.http
      .get(`${this.utils.getEnvironmentValue("apiUrl")}verifyuser?user=${id}`, this.prepareHeaders())
      .toPromise()
      .then((response) => response.json());
  }

  async sendVerifySMS(phone) {
    let user = this.utils.getLoggedUser();
    user.userId = user.id;
    user.phone = phone;
    return this.http
      .post(
        `${this.utils.getEnvironmentValue("apiUrl")}users/${user.id}/sendverifysms`,
        user,
        this.prepareHeaders()
      )
      .toPromise()
      .then((response) => response.json());
  }

  async verifyUserPhone(code) {
    let user = this.utils.getLoggedUser();
    user.userId = user.id;
    user.code = code;
    return this.http
      .post(
        `${this.utils.getEnvironmentValue("apiUrl")}users/${user.id}/verifyphone`,
        user,
        this.prepareHeaders()
      )
      .toPromise()
      .then((response) => response.json());
  }

  async isPhoneVerified() {
    let user = this.utils.getLoggedUser();
    return this.http
      .get(`${this.utils.getEnvironmentValue("apiUrl")}users/${user.id}/getverifystatus`, this.prepareHeaders())
      .toPromise()
      .then((response) => response.json());
  }

  async checkPhoneNumber(country, phone) {
    return this.http
      .post(`${this.utils.getEnvironmentValue("apiUrl")}users/checkphone`, { country: country, phone: phone }, this.prepareHeaders())
      .toPromise()
      .then((response) => response.json());
  }

  async getPrevUsedBookingIntro() {
    let user = this.utils.getLoggedUser();
    return this.http
      .get(`${this.utils.getEnvironmentValue("apiUrl")}users/${user.id}/getintro`, this.prepareHeaders())
      .toPromise()
      .then((response) => response.json());
  }

  async contactOwner(data) {
    return this.http
      .post(
        `${this.utils.getEnvironmentValue("apiUrl")}mail/contactowner`,
        data,
        this.prepareHeaders()
      )
      .toPromise()
      .then((response) => response.json());
  }

  async setBookmark(id, isApartment, isBookmarked) {
    let user = this.utils.getLoggedUser();
    if (isBookmarked) {
      user.bookmarks = user.bookmarks.filter(function(bookmark) {
        return bookmark.id != id;
      });
    } else {
      user.bookmarks.push({ id: id, isApartment: isApartment });
    }

    return await this.updateUser(user.id, user);
  }

  async getSecureDocs(type, filename) {
    return this.http
      .get(this.utils.getEnvironmentValue("apiUrl") + `users/get-secure-docs/${type}/${filename}`, this.prepareHeaders()).toPromise();
  }

  getLoginService() {
    return this.loginService;
  }

  getLogoutService() {
    return this.logoutService;
  }

  getUpdateUserService() {
    return this.updateUserService;
  }

  getSubscribeLogin(completed = false) {
    if (completed)
      return this.getLoginService().loginCompletedNotifyObservable$;
    else return this.getLoginService().loginNotifyObservable$;
  }

  getSubscribeLoginModal(completed = false) {
    if (completed)
      return this.getLoginService().loginModalCompletedNotifyObservable$;
    else return this.getLoginService().loginModalNotifyObservable$;
  }

  getSubscribeLogout(completed = false) {
    if (completed)
      return this.getLogoutService().logoutCompletedNotifyObservable$;
    else return this.getLogoutService().logoutNotifyObservable$;
  }

  getSubscribeUpdateUser(completed = false) {
    if (completed)
      return this.getUpdateUserService().updateCompletedNotifyObservable$;
    else return this.getUpdateUserService().updateNotifyObservable$;
  }

  isObjectBookmarked(user, id): Promise<boolean> {
    return new Promise(function(resolve, reject) {
      const apt =
        user &&
        user.bookmarks.find((bookmark) => {
          return bookmark.id == id;
        });
      apt ? resolve(true) : resolve(false);
    });
  }

  setSEO(user) {
    this.utils.setSeoMetaParams(
      user.first_name + " " + user.last_name + " | Hivenue",
      user.aboutme
        ? user.aboutme
        : user.first_name + " " + user.last_name + " | Hivenue"
    );
  }
}
