import { Injectable, Inject } from '@angular/core';
import { Subject }    from 'rxjs/Subject';
@Injectable()
export class LoginService {
  	private loginNotify = new Subject<any>();
  	loginNotifyObservable$ = this.loginNotify.asObservable();

  	constructor(){}

  	public loginNotifyOther() {
		  this.loginNotify.next();
  	}

  	private loginCompletedNotify = new Subject<any>();
  	loginCompletedNotifyObservable$ = this.loginCompletedNotify.asObservable();

  	public loginCompletedNotifyOther() {
		  this.loginCompletedNotify.next();
  	}

    private loginModalNotify = new Subject<any>();
    loginModalNotifyObservable$ = this.loginModalNotify.asObservable();

    public loginModalNotifyOther() {
      this.loginModalNotify.next();
    }

    private loginModalCompletedNotify = new Subject<any>();
    loginModalCompletedNotifyObservable$ = this.loginModalCompletedNotify.asObservable();

    public loginModalCompletedNotifyOther() {
      this.loginModalCompletedNotify.next();
    }
}