import { Component, ElementRef, Input, Renderer2 } from "@angular/core";
import { Utils } from "../../../utils/utils";
import { GeneralController } from "../../../controllers/controllers.module";

@Component({
  selector: "sendMessageModal",
  templateUrl: "./sendMessageModal.component.html",
  styleUrls: ["./sendMessageModal.component.css"],
})
export class SendMessageModalComponent {
  @Input() public object: any;
  @Input() public type: string;
  constructor(
    private el: ElementRef,
    private utils: Utils,
    private renderer: Renderer2,
    private genCtrl: GeneralController
  ) {}

  lang: string;

  onCloseModal = () => {
    this.genCtrl.hideModal(
      this.renderer,
      this.genCtrl.getModal(
        this.el,
        this.renderer,
        "div.sendMessageModal",
        false
      )
    );
  };

  ngOnInit() {
    this.utils.executeBrowserOnly(
      function() {
        this.lang = this.utils.getCurrentLang();

        const contentElem = document.querySelector('.modal-content');
        if (this.object.newFlow) {
          contentElem.setAttribute('style', 'height: 475px !important;');
        }
        else {
          contentElem.setAttribute('style', 'height: 570px !important;');
        }
      }.bind(this)
    );
  }
}
