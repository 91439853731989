import {
    Component,
    ElementRef,
    Input,
    ViewChild,
    Renderer2,
} from '@angular/core';
import {Cookie} from 'ng2-cookies';
import {NgForm} from '@angular/forms';
import {Apartment, Room} from '../../../models/apartment';
import {Router, ActivatedRoute} from '@angular/router';
import {Location} from '@angular/common';
import {Http} from '@angular/http';
import {DomSanitizer} from '@angular/platform-browser';
import {Utils} from '../../../utils/utils';
import {FileItem, FileUploader} from 'ng2-file-upload';
import {ConditionsObject} from '../../../models/conditionsobject';
import * as moment from 'moment'
import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {defineLocale, frLocale} from 'ngx-bootstrap/chronos';
import {environment} from 'environments/environment';
import {MIN_PHOTOS, MAX_PHOTOS, MIN_AGE, MAX_AGE} from '../../../utils/constants';
import {Constants} from '../../../utils/constants';
import {DropdownComponent} from '../../general/dropdown/Dropdown.component';
import { SimpleChanges } from '@angular/core';
import {
    UserController,
    GeneralController,
    ApartmentController,
    CityController,
} from '../../../controllers/controllers.module';

import {PhoneVerifyCodeComponent} from '../../profile/phoneverifycode/phoneVerifyCode.component';
import {env} from 'process';
import { CdkDrag, CdkDragMove, CdkDropList, CdkDropListContainer, CdkDropListGroup, moveItemInArray } from '@angular/cdk/drag-drop';
import {ViewportRuler} from "@angular/cdk/overlay";
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { LabelType, Options } from 'ng5-slider';
import {MyOptionComponent} from '../../general/dropdown/Dropdown.component';
import {GeneralUtils} from '../../../utils/general';
import { UserUtils } from 'app/utils/user';
import { AboutMeChangeEvent } from "app/modules/share/aboutmeform/AboutMeForm.types";
import { KeywordCategory } from "app/controllers/matching.types";

@Component({
    selector: 'createShareApartmentForm',
    templateUrl: './createShareApartmentForm.component.html',
    styleUrls: ['./createShareApartmentForm.component.css'],
})
export class CreateShareApartmentFormComponent {
    @Input() public timeout: number;

    step: number = 0;
    validSteps: any = [];
    viewedSteps: number = 0;
    formSubmitted: boolean = false;
    apartment: Apartment;
    lang: string;

    apartmentInfo: any = {
        address: '',                    // Location - address, value, updated when click next step
        apartmentSize: '7',             // Home details - home size, value
        availableRooms: 0,            // Home details - available rooms, value
        bathrooms: '0',                 // Home details - bathrooms, value
        homeAccomdations: [],           // Home details - home amenities, array
        acceptanceCriteria: [],         // Home details - acceptance criteria, array
        roomsInfo: [{
            moveInDate: '',                             // Availability - move in date, date
            moveOutDate: '',                            // Availability - move out date, date
            minimumStay: '1',                           // Availability - minimum stay, value
            rentType: '',                               // Calculated from date difference - Daily, Weekly, Monthly
            price: '',                                  // Pricing - price, value
            bedType: '',                                // Pricing - bed type, value
            facilities: this.utils.getFacilities(),     // Availability - facilities, array of { id, checked }
            _id: undefined,                             // Room id (must be preserved)
        }],
        listingTitle: '',               // Basic Information - listing title, value
        description: '',                // Basic Information - description, value
        videoTour: '',                  // A link of Youtube 
        isStudio: false                // A flag to indicate that if an apartment is a studio or not
    };
    autoSaveTimer: any;
    autocomplete: any = {};
    addressInvalid: boolean;
    cityInvalid: boolean = false;
    editing: any = undefined;
    isWholeApartment: boolean;          // Only the first room has a price, moveInDate, moveOutDate and minimum stay which is for the entire apartment
    hostLivesInApartment: boolean;      // For private rooms only
    minAge: number = MIN_AGE;
    maxAge: number = MAX_AGE;
    preferenceData: any = {
        preferMale: false,
        preferFemale: false,
        preferNonBinary: false,
        preferStudies: false,
        preferWork: false,
        preferTourism: false,
        ageGroupMin: MIN_AGE,
        ageGroupMax: MAX_AGE,
    };
    ageGroupSliderOptions: any = {
        floor: this.minAge,
        ceil: this.maxAge,
        translate: (value: number, label: LabelType): string => {
            return value >= MAX_AGE ? MAX_AGE + '+' : value.toString();
        }
    };
    propertyAccessMethod: string = 'host-wait-guest';
    hostWaitGuestTime: string;
    checkoutTime: string;
    additionalNote: string;

    loaderImage = 'assets/images/three-dots.svg';

    identity: string;
    identityUploading: boolean;
    identityUploaded: boolean;
    identityOriginalName: string;
    identityWarning: boolean;

    residency: string;
    residencyUploading: boolean;
    residencyUploaded: boolean;
    residencyOriginalName: string;
    residencyWarning: boolean;

    accomodations: any = [];
    accomodationsCriterias: any = [];
    propertyAccessList: any = [];
    hostWaitGuestTimeList: any = [];
    facilities: any = [];
    modals: any = {};
    conditions: ConditionsObject = this.genCtrl.getNewConditionsObject();
    selectedRoom: any = {};
    apartmentSizes: any = [];
    sectors: any = [];
    loggedUser: any;
    moveInDate: any;
    moveOutDate: any;
    availabilityError: boolean = false;
    minimumStayList: any;
    bedTypeList: any;
    minimumStay: string;
    verificationPhoneNumber: string;
    loginOperation: number = 0;       // 0: Just login; 1: verification code; 2: resume
    verificationPhoneValid: boolean = false;
    showPhotoError : boolean = false;
    showIdentityError: boolean = false;
    showSMSerror: boolean = false;
    SMSerrorReason: string = '';
    showPhoneInvalid: boolean = false;
    SMSsent: boolean = false;
    phoneVerified: boolean = false;
    formInvalid: boolean = false;
    countryCodes: any = [
        {'name': 'Canada', 'dial_code': '+1', 'code': 'CA'},
        {'name': 'United States', 'dial_code': '+1', 'code': 'US'}
    ];
    selectedCountry: number = 0;
    postalCodeMask = [
        /[a-zA-Z]/,
        /[0-9]/,
        /[a-zA-Z]/,
        ' ',
        /[0-9]/,
        /[a-zA-Z]/,
        /[0-9]/,
    ];
    imageHost: string = this.utils.getEnvironmentValue('amazonS3Url');
    uploader: FileUploader;
    identityUploader: FileUploader; 
    residencyUploader: FileUploader; 
    hasSaved: boolean = false;
    hideIntro: boolean = false;
    subscriptions: any = {};
    fileToUpload: File | null = null;
    uploadingStatus: any = {};
    misc: any = {};                 // Used to pass extra info to confirm component
    payoutMethod: string = '';
    payoutMethodValue: string = '';
    messageBoxText: string = '';
    onMsgboxClose: any = () => {};
    payoutMethodInvalid: boolean = false;
    objectKeys = Object.keys;
    roomTags: any = [
        'Balcony',
        'Den',
        'Dining Room',
        'Attic',
        'Garage',
        'Garden',
        'Bathroom',
        'Living Room',
        'Basement',
        'Gym',
        'Hall',
        'Laundry Room',
        'Office',
        'Kitchen',
        'Parking',
        'Toilet',
        'Other'
    ];
    roomTagsFr: any = [
        'Balcon',
        'Coin détente',
        'Salle à manger',
        'Grenier',
        'Garage',
        'Jardin',
        'Salle de bains',
        'Salon',
        'Sous-sol',
        'Gym',
        'Hall',
        'Buanderie',
        'Bureau',
        'Cuisine',
        'Parking',
        'Toilette',
        'Autre'
    ];
    lastEvent: AboutMeChangeEvent;
    cities: any = [];

    MIN_PHOTOS = MIN_PHOTOS;
    MAX_PHOTOS = MAX_PHOTOS;

    // current user
    currentUser: any;

    center = {lat: 45.516136, lng: -73.65683};

    @ViewChild('apartmentForm') public apartmentForm: NgForm;
    @ViewChild('cityDropdown') cityDropdown: DropdownComponent;

    @Input() public verifyUser: boolean;
    @Input() public isEdit: boolean;

    @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;

    placeholder: CdkDropList;
    @ViewChild(CdkDropList) set content(content: CdkDropList) {
        this.placeholder = content;
        if (this.step === 4 && this.placeholder) {
            let phElement = this.placeholder.element.nativeElement;
            phElement.style.display = 'none';
            if (phElement.parentElement) {
                phElement.parentElement.removeChild(phElement);
            }
        }
    }
    public items: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    public target: CdkDropList;
    public targetIndex: number;
    public source: CdkDropList;
    public sourceIndex: number;
    public dragIndex: number;
    public activeContainer;
    public cityToSearchFrom: string;
    videoTour: string = "";
    videoTourInvalidUrl: boolean = false;
    isStudio: boolean = false;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private http: Http,
        private utils: Utils,
        private el: ElementRef,
        private renderer: Renderer2,
        private localeService: BsLocaleService,
        private genCtrl: GeneralController,
        private userCtrl: UserController,
        private aptCtrl: ApartmentController,
        private domSanitizer: DomSanitizer,
        private viewportRuler: ViewportRuler,
        public cityCtrl: CityController,
        private genUtils: GeneralUtils,
        private translate: TranslateService,
        private userUtil: UserUtils
    ) {
        this.target = null;
        this.source = null;
        this.activatedRoute.queryParams.subscribe(params => {
            if ('edit' in params) {
                if ('token' in params && 'owner' in params) {
                    this.utils.storeLoginToken(params['token']);
                    this.userUtil.setUserLoginCookies({_id: params['owner']});
                }
            }

        })
        this.uploader = new FileUploader({
            url: this.utils.getEnvironmentValue('apiUrl') + 'upload-image/apartment',
            removeAfterUpload: true,
            allowedFileType: ['image'],
            headers: [
                {name: 'authorization', value: this.utils.getLoginToken()},
                {name: 'Authentication', value: this.userUtil.getLoggedUser().id}
            ],
        });
        this.identityUploader = new FileUploader({
            url: this.utils.getEnvironmentValue('apiUrl') + 'upload-image/identities',
            removeAfterUpload: true,
            allowedFileType: ['pdf', 'image'],
            headers: [
                {name: 'Authorization', value: this.utils.getLoginToken()},
                {name: 'Authentication', value: this.userUtil.getLoggedUser().id}
            ],
        }); 
        this.residencyUploader = new FileUploader({
            url: this.utils.getEnvironmentValue('apiUrl') + 'upload-image/residency',
            removeAfterUpload: true,
            allowedFileType: ['pdf', 'image'],
            headers: [
                {name: 'Authorization', value: this.utils.getLoginToken()},
                {name: 'Authentication', value: this.userUtil.getLoggedUser().id}
            ],
        });
        this.identityUploader.onBeforeUploadItem = () => {
            this.showIdentityError = false;
            this.identityUploading = true;
        }
        this.identityUploader.onCompleteItem = (item: any, response: any) => {
            const uploadedFile = JSON.parse(response);
            this.identity = uploadedFile.filename;
            this.identityOriginalName = item._file.name;
            this.identityUploaded = true;
        };
        this.identityUploader.onWhenAddingFileFailed= () => {
            this.identityUploading = false;
            this.showIdentityError = true;
        }

        this.residencyUploader.onBeforeUploadItem = () => {
            this.showIdentityError = false;
            this.residencyUploading = true;
        }
        this.residencyUploader.onCompleteItem = (item: any, response: any) => {
            const uploadedFile = JSON.parse(response);
            this.residency = uploadedFile.filename;
            this.residencyOriginalName = item._file.name;
            this.residencyUploaded = true;
        };
        this.residencyUploader.onWhenAddingFileFailed= () => {
            this.residencyUploading = false;
            this.showIdentityError = true;
        }

        this.apartmentSizes = utils.getApartmentSizes();
        this.accomodations = utils.getAccomodations();
        this.apartmentInfo.homeAccomdations = utils.getAccomodations();
        this.minimumStayList = utils.getMinimumStayList();
        this.bedTypeList = utils.getBedTypeList();
        this.accomodationsCriterias = utils.getAccomodationsCriterias();
        this.propertyAccessList = utils.getPropertyAccessList();
        this.hostWaitGuestTimeList = utils.getHostWaitGuestTimeList();
        this.apartmentInfo.acceptanceCriteria = utils.getAccomodationsCriterias();
        this.apartmentInfo.availableRooms = 0;
        this.facilities = utils.getFacilities();
        this.apartment = new Apartment();
        (async () => {
            this.cities = await this.cityCtrl.getCities();
        })();

        this.subscriptions.login = this.userCtrl
            .getSubscribeLogin(true)
            .subscribe((res) => {
                this.validateUser();
            });

        this.subscriptions.modal = this.userCtrl
            .getSubscribeLoginModal(true)
            .subscribe((res) => {
                this.validateUser();
                if (this.loginOperation == 2) {
                    this.onOpenResumeModal();
                }
            });

        this.subscriptions.logout = this.userCtrl
            .getSubscribeLogout(true)
            .subscribe((res) => {
                this.conditions.isLogged = false;
                this.loggedUser = null;
                this.getPhoneVerifyStatus();
            });

        this.uploader.onBeforeUploadItem = (
            item: FileItem
        ) => {
            this.showPhotoError = false;
            this.uploadingStatus[item.index] = 'assets/images/single-property-01.jpg'
            let tmpIndex = item.index;
            item.onComplete = () => {
                delete this.uploadingStatus[tmpIndex]
            };
        };

        this.uploader.onWhenAddingFileFailed= () => {
            this.showPhotoError = true;
        }

        this.uploader.onCompleteItem = (
            item: any,
            response: any,
            status: any,
            headers: any
        ) => {
            try {
                var responsePath = JSON.parse(response);
                this.apartment.addNewPhoto(responsePath.filename);
            } catch (e) {
                this.genCtrl.triggerAlertError(this.utils.getTranslator().instant('InvalidImgErrMsg'));
            }
        };
    }

    ngOnInit() {
        this.lang = this.utils.getCurrentLang();
        this.utils.executeBrowserOnly(async () => {
            setInterval(() => {
                this.updateDropdown();
            }, 1000);

            if (this.utils.getCurrentLang() !== 'en') {
                defineLocale(this.utils.getCurrentLang(), frLocale);
                this.localeService.use(this.utils.getCurrentLang());
            }

            window.scrollTo(0, 0);
            this.conditions.isBrowser = true;

            this.hideIntro = Cookie.get('hideApptIntroMsg') ? true : false;

            await this.getCountryCodes();
            this.resetAccomodations();
            this.resetFacilities();
            this.validateUser();
            this.getPayoutMethod();
            this.readTraitsFromProfile();
            // this.getCurrentCity();
            this.apartment.addEmptyRoom(
                this.utils.getTranslator().instant('Room') + ' 1'
            );
            this.utils.hideOverlay();

            this.validSteps = [undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined];

            this.loadStoredFormData();
            this.autoSaveTimer = setInterval(() => {
                this.storeFormData();
            }, 1000);
            this.currentUser = await this.userCtrl.getUser(this.userUtil.getLoggedUser().id);
            if (this.currentUser.identity) {
                this.identity = this.currentUser.identity[this.currentUser.identity.length -1].filename;
                this.identityUploaded = true;
                this.identityOriginalName = this.identity;
            }
            if (this.currentUser.residency) {
                this.residency= this.currentUser.residency[this.currentUser.residency.length - 1].filename;
                this.residencyUploaded = true;
                this.residencyOriginalName = this.residency;
            }
            // Auto update location info
            let prevAddr = '';
            setInterval(() => {
                if (this.step == 0) {
                    let elem = document.getElementById('addressGoogleCompletion');
                    if (!elem) return;
                    let currAddr = elem['value'];
                    if (currAddr != prevAddr) {
                        prevAddr = currAddr;
                        this.apartmentInfo.address = currAddr;
                        this.getAddressInfo();
                    }
                }
            }, 200);

            if (this.step === 9) {
               this.checkAndSetPayoutEmail(this.payoutMethodValue)
            }

            if (this.step === 3) {
                this.initBed(); 
            }

            window['gtag']('event', 'Creating_Enter_create_listing', {
                'event_category': 'Creating_Enter_create_listing',
                'event_label': 'Creating_Enter_create_listing'
            });
        });
    }

    // getCurrentCity() {
    //     if (this.cityToSearchFrom === undefined) {
    //         if (navigator.geolocation)
    //         {
    //         navigator.geolocation.getCurrentPosition(async (position) => {
    //             const lat=position.coords.latitude;
    //             const lon=position.coords.longitude;
    //             const latLongStr = '' + lat + ',' + lon;

    //             let addrInfo: any = await this.getAddressByLatLong(latLongStr);

    //             try {
    //                 let locality = addrInfo.results[0].address_components.filter(x => x.types.includes('locality'))[0];
    //                 this.cityToSearchFrom = this.parceSpecialCharacters(locality.long_name);
    //                 this.cityChanged(this.cityToSearchFrom);
    //                 return true;
    //             } catch (e) {
    //                 return false;
    //             }
    //         });
    //         }

    //     }
    // }

    uploadIdentity() {
        this.identityUploader.uploadAll();
        window['gtag']('event', 'Creating_Uploaded_ID_create_form', {
            'event_category': 'Creating_Uploaded_ID_create_form',
            'event_label': 'Creating_Uploaded_ID_create_form'
        });
    }

    deleteIdentity() {
        this.identityUploading = false;
        this.identityUploaded = false;
    }

    uploadResidency() {
        this.residencyUploader.uploadAll();
        window['gtag']('event', 'Creating_Uploaded_residency_create_form', {
            'event_category': 'Creating_Uploaded_residency_create_form',
            'event_label': 'Creating_Uploaded_residency_create_form'
        });
    }

    deleteResidency() {
        this.residencyUploading = false;
        this.residencyUploaded = false;
    }

    hideIntroMsg() {
        Cookie.set('hideApptIntroMsg', 'true', 365, '/');
        this.hideIntro = true;
    }

    onDateChange(closingDateChange = false, index) {
        if (closingDateChange) {
            this.moveOutDate = null
        }
        this.setRentType(index)
    }

    onAgeGroupSliderChange(event) {
        this.preferenceData.ageGroupMin = event.value ? event.value : MIN_AGE;
        this.preferenceData.ageGroupMax = event.highValue >= MAX_AGE ? MAX_AGE : event.highValue;
    }

    setRentType(index) {
        const availabilityInDays = moment(this.apartmentInfo.roomsInfo[index].moveOutDate).diff(moment(this.apartmentInfo.roomsInfo[index].moveInDate), 'days')
        if (availabilityInDays >= 30) {
            this.apartmentInfo.roomsInfo[index].rentType = 'Monthly'
        } else if (availabilityInDays < 30 && availabilityInDays >= 7) {
            this.apartmentInfo.roomsInfo[index].rentType = 'Weekly'
        } else {
            this.apartmentInfo.roomsInfo[index].rentType = 'Daily'
        }
    }

    validateUser() {
        const loggedUser = this.utils.getLoggedUser();
        if (loggedUser) {
            this.conditions.isLogged = true;
            this.loggedUser = loggedUser;
            if (loggedUser.isBlocked) {
                this.router.navigate([this.utils.getCurrentLang() + '/notfound']);
            }

            if (loggedUser.first_name && loggedUser.last_name) {
                this.apartment.contact.name = `${loggedUser.first_name} ${loggedUser.last_name}`;
            }
            this.getPhoneVerifyStatus();
        }
    }

    uploadAll() {
        console.log('....this.uploader.queue: ', this.uploader.queue);
        if (this.uploader.queue.length + this.apartment.photos.length > MAX_PHOTOS) {
            this.uploader.queue.splice(MAX_PHOTOS - this.apartment.photos.length);
        }

        this.uploader.uploadAll();
    }

    onlyNumberKey(event) {
        return event.charCode == 8 || event.charCode == 0
            ? null
            : event.charCode >= 48 && event.charCode <= 57;
    }
    dragMoved(e: CdkDragMove) {
        let point = this.getPointerPositionOnPage(e.event);

        this.listGroup._items.forEach(dropList => {
          if (this.utils.isInsideDropListClientRect(dropList, point.x, point.y)) {
            this.activeContainer = dropList;
            return;
          }
        });
      }

      dropListDropped() {
        if (!this.target)
          return;

        let phElement = this.placeholder.element.nativeElement;
        let parent = phElement.parentElement;

        phElement.style.display = 'none';

        parent.removeChild(phElement);
        parent.appendChild(phElement);
        parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);

        this.target = null;
        this.source = null;

        if (this.sourceIndex != this.targetIndex)
          moveItemInArray(this.apartment.photos, this.sourceIndex, this.targetIndex);
      }

      dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
        if (drop == this.placeholder)
          return true;

        if (drop != this.activeContainer)
          return false;

        let phElement = this.placeholder.element.nativeElement;
        let sourceElement = drag.dropContainer.element.nativeElement;
        let dropElement = drop.element.nativeElement;

        let dragIndex = this.utils.indexOf(dropElement.parentElement.children, (this.source ? phElement : sourceElement));
        let dropIndex = this.utils.indexOf(dropElement.parentElement.children, dropElement);

        if (!this.source) {
          this.sourceIndex = dragIndex;
          this.source = drag.dropContainer;

          phElement.style.width = (sourceElement.clientWidth - 1) + 'px';
          phElement.style.height = sourceElement.clientHeight + 'px';

          sourceElement.parentElement.removeChild(sourceElement);
        }

        this.targetIndex = dropIndex;
        this.target = drop;

        phElement.style.display = '';
        dropElement.parentElement.insertBefore(phElement, (dropIndex > dragIndex
          ? dropElement.nextSibling : dropElement));

        this.placeholder.enter(drag, drag.element.nativeElement.offsetLeft, drag.element.nativeElement.offsetTop);
        return false;
      }

      /** Determines the point of the page that was touched by the user. */
      getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
        // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
        const point = this.utils.isTouchEvent(event) ? (event.touches[0] || event.changedTouches[0]) : event;
            const scrollPosition = this.viewportRuler.getViewportScrollPosition();

            return {
                x: point.pageX - scrollPosition.left,
                y: point.pageY - scrollPosition.top
            };
        }

    cityChanged(city) {
        this.setCityBound(city)
        if (this.cityToSearchFrom !== city) {
            this.cityToSearchFrom = city
            this.apartmentInfo.address = ""
            this.apartment.adress = null
        }
        this.cityInvalid = false
        this.updateDropdown();
    }

    setCityBound(city) {
        let cityBounds = this.getCityBounds(city);
        if (this.autocomplete.setBounds)
            this.autocomplete.setBounds(cityBounds)
        this.setMapURL(city);
    }

    getCityBounds(city) {
        return this.cities.find(c => c.name === city).cityBounds;
    }

    ngAfterViewInit() {
        this.utils.executeBrowserOnly(() => {
            // Load Google Place Autocomplete
            let that = this;
            window['initMap'] = function () {
                let found = false;
                setInterval(() => {
                    let input = document.getElementById('addressGoogleCompletion');
                    if (input) {
                        if (!found) {
                            let cityBounds = that.getCityBounds(that.cityToSearchFrom);

                            that.autocomplete = new window['google'].maps.places.Autocomplete(input, {
                                bounds: cityBounds,
                                componentRestrictions: {country: 'ca'},
                                strictBounds: true,
                                fields: ['address_components', 'geometry'],
                                types: ['address']
                            });
                            found = true;
                        }
                    } else {
                        found = false;
                    }
                }, 500);
            };

            let s = document.createElement('script');
            s.type = 'text/javascript';
            s.src = 'https://maps.googleapis.com/maps/api/js?key=' + environment.googleLocationAPIKey + '&libraries=places&callback=initMap';
            this.el.nativeElement.appendChild(s);

            this.utils.setTooltips();
            this.utils.setToggle();

            this.modals.assignUser = this.el.nativeElement.querySelector(
                'div.modal-assign-user'
            );
            this.modals.login =
                this.el.nativeElement.querySelector('div.modal-login');
            this.modals.resume = this.el.nativeElement.querySelector(
                'div.modal-share-apartment-resume'
            );
            this.modals.phoneVerify = this.el.nativeElement.querySelector(
                'div.modal-phone-verify-code'
            )});
    }

    // OPEN / CLOSE MODAL
    showModal(modal) {
        this.genCtrl.showModal(this.renderer, modal);
    }

    onOpenAssignUserModal(room: any) {
        this.selectedRoom = room;
        this.showModal(this.modals.assignUser);
    }

    validatePhoneNumber() {
        this.verificationPhoneValid =
            this.verificationPhoneNumber != undefined &&
            this.verificationPhoneNumber.length > 3 &&
            /\+?[0-9]+$/.test(this.verificationPhoneNumber);
        return this.verificationPhoneValid;
    }

    async checkPhone() {
        if (!this.verificationPhoneNumber || this.verificationPhoneNumber.length < 3) {
            return false;
        }
        const numberToCheck = this.countryCodes[this.selectedCountry].dial_code + this.verificationPhoneNumber;
        try {
            const res = await this.userCtrl.checkPhoneNumber(this.countryCodes[this.selectedCountry].code, numberToCheck);
            return res.success;
        } catch (err) {
            return false;
        }
    }

    displayVerifyCodePopup() {
        const digits = (new PhoneVerifyCodeComponent(this.utils, this.el, this.renderer, this.genCtrl, this.userCtrl)).digits;
        for (let i = 0; i < digits; ++i) {
            this.el.nativeElement.querySelector(
                '#verify-digit-' + i
            ).value = '';
        }
        this.showModal(this.modals.phoneVerify);
        this.el.nativeElement.querySelector(
            '#verify-digit-0'
        ).focus();
    }

    async onOpenVerifyPhoneModal() {
        if (!this.validatePhoneNumber()) {
            this.showPhoneInvalid = true;
            return;
        }

        const phoneValid = await this.checkPhone();
        if (!phoneValid) {
            this.verificationPhoneValid = false;
            this.showPhoneInvalid = true;
            return;
        }
        
        if (!this.utils.getLoggedUser()) {
            this.loginOperation = 1;
            this.onOpenLoginModal();
            return;
        }
        const numberChanged =
            this.el.nativeElement.querySelector(
                '#verify-phone-no'
            ).innerHTML !== this.verificationPhoneNumber;
        if (!this.SMSsent || numberChanged) {
            let processedNumber = this.verificationPhoneNumber;
            if (this.countryCodes[this.selectedCountry].code == 'FR' && processedNumber.charAt(0) === '0') {
                processedNumber = processedNumber.slice(1);
            }
            processedNumber = this.countryCodes[this.selectedCountry].dial_code + ' ' + processedNumber;

            this.userCtrl.sendVerifySMS(processedNumber)
                .then(() => {
                    this.SMSsent = true;
                    this.showSMSerror = false;
                    this.timeout = 30;
                    this.el.nativeElement.querySelector(
                        '#verify-phone-no'
                    ).innerHTML = processedNumber;
                    this.displayVerifyCodePopup();
                })
                .catch((err) => {
                    this.SMSsent = false;
                    this.showSMSerror = true;
                    if (err._body) {
                        const errMsg = JSON.parse(err._body).error;
                        if (errMsg) {
                            this.SMSerrorReason = errMsg;
                        } else {
                            this.SMSerrorReason = err._body;
                        }
                    } else {
                        this.SMSerrorReason = err.statusText;
                    }
                });
        } else {
            this.displayVerifyCodePopup();
        }
    }

    async getCountryCodes() {
        this.countryCodes = await this.http.get('assets/i18n/country_codes.json').toPromise().then((response) => {
            return response.json();
        }
        ).catch((err) => {
            console.log(err);
        });
    }

    matchCountryCodeByPhone() {
        let strMatch = '';

        if (this.verificationPhoneNumber.charAt(0) != '+') {
            this.verificationPhoneNumber = '+' + this.verificationPhoneNumber;
        }

        for (let i = 0; i < Math.min(this.verificationPhoneNumber.length, 4); ++i) {
            strMatch += this.verificationPhoneNumber.charAt(i);
            for (let j = 0; j < this.countryCodes.length; ++j) {
                if (this.countryCodes[j].dial_code == strMatch) {
                    this.selectedCountry = j;
                    this.verificationPhoneNumber = this.verificationPhoneNumber.substr(i + 1);
                    return;
                }
            }
        }
    }

    getPhoneVerifyStatus() {
        this.userCtrl.isPhoneVerified()
            .then((res) => {
                if (res.phone && res.verified && res.verified === true) {
                    this.phoneVerified = true;
                    this.verificationPhoneNumber = res.phone;
                    this.matchCountryCodeByPhone();
                } else {
                    this.phoneVerified = false;
                }
            })
            .catch((err) => {
                this.phoneVerified = false;
                console.log(err);
            })
    }

    async getPayoutMethod() {
        if(!this.utils.getLoggedUser()) return;
        let userInfo = await this.userCtrl.getUser(this.utils.getLoggedUser().id);
        if (userInfo.payoutMethodValue && userInfo.payoutMethodValue != '') {
            this.payoutMethodValue = userInfo.payoutMethodValue;
        }
    }

    onVerified(verified: boolean) {
        if (!this.phoneVerified && verified && this.conditions.hasError) {
            this.conditions.hasError = false;
        }
        this.phoneVerified = verified;
        if (verified) {
            const message = this.utils
                .getTranslator()
                .instant('VerifiedMsg');
            this.genCtrl.triggerAlertSuccess(message);
            
            window['gtag']('event', 'Creating_Phone_verified_create_form', {
                'event_category': 'Creating_Phone_verified_create_form',
                'event_label': 'Creating_Phone_verified_create_form'
            });
        }
        else {
            const message = this.utils
                .getTranslator()
                .instant('The code you provided is incorrect');
            this.genCtrl.triggerAlertError(message);
            window['gtag']('event', 'Creating_Phone_not_verified_create_form', {
                'event_category': 'Creating_Phone_not_verified_create_form',
                'event_label': 'Creating_Phone_not_verified_create_form'
            });
        }
    }

    onOpenLoginModal() {
        this.showModal(this.modals.login);
    }

    // check mandtory fields
    isAbleToPost(): boolean {
       return this.phoneVerified && this.identityUploaded && this.residencyUploaded && !this.payoutMethodInvalid;
    }

    // check if payout email is valid
    isPayoutEmailValid(payoutMethodValue: string): boolean {
        let regexp = new RegExp(
            '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$'
        );
        return regexp.test(payoutMethodValue); 
    }

    checkAndSetPayoutEmail(payoutMethodValue: string) {
        if (this.isPayoutEmailValid(payoutMethodValue)) {
            this.payoutMethodInvalid = false;
            this.misc.payoutMethod = this.payoutMethod;
            this.misc.payoutMethodValue = this.payoutMethodValue; 
        } else {
            this.payoutMethodInvalid = true;
            this.conditions.hasError = true;
        }
    }

    async onOpenResumeModal() {
        this.utils.showOverlay();
        const success = await this.validateForm(this.apartmentForm);
        if (!success) {
            this.conditions.hasError = true;
            this.formInvalid = true;
        }
        this.validSteps[9] = false;

        if (!this.phoneVerified) {
            this.conditions.hasError = true;
            this.utils.hideOverlay();
            return;
        }

        if (!this.identityUploaded) {
            this.identityWarning = true;
            this.conditions.hasError = true;
            this.utils.hideOverlay();
            return;
        }

        if (!this.residencyUploaded) {
            this.residencyWarning = true;
            this.conditions.hasError = true;
            this.utils.hideOverlay();
            return;
        }

        // Check if payout email is valid
        this.payoutMethodValue = this.payoutMethodValue.trim();
        const validPayoutEmail = this.isPayoutEmailValid(this.payoutMethodValue)
        this.checkAndSetPayoutEmail(this.payoutMethodValue);

        this.validSteps[9] = true;

        // --------------------------------------------------
        // (Re)obtain address info
        if (!this.apartmentInfo.address) {
            this.step = 0;
            this.addressInvalid = true;
            this.conditions.hasError = true;
            this.utils.hideOverlay();
            return;
        }
        let addressValid = await this.getAddressInfo();
        if (!addressValid) {
            this.step = 0;
            this.addressInvalid = true;
            this.utils.hideOverlay();
            return;
        } else {
            this.addressInvalid = false;
        }

        let cities = await this.utils.getCities();
        this.cityToSearchFrom = this.cityToSearchFrom.trim().toLowerCase();
        if(!this.cityToSearchFrom || this.cityToSearchFrom.length <= 0 ||
            !cities.find(city => city.name.toLowerCase() === this.cityToSearchFrom || city.name_fr.toLowerCase() === this.cityToSearchFrom || city.value.toLowerCase() === this.cityToSearchFrom)) {
            this.cityInvalid = true;
            this.cityToSearchFrom = "";
            this.utils.hideOverlay();
            return;
        } else {
            this.cityToSearchFrom = cities.find(city => city.name.toLowerCase() === this.cityToSearchFrom || city.name_fr.toLowerCase() === this.cityToSearchFrom || city.value.toLowerCase() === this.cityToSearchFrom).value;
            this.apartment.adress.city = this.utils.titlecase(this.cityToSearchFrom);
            this.cityInvalid = false;
        }

        // Check if all steps are valid
        for (let i = 0; i < this.validSteps.length; i++) {
            if (i === 6 && !this.hostLivesInApartment) {
                continue;
            }
            if (!this.validSteps[i]) {
                this.step = i;
                this.conditions.hasError = true;
                this.utils.hideOverlay();
                return;
            }
        }

        // Check room info
        if (!this.checkAvailabilityInfo() || !this.checkPriceInfo()) {
            this.step = 2;
            this.conditions.hasError = true;
            this.utils.hideOverlay();
            return;
        }

        this.conditions.hasError = false;

        // Convert apartmentInfo to apartment
        this.apartment.name = this.apartmentInfo.listingTitle;
        this.apartment.description = this.apartmentInfo.description;
        this.apartment.size = this.apartmentInfo.apartmentSize;
        this.apartment.roomsCount = this.apartmentInfo.availableRooms;
        this.apartment.bathCount = this.apartmentInfo.bathrooms;

        // Copy home accomodations and acceptance criteria
        this.misc.accomodations = new Array();
        for (let acc of this.apartmentInfo.homeAccomdations) {
            if (acc.isSelected) {
                this.misc.accomodations.push({id: acc.id});
            }
        }

        for (let acc of this.apartmentInfo.acceptanceCriteria) {
            if (acc.isSelected) {
                this.misc.accomodations.push({id: acc.id});
            }
        }

        // Find the earlist move in date and the latest move out date
        // Also find the min price and max price
        this.apartment.minprice = undefined;
        this.apartment.maxprice = undefined;
        if (this.isWholeApartment) {
            this.apartment.minprice = Number(this.apartmentInfo.roomsInfo[0].price);
            this.apartment.maxprice = Number(this.apartmentInfo.roomsInfo[0].price);
        }
        else {
            for (let room of this.apartmentInfo.roomsInfo) {
                if (!this.apartment.minprice || room.price < Number(this.apartment.minprice)) {
                    this.apartment.minprice = Number(room.price);
                }
                if (!this.apartment.maxprice || room.price > Number(this.apartment.maxprice)) {
                    this.apartment.maxprice = Number(room.price);
                }
            }
        }

        // Handle entire apartment situation
        if (this.isWholeApartment) {
            for (let i = 1; i < this.apartmentInfo.roomsInfo.length; i++) {
                this.apartmentInfo.roomsInfo[i].moveInDate = this.apartmentInfo.roomsInfo[0].moveInDate;
                this.apartmentInfo.roomsInfo[i].moveOutDate = this.apartmentInfo.roomsInfo[0].moveOutDate;
                this.apartmentInfo.roomsInfo[i].minimumStay = this.apartmentInfo.roomsInfo[0].minimumStay;
                this.apartmentInfo.roomsInfo[i].rentType = this.apartmentInfo.roomsInfo[0].rentType;
                this.apartmentInfo.roomsInfo[i].price = this.apartmentInfo.roomsInfo[0].price;
            }
        }

        // Only keep dates as strings
        for (let room of this.apartmentInfo.roomsInfo) {
            room.moveInDate = room.moveInDate.getFullYear() + '-' + (room.moveInDate.getMonth() + 1) + '-' + room.moveInDate.getDate();
            room.moveOutDate = room.moveOutDate.getFullYear() + '-' + (room.moveOutDate.getMonth() + 1) + '-' + room.moveOutDate.getDate();
        }

        // Convert roomsInfo to rooms
        this.apartment.rooms = [];
        let roomIndex = 1;
        for (let room of this.apartmentInfo.roomsInfo) {
            let roomInfo = new Room();
            roomInfo._id = room._id;
            roomInfo.moveInDate = room.moveInDate;
            roomInfo.moveOutDate = room.moveOutDate;
            roomInfo.minimumStay = room.minimumStay;
            roomInfo.price = room.price;
            roomInfo.bedType = room.bedType;
            roomInfo.beds = room.beds;
            roomInfo.facilities = [];
            roomInfo.rentType = room.rentType;
            roomInfo.description = this.utils.getTranslator().instant('Room') + ' ' + roomIndex;
            for (let f of room.facilities) {
                if (f.isSelected) {
                    roomInfo.facilities.push({id: f.id});
                }
            }
            this.apartment.rooms.push(roomInfo);
            roomIndex++;
        }

        // Find the room with highest price, then set rentType to match the rentType of that room
        let maxPrice = -999999;
        this.apartment.rentType = 'Monthly';
        for (let room of this.apartment.rooms) {
            if (parseInt(room.price.toString()) > maxPrice) {
                maxPrice = parseInt(room.price.toString());
                this.apartment.rentType = room.rentType;
            }
        }

        this.misc.editing = this.editing;       // Tell the confirm window, the user is editing instead of creating

        // --------------------------------------------------

        this.validSteps[this.step] = success;

        if (this.stepsValid()) {
            this.formSubmitted = false;
            this.setAccomodations();
            this.setFacilities();

            // Send request
            this.onSave()
                .then((response: any) => {
                    if (this.misc.editing) {
                        this.messageBoxText = 'updated';
                    }
                    else {
                        this.messageBoxText = 'created';
                    }
                    const successModal = this.el.nativeElement.querySelector('div.modal-success');
                    this.utils.hideOverlay();
                    this.genCtrl.showModal(this.renderer, successModal);
                    this.onMsgboxClose = () => {
                        this.router.navigate([`${this.utils.getCurrentLang()}/myprofile/sharings`]);
                    };
                })
                .catch(() => {
                    this.utils.hideOverlay();
                    this.genCtrl.triggerAlertError(this.utils.getTranslator().instant('Request failed, please try again!'));
                });

            this.conditions.hasError = false;
            window['gtag']('event', 'Creating_Click_publish_button', {
                'event_category': 'Creating_Click_publish_button',
                'event_label': 'Creating_Click_publish_button'
            });
        } else {
            this.utils.hideOverlay();
            this.formSubmitted = true;
            window.scrollTo(0, 0);
        }
    }

    async onSave() {
        return new Promise(async (resolve, reject) => {
            // Copy home accomodations
            this.apartment.accomodations = new Array();
            for (let acc of this.misc.accomodations) {
                this.apartment.accomodations.push({ id: acc.id });
            }

            // Handle whole apartment situation
            this.apartment.wholeApartment = this.isWholeApartment;

            // Handle host lives in the apartment
            this.apartment.hostLivesInApartment = this.hostLivesInApartment;
            this.apartment.preferenceData = this.preferenceData;

            this.apartment.adress.city = this.apartment.adress.city;
            this.apartment.videoTour = this.videoTour; 
            this.apartment.isStudio = this.apartmentInfo.isStudio;

            // Store move-in details
            this.apartment.moveInDetails = {
                propertyAccessMethod: this.propertyAccessMethod,
                hostWaitGuestTime: this.hostWaitGuestTime,
                additionalNote: this.additionalNote,
                checkoutTime: this.checkoutTime
            }

            let user = this.utils.getLoggedUser();
            let postData = JSON.parse(JSON.stringify(this.apartment));
            postData._id = undefined;

            // Check edit mode
            let response;
            postData.updateInfo = {
                proformer: "owner"
            }
            if (this.misc.editing) {
                response = await this.aptCtrl.updateApartment(this.misc.editing, postData);
            }
            else {
                response = await this.aptCtrl.postApartment(postData, user.id);
            }
            resolve(response);
        });
    }

    addRoom() {
        if (this.apartment.rooms.length < 10) {
            this.apartment.addEmptyRoom(
                this.utils.getTranslator().instant('Room') +
                ' ' +
                (this.apartment.rooms.length + 1)
            );
            this.utils.setTooltips();
        }
    }

    async removePhoto(photo: any) {
        await this.aptCtrl.removePhoto(photo.source).catch((e) => {
            let error = JSON.parse(e.text());
            console.log(error.error);
            window.scrollTo(0, 0);
        });
        this.apartment.photos.splice(this.apartment.photos.indexOf(photo), 1);
    }

    getAddress() {
        return new Promise((resolve, reject) => {
            this.http
                .get(
                    `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCCyG3_3J__P-wpE5WYLiZ9A_-76dMnyz0&address=` +
                    this.apartmentInfo.address
                )
                .toPromise()
                .then((response) => response.json())
                .then((response) => resolve(response))
                .catch((err) => reject(true));
        });
    }

    getAddressByLatLong(latLong) {
        return new Promise((resolve, reject) => {
            this.http
                .get(
                    `https://maps.googleapis.com/maps/api/geocode/json?key=${environment.googleLocationAPIKey}&latlng=` +
                    latLong
                )
                .toPromise()
                .then((response) => response.json())
                .then((response) => resolve(response))
                .catch((err) => reject(true));
        });
    }
    async getAddressInfo() {
        // Obtain address info
        let addrInfo: any = await this.getAddress();

        // Get the first sublocality item and first postal_code item
        try {
            let addressComponents = addrInfo.results[0].address_components;


            let adminArea = (addressComponents.filter(x => x.types.includes('administrative_area_level_3'))[0] ||
                addressComponents.filter(x => x.types.includes('locality'))[0]).long_name;
            let sublocality = (addressComponents.filter(x => x.types.includes('sublocality'))[0] ||
                addressComponents.filter(x => x.types.includes('neighborhood'))[0] ||
                addressComponents.filter(x => x.types.includes('locality'))[0]).long_name;

            let postalCode = addressComponents.filter(x => x.types.includes('postal_code'))[0].long_name;
            this.apartment.adress = {
                adress: this.apartmentInfo.address,
                city: this.utils.titlecase(this.cityToSearchFrom),
                neighborhood: sublocality,
                postalCode: postalCode,
                lat: addrInfo.results[0].geometry.location.lat,
                lng: addrInfo.results[0].geometry.location.lng
            };

            this.setMapURL(this.utils.titlecase(this.cityToSearchFrom));
            return true;
        } catch (e) {
            if (this.apartment.adress) {
                this.apartment.adress.adress = ''
            }
            return false;
        }
    }

    resetAccomodations() {
        for (let accomodation of this.accomodations) {
            accomodation.isSelected = false;
        }

        for (let accomodationCriteria of this.accomodationsCriterias) {
            accomodationCriteria.isSelected = false;
        }
    }

    setAccomodations() {
        this.apartment.accomodations = [];
        for (let accomodation of this.accomodations) {
            if (accomodation.isSelected) {
                this.apartment.accomodations.push({id: accomodation.id});
            }
        }

        for (let accomodationCriteria of this.accomodationsCriterias) {
            if (accomodationCriteria.isSelected) {
                this.apartment.accomodations.push({id: accomodationCriteria.id});
            }
        }
    }

    resetFacilities() {
        for (let facility of this.facilities) {
            facility.isSelected = false;
        }
    }

    setFacilities() {
        this.apartment.facilities = [];
        for (let facility of this.facilities) {
            if (facility.isSelected) {
                this.apartment.facilities.push({id: facility.id});
            }
        }
    }

    myListFormatter(data: any): String {
        return data.value;
    }

    ngOnDestroy() {
        this.utils.executeBrowserOnly(() => {
            this.utils.showOverlay();
            [
                this.subscriptions.logout,
                this.subscriptions.login,
                this.subscriptions.modal,
            ].map((obs) => this.utils.unsubscribeObservable(obs));
        });
    }

    validateForm(form) {
        return new Promise((resolve) => {
            if (form.valid) {
                resolve(true);
            } else {
                for (var i in form.controls) {
                    form.controls[i].markAsTouched();
                }
                resolve(false);
            }
        });
    }

    async nextStep() {
        if (this.step === 0) {
            this.apartmentInfo.address = document.getElementById('addressGoogleCompletion')['value'];
            let addressValid = await this.getAddressInfo();
            if (!addressValid) {
                this.addressInvalid = true;
                window['gtag']('event', 'Creating_Select_neighborhood_error', {
                    'event_category': 'Creating_Select_neighborhood_error',
                    'event_label': 'Creating_Select_neighborhood_error'
                });
                return;
            } else {
                this.addressInvalid = false;
            }
        }
        else if (this.step === 6) {
            if (!this.validateTraitsSelections()) {
                return;
            }
            this.updateUserProfile();
        }

        if (this.viewedSteps == this.step) {
            this.viewedSteps++;
        }

        if (this.step === 5) {
            if (this.isWholeApartment || !this.hostLivesInApartment) {
                this.step = 7;
                this.validSteps[5] = true;
                this.viewedSteps++;
            }
        }

        if (this.step === 4 && this.videoTour.length > 0) {
            this.videoTourInvalidUrl = !this.matchYoutubeUrl(this.videoTour);
        }
        if (this.step === 8) {
            this.checkAndSetPayoutEmail(this.payoutMethodValue)
        }
        if (this.step === 2) {
            this.initBed();
        }

        let currentStep = this.step;
        this.setStep(++currentStep);
        this.conditions.hasError = false;
        window.scrollTo(0, 0);
        window['gtag']('event', 'Creating_Enter_step_' + this.step.toString(), {
            'event_category': 'Creating_Enter_step_' + this.step.toString(),
            'event_label': this.step.toString()
        });
    }

    async previousStep() {
        if (this.step === 0) {
            this.router.navigate([this.utils.getCurrentLang() + '/share/newapartment'])
        } else {
            if (this.step === 7) {
                if (this.isWholeApartment || !this.hostLivesInApartment) {
                    this.step = 6;
                }
            }
            let currentStep = this.step;
            this.setStep(--currentStep);
            this.conditions.hasError = false;
            window.scrollTo(0, 0);
            this.viewedSteps--;
            setTimeout(() => {
                this.setMapURL(this.apartment.adress.city);
            }, 1000);
        }
    }

    async setStep(step) {
        if (this.viewedSteps < step && step >= this.step) {
            return false;
        }

        if (step >= 0 && step < 10 && step != this.step) {
            const success = await this.validateForm(this.apartmentForm);
            this.validSteps[this.step] = success;
            this.step = step;
            this.initComponents();
        }

        if (step == 9) {
            this.getPhoneVerifyStatus();
        }
        else if (step == 6) {
            this.readTraitsFromProfile();
        }

        if (!this.apartmentInfo.address) {
            this.validSteps[0] = false;
        }

        this.updateUrl();
        this.viewedSteps = step;
    }

    calculateMinDate(roomInfo) {
        // Ignore hours, minutes, seconds, milliseconds
        const moveInDate = moment(roomInfo.moveInDate).startOf('day').toDate();

        // Check if the moveInDate is at the beginning of the month.
        // If yes, then directly add the corresponding number of months to the moveInDate
        // If not, then use 30 days as one month
        let monthsToAdd = parseInt(roomInfo.minimumStay) - 1;
        if (monthsToAdd >= 12) {
            monthsToAdd = 12;
        }
        if (moment(moveInDate).date() == 1) {
            return moment(moveInDate).add(monthsToAdd, 'month').add(-1, "days").toDate();
        } else {
            return moment(moveInDate).add(monthsToAdd * 30, 'day').toDate();
        }
    }

    initComponents() {
        this.utils.setTooltips();
        this.utils.setToggle();
    }

    stepsValid() {
        let isValid = true;
        for (let i = 0; i < this.validSteps.length; i++) {
            if (i === 6 && !this.hostLivesInApartment) {
                continue;
            }
            if (!this.validSteps[i]) {
                isValid = false;
            }
        }

        if (!this.phoneVerified) {
            isValid = false;
        }

        return isValid;
    }

    removeUser(room) {
        room.userId = undefined;
        room.userName = '';
        room.userPhoto = '';
    }

    removeRoom(room) {
        let index: number = this.apartment.rooms.indexOf(room);
        if (index !== -1) {
            this.apartment.rooms.splice(index, 1);
        }
    }

    handleFileInput(files: FileList) {
        console.log('FILE INPUT');
        this.fileToUpload = files.item(0);
    }

    isAvailableFromInThePast() {
        for (let i = 0; i < this.apartmentInfo.roomsInfo.length; i++) {
            if (moment(this.apartmentInfo.roomsInfo[i].moveInDate).isBefore(moment(), 'day')) {
                return true;
            }
        }
        return false;
    }

    getPriceHeading(index) {
        switch (this.apartmentInfo.roomsInfo[index].rentType) {
            case 'Daily':
                return 'Daily price'
            case 'Weekly':
                return 'Weekly price'
            case 'Monthly':
                return 'Monthly price'
            default:
                return 'Monthly price'
        }
    }

    updateRoomInfoArray() {
        let newRoomsCount = parseInt(this.apartmentInfo.availableRooms);
        if (this.apartmentInfo.roomsInfo.length < newRoomsCount) {
            let needed = newRoomsCount - this.apartmentInfo.roomsInfo.length;
            for (let i = 0; i < needed; i++) {
                this.apartmentInfo.roomsInfo.push({
                    moveInDate: '',
                    moveOutDate: '',
                    minimumStay: '',
                    price: '',
                    bedType: '',
                    facilities: JSON.parse(JSON.stringify(this.utils.getFacilities()))
                });
            }
        } else {
            this.apartmentInfo.roomsInfo = this.apartmentInfo.roomsInfo.slice(0, newRoomsCount);
        }
    }

    checkAvailabilityInfo() {
        for (let item of (this.isWholeApartment ? [this.apartmentInfo.roomsInfo[0]] : this.apartmentInfo.roomsInfo)) {
            if (!item.moveInDate || !item.moveOutDate || !item.minimumStay || item.moveOutDate < this.calculateMinDate(item)) {
                if (item.moveInDate && item.moveOutDate && item.minimumStay) {
                    this.availabilityError = true;
                }
                return false;
            }
        }
        this.availabilityError = false;
        return true;
    }

    checkPriceInfo() {
        console.log(this.apartmentInfo.roomsInfo);
        for (let item of (this.isWholeApartment ? [this.apartmentInfo.roomsInfo[0]] : this.apartmentInfo.roomsInfo)) {
            if ('price' in item && !item['price']) {
                return false;
            }
        }
        return true;
    }

    checkBedTypeInfo() {
        for (let room of this.apartmentInfo.roomsInfo) {
            if (!Array.isArray(room.beds) || room.beds.length == 0) return false;
            if (room.beds.length == 0) return false;
            for (let bed of room.beds) {
                if (typeof bed.bedType === 'undefined')
                    return false;
            } 
        }
        return true;
    }

    checkIdealRenterInfo() {
        if ((!this.preferenceData.preferMale && !this.preferenceData.preferFemale && !this.preferenceData.preferNonBinary) ||
            (!this.preferenceData.preferStudies && !this.preferenceData.preferWork && !this.preferenceData.preferTourism)) {
            return false;
        }
        return true;
    }

    checkAvailableRooms() {
        console.log(this.apartmentInfo)
        return this.apartmentInfo.availableRooms > 0;
    }

    checkMoveInDetailsInfo() {
        if (!this.propertyAccessMethod)
            return false;
        if (this.cityToSearchFrom.toLowerCase() !== 'montreal' && this.propertyAccessMethod == 'agent')
            return false;
        if (this.propertyAccessMethod == 'host-wait-guest' && !this.hostWaitGuestTime &&!this.checkoutTime)
            return false;
        return true;
    }

    async loadFormDataFromApartmentId(aptId) {
        // Get apartment info
        const apartment: Apartment = await this.aptCtrl.getApartment(this.editing).catch((err) => {
            this.router.navigate(['404']);
        });
        console.log(apartment)

        if (!apartment) {
            this.router.navigate(['404']);
        }

        if (apartment.owner.userId._id != this.loggedUser.id 
            && apartment.owner.userId._id != this.loggedUser._id
        ) {
            this.router.navigate(['404']);
        }

        this.apartment = apartment;
        this.isWholeApartment = (apartment.wholeApartment) as boolean;
        this.hostLivesInApartment = (apartment.hostLivesInApartment) as boolean;
        this.preferenceData = apartment.preferenceData;
        if (apartment.moveInDetails) {
            this.propertyAccessMethod = apartment.moveInDetails.propertyAccessMethod;
            this.hostWaitGuestTime = apartment.moveInDetails.hostWaitGuestTime;
            this.checkoutTime = apartment.moveInDetails.checkoutTime;
            this.additionalNote = apartment.moveInDetails.additionalNote;
        }
        if (this.preferenceData) {
            this.minAge = this.preferenceData.ageGroupMin;
            this.maxAge = this.preferenceData.ageGroupMax;
        }
        else {
            // The apartment does not have preference data, use default values
            this.preferenceData = {
                preferMale: false,
                preferFemale: false,
                preferNonBinary: false,
                preferStudies: false,
                preferWork: false,
                preferTourism: false,
                ageGroupMin: MIN_AGE,
                ageGroupMax: MAX_AGE,
            };
        }
        this.apartment.addNewPhoto = (new Apartment).addNewPhoto;

        this.apartmentInfo.address = this.apartment.adress.adress;

        this.apartmentInfo.apartmentSize = apartment.size;
        this.apartmentInfo.availableRooms = apartment.rooms.length;
        this.apartmentInfo.bathrooms = (apartment.bathCount ? apartment.bathCount : 0).toString();
        this.apartmentInfo.listingTitle = apartment.name;
        this.apartmentInfo.description = apartment.description;
        this.apartmentInfo.roomsInfo = [];

        this.apartmentInfo.videoTour = (apartment.videoTour ? apartment.videoTour : "");
        this.videoTour = this.apartmentInfo.videoTour;
        this.isStudio = this.apartment.isStudio && this.apartment.isStudio.valueOf();
        this.apartmentInfo.isStudio = this.isStudio;
        
        // Copy accomodations and acceptance criteria
        for (let acc of apartment.accomodations) {
            let matched = false;
            for (let item of this.apartmentInfo.homeAccomdations) {
                if (item.id == acc.id) {
                    item.isSelected = true;
                    matched = true;
                    break;
                }
            }
            if (!matched) {
                for (let item of this.apartmentInfo.acceptanceCriteria) {
                    if (item.id == acc.id) {
                        item.isSelected = true;
                        matched = true;
                        break;
                    }
                }
            }
        }

        // Copy room info
        for (let room of apartment.rooms) {
            let facilities = JSON.parse(JSON.stringify(this.utils.getFacilities()));

            for (let f of room.facilities) {
                for (let item of facilities) {
                    if (item.id == f.id) {
                        item.isSelected = true;
                        break;
                    }
                }
            }

            this.apartmentInfo.roomsInfo.push({
                moveInDate: moment(room.moveInDate.toString().split('T')[0]).toDate(),
                moveOutDate: moment(room.moveOutDate.toString().split('T')[0]).toDate(),
                minimumStay: room.minimumStay,
                rentType: room.rentType,
                price: room.price,
                bedType: room.bedType,
                beds: room.beds,
                facilities: JSON.parse(JSON.stringify(facilities)),
                _id: room._id
            });
        }

        const cities = await this.cityCtrl.getCities();
        this.cityToSearchFrom = this.apartment.adress.city.toLowerCase();
        this.apartment.adress.city = cities.find(
            city => city.name.toLowerCase() === this.cityToSearchFrom || city.name_fr.toLowerCase() === this.cityToSearchFrom || city.value.toLowerCase() === this.cityToSearchFrom
        ).value;
        this.setCity();
        this.cityChanged(this.apartment.adress.city);

        // Mark all steps as valid
        this.viewedSteps = 9;
        this.validSteps = [true, true, true, true, true, true, true, true];
    }

    setCity() {
        this.cityToSearchFrom = this.apartment.adress.city.toLowerCase();
        this.setCityBound(this.cityToSearchFrom)
    }

    loadStoredFormData() {
        const {step, edit} = this.activatedRoute.snapshot.queryParams;

        // Edit apartment mode
        if (edit) {
            this.editing = edit;
            if (!step) {
                this.loadFormDataFromApartmentId(edit);
            }
        }

        if (!step) {
            this.isWholeApartment = localStorage.getItem('isWholeApartment') === 'true';
            return;
        }

        let prevStep = localStorage.getItem('prevStep');
        let viewedSteps = localStorage.getItem('viewedSteps');
        let validSteps = localStorage.getItem('validSteps');
        let apartmentInfo = localStorage.getItem('apartmentInfo');
        let photos = localStorage.getItem('photos');
        this.cityToSearchFrom = localStorage.getItem('cityToSearchFrom');
        this.isWholeApartment = localStorage.getItem('prevIsWholeApartment') === 'true';
        this.hostLivesInApartment = localStorage.getItem('hostLivesInApartment') === 'true';
        this.preferenceData = JSON.parse(localStorage.getItem('preferenceData'));
        this.minAge = this.preferenceData.ageGroupMin;
        this.maxAge = this.preferenceData.ageGroupMax;
        this.propertyAccessMethod = localStorage.getItem('propertyAccessMethod');
        this.hostWaitGuestTime = localStorage.getItem('hostWaitGuestTime');
        this.checkoutTime = localStorage.getItem('checkoutTime');
        this.additionalNote = localStorage.getItem('additionalNote');

        if (prevStep && apartmentInfo && viewedSteps && validSteps) {
            this.viewedSteps = parseInt(viewedSteps);
            this.validSteps = JSON.parse(validSteps);
            this.apartmentInfo = JSON.parse(apartmentInfo);
            for (let room of this.apartmentInfo.roomsInfo) {
                if (room.moveInDate) {
                    room.moveInDate = new Date(room.moveInDate);
                } else {
                    room.moveInDate = undefined;
                }
                if (room.moveOutDate) {
                    room.moveOutDate = new Date(room.moveOutDate);
                } else {
                    room.moveOutDate = undefined;
                }
            }
            this.apartment.photos = JSON.parse(photos);
            this.setStep(parseInt(prevStep));
        }
    }

    storeFormData() {
        if (!this.apartment)
            return;
        localStorage.setItem('prevStep', this.step.toString());
        localStorage.setItem('viewedSteps', this.viewedSteps.toString());
        localStorage.setItem('validSteps', JSON.stringify(this.validSteps));
        localStorage.setItem('apartmentInfo', JSON.stringify(this.apartmentInfo));
        localStorage.setItem('photos', JSON.stringify(this.apartment.photos));
        localStorage.setItem('prevIsWholeApartment', this.isWholeApartment ? 'true' : 'false');
        localStorage.setItem('hostLivesInApartment', this.hostLivesInApartment ? 'true' : 'false');
        localStorage.setItem('preferenceData', JSON.stringify(this.preferenceData));
        localStorage.setItem('cityToSearchFrom', this.cityToSearchFrom);
        localStorage.setItem('propertyAccessMethod', this.propertyAccessMethod);
        localStorage.setItem('hostWaitGuestTime', this.hostWaitGuestTime);
        localStorage.setItem('checkoutTime', this.checkoutTime);
        localStorage.setItem('additionalNote', this.additionalNote);
    }

    updateUrl() {
        if (this.editing) {
            const url = this.router
                .createUrlTree([], {
                    relativeTo: this.activatedRoute,
                    queryParams: {step: this.step, edit: this.editing}
                })
                .toString();
            this.location.go(url);
        }
        else {
            const url = this.router
                .createUrlTree([], {
                    relativeTo: this.activatedRoute,
                    queryParams: {step: this.step}
                })
                .toString();
            this.location.go(url);
        }
    }
    
    getSecureDoc(fileName, folder){
        this.userCtrl.getSecureDocs(folder,fileName).then((secureDoc) => {
            window.open(secureDoc.url,'_blank');
        });
    }

    prepareFileName(fileName) {
        const extension = this.getExtension(fileName)
        const chunkedFile = fileName.split('')
        if (chunkedFile.length > 20) {
            const newName = []
            for (let i = 0; i < 4; i++) {
                newName.push(chunkedFile[i])
            }
            return newName.join('') + '...' + extension

        } else {
            return fileName
        }
    }

    getExtension(fileName) {
        const chunked = fileName.split('.')
        return chunked[1]
    }

    deleteListing() {
        this.genCtrl.showModal(this.renderer, this.el.nativeElement.querySelector('div.modal-delete-confirm'));
    }

    async confirmDelete(confirmed) {
        if (confirmed === true) {
            await this.aptCtrl.deleteApartment(this.editing, this.apartment);
            this.router.navigate([this.utils.getCurrentLang() + '/myprofile/sharings']);
        }
    }

    getRoomCollection() {
        return this.apartmentInfo.availableRooms > 0 ? Array.apply(0, new Array(Number(this.apartmentInfo.availableRooms))) : [];
    }

    getRoomTags() {
        let res: any = [];
        let added = new Set();

        for (let i = 0; i < this.roomTags.length; ++i)  {
            this.translate.get(this.roomTags[i]).subscribe(trans => {
                res.push(trans);
            })
            added.add(this.roomTags[i]);
            added.add(this.roomTagsFr[i]);
        }
        
        for (let i = 1; i <= this.apartmentInfo.roomsInfo.length; ++i) {
            this.translate.get("Bedroom").subscribe(trans => {
                res.push(trans + (this.apartmentInfo.roomsInfo.length > 1 ? (' ' + i.toString()) : ''));
            })

            const index = this.roomTagsFr.indexOf("Bedroom");
            added.add("Bedroom" + (this.apartmentInfo.roomsInfo.length > 1 ? (' ' + i.toString()) : ''));
            added.add(this.roomTagsFr[index] + (this.apartmentInfo.roomsInfo.length > 1 ? (' ' + i.toString()) : ''));
        }

        // Also add those tags that we don't have, except bedrooms which should be added above
        for (let photo of this.apartment.photos) {
            if (photo.tag &&
                !photo.tag.toLowerCase().includes('bedroom ') &&
                !photo.tag.toLowerCase().includes('chambre ') &&
                !res.includes(photo.tag) &&
                !added.has(photo.tag)
            ) {
                res.push(photo.tag);
            }
            else {
                // If the tag is in french, find the corresponding in english
                const index = this.roomTagsFr.indexOf(photo.tag);
                if (index >= 0) {
                    photo.tag = this.roomTags[index];
                }
            }
        }

        return res.sort();
    }

    validateTraitsSelections() {
        if (!this.lastEvent) return true;
        if (this.lastEvent.valid) return true;

        // Check for missing about me
        if (this.lastEvent.missingAboutMe) {
            this.genCtrl.triggerAlertError(this.utils.getTranslator().instant('missing.aboutme.error'));
            return false;
        }

        // Check for incomplete categories
        let problematicCategoryName: KeywordCategory | undefined;
        if (this.lastEvent.incompleteCategories.length > 0) {
            problematicCategoryName = this.lastEvent.incompleteCategories[0]
        }
        else if (this.lastEvent.overloadedCategories.length > 0) {
            problematicCategoryName = this.lastEvent.overloadedCategories[0]
        }
        if (problematicCategoryName) {
            const errorMessage = this.utils.getTranslator().instant('Please select') +
                ' ' + problematicCategoryName.min + ' ' + this.utils.getTranslator().instant('to') + ' ' + problematicCategoryName.max + ' ' +
                this.utils.getTranslator().instant('tokens') + ' ' +
                this.utils.getTranslator().instant('in the category') + ' "' + problematicCategoryName.name[this.lang] + '"';
            this.genCtrl.triggerAlertError(errorMessage);
            return false;
        }
        return false;
    }

    updateUserProfile() {
        if (!this.lastEvent) return;

        // Valid changes, just update user profile
        if (this.lastEvent.valid) {
        this.loggedUser.aboutme = this.lastEvent.aboutme;
        this.loggedUser.keyword = this.lastEvent.selectedKeywords;
        this.userCtrl.updateUser(this.loggedUser._id, JSON.parse(JSON.stringify(this.loggedUser)))
            .then(() => {
                const message = this.utils.getTranslator().instant("myprofile.save");
                this.genCtrl.triggerAlertSuccess(message);
            })
            .catch((err) => {
                this.genCtrl.triggerAlertError(err);
            });
        }
    }

    async readTraitsFromProfile() {
        // Read current user profile
        this.loggedUser = await this.userCtrl.getUser(this.utils.getLoggedUser().id);
        if (!this.loggedUser) {
            this.router.navigate(['/' + this.lang + '/login'], { queryParams: { redirect: this.router.url } });
            return;
        }
        if (!this.loggedUser.matching_info) {
            this.loggedUser.matching_info = {};
        }
        if (!this.loggedUser.aboutme) {
            this.loggedUser.aboutme = '';
        }
    }

    setMapURL(city: string) {
        // Update map URL
        let location = this.apartmentInfo.address ?
          this.apartmentInfo.address + ', ' + city + ', Canada' :
          city + ', Canada';
        const mapElem = document.getElementById('google-apt-map');
        if (!mapElem)
            return;
        mapElem.setAttribute('src',
          `https://www.google.com/maps/embed/v1/place?key=AIzaSyCZStE1RjCN1UqHvxMo47WYQ7ftwyqtevE&q=${location}`
        )
    }

    updateDropdown() {
        if (!this.cityDropdown)
            return;
        let allOptionsArr: Array<MyOptionComponent> = [];
        for (const city of this.cities) {
            const newElem = document.createElement('div');
            newElem.innerHTML = this.genUtils.titlecase(city.name);
            let option = new MyOptionComponent(new ElementRef(newElem));
            option.value = city.name;
            if (this.cityToSearchFrom && (
                this.cityToSearchFrom.toLowerCase() === city.name.toLowerCase() || this.cityToSearchFrom.toLowerCase() === city.name_fr.toLowerCase() || this.cityToSearchFrom.toLowerCase() === city.value.toLowerCase()
            )) {
                option.selected = true;
            }
            allOptionsArr.push(option);
        }
        this.cityDropdown.updateItems(allOptionsArr);
    }
    
    matchYoutubeUrl(url): boolean {
        return this.utils.matchYoutubeUrl(url);
    }

    getYoutubeVideoId(url: string): string {
        return this.utils.getYoutubeVideoId(url);
    }

    transformYoutubeURL2EmbedURL(url: string): string {
        return this.utils.transformYoutubeURL2EmbedURL(url);
    }

    changeVideoTour() {
        this.videoTourInvalidUrl = !this.matchYoutubeUrl(this.videoTour); 
    }

    formatDate(date) {
        const locale = this.utils.getCurrentLang();
        return moment(date).locale(locale).format('dddd, MMMM DD, YYYY');
    }

    getAccessMethods() {
        const newList = this.cityToSearchFrom.toLowerCase() === 'montreal' ?
            this.propertyAccessList:
            this.propertyAccessList.filter(item => item.value !== 'agent');
        return newList;
    }

    getAdditionalNotePlaceholder() {
        switch (this.propertyAccessMethod) {
            case 'host-wait-guest':
            case 'agent':
                return "Add any additional information that will help your guest accessing the property easily.";
            case 'host-give-keys':
                return "Indicate an address and a time during the check-in date.";
            case 'someone-give-keys':
                return "Indicate the contact name of the person and their phone number.";
            case 'self-checkin':
                return "Indicate a passcode to access the property or indicate where  the keys are and how to retrieve them.";
            default:
                return "Add any additional information that will help your guest accessing the property easily.";
        }
    }

    selectRoomSize(value: string): void {
        if (value === 'Studio') {
            this.isStudio = true;
            this.apartmentInfo.availableRooms = 1;
            this.apartmentInfo.isStudio = true;
        } else {
            this.apartmentInfo.availableRooms = Number(value);
            this.isStudio = false;
            this.apartmentInfo.isStudio = false;
        }
    }

    onPayoutMethodChange(payout: string): void {
        if (this.isPayoutEmailValid(payout)) {
            this.payoutMethodInvalid = false;
        } else {
            this.conditions.hasError = true;
            this.payoutMethodInvalid = true;
        }
    }

    createNewBed(roomIndex: number): void {
        if (typeof this.apartmentInfo.roomsInfo[roomIndex].beds === 'undefined') {
            this.apartmentInfo.roomsInfo[roomIndex].beds = [{}]
        } else {
            this.apartmentInfo.roomsInfo[roomIndex].beds.push({})
        }
    }

    initBed(): void {
        for (let i = 0; i < this.apartmentInfo.roomsInfo.length; ++i) {
            if (typeof this.apartmentInfo.roomsInfo[i].beds === 'undefined')
                this.apartmentInfo.roomsInfo[i].beds = [{}];
        }
    }

    removeBed(roomIndex: number, bedIndex: number): void {
       const res = []
       for (let i = 0; i < this.apartmentInfo.roomsInfo[roomIndex].beds.length; ++i) {
            if (i === bedIndex) {
                continue;
            }
            res.push(this.apartmentInfo.roomsInfo[roomIndex].beds[i])
       }
       this.apartmentInfo.roomsInfo[roomIndex].beds = res;
    }

}
