import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { Utils } from './utils';
import { SeoUtils } from './seo';
import { UserUtils } from './user';
import { GeneralUtils } from './general';

@NgModule({
  imports: [
  	CommonModule
  ],
  providers: [
    Utils,
    SeoUtils,
    UserUtils,
    GeneralUtils
  ],
})
export class UtilsModule {}