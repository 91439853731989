import {
  Component,
  ElementRef,
  Renderer2,
  Input,
  Output,
  EventEmitter
} from "@angular/core";
import { Utils } from "../../../utils/utils";

import { ConditionsObject } from "../../../models/conditionsobject";

import {
  GeneralController, UserController
} from "../../../controllers/controllers.module";

@Component({
  selector: "phoneVerifyCode",
  templateUrl: "./phoneVerifyCode.component.html",
  providers: [Utils]
})
export class PhoneVerifyCodeComponent {
  @Input() public timeout: number;
  @Output() verifiedEvent = new EventEmitter<Boolean>();

  conditions: ConditionsObject = this.genCtrl.getNewConditionsObject();
  digits: number = 6;
  codeValid: boolean = false;
  verifyFailed: boolean = false;
  showSMSerror: boolean = false;
  SMSerrorReason: string = "";

  modalEl = null;

  constructor(
    private utils: Utils,
    private el: ElementRef,
    private renderer: Renderer2,
    private genCtrl: GeneralController,
    private UserCtrl: UserController
  ) {}

  onCloseModal() {
    this.renderer.setStyle(this.modalEl, "display", "none");
    this.renderer.removeClass(this.modalEl, "opened");
  }

  ngOnInit() {
    this.utils.executeBrowserOnly(() => {
      this.conditions.isBrowser = true;
    });
    setInterval(() => {
      if (this.timeout > 0)
        --this.timeout;
    }, 1000);
  }

  processDigit(ev, index) {
    const keyCode = ev.keyCode || ev.charCode;

    // Special case for Android devices
    if (keyCode == 229) {
      return;
    }

    ev.preventDefault();
    if (keyCode == 8 || keyCode == 46) {
      // User pressed backspace or delete
      // Focus the previous digit
      this.el.nativeElement.querySelector(
        "#verify-digit-" + index
      ).value = "";
      if (index > 0) {
        const nextElem = this.el.nativeElement.querySelector(
          "#verify-digit-" + (index - 1)
        );
        nextElem.focus();
        nextElem.select();
      }
    } else if (keyCode == 13) {
      // User pressed enter
      // Confirm if the digits are valid
      if (this.validateCode())
        this.onConfirm();
    } else if (keyCode >= 48 && keyCode <= 57) {
      // User pressed a digit key
      this.el.nativeElement.querySelector(
        "#verify-digit-" + index
      ).value = keyCode - 48;
      if (index + 1 < this.digits) {
        // User inputted a digit, focus the next digit
        const nextElem = this.el.nativeElement.querySelector(
          "#verify-digit-" + (index + 1)
        );
        nextElem.focus();
        nextElem.select();
      }
    }
    this.codeValid = this.validateCode();
  }

  handleAndroidKeyup(ev, index) {
    const keyCode = ev.keyCode || ev.charCode;

    // Special case for Android devices
    if (keyCode == 229) {
      this.codeValid = this.validateCode();
      if (index + 1 < this.digits) {
        // User inputted a digit, focus the next digit
        const nextElem = this.el.nativeElement.querySelector(
          "#verify-digit-" + (index + 1)
        );
        nextElem.focus();
        nextElem.select();
      }
      return;
    }
  }

  resendCode() {
    const phoneNumber = this.el.nativeElement.querySelector(
      "#verify-phone-no"
    ).innerHTML;
    this.UserCtrl.sendVerifySMS(phoneNumber)
      .then(() => {
        this.timeout = 30;
        this.verifyFailed = false;
        this.showSMSerror = false;
      })
      .catch((err) => {
        this.showSMSerror = true;
        if (err._body) {
          const errMsg = JSON.parse(err._body).error;
          if (errMsg)
            this.SMSerrorReason = errMsg;
          else
            this.SMSerrorReason = err._body;
        }
        else
          this.SMSerrorReason = err.statusText;
      });
    for (let i = 0; i < this.digits; ++i) {
      this.el.nativeElement.querySelector(
        "#verify-digit-" + i
      ).value = "";
    }
    this.codeValid = false;
    this.el.nativeElement.querySelector(
      "#verify-digit-0"
    ).focus();
  }

  validateCode() {
    for (let i = 0; i < this.digits; ++i) {
      const elem = this.el.nativeElement.querySelector(
        "#verify-digit-" + i
      );
      if (!(elem.value != "" && elem.value >= 0 && elem.value < 10)) {
        return false;
      }
    }
    return true;
  }

  onConfirm() {
    let code = "";
    for (let i = 0; i < this.digits; ++i) {
      const elem = this.el.nativeElement.querySelector(
        "#verify-digit-" + i
      );
      if (elem.value != "" && elem.value >= 0 && elem.value < 10) {
        code += elem.value;
      }
    }
    if (code.length == this.digits) {
      this.UserCtrl.verifyUserPhone(code)
        .then(() => {
          this.verifiedEvent.emit(true);
          this.onCloseModal();
        })
        .catch((err) => {
          this.verifiedEvent.emit(false);
          this.verifyFailed = true;
          const elem = this.el.nativeElement.querySelector(
            "#verify-digit-0"
          );
          elem.focus();
          elem.select();
        });
    }
  }

  ngAfterViewInit() {
    if (this.el.nativeElement.querySelector) {
      this.modalEl = this.el.nativeElement.querySelector("div.modal");
    }
  }
}
