import { Injectable } from "@angular/core";
import { AlertService } from "../modules/services/alertService";
import { SetLangService } from "../modules/services/setLangService";
import { OverlayService } from "../modules/services/overlayService";
import { ConditionsObject } from "app/models/conditionsobject";

@Injectable()
export class GeneralController {
  constructor(
    private alertService: AlertService,
    private setLangService: SetLangService,
    private overlayService: OverlayService
  ) {}

  initExtra(completeList, extras) {
    let objs = [];
    for (let obj of extras) {
      let res = completeList.find(function(oObj) {
        return oObj.id == obj.id;
      });
      if (res) {
        objs.push(res.name);
      }
    }

    return objs;
  }

  getAlertSuccessObservable() {
    return this.alertService.alertSuccessNotifyObservable$;
  }

  triggerAlertSuccess(message) {
    this.alertService.alertSuccessNotifyOther(message);
  }

  getAlertErrorObservable() {
    return this.alertService.alertErrorNotifyObservable$;
  }

  triggerAlertError(message) {
    this.alertService.alertErrorNotifyOther(message);
  }

  getSubscribeLang() {
    return this.setLangService.setLangCompletedNotifyObservable$;
  }

  getSubscribeShowOverlay() {
    return this.overlayService.showOverlayNotifyObservable$;
  }

  getSubscribeHideOverlay() {
    return this.overlayService.hideOverlayNotifyObservable$;
  }

  langNotifyOther() {
    this.setLangService.setLangCompletedNotifyOther();
  }

  getNewConditionsObject() {
    return new ConditionsObject();
  }

  getModal(el, renderer, selector, showModal) {
    let modal = el.nativeElement.querySelector(selector);
    if (showModal) {
      this.showModal(renderer, modal);
    }

    return modal;
  }

  showModal(renderer, modal) {
    renderer.setStyle(modal, "display", "block");
    renderer.addClass(modal, "opened");
  }

  hideModal(renderer, modal) {
    renderer.setStyle(modal, "display", "none");
    renderer.removeClass(modal, "opened");
  }
}
