import { Injectable, Inject } from "@angular/core";
import { Subject } from "rxjs/Subject";
@Injectable()
export class ApartmentService {
  constructor() {}

  private moveInDateNotify = new Subject<Date>();
  moveInDateNotifyObservable$ = this.moveInDateNotify.asObservable();

  public moveInDateNotifyOther(moveInDate: Date) {
    this.moveInDateNotify.next(moveInDate);
  }

  private apartmentHoverSubject = new Subject<{id: string, isHovered: boolean}>();
  apartmentHoverState$ = this.apartmentHoverSubject.asObservable();

  notifyHoverState(apartmentId: string, isHovered: boolean) {
    this.apartmentHoverSubject.next({ id: apartmentId, isHovered });
  }

  private searchComponent: any = null;
  setSearchComponent(component: any) {
    this.searchComponent = component;
  }

  getSearchComponent() {
    return this.searchComponent;
  }
}
