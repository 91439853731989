import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Router }   from '@angular/router';
import { Utils } from "app/utils/utils";

import { Cookie } from 'ng2-cookies';

@Injectable()
export class IsLoggedRouteGuard implements CanActivate {

  constructor(private _router: Router, 
				private utils:Utils) {}

  canActivate() {
  	if(this.utils.getLoggedUser()) {
	  	let lang = Cookie.check('lang') ? Cookie.get('lang') : 'fr';
			this._router.navigate(["/" + lang + "/apartments" ]);
			return false;
	}
	return true;
  }
}