import { Injectable, Inject } from "@angular/core";
import { DOCUMENT, LocationStrategy } from "@angular/common";
import { Meta, Title } from "@angular/platform-browser";

@Injectable()
export class SeoUtils {
  constructor(
    private meta: Meta,
    private titleService: Title,
    private _url: LocationStrategy,
    @Inject(DOCUMENT) private dom
  ) {}

  createCanonicalURL() {
    let link: HTMLLinkElement = this.dom.createElement("link");
    link.setAttribute("rel", "canonical");
    this.dom.head.appendChild(link);
    link.setAttribute(
      "href",
      `https://hivenue.ca${this.dom.URL.split("?")[0]}`
    );
  }

  setSeoMeta() {
    let currentPath = this._url.path();
    let meta = this.getMeta(currentPath);

    this.setOGUrl();
    this.setOGImage("https://hivenue.ca/assets/logoshare.jpg");

    this.meta.updateTag({ name: "description", content: meta.desc });
    this.meta.updateTag({ property: "og:description", content: meta.desc });
    if (meta.ogtitle) {
      this.meta.updateTag({ property: "og:title", content: meta.ogtitle });
    } else {
      this.meta.updateTag({ property: "og:title", content: meta.title });
    }
    this.titleService.setTitle(meta.title);
  }

  setSeoMetaParams(title, description) {
    this.setOGUrl();

    if (description) {
      this.meta.updateTag({ name: "description", content: description });
      this.meta.updateTag({ property: "og:description", content: description });
    }

    this.meta.updateTag({ property: "og:title", content: title });
    this.titleService.setTitle(`${title} | Hivenue`);
  }

  setOGImage(image) {
    this.meta.updateTag({ property: "og:image", content: image });
  }

  setOGUrl() {
    let currentPath = this._url.path();
    this.meta.updateTag({
      property: "og:url",
      content: "https://hivenue.ca" + currentPath
    });
  }

  getMeta(url) {
    let meta: any = {
      title: "Rooms and Apartments for Rent | Hivenue",
      desc:
        "A community marketplace for people to list and book spaces with like-minded roommates.",
      ogtitle: ""
    };
    switch (url) {
      case "/fr/apartments":
      case "/fr/apartments/":
        meta = {
          title:
            "Appartements à louer à Montréal | Trouvez votre prochain logement | Hivenue",
          desc:
            "Trouver les meilleures chambres à louer peut être un défi et Hivenue vous facilite la tâche. Que vous cherchiez à poster votre appartement ou à trouver un colocataire, Hivenue est là pour vous. Hivenue s'efforce de vous aider à trouver les meilleurs appartements à Montréal pour votre budget.",
          ogtitle: "Appartements à louer à Montréal"
        };
        break;
      case "/en/apartments":
      case "/en/apartments/":
        meta = {
          title:
            "Apartments for Rent in Montreal | Hivenue",
          desc:
            "Finding the best rooms for rent can be a challenge and Hivenue makes it easy. Whether you are looking to post your apartment or find a roommate, Hivenue has you covered. Hivenue strives to help you find the best Montreal apartments for your budget.",
          ogtitle: "Apartments for Rent in Montreal"
        };
        break;
      case "/fr/roommates":
      case "/fr/roommates/":
        meta = {
          title:
            "Chambre à louer à Montréal | Trouvez le colocataire parfait | Hivenue",
          desc:
            "Trouver les meilleures chambres à louer peut être un défi et Hivenue vous facilite la tâche. Que vous cherchiez à poster votre appartement ou à trouver un colocataire, Hivenue est là pour vous. Hivenue s'efforce de vous aider à trouver les meilleurs appartements à Montréal pour votre budget.",
          ogtitle: "Chambre à louer à Montréal"
        };
        break;
      case "/en/roommates":
      case "/en/roommates/":
        meta = {
          title:
            "Rooms for Rent in Montreal | Find the Perfect Roommate | Hivenue",
          desc:
            "Finding the best rooms for rent can be a challenge and Hivenue makes it easy. Whether you are looking to post your apartment or find a roommate, Hivenue has you covered. Hivenue strives to help you find the best Montreal apartments for your budget.",
          ogtitle: "Rooms for Rent in Montreal"
        };
        break;
      case "/fr/share/apartment":
      case "/fr/share/apartment/":
        meta = {
          title: "Partagez votre logement | Hivenue",
          desc:
            "Partagez votre logement et trouvez le colocataire parfait. Afficher une annonce dès maintenant | Hivenue"
        };
        break;
      case "/en/share/apartment":
      case "/en/share/apartment/":
        meta = {
          title: "List your apartment | Hivenue",
          desc:
            "List your apartment and find the perfect roommate. Post an ad now | Hivenue"
        };
        break;
      case "/fr/share/roommate":
      case "/fr/share/roommate/":
        meta = {
          title: "Partagez votre profil | Hivenue",
          desc:
            "Définissez vos besoin pour trouver le logement parfait. Afficher une annonce dès maintenant | Hivenue"
        };
        break;
      case "/en/share/roommate":
      case "/en/share/roommate/":
        meta = {
          title: "List your profile | Hivenue",
          desc:
            "Define your need to find the perfect apartment. View an ad now | Hivenue"
        };
        break;
      case "/fr/terms-condition":
      case "/fr/privacy-policy":
        meta = {
          title: "Chambres et appartements à louer | Hivenue",
          desc:
            "Une marketplace communautaire permettant aux personnes de partager et de réserver des logements avec des colocataires."
        };
        break;
      case "/en/terms-condition":
      case "/en/privacy-policy":
        meta = {
          title: "Rooms and Apartments for Rent | Hivenue",
          desc:
            "A community marketplace for people to list and book spaces with like-minded roommates."
        };
        break;
      case "/fr":
        meta = {
          title: "Chambres et appartements à louer | Hivenue",
          desc:
            "Une marketplace communautaire permettant aux personnes de partager et de réserver des logements avec des colocataires."
        };
        break;
      case "/en":
        meta = {
          title: "Rooms and Apartments for Rent | Hivenue",
          desc:
            "A community marketplace for people to list and book spaces with like-minded roommates."
        };
        break;
      default:
        meta = {
          title: "Chambres et appartements à louer | Hivenue",
          desc:
            "Une marketplace communautaire permettant aux personnes de partager et de réserver des logements avec des colocataires."
        };
    }

    return meta;
  }
}
