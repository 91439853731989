export class User {
  constructor() {
    this._id = "";
    this.first_name = "";
    this.last_name = "";
    this.userCode = "";
    this.email = "";
    this.status = "";
    this.phone = "";
    this.phoneVerified = false
    this.unverified_phone = "";
    this.onboarding_completed = false;
    this.onboarding_skipped = false;
    this.matching_info = {};
    this.aboutme = "";
    this.gender = "";
    this.image = "";
    this.customImage = "";
    this.language = "fr";
    this.notifications = new Array<Notification>();
    this.bookmarks = new Array<Bookmark>();
    this.socialMedia = new SocialMedia();
    this.payoutMethodValue = "";
  }

  addBookmark(id, isApartment) {
    let bookmark = new Bookmark(isApartment);
    bookmark.id = id;
    this.bookmarks.push(bookmark);
  }

  addNotification(userId, name, message) {
    let notification = new Notification();
    notification.userId = userId;
    notification.name = name;
    notification.message = message;
    this.notifications.push(notification);
  }

  _id: string;
  first_name: string;
  last_name: string;
  userCode: string;
  email: string;
  status: string;
  phone: string;
  phoneVerified: boolean;
  unverified_phone: string;
  onboarding_completed: boolean;
  onboarding_skipped: boolean;
  matching_info: any;
  aboutme: string;
  gender: string;
  image: string;
  language: string;
  customImage: "";
  notifications: Array<Notification>;
  bookmarks: Array<Bookmark>;
  socialMedia: SocialMedia;
  payoutMethodValue: string;
  languageSpoken: any = [];
  birthDate: string;
}

export class Notification {
  constructor() {
    this.userId = null;
    this.name = "";
    this.message = "";
    this.creationDate = new Date();
    this.isConfirmed = false;
  }
  userId: string;
  name: string;
  message: string;
  creationDate: Date;
  isConfirmed: boolean;
}

class Bookmark {
  constructor(isApartment: boolean) {
    this.id = "";
    this.isApartment = isApartment;
  }
  id: string;
  isApartment: boolean;
}

class SocialMedia {
  constructor() {
    this.facebook = "";
    this.twitter = "";
    this.instagram = "";
    this.linkedin = "";
  }
  facebook: string;
  linkedin: string;
  twitter: string;
  instagram: string;
}
