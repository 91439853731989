import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import { Utils } from "../../../utils/utils";

import { ConditionsObject } from "../../../models/conditionsobject";

import {
  ApartmentController,
  GeneralController,
  UserController
} from "../../../controllers/controllers.module";

@Component({
  selector: "shareApartmentConfirm",
  templateUrl: "./shareApartmentConfirm.component.html",
  providers: [Utils]
})
export class ShareApartmentConfirmComponent {
  @Input() public apartment: any;
  @Input() public misc: any;        // Fields: payoutMethod, payoutMethodValue
  errors: any = {};
  conditions: ConditionsObject = this.genCtrl.getNewConditionsObject();
  secretCode: string;
  mask = [
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];

  @ViewChild("apartmentResumeForm") public apartmentResumeForm: NgForm;

  constructor(
    private utils: Utils,
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer2,
    private genCtrl: GeneralController,
    private aptCtrl: ApartmentController,
    private userCtrl: UserController
  ) {}

  modalEl = null;

  onCloseModal() {
    this.renderer.setStyle(this.modalEl, "display", "none");
    this.renderer.removeClass(this.modalEl, "opened");
  }

  ngOnInit() {
    this.utils.executeBrowserOnly(() => {
      this.conditions.isBrowser = true;
    });
  }

  onConfirm() {
    this.validateForm(this.apartmentResumeForm).then((response) => {
      this.onSave()
        .then((response: any) => {
          if (response) {
            if (response && response.slug && response._id) {
              this.router.navigate([
                `${this.utils.getCurrentLang()}/apartments/${response._id}/${
                  response.slug
                }`
              ]);
            } else {
              this.router.navigate([
                `${this.utils.getCurrentLang()}/apartments/${response._id}/${
                  response.slug
                }`
              ]);
            }
            window['gtag']('event', 'Creating_After_confirm_share', {
                'event_category': 'Creating_After_confirm_share',
                'event_label': 'Creating_After_confirm_share'
            });
          }
        })
        .catch(function(err) {
          let error = JSON.parse(err.text());
          console.log(error.error);
          window.scrollTo(0, 0);
        });
    });
  }

  validateForm(form) {
    return new Promise(function(resolve, reject) {
      if (form.valid) {
        resolve(true);
      } else {
        for (var i in form.controls) {
          form.controls[i].markAsTouched();
        }
        reject();
      }
    });
  }

  ngAfterViewInit() {
    if (this.el.nativeElement.querySelector) {
      this.modalEl = this.el.nativeElement.querySelector("div.modal");
    }
  }

  async onSave() {
    return new Promise(async (resolve, reject) => {
      // Copy home accomodations
      this.apartment.accomodations = new Array();
      for (let acc of this.misc.accomodations) {
        this.apartment.accomodations.push({ id: acc.id });
      }

      let user = this.utils.getLoggedUser();
      let postData = JSON.parse(JSON.stringify(this.apartment));

      // Update payout method
      await this.userCtrl.updateUser(user.id, { payoutMethodValue: this.misc.payoutMethodValue });

      // Check edit mode
      let response;
      if (this.misc.editing) {
        response = await this.aptCtrl.updateApartment(this.misc.editing, postData);
      }
      else {
        response = await this.aptCtrl.postApartment(postData, user.id);
      }
      resolve(response);
    });
  }
}
