export interface FeedbackForm {
  questions: Question[]
  _id: string
}

export interface Question {
  title: { fr: string; en: string }
  _id: string
  inputType: InputType
  required: boolean
  options: {
    range?: { min: number; max: number }
    select?: { values: string[] }
    checkbox?: { values: string[] }
    text?: { placeholder: string }
  }
}

export interface Answer {
  questionId: string
  answer: string | number | [string] | null
  questionTitle: string
}

export interface PendingFeedbackApiResponse {
  userId: string
  apartmentId: string
  rentRequestId: string
  userStatus: string
}

export interface Answers extends PendingFeedbackApiResponse {
  formId: string
  answers: Answer[]
}

export const FORM_INITIAL_VALUES = {
  select: null,
  range: 0,
  text: '',
  checkbox: null,
}

export enum InputType {
  SELECT = 'select',
  RANGE = 'range',
  TEXT = 'text',
  CHECKBOX = 'checkbox',
}
