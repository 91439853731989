import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

interface Apartment {
  _id: string;
  name: string;
  adress: {
    lat: number;
    lng: number;
    neighborhood: string;
  };
  photos: Array<{source: string}>;
  price?: number;
  wholeApartment: boolean;
  rooms: Array<{price: number}>;
  minprice: number;
  isHovered: boolean;
}

@Component({
  selector: 'app-bottom-sheet',
  template: `
    <div class="modal-overlay" [ngClass]="{'hidden': !isOpen}" (click)="onClose()">
      <div class="floating-card" *ngIf="apartment" (click)="$event.stopPropagation()">
        <!-- Image -->
        <div class="carousel-container" (click)="navigateToApartment()">
          <img 
            [src]="getPhotoUrl()"
            [alt]="apartment.name"
            class="carousel-image cursor-pointer"
            (error)="onImageError($event)">
          
          <!-- Image controls -->
          <div class="image-controls">
            <button class="mapboxgl-popup-close-button" (click)="onClose(); $event.stopPropagation()">×</button>
          </div>
          
          <!-- Price overlay -->
          <div class="price-overlay">
            {{apartment.minprice}}+ CAD / Month
          </div>
          
          <!-- Carousel indicators -->
          <div class="carousel-indicators">
            <span class="indicator active"></span>
            <span class="indicator"></span>
            <span class="indicator"></span>
            <span class="indicator"></span>
          </div>
        </div>

        <!-- Details -->
        <div class="property-details">
          <h2 class="property-title cursor-pointer" (click)="navigateToApartment()">
            {{apartment.name}}
          </h2>
          
          <p class="location">
            <i class="fa fa-map-marker"></i> 
            {{apartment.adress?.neighborhood || ''}}
          </p>
          
          <div class="property-info">
            <span class="info-item">
              <i class="fa fa-bed"></i>
              {{apartment.rooms.length}} {{apartment.rooms.length === 1 ? 'Room' : 'Rooms'}}
            </span>
            <span class="info-item">
              <i class="fa fa-home"></i>
              {{apartment.wholeApartment ? 'Entire home' : 'Private room'}}
            </span>
          </div>
        </div>
      </div>
    </div>
  `,
  styles: [`
    .modal-overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      padding: 20px;
      pointer-events: none;
    }

    .modal-overlay.hidden {
      display: none;
    }

    .floating-card {
      background: white;
      width: 100%;
      max-width: 400px;
      border-radius: 12px;
      overflow: hidden;
      box-shadow: 0 4px 16px rgba(0,0,0,0.12);
      pointer-events: auto;
      margin-bottom: 60px;
    }

    .carousel-container {
      position: relative;
      width: 100%;
      height: 200px;
    }

    .carousel-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .cursor-pointer {
      cursor: pointer;
    }

    .image-controls {
      position: absolute;
      top: 16px;
      right: 16px;
      display: flex;
      gap: 12px;
    }

    .price-overlay {
      position: absolute;
      bottom: 16px;
      left: 16px;
      background: rgba(0, 0, 0, 0.6);
      color: white;
      padding: 8px 12px;
      border-radius: 4px;
      font-weight: 500;
      font-size: 14px;
    }

    :host ::ng-deep .mapboxgl-popup-close-button {
      padding: 5px !important;
      right: 8px !important;
      top: 5px !important;
      color: #666 !important;
      font-size: 18px !important;
      z-index: 2 !important;
      background: white !important;
      border-radius: 50% !important;
      width: 30px !important;
      height: 30px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      line-height: 1 !important;
      border: none !important;
      cursor: pointer !important;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1) !important;
      outline: none !important;
      position: absolute;
      font-weight: 300;
      font-family: "Arial", sans-serif;
      font-size: 25px;
    }

    :host ::ng-deep .mapboxgl-popup-close-button:hover {
      background: #f5f5f5 !important;
      color: #333 !important;
    }

    .carousel-indicators {
      position: absolute;
      bottom: 16px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      gap: 4px;
    }

    .indicator {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.5);
    }

    .indicator.active {
      background: white;
    }

    .property-details {
      padding: 16px;
    }

    .property-title {
      font-size: 20px;
      font-weight: 600;
      margin: -16px 0 -12px;
      color: #333;
    }

    .location {
      font-size: 14px;
      color: #666;
      margin: 0 0 12px;
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .property-info {
      display: flex;
      gap: 16px;
      font-size: 14px;
      color: #666;
    }

    .info-item {
      display: flex;
      align-items: center;
      gap: 4px;
    }

    @media (min-width: 768px) {
      .modal-overlay {
        display: none;
      }
    }
  `]
})
export class BottomSheetComponent {
  @Input() apartment: Apartment | null;
  @Input() imageHost: string;
  @Input() isOpen: boolean = false;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  constructor(private router: Router) {}

  getPhotoUrl(): string {
    if (this.apartment && this.apartment.photos && this.apartment.photos.length > 0) {
      return this.imageHost + 'apartment/' + this.apartment.photos[0].source;
    }
    return 'assets/images/small-no-picture.png';
  }

  onClose(): void {
    this.close.emit();
  }

  onImageError(event: any): void {
    event.target.src = 'assets/images/small-no-picture.png';
  }

  navigateToApartment(): void {
    if (this.apartment) {
      const currentPath = window.location.pathname;
      const prefix = currentPath.startsWith('/fr') ? '/fr' : '/en';
      this.router.navigate([`${prefix}/apartments/${this.apartment._id}`]);
    }
  }
}