import { Injectable, Inject } from '@angular/core';
import { Subject }    from 'rxjs/Subject';
@Injectable()
export class UpdateUserService {
  	private updateNotify = new Subject<any>();
  	/**
   	* Observable string streams
   	*/
  	updateNotifyObservable$ = this.updateNotify.asObservable();

  	constructor(){}

  	public updateNotifyOther() {
		  this.updateNotify.next();
  	}

  	private updateCompletedNotify = new Subject<any>();
  	/**
   	* Observable string streams
   	*/
  	updateCompletedNotifyObservable$ = this.updateCompletedNotify.asObservable();

  	public updateCompletedNotifyOther() {
		  this.updateCompletedNotify.next();
  	}
}
