import { Component, ElementRef, Input, Renderer2, OnInit, OnChanges, SimpleChanges } from "@angular/core";
import { Utils } from "../../../utils/utils";
import { GeneralController, MatchingController } from "../../../controllers/controllers.module";
import { KeywordCategory } from "app/controllers/matching.types";

@Component({
  selector: "matchDetailsModal",
  templateUrl: "./matchDetailsModal.component.html",
  styleUrls: ['./matchDetailsModal.component.css']
})
export class MatchDetailsModalComponent implements OnInit, OnChanges {
  @Input() public matchLevel: any;
  @Input() public matchReason: any;
  @Input() public neighborhoodMatchLevel: any;
  @Input() public neighborhoodMatchReason: any;


  constructor(
    private el: ElementRef,
    private utils: Utils,
    private renderer: Renderer2,
    private genCtrl: GeneralController,
    private matchCtrl: MatchingController,
  ) {}

  lang: string;
  categories: KeywordCategory[];
  keywords: {[category: string]: string[]} = {};
  neighborhoodKeywords: string[] = []

  onCloseModal = () => {
    this.genCtrl.hideModal(
      this.renderer,
      this.genCtrl.getModal(
        this.el,
        this.renderer,
        "div.matchDetailsModal",
        false
      )
    );
  };

  ngOnChanges(changes: SimpleChanges) {
    this.fetchCategories();
  }

  ngOnInit() {
    this.utils.executeBrowserOnly(
      async function() {
        this.lang = this.utils.getCurrentLang();
        await this.fetchCategories();
      }.bind(this)
    );
  }

  async fetchCategories() {
    if ((!this.matchLevel             || this.matchLevel == 'neutral'            ) && 
        (!this.neighborhoodMatchLevel || this.neighborhoodMatchLevel == 'neutral')) return;
    this.categories = await this.matchCtrl.getKeywords();
    for (const category of this.categories) {
      category.selected = false;
      for (const keyword of category.keywords) {
        if (this.matchReason.indexOf(keyword._id) !== -1) {
          keyword.selected = true;
          category.selected = true;
        } else {
          keyword.selected = false;
        }
      }
    }
    this.getNeighborhoodKeywords();
  }

  getNeighborhoodKeywords() { 
    this.neighborhoodKeywords = [];
    if (!this.neighborhoodMatchReason) return;

    const userNeighborhoodPreferences = this.categories.find(category => category.name['en'] === 'Neighborhood Preferences').keywords.filter(keyword => keyword.selected);

    for (const keywordId of this.neighborhoodMatchReason) {
      for (const category of this.categories) {
        for (const keyword of category.keywords) {
          if (keyword._id === keywordId && (userNeighborhoodPreferences.find(k => k.name['en'] === keyword.name['en']) || this.matchReason.length === 0)) {
            this.neighborhoodKeywords.push(keyword.name[this.lang]);
          }
        }
      }
    }
  }

  get genMatchLevel() {
    if (this.matchLevel === 'great' || this.neighborhoodMatchLevel === 'great') {
      return 'great';
    }
    else if (this.matchLevel === 'good' || this.neighborhoodMatchLevel === 'good') {
      return 'good';
    }
    return 'neutral';
  } 
}
