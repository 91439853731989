import { Component } from "@angular/core";
import { Utils } from '../../../utils/utils';
import { Headers, Http, RequestOptions } from "@angular/http";
import * as moment from "moment";
import { UserController } from "../../../controllers/controllers.module";
import { Cookie } from "ng2-cookies";
import { Router } from "@angular/router";

@Component({
  selector: "ChatBot",
  templateUrl: "./Chatbot.component.html",
  styleUrls: ["./Chatbot.component.css"]
})
export class ChatbotComponent {
  subscriptions: any = {};
  showChatBox: boolean = false;
  chatHistory: { message: string; isUser: boolean, time: Date }[] = [];   // time is only relevant if isUser is true
  message: string = '';
  allowSend: boolean = true;
  forceHideButton: boolean = false;
  processing: boolean = false;

  constructor(
    private utils: Utils,
    private http: Http,
    private userCtrl: UserController,
    private router: Router,
  ) {
    this.utils.executeBrowserOnly(async () => {
      this.subscriptions.loginCompleted = this.userCtrl
        .getSubscribeLogin(true)
        .subscribe(async (res) => {
          this.loadChatHistory();
        });

      this.subscriptions.logout = userCtrl
        .getSubscribeLogout(true)
        .subscribe(async (res) => {
          this.chatHistory = [];
          Cookie.delete('session');
        });
    });
  }

  ngOnInit() {
    this.loadChatHistory();
  }

  hasIncentivePopup() {
    // If the incentive popup is shown, make sure we acknowledge it
    // A class will be added to the chatbot button, which will
    // move the button up on smaller screens to avoid overlapping
    return !!document.getElementById('matchingPopup');
  }

  forceButtonMoveUp() {
    // If an element on the page indicates that the chatbot button
    // should be moved up, add a class to the button to do so
    return !!document.getElementById('forceChatBtnMoveUp');
  }

  buttonUnavailable() {
    if (this.router.url.includes('rent-request-details')) {
      return true;
    }
    return false;
  }

  ngOnDestroy() {
    this.utils.executeBrowserOnly(() => {
      [this.subscriptions.logout].map((obs) =>
        this.utils.unsubscribeObservable(obs)
      );
      [this.subscriptions.loginCompleted].map((obs) =>
        this.utils.unsubscribeObservable(obs)
      );
    });
  }

  toggleChatBox() {
    this.showChatBox = !this.showChatBox;

    // Focus on the input field if the chatbox is shown
    if (this.showChatBox) {
      // Add bot intro if chat history is empty
      if (this.chatHistory.length === 0) {
        this.chatHistory.push({
          message: this.utils.getTranslator().instant('chatbot.intro'),
          isUser: false,
          time: new Date()
        });
      }

      setTimeout(() => {
        document.getElementById("chatInput").focus();
      }, 100);
      this.scrollToBottom();
    }
  }

  sendMessage() {
    if (!this.allowSend) {
      return;
    }
    this.message = this.message.trim();
    if (this.message === '') {
      return;
    }

    // Add message to chat history
    this.allowSend = false;
    this.chatHistory.push({
      message: this.generateLinkHyperlinks(this.message),
      isUser: true,
      time: new Date()
    });

    // Send message
    this.processing = true;

    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append('Authorization', this.utils.getLoginToken());
    this.http
      .post(`${this.utils.getEnvironmentValue("apiUrl")}chat`, { question: this.message }, { withCredentials: true, headers: headers })
      .toPromise()
      .then((response) => response.json())
      .then((response) => {
        this.chatHistory.push({
          message: this.generateLinkHyperlinks(response.answer),
          isUser: false,
          time: undefined
        });
        this.allowSend = true;
        this.processing = false;
        this.scrollToBottom();
      })
      .catch((err) => {
        console.log(err);
        this.chatHistory.push({
          message: this.utils.getTranslator().instant('chatbot.unavailable'),
          isUser: false,
          time: new Date()
        });
        this.allowSend = false;
        this.processing = false;
      });

    // Clean up the textbox, focus on the input field, and scroll to the bottom
    this.message = '';
    document.getElementById("chatInput").focus();
    this.scrollToBottom();
  }

  async loadChatHistory() {
    this.chatHistory = [];
    
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append('Authorization', this.utils.getLoginToken());
    this.http
      .get(`${this.utils.getEnvironmentValue("apiUrl")}history`, { withCredentials: true, headers: headers })
      .toPromise()
      .then((response) => response.json())
      .then((response) => {
        for (const entry of response) {
          this.chatHistory.push({
            message: this.generateLinkHyperlinks(entry.question),
            isUser: true,
            time: new Date(entry.timestamp),
          });
          this.chatHistory.push({
            message: this.generateLinkHyperlinks(entry.answer),
            isUser: false,
            time: undefined,
          });
        }
      })
      .catch((err) => {
        console.log(err);
        this.allowSend = false;
        this.forceHideButton = true;
        this.showChatBox = false;
      });
  }

  formatTime(time) {
    return moment(time).format('h:mm A');
  }

  scrollToBottom() {
    setTimeout(() => {
      document.getElementById("chatHistory").scrollTo({
        top: document.getElementById("chatHistory").scrollHeight,
        behavior: "smooth"
      });
    }, 100);
  }

  generateLinkHyperlinks(message) {
    // Generate link for URLs and emails
    const urlRegex = /((http|https):\/\/[^\s]+[^\.])/g;
    const emailRegex = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/g;
    const urlMatches = message.match(urlRegex);
    const emailMatches = message.match(emailRegex);
    if (urlMatches) {
      for (const match of urlMatches) {
        message = message.replace(match, `<a style="text-decoration: underline" href="${match}" target="_blank">${match}</a>`);
      }
    }
    if (emailMatches) {
      for (const match of emailMatches) {
        message = message.replace(match, `<a style="text-decoration: underline" href="mailto:${match}" target="_blank">${match}</a>`);
      }
    }
    return message;
  }
}
