import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild
} from "@angular/core";
import { NgForm } from "@angular/forms";

import { Router } from "@angular/router";
import { Http } from "@angular/http";
import { Utils } from "../../../utils/utils";

import { ConditionsObject } from "../../../models/conditionsobject";

import {
  ProfileController,
  GeneralController
} from "../../../controllers/controllers.module";

@Component({
  selector: "shareProfileConfirm",
  templateUrl: "./shareProfileConfirm.component.html",
  providers: [Utils]
})
export class ShareProfileConfirmComponent {
  @Input() public profile: any;
  errors: any = {};
  conditions: ConditionsObject = this.genCtrl.getNewConditionsObject();
  secretCode: string;
  mask = [
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];

  @ViewChild("profileResumeForm") public profileResumeForm: NgForm;

  constructor(
    private router: Router,
    private http: Http,
    private utils: Utils,
    private el: ElementRef,
    private renderer: Renderer2,
    private genCtrl: GeneralController,
    private profCtrl: ProfileController
  ) {}

  modalEl = null;

  onCloseModal() {
    this.renderer.setStyle(this.modalEl, "display", "none");
    this.renderer.removeClass(this.modalEl, "opened");
  }

  ngOnInit() {
    this.utils.executeBrowserOnly(() => {
      this.conditions.isBrowser = true;
    });
  }

  onConfirm() {
    this.validateForm(this.profileResumeForm).then((response) => {
      this.onSave()
        .then((response: any) => {
          if (response) {
            if (
              response !== null &&
              response.slug != "" &&
              response._id != ""
            ) {
              this.router.navigate([
                `${this.utils.getCurrentLang()}/roommates/${response._id}/${
                  response.slug
                }`
              ]);
            } else {
              this.router.navigate([
                `${this.utils.getCurrentLang()}/roommates/${response._id}/${
                  response.slug
                }`
              ]);
            }
          }
        })
        .catch(function(err) {
          let error = JSON.parse(err.text());
          console.log(error.error);
          window.scrollTo(0, 0);
        });
    });
  }

  validateForm(form) {
    return new Promise((resolve, reject) => {
      if (form.valid) {
        resolve(true);
      } else {
        for (var i in form.controls) {
          form.controls[i].markAsTouched();
        }
        reject();
      }
    });
  }

  ngAfterViewInit() {
    if (this.el.nativeElement.querySelector) {
      this.modalEl = this.el.nativeElement.querySelector("div.modal");
    }
  }

  onSave() {
    return new Promise(async (resolve, reject) => {
      if (this.profile.minprice > this.profile.maxprice) {
        let tempPrice = this.profile.maxprice;
        this.profile.maxprice = this.profile.minprice;
        this.profile.minprice = tempPrice;
      }
    });
  }
}
