import { Utils } from '../../../utils/utils';
import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Cookie } from 'ng2-cookies';
import { faCalendarDay} from "@fortawesome/free-solid-svg-icons";

@Component({
  selector: 'hosthome',
  templateUrl: './hosthome.component.html',
  styleUrls: ['./hosthome.component.css']
})
export class HostHomeComponent {
  constructor(
    private utils: Utils,
    private router: Router
  ) {}
  
  //icons
  faCalendarDay = faCalendarDay;

  lang: string;
  
  ngOnInit() {
    this.lang = Cookie.get("lang") == "en" ? "en" : "fr";
    this.utils.setSeoMeta();
    this.utils.executeBrowserOnly(async () => {
      this.utils.hideOverlay();
      window['gtag']('event', 'General_Enter_host_page', {
              'event_category': 'General_Enter_host_page',
              'event_label': 'General_Enter_host_page'
          });
    });
  }

  userLoggedIn() {
    return this.utils.getLoggedUser();
  }

  goToShare() {
    this.router.navigate([this.lang + '/plans']);
  }
}
