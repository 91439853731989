import {Component, ElementRef, OnInit, Renderer2} from '@angular/core';
import {Router, ActivatedRoute} from '@angular/router'
import {Cookie} from "ng2-cookies";
import {Utils} from '../../../utils/utils';

import * as JsZip from 'jszip'
import {saveAs} from 'file-saver'

import * as _ from 'lodash'
import {
    RentRequestController,
    GeneralController,
    UserController,
    PaymentController
} from '../../../controllers/controllers.module';
import {HttpClient} from '@angular/common/http'
import {environment} from '../../../../environments/environment';
import {MAX_SHORT_STAY_DAYS} from '../../../utils/constants';
@Component({
    selector: 'app-rent-request-details',
    templateUrl: './rent-request-details.component.html',
    styleUrls: ['./rent-request-details.component.css'],
})
export class RentRequestDetailsComponent implements OnInit {
    durationMoreThanThreeMonths = false
    id: string
    paymentData: any
    moveInDate: any
    moveOutDate: any
    roomIds: any
    stayInDays = 0
    stayInMonths = 0
    user: any
    error: string
    userMessage: string
    userName: string
    message: string
    confirmationModal: any
    stage: string
    status: string
    _id: string
    identity: string
    poi: string
    pos: string
    poe: string
    identityVerified: boolean
    poiVerified: boolean
    otherDocument: string
    otherDocumentVerified: boolean
    posVerified: boolean;
    apartmentPhoto: string
    thisClass: any
    imageHost: string = this.utils.getEnvironmentValue('amazonS3Url');
    apartmentId: string
    loginModal: any;
    isError: boolean
    errorMessage: string
    isSuccess: boolean
    successMessage: string
    submitted: boolean
    successModal: any
    rentType: string
    originalRent: number
    ownerEmail: string
    apartmentName: string
    rentedRooms: any[]
    userId: string
    firstName: string
    lastName: string
    languageSpokenName: string = "";
    matchRequest = {
        matchLevel: 'neutral',
        matchReason: []
    }
    idealTenantScore: number = 0.0;
    payouts: any = [];
    adjustment: number = 0
    recurringPayment: number = 0
    guestImage: string

    MAX_SHORT_STAY_DAYS = MAX_SHORT_STAY_DAYS;

    constructor(
        private route: ActivatedRoute,
        private utils: Utils,
        private rentReqController: RentRequestController,
        private router: Router,
        private el: ElementRef,
        private genCtrl: GeneralController,
        private renderer: Renderer2,
        private httpRequest: HttpClient,
        private userCtrl: UserController,
        private paymentController: PaymentController
    ) {
    }

    ngOnInit() {
        this.route.params.subscribe(async (params) => {
            this.id = params['id']
            
            // Check if user is logged in
            if (Cookie.get("user")) {
                const storedUser = JSON.parse(Cookie.get("user"));
                try {
                    const user = await this.userCtrl.verifyUser(storedUser.id);

                    if (!user) {
                        this.utils.resetUser();
                    } else {
                        this.utils.setLoggedUser(user);
                        this.userCtrl.getLoginService().loginCompletedNotifyOther();
                    }
                } catch (err) {
                    this.utils.resetUser();
                }
            }
            if (!this.utils.getLoggedUser()) {
                this.router.navigate(['/' + this.utils.getCurrentLang() + '/login'], {queryParams: {redirect: this.utils.getCurrentLang() + '/rent-request-details/' + this.id}});
                this.genCtrl.triggerAlertError(this.utils.getTranslator().instant('Please login and then retry'));
            }

            // Get booking data
            const rentRequest = await this.rentReqController.fetchRentRequest(this.id);
            if (_.isEmpty(rentRequest.data)) {
                this.router.navigate(['/'])
                this.genCtrl.triggerAlertError(this.utils.getTranslator().instant(JSON.parse(rentRequest.error._body).error));
            }
            else {
                this.setBookingData(rentRequest.data);
            }

            // get payouts
            this.payouts = await this.paymentController.cauculateRent(this.id);
            this.adjustment = await this.amountAdjustment()
            this.recurringPayment = await this.getRecurringPayment();
        
        })
        this.utils.hideOverlay()
    }

    async setBookingData(bookingData) {
        this._id = bookingData._id
        this.paymentData = bookingData.paymentData;
        this.paymentData.refundableDeposit = 0;
        this.roomIds = bookingData.roomIds
        this.moveInDate = bookingData.moveInDate
        this.moveOutDate = bookingData.moveOutDate
        this.stayInDays = bookingData.stayInDays
        this.stayInMonths = bookingData.stayInMonths
        this.stage = bookingData.stage
        this.identity = bookingData.identity
        this.poi = bookingData.poi
        this.poe = bookingData.poe
        this.identityVerified = bookingData.identityVerified
        this.poiVerified = bookingData.poiVerified
        this.otherDocument = bookingData.otherDocument
        this.pos = bookingData.pos
        this.otherDocumentVerified = bookingData.otherDocumentVerified
        this.posVerified = bookingData.posVerified;
        this.userId = bookingData.userId._id
        this.userMessage = bookingData.message
        this.apartmentPhoto = bookingData.apartment.photos[0].source
        this.apartmentId = bookingData.apartmentId
        this.rentType = bookingData.apartment.rentType
        this.originalRent = bookingData.originalRent
        this.apartmentName = bookingData.apartment.name
        this.ownerEmail = bookingData.apartment.contact.email
        this.status = bookingData.status
        this.matchRequest.matchLevel = bookingData.matchLevel
        this.matchRequest.matchReason = bookingData.matchReason
        this.idealTenantScore = bookingData.idealTenantScore

        this.user = bookingData.userId;
        await this.getLanguagueSpokenName();
        this.firstName = this.user.first_name
        this.lastName = this.user.last_name

        // Get guest's image
        if (this.user.customImage) {
            const url = await this.userCtrl.getUserImageUrl(this.user._id);
            this.guestImage = url.url;
        }

        if (this.stayInMonths >= 3) {
            this.durationMoreThanThreeMonths = true
        }
        this.rentedRooms = [];
        for (let rentedRoom of bookingData.roomIds) {
            for (let room of bookingData.apartment.rooms) {
                if (room._id === rentedRoom) {
                    this.rentedRooms.push(room);
                    break;
                }
            }
        }

        this.thisClass = this
    }

    translateRoomDesc(desc: string) {
        const tmp = desc.split(' ');
        return this.utils.getTranslator().instant('Room') + ' ' + this.utils.getTranslator().instant(tmp[1]);
    }

    prepareApartmentNameAndNbOfRooms(rooms) {
        if (!rooms) {
            return ''
        }
        return `${this.apartmentName} (${rooms.length}`
    }

    prepareApartmentBedroom(rooms) {
        if (!rooms) {
            return ''
        }
        const unit = this.suitableUnit(rooms.length, 'bedroom')
        return `${unit}`
    }

    formatDate(date) {
        if (!date) {
            return ''
        }
        const dateStr = date.toString().split('T')[0]
        return this.utils.formatLocaleDateString(dateStr, this.utils.getCurrentLang());
    }

    formatStay() {
        return this.utils.formatDuration(this.stayInDays, this.stayInMonths)
    }

    getApartmentLink() {
        return `en/apartments/${this.apartmentId}`
    }

    ngAfterViewInit() {
        this.utils.executeBrowserOnly(() => {
            this.loginModal = this.el.nativeElement.querySelector('div.modal-login');
        });
    }

    userHasLoggedIn() {
        return !_.isEmpty(this.utils.getLoggedUser())
    }

    formatAmount(amount) {
        return this.utils.formatCurrency(_.round(amount, 0), this.utils.getCurrentLang())
    }

    async amountAdjustment() {
      const data = await this.rentReqController.getAdjustment({amount:this.originalRent,stayInDays:this.stayInDays});
     return data.adjustment;
    }

    calculateRoomsRent(days, rooms) {
        let total = 0;
        for (let i = 0; i < rooms.length; i++) {
            switch (rooms[i].rentType.toLowerCase()) {
                case 'daily':
                    total += rooms[i].price * days;
                    break;

                case 'weekly':
                    total += (rooms[i].price * days) / 7;
                    break;

                case 'monthly':
                    total += (rooms[i].price * days) / 30;
                    break;
            }
        }
        return total;
    };

    async submit(status) {
        if (!this.userHasLoggedIn()) {
            this.showModal(this.loginModal)
            return {}
        }
        this.status = status
        this.setModal()
        this.showModal(this.confirmationModal)
    }

    async receiveMessage(message, rentRequestSuccess = false) {
        this.utils.showOverlay();
        if (rentRequestSuccess) {
            this.utils.hideOverlay();
            this.goHome()
            return Promise.resolve()
        }
        this.message = message
        if (message === 'close') {
            this.utils.hideOverlay();
            this.goHome()
        }
        try {
            this.submitted = true
            let data
            if (this.stage === 'HOST') {
                data = await this.rentReqController.approveDeclineRequest(this._id, this.status, this.message)
            } else {
                data = await this.rentReqController.sendToHostOrDecline(this._id, this.user, this.status)
            }

            if (data.code === 'permissionDenied') {
                this.utils.hideOverlay();
                this.setError('You do not have permission to approve or decline this rent request.')
                this.submitted = false
            } else {
                this.utils.hideOverlay();
                this.setModalForSuccess()
            }

        } catch (error) {
            this.submitted = false
            if (JSON.parse(error._body).code === "payment_failed") {
              this.status = "pending";
              this.utils.hideOverlay();
              this.setModalForSuccess();
            }else{
              this.utils.hideOverlay();
              this.setError('Some unknown error occurred.')
            }
        }
    }

    setModalForSuccess() {
        this.setSuccessModal()
        this.showModal(this.successModal)
    }

    successOk() {
        this.goHome()
    }

    close() {
        this.status = 'close'
        this.setModal()
        this.showModal(this.confirmationModal)
    }

    showModal(modal) {
        this.genCtrl.showModal(this.renderer, modal);
    }

    setModal() {
        this.utils.executeBrowserOnly(() => {
            this.utils.setTooltips();
            this.utils.setToggle();
            this.confirmationModal = this.el.nativeElement.querySelector(
                'div.modal-confirmation'
            );
        });
    }

    setSuccessModal() {
        this.utils.executeBrowserOnly(() => {
            this.utils.setTooltips();
            this.utils.setToggle();
            this.successModal = this.el.nativeElement.querySelector(
                'div.modal-success'
            );
        });
    }

    goHome() {
        this.router.navigate(['/']).then(() => {
            this.router.navigate(['/'])
        })
    }

    suitableUnit(number, unit) {
        return this.utils.suitableUnit(number, unit)
    }

    prepareUrlForFiles(fileName, folder) {
        return `${this.imageHost + folder}/${fileName}`
    }

    getFileExtension(fileName) {
        console.log(fileName)
        const chunks = fileName.split('.')
        return chunks[chunks.length - 1]
    }

    isButtonNeeded() {
        const user = this.utils.getLoggedUser()
        return this.stage === 'ADMIN' && !_.isEmpty(user) && user.email === environment.adminEmail
    }

    async downloadZip() {
        if (this.identityIsThere()) {
            this.downloadIdentity()
        }

        if (this.poiIsThere()) {
            this.downloadPoi()
        }

        if (this.posIsThere()) {
            this.downloadPos()
        }

        if (this.poeIsThere()) {
            this.downloadPoe()
        }

        if (this.otherDocumentIsThere()) {
            this.downloadOtherDocument()
        }

    }

    downloadIdentity() {
        const identityName = `${this.userName}-identity`
        const identityInfo = {
            imageName: `${identityName}.${this.getFileExtension(this.identity)}`,
            archiveName: identityName
        }
        this.loadData(this.prepareUrlForFiles(this.identity, 'identities'), identityInfo, this.saveAsZip);
    }

    downloadPoi() {
        const poiName = `${this.userName}-poi`
        const poiInfo = {
            imageName: `${poiName}.${this.getFileExtension(this.poi)}`,
            archiveName: poiName
        }
        this.loadData(this.prepareUrlForFiles(this.poi, 'proofs-of-income'), poiInfo, this.saveAsZip)
    }

    downloadPos() {
        const posName = `${this.userName}-pos`
        const posInfo = {
            imageName: `${posName}.${this.getFileExtension(this.pos)}`,
            archiveName: posName
        }
        this.loadData(this.prepareUrlForFiles(this.pos, 'proofs-of-stay'), posInfo, this.saveAsZip)
    }

    downloadPoe() {
        const poeName = `${this.userName}-poe`
        const poeInfo = {
            imageName: `${poeName}.${this.getFileExtension(this.poe)}`,
            archiveName: poeName
        }
        this.loadData(this.prepareUrlForFiles(this.poe, 'proofs-of-enrollment'), poeInfo, this.saveAsZip)
    }

    downloadOtherDocument() {
        const otherDocumentName = `${this.userName}-other`
        const otherDocumentInfo = {
            imageName: `${otherDocumentName}.${this.getFileExtension(this.otherDocument)}`,
            archiveName: otherDocumentName
        }
        this.loadData(this.prepareUrlForFiles(this.otherDocument, 'others'), otherDocumentInfo, this.saveAsZip)
    }

    loadData(url: string, info, callback: Function): void {
        console.log(`Loading data from url: ${url}`)
        this.httpRequest.get(url, {responseType: 'arraybuffer'})
            .subscribe(x => callback(x, info));
    }

    saveAsZip(content: Blob, info): void {
        const zip = new JsZip();
        zip.file(info.imageName, content);
        zip.generateAsync({type: 'blob'})
            .then(blob => saveAs(blob, info.archiveName));
    };

    setError(errorMessage) {
        this.removeSuccess()
        this.isError = true
        this.errorMessage = errorMessage
    }

    removeError() {
        this.isError = false
        this.errorMessage = ''
    }

    setSuccess(successMessage) {
        this.removeError()
        this.isSuccess = true
        this.successMessage = successMessage
    }

    removeSuccess() {
        this.isSuccess = false
        this.successMessage = ''
    }

    setTimeOutAndRedirect(redirectionUrl) {
        return setTimeout(() => {
            return this.router.navigate([redirectionUrl])
        }, 3000);
    }

    getNumberOfDocuments() {
        let number = 1              // identity is always there
        if (this.poiIsThere()) {
            number = number + 1
        }
        if (this.posIsThere()) {
            number = number + 1
        }
        if (this.poeIsThere()) {
            number = number + 1
        }
        if (this.otherDocumentIsThere()) {
            number = number + 1
        }
        return number
    }

    identityIsThere() {
        return !_.isEmpty(this.identity)
    }

    otherDocumentIsThere() {
        return !_.isEmpty(this.otherDocument)
    }

    poiIsThere() {
        return !_.isEmpty(this.poi)
    }

    posIsThere() {
        return !_.isEmpty(this.pos);
    }

    poeIsThere() {
        return !_.isEmpty(this.poe)
    }

    calculateAge(dob) {
        var diff_ms = Date.now() - (new Date(dob)).getTime();
        var age_dt = new Date(diff_ms); 
        
        return Math.abs(age_dt.getUTCFullYear() - 1970);
    }

    async getLanguagueSpokenName() {
        const languages = await this.utils.getLanguages();
        for (const language of this.user.languageSpoken) {
            let languageName = languages[language].name;
            this.languageSpokenName += languageName;
            if (this.user.languageSpoken.indexOf(language) !== (this.user.languageSpoken.length - 1)) {
                this.languageSpokenName += ", ";
            }
        }
    }

    hasMatchingCategoriesFill() {
        if (Object.keys(this.user.matching_info).length === 6 && this.user.matching_info['personality'].length > 0) {
            for(const matching_category of this.user.matching_info) {
                if(this.user.matching_info[matching_category].length  === 0) {
                    return false;
                }    
            }
            return true;
        } else {
            return false;
        }
    }

    filterEmptyMatchingInfo(matching_info) {
        if (matching_info) {
            let result = {};
            for (const matching_category of Object.keys(matching_info)) {
                if (matching_info[matching_category].length > 0) {
                    result[matching_category] = matching_info[matching_category];
                }
            }
            return result;
        }
        else {
            return [];
        }
    }

    capitalize(text) {
        return text.charAt(0).toUpperCase() + text.slice(1);
    }

    onMatchClick() {
        let modalEl = this.genCtrl.getModal(
            this.el,
            this.renderer,
            'div.matchDetailsModal',
            true
        );
    }

    getFirstPayment(): number {
        for (let i = 0; i < this.payouts.length; ++i) {
            if (this.payouts[i].isFirstPayout) {
                return this.payouts[i].amountDue;
            }
        }
        return this.paymentData.roomRent;
    }

    async getRecurringPayment(): Promise<number> {
        for (let i = 0; i < this.payouts.length; ++i) {
            if (!this.payouts[i].isFirstPayout && !this.payouts[i].isLastPayout && this.payouts[i].period.isFullMonth) {
               return this.payouts[i].amountDue;
            }
        }
        const data = await this.rentReqController.getRecurringPaymentAmount({originalRent:this.originalRent});
        return data.amount;
    }
}
