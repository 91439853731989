import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Utils } from "../../../utils/utils";
import {Location} from '@angular/common';
@Component({
  selector: "shareSpace",
  templateUrl: "./shareSpace.component.html",
  styleUrls: ["./shareSpace.component.css"]
})
export class ShareSpaceComponent {
  constructor(private router: Router, private utils: Utils, private _location: Location) {}

  lang: string;
  ngOnInit() {
    this.lang = this.utils.getCurrentLang();
    this.utils.executeBrowserOnly(() => {
      window.scrollTo(0, 0);
      this.utils.hideOverlay();
    });
  }

  onNavigate(location) {
    if (location === "entire-place") {
      localStorage.setItem("isWholeApartment", "true");
    }
    else if (location === 'private-room') {
      localStorage.setItem("isWholeApartment", "false");
    }
    this.router.navigate([`/${this.lang}/share/apartment`]);
  }
  goBack(){
   this._location.back();
  }
}
