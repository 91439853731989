import {UUID} from 'angular2-uuid';

export class Apartment {

    constructor() {
        this._id = '';
        this.name = '';
        this.slug = '';
        this.description = '';
        this.creationDate = new Date;
        this.isListed = true;
        this.listedDate = new Date;
        this.rentaltype = 'longterm';
        this.minimumStay = ''
        this.rentType = null
        this.adress = new Address();
        this.owner = new Owner();
        this.contact = new Contact();
        this.photos = new Array<Photo>();
        this.roomsCount = 0;
        this.bathCount = '0';
        this.minprice = 999999;
        this.maxprice = 0;
        this.size = '';
        this.rooms = new Array<Room>();
        this.currency = '';
        this.nearestMetroLine = '';
        this.nearestMetroStation = '';
        this.accomodations = new Array<Accomodation>();
        this.facilities = new Array<Facility>();
        this.newFlow = false
        this.bookings = []
        this.occupied = false;
        this.wholeApartment = false;
        this.hostLivesInApartment = false;
        this.preferenceData = {};
        this.videoTour = "";
        this.moveInDetails = {};
        this.isStudio = false;
    }


    addEmptyRoom(description: string) {
        let room = new Room();
        room.description = description;
        this.rooms.push(room);
    }

    addNewPhoto(source: string) {
        let photo = new Photo();
        photo.source = source;
        photo.id = UUID.UUID();
        this.photos.push(photo);
    }

    addNewAccomodation(newAccomodation: any) {
        let accomodation = new Accomodation();
        accomodation.id = newAccomodation.id;
        this.accomodations.push(accomodation);
    }

    addNewFacility(newFacility: any) {
        let facility = new Facility();
        facility.id = newFacility.id;
        this.facilities.push(facility);
    }

    createNewRoom() {
        return new Room();
    }

    _id: string;
    name: string;
    slug: string;
    description: string;
    creationDate: Date;
    isListed: boolean;
    listedDate: Date;
    rentaltype: string;
    adress: Address;
    contact: Contact;
    owner: Owner;
    photos: Array<Photo>;
    roomsCount: number;
    minimumStay: String;
    bathCount: String;
    minprice: number;
    maxprice: number;
    size: string;
    rooms: Array<Room>;
    currency: string;
    nearestMetroLine: string;
    nearestMetroStation: string;
    accomodations: Array<Accomodation>;
    facilities: Array<Facility>;
    newFlow: Boolean
    bookings: any;
    rentType: string;
    occupied: Boolean;
    wholeApartment: Boolean;
    hostLivesInApartment: Boolean;
    preferenceData: any;
    availableNow: Boolean;
    nextAvailability: Date | boolean;
    roomAvails: any;
    matching_info: any;
    matchLevel: any;
    neighborhoodMatchLevel: any;
    moveInDetails: any;
    videoTour: string;
    isStudio: Boolean;
}

export class Room {
    constructor() {
        this.userName = '';
        this.userPhoto = '';
        this.price = 0;
        this.description = '';
        this.isFurnished = true;
        this.photos = new Array<Photo>();
        this.rentType = '';
        this._id = '';
        this.isOccupied = false;
        this.isHidden = false;
    }

    _id: string;
    userId: string;
    userName: string;
    userPhoto: string;
    price: number;
    description: string;
    isFurnished: boolean;
    photos: Array<Photo>;

    // New info for rooms
    moveInDate: Date;
    moveOutDate: Date;
    rentType: string;
    minimumStay: string;
    bedType: string;
    facilities: Array<Facility>;
    beds: Array<Object>;
    isOccupied: boolean;
    isHidden: boolean;
}

class Contact {
    constructor() {
        this.name = '';
        this.email = '';
        this.phone = '';
    }

    name: string;
    email: string;
    phone: string;
}

class Address {
    constructor() {
        this.adress = '';
        this.city = '';
        this.neighborhood = '';
        this.postalCode = '';
        this.lat = -1;
        this.lng = -1;
    }

    adress: string;
    city: string;
    neighborhood: string;
    postalCode: string;
    lat: number;
    lng: number;
}

class Owner {
    constructor() {
        this.userId = '';
        this.name = '';
        this.photo = '';
    }

    userId: any;
    name: string;
    photo: string;
}

class Photo {
    constructor() {
        this.id = '';
        this.source = '';
        this.description = '';
        this.tag = "";
    }

    id: string;
    source: string;
    description: string;
    tag: string;
}

class Accomodation {
    constructor() {
    }

    id: number;
}

class Facility {
    constructor() {
    }

    id: number;
}
