import {Injectable} from '@angular/core';
import {Headers, Http, RequestOptions} from '@angular/http';
import {Utils} from '../utils/utils';

@Injectable()
export class RentRequestController {
    constructor(
        private http: Http,
        private utils: Utils,
    ) {
    }

    prepareHeaders(authRequest = true) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (authRequest) {
            headers.append('authentication', this.utils.getLoggedUser().id);
            headers.append('Authorization', this.utils.getLoginToken());
        }
        return new RequestOptions({headers: headers});
    }


    async postRentRequest(data) {
        const options = this.prepareHeaders(true)
        return this.http
            .post(
                `${this.utils.getEnvironmentValue('apiUrl')}rent-requests`,
                data,
                options
            )
            .toPromise()
            .then((response) => response.json());
    }

    async fetchRentRequest(id) {
        const options = this.prepareHeaders(true)
        try {
            const data = (await this.http.get(`${this.utils.getEnvironmentValue('apiUrl')}rent-requests/${id}`, options).toPromise()).json()
            return Promise.resolve({data: data.content, success: true, error: {}})
        } catch (error) {
            return Promise.resolve({data: {}, success: false, error: error})
        }
    }

    async getPendingRentRequestByUserId() {
        const options = this.prepareHeaders(true)
        return await this.http.get(`${this.utils.getEnvironmentValue('apiUrl')}rent-requests/guest/pending-requests`, options)
            .toPromise()
            .then((response) => response.json()); 
    }

    approveDeclineRequest(_id, status, message) {
        switch (status) {
            case 'approve':
                return this.approveRentRequest(_id, message)
            case 'decline':
                return this.declineRentRequest(_id)
        }
    }

    sendToHostOrDecline(_id, user, status) {
        switch (status) {
            case 'approve':
                return this.sendToHost(_id)
            case 'decline':
                return this.declineRentRequest(_id)
        }
    }

    approveRentRequest(_id, message) {
        const options = this.prepareHeaders(true)
        return this.http
            .post(
                `${this.utils.getEnvironmentValue('apiUrl')}rent-requests/approve`,
                {_id, message},
                options
            )
            .toPromise()
            .then((response) => response.json());
    }

    declineRentRequest(_id) {
        const options = this.prepareHeaders(true)
        return this.http
            .post(
                `${this.utils.getEnvironmentValue('apiUrl')}rent-requests/decline`,
                {_id},
                options
            )
            .toPromise()
            .then((response) => response.json());
    }

    sendToHost(_id) {
        const options = this.prepareHeaders(true)
        return this.http
            .post(
                `${this.utils.getEnvironmentValue('apiUrl')}rent-requests/send-to-host`,
                {_id, user: this.utils.getLoggedUser() },
                options
            )
            .toPromise()
            .then((response) => response.json());
    }

    fetchForPayment(_id) {
        return this.http
            .get(
                `${this.utils.getEnvironmentValue('apiUrl')}rent-requests/for-payment/${_id}`
            )
            .toPromise()
            .then((response) => response.json());
    }

    isStayValid(apartmentId, roomIds) {
        const options = this.prepareHeaders(true)
        return this.http
            .post(
                `${this.utils.getEnvironmentValue('apiUrl')}rent-requests/is-stay-valid`,
                {apartmentId, roomIds},
                options
            )
            .toPromise()
            .then((response) => response.json());
    }

    makePendingRentRequest(data) {
        /*
        Required fields for data:
        apartmentId, roomIds, moveInDate, moveOutDate,
        identity, poi, otherDocument, purposeOfStay, message, phone
        */
        const options = this.prepareHeaders(true);
        return this.http
            .post(
                `${this.utils.getEnvironmentValue('apiUrl')}apartments/make-rent-request`,
                data,
                options
            )
            .toPromise()
            .then((response) => response.json());
    }

    async getAllPendingRequests() {
        const options = this.prepareHeaders(true)
        return this.http
            .get(
                `${this.utils.getEnvironmentValue('apiUrl')}rent-requests/user/pending`,
                options
            )
            .toPromise()
            .then((response) => response.json());
    }

    async getAllReservations() {
        const options = this.prepareHeaders(true)
        return this.http
            .get(
                `${this.utils.getEnvironmentValue('apiUrl')}rent-requests/user/approved`,
                options
            )
            .toPromise()
            .then((response) => response.json());
    }

    async getAllGuestReservations() {
        const options = this.prepareHeaders(true)
        return this.http
            .get(
                `${this.utils.getEnvironmentValue('apiUrl')}rent-requests/reservations`,
                options
            )
            .toPromise()
            .then((response) => response.json().reservations);
    }

    async getAllPastReservations() {
        const options = this.prepareHeaders(true)
        return this.http
            .get(
                `${this.utils.getEnvironmentValue('apiUrl')}rent-requests/past-reservations`,
                options
            )
            .toPromise()
            .then((response) => response.json().reservations);
    }

    async getAllHostPastReservations() {
        const options = this.prepareHeaders(true)
        return this.http
            .get(
                `${this.utils.getEnvironmentValue('apiUrl')}rent-requests/host-past-reservations`,
                options
            )
            .toPromise()
            .then((response) => response.json().reservations);
    }

    async getMenuStatus() {
        const options = this.prepareHeaders(true)
        return this.http
            .get(
                `${this.utils.getEnvironmentValue('apiUrl')}rent-requests/user/menustatus`,
                options
            )
            .toPromise()
            .then((response) => response.json());
    }

    async getApprovedRequestByAptId(appId: string) {
        return this.http
            .get(`${this.utils.getEnvironmentValue('apiUrl')}rent-requests/approved/${appId}`)
            .toPromise()
            .then((response) => response.json());
    }

    async getAdjustment(data) {
        const options = this.prepareHeaders(true);
        return this.http
            .post(
                `${this.utils.getEnvironmentValue('apiUrl')}apartments/adjustment`,
                data,
                options
            )
            .toPromise()
            .then((response) => response.json());
    }


    async getRecurringPaymentAmount(data) {
        const options = this.prepareHeaders(true);
        return this.http
            .post(
                `${this.utils.getEnvironmentValue('apiUrl')}apartments/recurring-payment-amount`,
                data,
                options
            )
            .toPromise()
            .then((response) => response.json());
    }
}
