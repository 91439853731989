import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  Renderer2
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { Profile } from "../../../models/profile";
import { Router, ActivatedRoute } from "@angular/router";
import { Utils } from "../../../utils/utils";

import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { defineLocale } from "ngx-bootstrap/chronos";
import { frLocale } from "ngx-bootstrap/locale";

import { ConditionsObject } from "../../../models/conditionsobject";

import {
  ProfileController,
  UserController,
  GeneralController
} from "../../../controllers/controllers.module";

@Component({
  selector: "editShareProfileForm",
  templateUrl: "./editShareProfileForm.component.html",
  styleUrls: ["./editShareProfileForm.component.css"]
})
export class EditShareProfileFormComponent {
  profile: Profile;
  accomodations: any = [];
  accomodationsCriterias: any = [];
  facilities: any = [];
  loggedUser: any;
  mask = [
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
  id: any;
  imageHost: string = this.utils.getEnvironmentValue("amazonS3Url");
  modals: any = {};
  duration: any = {};
  conditions: ConditionsObject = this.genCtrl.getNewConditionsObject();
  subscriptions: any = {};
  @ViewChild("profileForm") public profileForm: NgForm;

  @Input() public verifyUser: boolean;
  @Input() public isEdit: boolean;

  constructor(
    private router: Router,
    private utils: Utils,
    private route: ActivatedRoute,
    private el: ElementRef,
    private renderer: Renderer2,
    private localeService: BsLocaleService,
    private genCtrl: GeneralController,
    private userCtrl: UserController,
    private profCtrl: ProfileController
  ) {
    this.accomodations = utils.getAccomodations();
    this.accomodationsCriterias = utils.getAccomodationsCriterias();
    this.facilities = utils.getFacilities();
    this.profile = new Profile();

    this.subscriptions.login = this.userCtrl
      .getSubscribeLogin(true)
      .subscribe((res) => {
        this.validateUser();
      });

    this.subscriptions.modal = this.userCtrl
      .getSubscribeLoginModal(true)
      .subscribe((res) => {
        this.validateUser();
      });

    this.subscriptions.logout = this.userCtrl
      .getSubscribeLogout(true)
      .subscribe((res) => {
        this.conditions.isLogged = false;
        this.loggedUser = null;
      });
  }

  ngOnInit() {
    this.utils.executeBrowserOnly(() => {
      if (this.utils.getCurrentLang() != "en") {
        defineLocale(this.utils.getCurrentLang(), frLocale);
        this.localeService.use(this.utils.getCurrentLang());
      }

      this.resetAccomodations();
      this.resetFacilities();
      window.scrollTo(0, 0);
      this.conditions.isBrowser = true;
      this.validateUser();

      this.subscriptions.id = this.route.params.subscribe(async (params) => {
        this.id = params["id"];
        if (this.id != null) {
          if (this.verifyUser) {
            let owner = this.profile.owner.userId;
            if (this.loggedUser.id !== owner._id) {
              this.router.navigate([`${this.utils.getCurrentLang()}/notfound`]);
            }
          }
          this.initAccomodations();
          this.initFacilities();

          if (this.profile.durationFrom && this.profile.durationTo) {
            this.duration = [
              new Date(this.profile.durationFrom),
              new Date(this.profile.durationTo)
            ];
          }

          this.utils.hideOverlay();
        }
      });
    });
  }

  onDateChange(duration: Date) {
    if (duration) {
      this.duration = duration;

      this.profile.durationFrom = duration[0];
      this.profile.durationTo = duration[1];
    } else {
      this.profile.durationFrom = null;
      this.profile.durationTo = null;
    }
  }

  validateUser() {
    let loggedUser = this.utils.getLoggedUser();
    if (loggedUser) {
      this.conditions.isLogged = true;
      this.loggedUser = loggedUser;
      if (loggedUser.isBlocked) {
        this.router.navigate([`${this.utils.getCurrentLang()}/notfound`]);
      }

      if (loggedUser.first_name && loggedUser.last_name) {
        this.profile.contact.name = `${loggedUser.first_name} ${
          loggedUser.last_name
        }`;
      }
      if (loggedUser.email) {
        this.profile.contact.email = loggedUser.email;
      }
    }
  }

  ngAfterViewInit() {
    this.utils.executeBrowserOnly(() => {
      this.utils.setTooltips();
      this.utils.setToggle();
    });
  }

  onlyNumberKey(event) {
    return event.charCode == 8 || event.charCode == 0
      ? null
      : event.charCode >= 48 && event.charCode <= 57;
  }

  onOpenAddRoomateModal() {
    this.modals.addUser = this.genCtrl.getModal(
      this.el,
      this.renderer,
      "div.modal-assign-user",
      true
    );
  }

  onOpenLoginModal() {
    this.modals.login = this.genCtrl.getModal(
      this.el,
      this.renderer,
      "div.modal-login",
      true
    );
  }

  removeRoommate(roommate) {
    this.profile.roommates.splice(this.profile.roommates.indexOf(roommate), 1);
  }

  async onSave() {

  }

  initAccomodations() {
    for (let accomodation of this.profile.accomodations) {
      let acc = this.accomodations.find(function(oAcc) {
        return oAcc.id == accomodation.id;
      });
      if (acc) {
        acc.isSelected = true;
      } else {
        let accCriteria = this.accomodationsCriterias.find(function(oAcc) {
          return oAcc.id == accomodation.id;
        });

        if (accCriteria) {
          accCriteria.isSelected = true;
        }
      }
    }
  }

  resetAccomodations() {
    for (let accomodation of this.accomodations) {
      accomodation.isSelected = false;
    }

    for (let accomodationCriteria of this.accomodationsCriterias) {
      accomodationCriteria.isSelected = false;
    }
  }

  setAccomodations() {
    this.profile.accomodations = [];
    for (let accomodation of this.accomodations) {
      if (accomodation.isSelected) {
        this.profile.accomodations.push({ id: accomodation.id });
      }
    }

    for (let accomodationCriteria of this.accomodationsCriterias) {
      if (accomodationCriteria.isSelected) {
        this.profile.accomodations.push({ id: accomodationCriteria.id });
      }
    }
  }

  initFacilities() {
    for (let facility of this.profile.facilities) {
      let fac = this.facilities.find(function(oFac) {
        return oFac.id == facility.id;
      });
      if (fac) {
        fac.isSelected = true;
      }
    }
  }

  resetFacilities() {
    for (let facility of this.facilities) {
      facility.isSelected = false;
    }
  }

  setFacilities() {
    this.profile.facilities = [];
    for (let facility of this.facilities) {
      if (facility.isSelected) {
        this.profile.facilities.push({ id: facility.id });
      }
    }
  }

  ngOnDestroy() {
    this.utils.executeBrowserOnly(() => {
      this.utils.showOverlay();
      [
        this.subscriptions.logout,
        this.subscriptions.login,
        this.subscriptions.modal,
        this.subscriptions.id
      ].map((obs) => this.utils.unsubscribeObservable(obs));
    });
  }

  onSubmitForm(form) {

  }

  deleteListing() {
    this.genCtrl.showModal(this.renderer, this.el.nativeElement.querySelector("div.modal-delete-confirm"));
  }

  async confirmDelete(confirmed) {
    if (confirmed === true) {
      this.router.navigate([this.utils.getCurrentLang() + "/myprofile/sharings"]);
    }
  }
}
