import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { Router } from "@angular/router";
import { Utils } from "../../../utils/utils";
import {
  ProfileController,
  UserController,
  GeneralController,
  MatchingController
} from "../../../controllers/controllers.module";
import { Keyword, KeywordCategory } from "app/controllers/matching.types";
import { AboutMeChangeEvent } from "./AboutMeForm.types";

@Component({
  selector: "AboutMeForm",
  templateUrl: "./AboutMeForm.component.html",
  styleUrls: ["./AboutMeForm.component.css"],
})
export class AboutMeFormComponent implements OnInit, OnChanges {
  @Input()  aboutme: string = '';
  @Input()  keywords: string[] = [];
  @Output() onChange: EventEmitter<AboutMeChangeEvent> = new EventEmitter();

  categories: KeywordCategory[] = [];


  profiles: any;
  imageHost = this.utils.getEnvironmentValue("amazonS3Url");
  subscriptions: any = {};
  loggedUser: any;
  lang: string;


  constructor(
    private router: Router,
    private utils: Utils,
    private matchCtrl: MatchingController,
    private profCtrl: ProfileController,
    private userCtrl: UserController,
    private genCtrl: GeneralController
  ) {
    this.utils.executeBrowserOnly(() => {
      this.subscriptions.logout = this.userCtrl
        .getSubscribeLogout(true)
        .subscribe((res) => {
          this.router.navigate([this.utils.getCurrentLang() + "/apartments"]);
        });
    });
  }

  ngOnInit() {
    this.utils.setSeoMeta();
    this.lang = this.utils.getCurrentLang();
    this.utils.executeBrowserOnly(async () => {
      await this.fetchCategories();
      this.utils.hideOverlay();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.emitChange();
  }

  emitChange() {
    const event: AboutMeChangeEvent = {
        valid: true,
        aboutme: this.aboutme,
        selectedKeywords: this.keywords,
        missingAboutMe: false,
        incompleteCategories: [],
        overloadedCategories: [],
    }

    // Check missing 'About Me'
    if (!this.aboutme) {
        event.missingAboutMe = true;
        event.valid = false;
    }

    // Check for overloaded/incomplete categories
    for (const category of this.categories) {
        let count = 0;
        for (const keyword of category.keywords) {
            if (this.keywords.indexOf(keyword._id) > -1) {
                count += 1;
            }
        }
        if (count > category.max) {
            event.overloadedCategories.push(category)
            event.valid = false;
        }
        else if (count < category.min) {
            event.incompleteCategories.push(category)
            event.valid = false;
        }
    }
    console.log(event);
    this.onChange.emit(event);
  }

  async fetchCategories() { 
    this.categories = await this.matchCtrl.getKeywords();
    for (const category of this.categories) {
      for (const keyword of category.keywords) {
        keyword.selected = false;
      }
    }
  }

  toggleCategory(index: number){
    var triggers = document.getElementsByClassName('trigger');
    triggers[index].classList.toggle('active');
    var content = triggers[index].nextElementSibling;
    if (content['style'].maxHeight){
      content['style'].maxHeight = null;
    } else {
      content['style'].maxHeight = content.scrollHeight + "px";
    }
  }

  onKeywordClick(keyword: Keyword, category) {
    const index = this.keywords.indexOf(keyword._id);
    if (index >= 0) this.keywords.splice(index, 1);
    else {
        // Check for keywords count for current category
        let count = 0;
        for (const keyword of category.keywords) {
            if (this.keywords.indexOf(keyword._id) > -1) {
                count += 1;
            }
        }
        console.log(count, category.max);
        if (count >= category.max) {
            return;
        }

        this.keywords.push(keyword._id);
    }
    this.emitChange();
  }

  getCategoryLimitText(minimum, maximum) {
    if (minimum == 0) {
      return this.utils.getTranslator().instant("Select a maximum of") + " " + maximum;
    }
    else if (maximum == 0) {
      return this.utils.getTranslator().instant("Select a minimum of") + " " + minimum;
    }
    else {
      return this.utils.getTranslator().instant("Select") + " " + minimum + " " + this.utils.getTranslator().instant("to") + " " + maximum;
    }
  }
}
