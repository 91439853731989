import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Utils } from '../../../utils/utils';
import { GeneralUtils } from '../../../utils/general';
import { ApartmentService } from "../../services/apartmentService";
import {TranslateService} from '@ngx-translate/core';

import {
  ApartmentController,
  GeneralController,
  CityController,
  RentRequestController,
  UserController
} from "../../../controllers/controllers.module";
import { FilterObject } from 'app/models/filterobject';
import { ConditionsObject } from 'app/models/conditionsobject';
import { getParentRenderElement } from '@angular/core/src/view/util';
import * as moment from 'moment';
import { LocalFilters } from 'app/models/local-filters';
import { SearchApartmentMap } from './map/searchApartmentMap.component';

interface Apartment {
  isHovered: boolean;
  _id: string;
}

@Component({
  selector: "searchApartment",
  templateUrl: "./searchApartment.component.html",
  styleUrls: ["./searchApartment.component.css"],
})
export class SearchApartmentComponent {

  @ViewChild('mapComponent') mapComponent: SearchApartmentMap;

  constructor(
    private utils: Utils,
    private aptCtrl: ApartmentController,
    private genCtrl: GeneralController,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private el: ElementRef,
    private renderer: Renderer2,
    public cityCtrl: CityController,
    private apartmentService: ApartmentService,
    private rentRequestCtrl: RentRequestController,
    private genUtils: GeneralUtils,
    private translate: TranslateService,
    private userCtrl: UserController
  ) {
    this.apartmentService.setSearchComponent(this);
    this.subscriptions.moveInDate = this.apartmentService.moveInDateNotifyObservable$.subscribe(async (moveInDate: Date) => {
      this.filter["moveInDate"] = moveInDate;
      await this.filterData(true, true);
    });
  }

  @ViewChild("wrapperContainer") wrapperContainer: ElementRef;

  private readonly VIEW_MODE_KEY = 'apartmentViewMode';
  private readonly LAST_MARKER_KEY = 'lastClickedMarkerPosition';
  subscriptions: any = {};

  filter = new FilterObject();
  localFilters = new LocalFilters();
  filtersCount: number = 0;

  totalPages: number = 0; 
  totalListings: number = 0;
  pagesArray: Array<number | string> = [];
  itemsPerPage: number = 12;
  showMap: boolean = false;
  isMobile: boolean = false;

  lastListingScrollPosition: number = 0;
  loading = false;
  apartments: any = [];
  currentPage: number = 1;
  lang: string;
  today: Date = new Date();
  imageHost: string = this.utils.getEnvironmentValue("amazonS3Url");
  isSearching: boolean = false;
  conditions: ConditionsObject = this.genCtrl.getNewConditionsObject();
  dummyVar = "";
  rentTypeVar: string;
  distroyResources: Subject<boolean> = new Subject();
  availableLaterApartments: any = [];
  moveInDate: string | null = null;
  expectedMoveInDate: any;
  config = {
    backdrop: window.matchMedia('(max-width: 768px)').matches,
    ignoreBackdropClick: true,
    class: 'move-in-date-modal modal-sm',
    animated: false,
  };
  datepickerValue: Date = new Date();
  datepickerConfig = {
    isAnimated: true,
    dateInputFormat: 'YYYY-MM-DD',
    showWeekNumbers: false,
    selectFromOtherMonth: false,
    containerClass: 'theme-hivenue',
    minDate: new Date(),
  };
  loggedUser: any;
  selectedCity: string;

  async ngOnInit() {
    this.utils.executeBrowserOnly(() => {
      this.restoreViewState();
    });

    this.utils.executeBrowserOnly(() => {
      this.restoreViewState();
      
      if (this.showMap) {
        setTimeout(() => {
          this.resizeMap();
          setTimeout(() => {
            this.attemptToRestoreMarkerPosition();
          }, 500);
        }, 500);
      }
    });
    
    this.lang = this.utils.getCurrentLang();
    await this.updateLoggedUser();
    this.lang = this.utils.getCurrentLang();
    await this.updateLoggedUser();
    this.userCtrl.getSubscribeLogout(true).subscribe(async () => {
      await this.updateLoggedUser();
    });
    this.userCtrl.getSubscribeLogin(true).subscribe(async () => {
      await this.updateLoggedUser();
    });
    this.utils.setSeoMeta();

    const { page, city, neighborhood } =
      this.activatedRoute.snapshot.queryParams;

    if (neighborhood) {
      this.filter.neighborhoods = neighborhood;
    }

    if (this.filter["moveInDate"] === undefined) {
      this.filter["moveInDate"] = null;
    }

    if (this.cityCtrl.selectedCityName) {
      this.selectedCity = this.cityCtrl.selectedCityName;
      this.cityCtrl.neighborhoods = await this.aptCtrl.getApartmentsNeihghborhoodPerCity(
          this.cityCtrl.selectedCityName
      );
    }

    if (page) {
      this.utils.executeServerOnly(() => {
        this.utils.createCanonicalURL();
      });

      const defaultLimit = this.filter.limit;
      this.filter.limit = page * defaultLimit;
      this.currentPage = page;

      await this.filterData(true, false);

      this.filter.skip = this.filter.limit - defaultLimit;
      this.filter.limit = defaultLimit;
    } else {
      await this.filterData(true, false);
    }

    this.utils.executeBrowserOnly(() => {
      //restore view state
      this.restoreViewState();
      
      //handle map initialization
      if (this.showMap) {
        setTimeout(() => {
          this.resizeMap();
          
          setTimeout(() => {
            const savedPosition = this.restoreMarkerPosition();
            if (savedPosition && this.mapComponent && typeof this.mapComponent.centerOnPosition === 'function') {
              try {
                console.log('Centering on saved position:', savedPosition);
                this.mapComponent.centerOnPosition(savedPosition.lng, savedPosition.lat);
              } catch (e) {
                console.error('Error centering on position:', e);
              }
            } else {
              console.log('Cannot center: mapComponent or position not available', {
                savedPosition,
                mapComponentExists: !!this.mapComponent
              });
            }
          }, 1000); 
        }, 500);
      }
  
      window.scrollTo(0, 0);
      this.conditions.isBrowser = true;
      window["gtag"]("event", "General_Enter_search_page", {
        event_category: "General_Enter_search_page",
        event_label: "General_Enter_search_page",
      });
    });
    
    this.cityCtrl.selectedCity
      .pipe(takeUntil(this.distroyResources))
      .subscribe(async (city: string) => {
        this.selectedCity = city;
        this.filter = new FilterObject();
        this.moveInDate = null;
        this.expectedMoveInDate = null;
        this.datepickerValue = new Date();
        this.apartmentService.moveInDateNotifyOther(null);
        this.availableLaterApartments = [];
        await this.filterData(true, true);
      });

    if (city) {
      this.selectedCity = city;
      this.cityCtrl.cityChanged(city);
      if (this.filter) {
        this.filter.city = city;
      }
    }

    this.activatedRoute.params
      .pipe(takeUntil(this.distroyResources))
      .subscribe(async (params) => {
        const city = params["city"];
        if (city) {
          if (city !== this.filter.city) {
            this.selectedCity = city;
            this.cityCtrl.cityChanged(city);
          }
        } else {
          this.selectedCity = 'Montreal';
          this.cityCtrl.cityChanged("Montreal");
        }
      });

      this.checkMobile();
      window.addEventListener('resize', this.checkMobile.bind(this));
      
      
  }

  checkMobile() {
    const wasNotMobile = !this.isMobile;
    this.isMobile = window.innerWidth <= 767;
    
    if (wasNotMobile !== this.isMobile) {
      if (!this.isMobile) {
        this.showMap = true;
      }
      this.resizeMap();
    }
  }

saveViewState() {
  try {
    sessionStorage.setItem(this.VIEW_MODE_KEY, this.showMap ? 'map' : 'list');
    console.log('View state saved:', this.showMap ? 'map' : 'list');
  } catch (e) {
    console.error('Error saving view state:', e);
  }
}

saveMarkerPosition(lat: number, lng: number) {
  try {
    const markerPosition = { lat, lng };
    sessionStorage.setItem(this.LAST_MARKER_KEY, JSON.stringify(markerPosition));
    console.log('Marker position saved:', markerPosition);
  } catch (e) {
    console.error('Error saving marker position:', e);
  }
}

restoreMarkerPosition() {
  try {
    const positionStr = sessionStorage.getItem(this.LAST_MARKER_KEY);
    if (positionStr) {
      const position = JSON.parse(positionStr);
      console.log('Restoring marker position:', position);
      return position;
    }
    return null;
  } catch (e) {
    console.error('Error restoring marker position:', e);
    return null;
  }
}

restoreViewState() {
  try {
    const viewMode = sessionStorage.getItem(this.VIEW_MODE_KEY);
    console.log('Restoring view state:', viewMode);
    
    if (viewMode === 'map') {
      console.log('Setting view to MAP');
      this.showMap = true;
    } else if (viewMode === 'list') {
      console.log('Setting view to LIST');  
      this.showMap = false;
    }
  } catch (e) {
    console.error('Error restoring view state:', e);
  }
}

toggleMap() {
  if (this.isMobile) {
    if (!this.showMap) {
      this.lastListingScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      
      window.scrollTo({
        top: 0,
        behavior: 'instant'
      });
    } else {
      setTimeout(() => {
        window.scrollTo({
          top: this.lastListingScrollPosition,
          behavior: 'instant'
        });
      }, 0);
    }
  }
  
  this.showMap = !this.showMap;
  
  this.saveViewState();
  
  setTimeout(() => this.resizeMap(), 200);
}


navigateToApartment(apartmentId: string, openInNewTab: boolean = false) {
  this.saveViewState();

  const currentPath = window.location.pathname;
  const languagePrefix = !currentPath.startsWith('/fr') ? 'en' : 'fr';
  const apartmentUrl = `/${languagePrefix}/apartments/${apartmentId}`;
  
  if (openInNewTab && !this.isMobileUA()) {
    window.open(apartmentUrl, '_blank');
  } else {
    this.router.navigate([apartmentUrl]);
  }
}

private attemptToRestoreMarkerPosition(retryCount = 0, maxRetries = 3) {
  const savedPosition = this.restoreMarkerPosition();
  if (savedPosition && this.mapComponent && typeof this.mapComponent.centerOnPosition === 'function') {
    try {
      console.log(`Centering on saved position (attempt ${retryCount + 1}):`, savedPosition);
      this.mapComponent.centerOnPosition(savedPosition.lng, savedPosition.lat);
      return true;
    } catch (e) {
      console.error(`Error centering on position (attempt ${retryCount + 1}):`, e);
      if (retryCount < maxRetries) {
        setTimeout(() => {
          this.attemptToRestoreMarkerPosition(retryCount + 1, maxRetries);
        }, 500);
      }
      return false;
    }
  } else if (retryCount < maxRetries) {
    console.log(`Map component not ready yet, retrying (attempt ${retryCount + 1})`);
    setTimeout(() => {
      this.attemptToRestoreMarkerPosition(retryCount + 1, maxRetries);
    }, 500);
    return false;
  } else {
    console.log('Cannot center: mapComponent or position not available after max retries', {
      savedPosition,
      mapComponentExists: !!this.mapComponent
    });
    return false;
  }
}

  private resizeMap() {
    if (this.mapComponent) {
      this.mapComponent.resizeIfMobile(true);
      setTimeout(() => this.mapComponent.resizeIfMobile(true), 300);
    }
  }

  async onSort(event) {
    if (event == "price-asc") {
      this.filter.sort = "minprice";
      this.filter.sortorder = "asc";
    } else if (event == "price-desc") {
      this.filter.sort = "maxprice";
      this.filter.sortorder = "desc";
    } else if (event == "listeddate-asc") {
      this.filter.sort = "listedDate";
      this.filter.sortorder = "asc";
    } else if (event == "listeddate-desc") {
      this.filter.sort = "listedDate";
      this.filter.sortorder = "desc";
    }

    this.filter.skip = 0;
    await this.filterData(true, true);
  }

  initUtils() {
    this.utils.setListLayout();
    this.utils.hideOverlay();
  }

  updateUrl() {
    const url = this.router
      .createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: ++this.currentPage },
      })
      .toString();
    this.location.go(url);
  }
 /*
  async onScroll() {
    if (
      this.apartments &&
      !this.moveInDate &&
      this.apartments.length >= this.filter.limit + this.filter.skip
    ) {
      this.updateUrl();

      this.loading = true;
      this.filter.skip += this.filter.limit;

      await this.filterData(false, false);

      this.loading = false;
      this.utils.setListLayout();
    }
  }

  async onScrollAvailableLater() {
    if (
      this.availableLaterApartments &&
      !this.moveInDate &&
      this.availableLaterApartments.length >= this.filter.limit + this.filter.skip
    ) {
      this.updateUrl();

      this.loading = true;
      this.filter.skip += this.filter.limit;

      this.filtersCount = 0;
      const filters = this.getFilters();

      this.availableLaterApartments = this.availableLaterApartments.concat(
          await this.aptCtrl.getApartments(filters)
      );
      this.setAvailability();

      this.loading = false;
      this.utils.setListLayout();
    }
  }
  */

  onSearch = async () => {
    this.currentPage = 1;
    this.filter.skip = 0;
    
    const url = this.router.createUrlTree([], {
      relativeTo: this.activatedRoute,
      queryParams: { page: 1 }
    }).toString();
    this.location.go(url);

    await this.filterData(true, true);
  };

  getFilters = () => {
    let filters = "";
    const defaultFilters = new FilterObject();

    for (const key in this.filter) {
      const isFilterKey =
        key !== "sort" &&
        key !== "sortorder" &&
        key !== "limit" &&
        key !== "skip";

      if (key === "neighborhoods") {
        this.filter["neighborhoods"] = this.filter["neighborhoods"].filter(
          (item) => item != ""
        );
      }

      if (key === "city") {
        this.filter["city"] = this.cityCtrl.selectedCityName;
      }

      if (
        this.filter[key] != null &&
        this.filter[key] !== "" &&
        (!Array.isArray(this.filter[key]) || this.filter[key].length !== 0)
      ) {
        filters += `&${key}=${
          Array.isArray(this.filter[key])
            ? JSON.stringify(this.filter[key])
            : this.filter[key]
        }`;
      }

      if (
        this.filter[key] !== defaultFilters[key] &&
        isFilterKey &&
        key === "neighborhood" &&
        this.filter[key].length > 0
      ) {
        this.filtersCount++;
      }
    }

    return filters;
  };

  async filterData(showOverlay: boolean, resetList: boolean): Promise<void> {
    if (showOverlay) {
      this.utils.showOverlay();
    }
  
    try {
      await this.getTotalCityListings();
  
      if (resetList) {
        this.apartments = [];
        this.availableLaterApartments = [];
      }
  
      this.filter.skip = (this.currentPage - 1) * this.itemsPerPage;
      this.filter.limit = this.itemsPerPage;
  
      const filters = this.getFilters();
      const response = await this.aptCtrl.getApartments(filters);
      
      this.apartments = response.results || response;
      this.totalPages = Math.ceil(this.totalListings / this.itemsPerPage);
      
      if (resetList) {
        this.availableLaterApartments = this.apartments.filter(apartment => apartment.availableLater === true);
        this.apartments = this.apartments.filter(apartment => !apartment.availableLater);
      }
  
      await this.setAvailability();
      this.generatePagesArray();
  
      this.utils.executeBrowserOnly(() => {
        this.initUtils();
      });
    } finally {
      if (showOverlay) {
        this.utils.hideOverlay();
      }
    }
  }

  /** 
  * Generates array of page numbers for pagination display
  * For <= 7 pages: Shows all numbers (1,2,3,4,5,6,7)
  * For > 7 pages: Shows current page ±3 with ellipsis
  */
  generatePagesArray(): void {
    const pages: Array<number | string> = [];
    const showEllipsis = this.totalPages > 8;
    
    if (!showEllipsis) {
      // Show all page numbers
      for (let i = 1; i <= this.totalPages; i++) {
        pages.push(i);
      }
    } else {
      // Show first page
      pages.push(1);
      
      // Add ellipsis if current page > 3
      if (this.currentPage > 3) {
        pages.push('...');
      }
  
      // Add pages around current page
      for(let i = Math.max(2, this.currentPage - 1); 
          i <= Math.min(this.totalPages - 1, this.currentPage + 3); 
          i++) {
        pages.push(i);
      }
    }
    this.pagesArray = pages;
  }

  /**
  * Navigates to specified page if valid
  * Updates URL and refreshes data
  */
  async goToPage(page: number): Promise<void> {
    if (page === this.currentPage || page < 1 || page > this.totalPages) {
      return;
    }
    
    this.currentPage = page;
    this.filter.skip = (page - 1) * this.itemsPerPage;
    
    // Update URL without reloading
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { page },
      queryParamsHandling: 'merge',
      replaceUrl: true
    });
  
    // Show loading state while fetching
    this.loading = false;
    
    try {
      const filters = this.getFilters();
      const response = await this.aptCtrl.getApartments(filters);
      
      // Update apartments array with new page data
      this.apartments = response.results || response;
      
      // Handle available later apartments
      this.availableLaterApartments = this.apartments.filter(apartment => apartment.availableLater === true);
      this.apartments = this.apartments.filter(apartment => !apartment.availableLater);
      
      // Update availability and refresh pages array
      await this.setAvailability();
      this.generatePagesArray();
      
      // Smooth scroll to top of results
      if (this.wrapperContainer) {
        this.wrapperContainer.nativeElement.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }
    } catch (error) {
      console.error('Error fetching page data:', error);
    } finally {
      this.loading = false;
    }
  }
  

  /** Returns if given page is current page */
  isCurrentPage(page: number | string): boolean {
    return page === this.currentPage;
  }

  /** Type guard to check if value is number */
  isNumber(value: number | string): boolean {
    return typeof value === 'number';
  }

async getTotalCityListings(): Promise<void> {
    try {
      const filters = this.getFilters();
      const filterString = `${filters}&format=count`;
      const response = await this.aptCtrl.getApartments(filterString);
      this.totalListings = response;
    } catch (error) {
      console.error('Error fetching total listings:', error);
      this.totalListings = 0;
    }
  }

  over(apartment : Apartment) {
    apartment.isHovered = true;
    console.log("Mouseover triggered");
    this.apartmentService.notifyHoverState(apartment._id, true);
  }

  out(apartment : Apartment) {
    apartment.isHovered = false;
    console.log("Mouseout triggered");
    this.apartmentService.notifyHoverState(apartment._id, false);
  }

  async setAvailability() {
    for (let apartment of this.apartments) {
      try {
        if (apartment.nextAvailability && typeof apartment.nextAvailability === 'string') {
          apartment.nextAvailability = moment(apartment.nextAvailability.split('T')[0]).toDate();
        }
      } catch (e) {
        apartment.nextAvailability = null;
      }
    }
    for (let apartment of this.availableLaterApartments) {
      try {
        if (apartment.nextAvailability && typeof apartment.nextAvailability === 'string') {
          apartment.nextAvailability = moment(apartment.nextAvailability.split('T')[0]).toDate();
        }
      } catch (e) {
        apartment.nextAvailability = null;
      }
    }
  }

  formatAvailableFromDate(availableFromDate: Date) {
    if (!availableFromDate) {
      availableFromDate = new Date();
    }
    if (typeof availableFromDate === 'string') {
      availableFromDate = new Date(availableFromDate);
    }

    if (typeof(availableFromDate) === 'number') {
      availableFromDate = new Date(availableFromDate);
    }

    let cultureLang = this.translate.getBrowserCultureLang();
    if (!cultureLang.includes(this.translate.currentLang)) {
      cultureLang = this.translate.currentLang == "en" ? "en-US" : "fr-FR";
    }

    // Display year if the year is not the current year
    if (availableFromDate.getFullYear() !== new Date().getFullYear()) {
      return availableFromDate.toLocaleDateString(cultureLang, { day:"numeric", month:"short", year:"numeric" });
    }
    else {
        return availableFromDate.toLocaleDateString(cultureLang, { day:"numeric", month:"short" });
    }
  }

  ngOnDestroy() {
    this.distroyResources.next(true);
    this.distroyResources.complete();
    this.utils.executeBrowserOnly(() => {
      this.utils.showOverlay();
    });
    window.removeEventListener('resize', this.checkMobile.bind(this));
  }

  scrollToContainer() {
    this.wrapperContainer.nativeElement.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }

  isMobileUA() {
    // Credits: http://detectmobilebrowsers.com/
    let check = false;
    (function (a) {
      if (
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      )
        check = true;
    })(navigator.userAgent || navigator.vendor || (window as any).opera);
    return check;
  }

  recordNavInfo() {
    localStorage.setItem('selectedCity', this.selectedCity);
  }

  plurialBedroom(roomsAvailableCount: number, text: string) {
    if (roomsAvailableCount > 1 && this.lang === 'en') {
      return text + "s";
    } else {
      return text;
    }
  }

  getRoomsCount(rooms: any) {
    let count = 0;
    for (let room of rooms) {
      if (!room.isHidden) {
        count++;
      }
    }
    return count;
  }

  openMoveInDateChange(value: boolean) {
    if (value) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }

  resetMoveInDateChange() {
    this.expectedMoveInDate = null;
    this.currentPage = 1;
    this.filter.skip = 0;
    this.apartmentService.moveInDateNotifyOther(null);
    this.moveInDate = null;
    this.datepickerValue = new Date();
    document.body.classList.remove('modal-open');
  }

  setMoveInDate(value) {
    this.currentPage = 1;
    this.filter.skip = 0;
    this.datepickerValue = value;
  }

  applyMoveInDate() {
    this.expectedMoveInDate = moment(this.datepickerValue);
    this.moveInDate = moment(this.expectedMoveInDate).subtract(2, 'w').format('YYYY-MM-DD');
    // @ts-ignore
    this.apartmentService.moveInDateNotifyOther(this.expectedMoveInDate);
  }

  formatDate(date) {
    return this.utils.formatLocaleDateString(date, this.lang);
  }

  // --------------------------------------------------------------------------------- //
  //                                     Matching                                      //
  // --------------------------------------------------------------------------------- //

  applyLocalFilters(apartment: any): boolean {
    // Match level filtering
    if (this.localFilters.matchOnly)
      if (apartment.matchLevel === 'neutral' && apartment.neighborhoodMatchLevel === 'neutral')
        return false;
    return true;
  }

  isMatch(apartment): boolean {
    return (this.loggedUser && this.loggedUser.aboutme && this.loggedUser.keywords && this.loggedUser.keywords.length > 0) &&
           (apartment.matchLevel               || apartment.neighborhoodMatchLevel              ) &&
           (apartment.matchLevel !== 'neutral' || apartment.neighborhoodMatchLevel !== 'neutral')
  }

  async updateLoggedUser() {
      const loggedUser = await this.utils.getLoggedUser();
      if (!loggedUser) {
        this.loggedUser = undefined;
        return;
      }
      this.loggedUser = await this.userCtrl.getUser(loggedUser.id);
  }
}
