import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { Utils } from "../../../utils/utils";
import { Location } from '@angular/common';
import { UserController } from "app/controllers/user.controller";
import { ActivatedRoute } from "@angular/router";
import { UserUtils } from 'app/utils/user';

@Component({
  selector: "shareSpace",
  templateUrl: "./shareSpace.component.html",
  styleUrls: ["./shareSpace.component.css"]
})
export class ShareSpaceComponent {
  constructor(
    private router: Router,
    private utils: Utils,
    private _location: Location,
    private userCtrl: UserController,
    private activatedRoute: ActivatedRoute,
    private userUtil: UserUtils,
  ) {}

  lang: string;
  ngOnInit() {
    this.utils.showOverlay();
    this.lang = this.utils.getCurrentLang();
    this.utils.executeBrowserOnly(async () => {
      if ('token' in this.activatedRoute.snapshot.queryParams) {
        const token = this.activatedRoute.snapshot.queryParams.token;
        localStorage.setItem('token', token);
        this.utils.storeLoginToken(token);
      }

      // Check if current user is admin, as only admin can create listings
      try {
        const editingListing = this.activatedRoute.snapshot.queryParams.edit;
        const owner = this.activatedRoute.snapshot.queryParams.owner;

        const result = await this.userCtrl.isUserAdminOrOwner(editingListing, owner);
        if (!result || !result.isAdmin) {
          this.router.navigate([`/${this.lang}/host`]).then(() => {
            window.scrollTo(0, 0);
            this.utils.hideOverlay();
          });
        }
        else {
          this.userUtil.setUserLoginCookies({_id: result.userId});
          this.utils.hideOverlay();
        }
      } catch (error) {
        this.router.navigate([`/${this.lang}/host`]).then(() => {
          window.scrollTo(0, 0);
          this.utils.hideOverlay();
        });
      }
    });
  }

  onNavigate(location) {
    if (location === "entire-place") {
      localStorage.setItem("isWholeApartment", "true");
    }
    else if (location === 'private-room') {
      localStorage.setItem("isWholeApartment", "false");
    }
    this.router.navigate([`/${this.lang}/share/apartment`]);
  }
  goBack(){
   this._location.back();
  }
}
