import { Component, ContentChildren, Directive, Input, QueryList, ElementRef, Output, EventEmitter } from "@angular/core";

@Directive({selector: "myOption"})
export class MyOptionComponent {
  @Input() value!: any;
  @Input() selected!: boolean;
  @Input() all: boolean = false;      // only for checkboxes, this option is selected if all options are selected
  @Input() checked: boolean = false;  // only for checkboxes

  elRef: ElementRef

  constructor(elRef: ElementRef) {
    this.elRef = elRef;
  }

  getText() {
    return this.elRef.nativeElement.innerText;
  }
}

@Component({
  selector: "myDropdown",
  templateUrl: "./Dropdown.component.html",
  styleUrls: ["./Dropdown.component.css"]
})
export class DropdownComponent {
  @ContentChildren(MyOptionComponent) allOptions!: QueryList<MyOptionComponent>
  @Input() ngModel: any = undefined;
  @Input() fontBold: boolean = false;
  @Input() checkboxes: boolean = false;
  @Input() numberOfItemsText: string = 'Items';
  @Input() customClass: string = "";
  @Input() placeholder: string = "";
  @Output() change = new EventEmitter<any>(); 
  
  allOptionsArr: Array<MyOptionComponent> = undefined
  droppedDown: boolean = false;
  selectedIndex: number = -1;
  highlightedIndex: number = -1;

  getOptionsText() {
    return this.allOptions ? this.allOptions.map(item => item.getText()) : []
  }

  getSelectedIndex() {
    if (!this.allOptionsArr)
      this.allOptionsArr = this.allOptions.toArray();
    
    for (let i = 0; i < this.allOptionsArr.length; i++) {
      if (this.allOptionsArr[i].selected) {
        this.selectedIndex = i;
        break;
      }
    }

    return this.selectedIndex;
  }

  setSelectedIndex(newIndex: number) {
    if (!this.checkboxes) {
      this.ngModel = this.allOptionsArr[newIndex].value;
      this.change.emit({ "target": { "value": this.allOptionsArr[newIndex].value }});
      this.selectedIndex = newIndex;
      this.droppedDown = false;
    }
    else {
      this.allOptions.toArray()[newIndex].checked = !this.allOptions.toArray()[newIndex].checked;
      if (this.allOptions.toArray()[newIndex].all) {
        const checked = this.allOptions.toArray()[newIndex].checked;
        this.allOptions.toArray().forEach(item => item.checked = checked);
      }
      else {
        const allChecked = this.allOptions.toArray().every(item => item.all || item.checked);
        this.allOptions.toArray().forEach(item => { if (item.all) { item.checked = allChecked; } });
      }
      const items = this.allOptions.filter(item => item.checked).map(item => item.value);
      this.ngModel = items;
      this.change.emit({ "target": { "value": items }});
    }
  }

  getSelectedItemText() {
    if (!this.checkboxes) {
      let index = this.getSelectedIndex();
      if (this.ngModel !== undefined) {
        for (let i = 0; i < this.allOptionsArr.length; i++) {
          if (this.allOptionsArr[i].value === String(this.ngModel)) {
            return this.allOptionsArr[i].getText();
          }
        }
      }
      return index >= 0 ? this.allOptionsArr[index].getText() : this.placeholder;
    }
    else {
      if (!this.allOptionsArr) {
        this.allOptionsArr = this.allOptions.toArray();
      }
      const allItem = this.allOptions.find(item => item.all);
      if (allItem && allItem.checked) {
        return allItem.getText();
      }
      const checkedItems = this.allOptions.filter(item => item.checked);
      if (checkedItems.length === 0 && this.allOptionsArr.length > 0) {
        if (this.numberOfItemsText == 'neighborhoods' || this.numberOfItemsText == 'quartiers') {
          return 'All neighborhoods'
        } else {
          return this.allOptionsArr[0].getText();
        }
      }
      else if (checkedItems.length === 1) {
        return checkedItems[0].getText();
      } else if (checkedItems.length > 1 && checkedItems.length !== this.allOptionsArr.length) {
        return checkedItems.length + ' ' + this.numberOfItemsText;
      } else {
        if (this.numberOfItemsText == 'neighborhoods' || this.numberOfItemsText == 'quartiers') {
          return 'All neighborhoods';
        } else {
          return 'Error';
        }
        
      }
    }
  }

  getSelectedItemValue() {
    let index = this.getSelectedIndex();
    if (this.ngModel !== undefined) {
      return this.ngModel;
    }
    return index >= 0 ? this.allOptionsArr[index].value : undefined;
  }

  updateItems(allOptionsArr: Array<MyOptionComponent>) {
    this.allOptionsArr = allOptionsArr;
  }
}
