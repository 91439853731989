import {BrowserModule} from '@angular/platform-browser';
import {NgModule, LOCALE_ID} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {HttpModule} from '@angular/http';
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {AppComponent} from './modules/app.component';
import {AppModules} from './modules/app.modules';
import {FacebookModule} from 'ngx-facebook';
import {SocialLoginModule, AuthServiceConfig} from 'angularx-social-login';
import {GoogleLoginProvider, FacebookLoginProvider} from 'angularx-social-login';
import {environment} from 'environments/environment';
import {ServicesModule} from './modules/services/services.module';
import {UtilsModule} from './utils/utils.module';
import {ControllersModule} from './controllers/controllers.module';
import {Constants} from './utils/constants';
import {SelectDropDownModule} from 'ngx-select-dropdown'
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {NgxStripeModule} from 'ngx-stripe';


import {
    TranslateModule,
    TranslateService,
    TranslateLoader,
} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

import {RoutingModule} from './routing.module';

import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';

registerLocaleData(localeFr);

export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const config = new AuthServiceConfig([
    {
        id: GoogleLoginProvider.PROVIDER_ID,
        provider: new GoogleLoginProvider(environment.googleClientId)
    }
]);

export function provideConfig() {
    return config;
}

@NgModule({
    imports: [
        BrowserModule.withServerTransition({appId: 'universal'}),
        NgxStripeModule.forRoot(environment.stripePublishableKey),
        FormsModule,
        HttpModule,
        FacebookModule.forRoot(),
        SocialLoginModule,
        RoutingModule,
        AppModules,
        UtilsModule,
        ControllersModule,
        SelectDropDownModule,
        ServicesModule,
        HttpClientModule,
        AccordionModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient],
            },
        }),
    ],
    exports: [ControllersModule],
    providers: [
        Constants,
        {
            provide: LOCALE_ID,
            deps: [TranslateService],
            useFactory: localFactory,
        },
        {
            provide: AuthServiceConfig,
            useFactory: provideConfig
        }
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
}

export function localFactory(translater: TranslateService) {
    return translater.currentLang;
}
