import { Component } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Utils } from "../../../utils/utils";

@Component({
  selector: "shareApartment",
  templateUrl: "./shareApartment.component.html",
  providers: []
})
export class ShareApartmentComponent {
  constructor(private utils: Utils, private router: Router, private activatedRoute: ActivatedRoute) {
    const { step, edit } = this.activatedRoute.snapshot.queryParams;
    this.router.navigate([this.utils.getCurrentLang() + "/share/apartment"], { queryParams: { step: step, edit: edit } });
  }

  ngOnInit() {
    this.utils.setSeoMeta();
  }
}
