import {
  Component,
  ElementRef,
  Input,
  ViewChild,
  Renderer2
} from "@angular/core";
import { NgForm } from "@angular/forms";
import { Cookie } from "ng2-cookies";
import { Profile } from "../../../models/profile";
import { Router } from "@angular/router";
import { Utils } from "../../../utils/utils";

import { ConditionsObject } from "../../../models/conditionsobject";

import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { defineLocale } from "ngx-bootstrap/chronos";
import { frLocale } from "ngx-bootstrap/locale";

import {
  UserController,
  GeneralController
} from "../../../controllers/controllers.module";

@Component({
  selector: "createShareProfileForm",
  templateUrl: "./createShareProfileForm.component.html",
  styleUrls: ["./createShareProfileForm.component.css"]
})
export class CreateShareProfileFormComponent {
  step: number = 0;
  validSteps: any = [];
  viewedSteps: number = 0;
  formSubmitted: boolean = false;
  profile: Profile;
  accomodations: any = [];
  accomodationsCriterias: any = [];
  facilities: any = [];
  modals: any = {};
  conditions: ConditionsObject = this.genCtrl.getNewConditionsObject();
  subscriptions: any = {};
  loggedUser: any;
  mask = [
    "(",
    /[1-9]/,
    /\d/,
    /\d/,
    ")",
    " ",
    /\d/,
    /\d/,
    /\d/,
    "-",
    /\d/,
    /\d/,
    /\d/,
    /\d/
  ];
  id: any;
  hideIntro: boolean = false;
  duration: any = {};
  imageHost: string = this.utils.getEnvironmentValue("amazonS3Url");
  @ViewChild("profileForm") public profileForm: NgForm;

  @Input() public verifyUser: boolean;
  @Input() public isEdit: boolean;

  constructor(
    private router: Router,
    private utils: Utils,
    private el: ElementRef,
    private renderer: Renderer2,
    private localeService: BsLocaleService,
    private genCtrl: GeneralController,
    private userCtrl: UserController
  ) {
    this.accomodations = utils.getAccomodations();
    this.accomodationsCriterias = utils.getAccomodationsCriterias();
    this.facilities = utils.getFacilities();
    this.profile = new Profile();

    this.subscriptions.login = this.userCtrl
      .getSubscribeLogin(true)
      .subscribe((res) => {
        this.validateUser();
      });

    this.subscriptions.modal = this.userCtrl
      .getSubscribeLoginModal(true)
      .subscribe((res) => {
        this.validateUser();
        this.onOpenResumeModal();
      });

    this.subscriptions.logout = this.userCtrl
      .getSubscribeLogout(true)
      .subscribe((res) => {
        this.conditions.isLogged = false;
        this.loggedUser = null;
      });
  }

  ngOnInit() {
    this.utils.executeBrowserOnly(() => {
      if (this.utils.getCurrentLang() != "en") {
        defineLocale(this.utils.getCurrentLang(), frLocale);
        this.localeService.use(this.utils.getCurrentLang());
      }

      this.resetAccomodations();
      this.resetFacilities();

      this.hideIntro = Cookie.get("hideRoommateIntroMsg") ? true : false;

      window.scrollTo(0, 0);
      this.validateUser();

      this.utils.hideOverlay();

      this.validSteps = [undefined, undefined, undefined, undefined];
    });
  }

  onDateChange(duration: Date) {
    if (duration) {
      this.duration = duration;

      this.profile.durationFrom = duration[0];
      this.profile.durationTo = duration[1];
    } else {
      this.profile.durationFrom = null;
      this.profile.durationTo = null;
    }
  }

  validateUser() {
    let loggedUser = this.utils.getLoggedUser();
    if (loggedUser) {
      this.conditions.isLogged = true;
      this.loggedUser = loggedUser;
      if (loggedUser.isBlocked) {
        this.router.navigate([this.utils.getCurrentLang() + "/notfound"]);
      }

      if (loggedUser.first_name && loggedUser.last_name) {
        this.profile.contact.name =
          loggedUser.first_name + " " + loggedUser.last_name;
      }
    }
  }

  hideIntroMsg() {
    Cookie.set("hideRoommateIntroMsg", "true", 365, "/");
    this.hideIntro = true;
  }

  ngAfterViewInit() {
    this.utils.executeBrowserOnly(() => {
      this.utils.setTooltips();
      this.utils.setToggle();
      this.modals.login = this.el.nativeElement.querySelector(
        "div.modal-login"
      );
      this.modals.addUser = this.el.nativeElement.querySelector(
        "div.modal-assign-user"
      );
      this.modals.resume = this.el.nativeElement.querySelector(
        "div.modal-share-profile-resume"
      );
    });
  }

  onlyNumberKey(event) {
    return event.charCode == 8 || event.charCode == 0
      ? null
      : event.charCode >= 48 && event.charCode <= 57;
  }

  showModal(modal) {
    this.genCtrl.showModal(this.renderer, modal);
  }

  onOpenAddRoomateModal() {
    this.showModal(this.modals.addUser);
  }

  onOpenLoginModal() {
    this.showModal(this.modals.login);
  }

  async onOpenResumeModal() {
    const success = await this.validateForm(this.profileForm);

    if (!success) {
      this.conditions.hasError = true;
    }

    this.validSteps[this.step] = success;

    if (this.stepsValid()) {
      this.formSubmitted = false;
      this.setAccomodations();
      this.setFacilities();
      this.showModal(this.modals.resume);
    } else {
      this.formSubmitted = true;
      window.scrollTo(0, 0);
    }
  }

  removeRoommate(roommate) {
    this.profile.roommates.splice(this.profile.roommates.indexOf(roommate), 1);
  }

  resetAccomodations() {
    for (let accomodation of this.accomodations) {
      accomodation.isSelected = false;
    }

    for (let accomodationCriteria of this.accomodationsCriterias) {
      accomodationCriteria.isSelected = false;
    }
  }

  setAccomodations() {
    this.profile.accomodations = [];
    for (let accomodation of this.accomodations) {
      if (accomodation.isSelected) {
        this.profile.accomodations.push({ id: accomodation.id });
      }
    }

    for (let accomodationCriteria of this.accomodationsCriterias) {
      if (accomodationCriteria.isSelected) {
        this.profile.accomodations.push({ id: accomodationCriteria.id });
      }
    }
  }

  resetFacilities() {
    for (let facility of this.facilities) {
      facility.isSelected = false;
    }
  }

  setFacilities() {
    this.profile.facilities = [];
    for (let facility of this.facilities) {
      if (facility.isSelected) {
        this.profile.facilities.push({ id: facility.id });
      }
    }
  }

  ngOnDestroy() {
    this.utils.executeBrowserOnly(() => {
      this.utils.showOverlay();
      [
        this.subscriptions.logout,
        this.subscriptions.login,
        this.subscriptions.modal
      ].map((obs) => this.utils.unsubscribeObservable(obs));
    });
  }

  validateForm(form) {
    return new Promise(function(resolve, reject) {
      if (form.valid) {
        resolve(true);
      } else {
        for (var i in form.controls) {
          form.controls[i].markAsTouched();
        }
        window.scrollTo(0, 0);
        resolve(false);
      }
    });
  }

  async nextStep() {
    if (this.viewedSteps == this.step) {
      this.viewedSteps++;
    }

    let currentStep = this.step;
    this.setStep(++currentStep);
    this.conditions.hasError = false;
    window.scrollTo(0, 0);
  }

  async previousStep() {
    let currentStep = this.step;
    this.setStep(--currentStep);
    this.conditions.hasError = false;
    window.scrollTo(0, 0);
  }

  async setStep(step) {
    if (this.viewedSteps < step && step >= this.step) {
      return false;
    }

    if (step >= 0 && step < 5 && step != this.step) {
      const success = await this.validateForm(this.profileForm);
      this.validSteps[this.step] = success;
      this.step = step;
      this.initComponents();
    }
  }
  initComponents() {
    this.utils.setTooltips();
    this.utils.setToggle();
  }

  stepsValid() {
    let isValid = true;
    for (let i = 0; i < this.validSteps.length; i++) {
      if (!this.validSteps[i]) {
        isValid = false;
      }
    }

    return isValid;
  }
}
