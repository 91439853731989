import { Component, ViewChild, ElementRef, Renderer2, HostListener } from '@angular/core';
import { Utils } from "app/utils/utils";
import { Http } from "@angular/http";
import { ImageCropperComponent, CropperSettings } from "ng2-img-cropper";
import { GeneralController, UserController } from "app/controllers/controllers.module";
import {ActivatedRoute, Router} from '@angular/router';
import { Cookie } from "ng2-cookies";

@Component({
  selector: 'onboarding',
  templateUrl: './onboarding.component.html',
  styleUrls: ['./onboarding.component.css']
})
export class OnboardingComponent {
	constructor(
    public utils: Utils,
    private http: Http,
    private genCtrl: GeneralController,
    private el: ElementRef,
    private renderer: Renderer2,
    private userCtrl: UserController,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ){
    this.maxDate = new Date();
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
    this.utils.executeBrowserOnly(async () => {
      // For profile picture
      this.cropperSettings = new CropperSettings();
      this.cropperSettings.minWithRelativeToResolution = true;
      this.cropperSettings.width = 200;
      this.cropperSettings.height = 200;

      let innerWidthMinusPadding = 320 - 40;
      this.cropperSettings.croppedWidth = innerWidthMinusPadding;
      this.cropperSettings.croppedHeight = innerWidthMinusPadding;
      this.cropperSettings.canvasWidth = innerWidthMinusPadding;
      this.cropperSettings.canvasHeight = innerWidthMinusPadding;
      this.cropperSettings.noFileInput = true;
    });
  }

  // General
  lang: string;
  step: number = 0;
  isGuest: boolean = false;
  user: any;

  // ==========================================================================
  // For phone number verification and birthdate
  countryCodes: any = [
    { "name": "Canada", "dial_code": "+1", "code": "CA" },
    { "name": "United States", "dial_code": "+1", "code": "US"}
  ];
  selectedCountry: number = 0;
  verificationPhoneNumber: string;
  alreadyHasPhoneNumber: boolean = false;
  birthDate: string;
  skipDOB: boolean = false;
  verificationPhoneValid: boolean = false;
  // ==========================================================================

  // ==========================================================================
  // For profile picture
  @ViewChild("cropper", undefined) public cropper: ImageCropperComponent;

  cropperSettings: CropperSettings;
  isUploaded: boolean = false;
  imageUploaded: any = {};     // The content of the uploaded profile picture (not boolean)
  profilePictureUploaded: boolean = false;
  cropModal: any;
  newPrfilePicture: boolean = false;
  maxDate: Date;
  // ==========================================================================

	ngOnInit() { 
    this.utils.executeBrowserOnly(async () => {
      this.lang = this.utils.getCurrentLang();

      // Check if user is logged in
      if (Cookie.get("user")) {
          const storedUser = JSON.parse(Cookie.get("user"));
          try {
            const user = await this.userCtrl.verifyUser(storedUser.id);

            if (!user) {
              this.utils.resetUser();
            } else {
              this.utils.setLoggedUser(user);
              this.userCtrl.getLoginService().loginCompletedNotifyOther();
            }
          } catch (err) {
            this.router.navigate(['/' + this.lang + '/login'], { queryParams: { redirect: this.router.url } });
            this.utils.resetUser();
            return;
          }
      }
      if (!this.utils.getLoggedUser()) {
        this.router.navigate(['/' + this.lang + '/login'], { queryParams: { redirect: this.router.url } });
        return;
      }
      this.user = await this.userCtrl.getUser(this.utils.getLoggedUser().id);
      let user = this.user;
      if (!user) {
        this.router.navigate(['/' + this.lang + '/login'], { queryParams: { redirect: this.router.url } });
        return;
      }

      // Check if the user has already completed the onboarding process
      if (user.onboarding_completed) {
        this.router.navigate(['/' + this.lang]);
        return;
      }
      else {
        // Update the onboarding_skipped flag, to indicate that the user has visited the onboarding process
        user.onboarding_skipped = true;
        this.userCtrl.updateUser(this.utils.getLoggedUser().id, JSON.parse(JSON.stringify(user)));
      }

      // Jump to previous step
      const { step } = this.activatedRoute.snapshot.queryParams;
      if (step) {
        this.step = parseInt(step);
      }

      this.utils.hideOverlay();
      
      // ==========================================================================
      // For phone number verification
      this.getCountryCodes();
      this.getPhoneVerifyStatus();

      // ==========================================================================
      // For profile picture uploading
      this.profilePictureUploaded = false;
      if (user.customImage) {
        this.imageUploaded = { image: this.utils.getEnvironmentValue("amazonS3Url") + 'user/' + user.customImage };
        this.profilePictureUploaded = true;
      }
    });
  }

  ngOnDestroy(){
    this.utils.executeBrowserOnly(() => {
      this.utils.showOverlay();
    });
  }

  // ==========================================================================
  // General
  

  async completeOnboarding(uploadPic = true) {
    if (uploadPic && this.newPrfilePicture) {
      await this.saveProfilePicture();
    }
    let loggedUser = this.utils.getLoggedUser();
    let user = await this.userCtrl.getUser(loggedUser.id);
    user.onboarding_completed = true;
    user.onboarding_skipped = false;
    this.userCtrl.updateUser(loggedUser.id, JSON.parse(JSON.stringify(user)));
    this.updateStep(4);
  }

  // ==========================================================================
  // For phone number verification and DOB
  async getCountryCodes() {
      this.countryCodes = await this.http.get('assets/i18n/country_codes.json').toPromise().then((response) => {
          return response.json();
      }
      ).catch((err) => {
          console.log(err);
      });

  }

  matchCountryCodeByPhone() {
    let strMatch = '';

    if (this.verificationPhoneNumber.charAt(0) != '+') {
        strMatch = '+';
    }

    for (let i = 0; i < Math.min(this.verificationPhoneNumber.length, 4); ++i) {
        strMatch += this.verificationPhoneNumber.charAt(i);
        for (let j = 0; j < this.countryCodes.length; ++j) {
            if (this.countryCodes[j].dial_code == strMatch) {
                this.selectedCountry = j;
                this.verificationPhoneNumber = this.verificationPhoneNumber.substr(i + 1);
                return;
            }
        }
    }
  }

  validatePhoneNumber() {
    this.verificationPhoneValid =
      this.verificationPhoneNumber != undefined &&
      this.verificationPhoneNumber.length > 3 &&
      /\+?[0-9]+$/.test(this.verificationPhoneNumber);
    return this.verificationPhoneValid;
  }

  async savePhoneNumber() {
    let loggedUser = this.utils.getLoggedUser();
    let user = await this.userCtrl.getUser(loggedUser.id);

    if (this.verificationPhoneValid) {
      const numberToCheck = this.countryCodes[this.selectedCountry].dial_code + this.verificationPhoneNumber;
      try {
        const res = await this.userCtrl.checkPhoneNumber(this.countryCodes[this.selectedCountry].code, numberToCheck);
        if (res.success) {
          user.unverified_phone = res.phone;

          // Also save birth date
          user.birthDate = this.birthDate;

          this.userCtrl.updateUser(loggedUser.id, JSON.parse(JSON.stringify(user)));

          this.updateStep(3);
        }
        else {
          this.genCtrl.triggerAlertError(this.utils.getTranslator().instant('Please provide a valid phone number.'));
        }
      } catch (err) {
        this.genCtrl.triggerAlertError(this.utils.getTranslator().instant('Please provide a valid phone number.'));
      }
    }
  }

  getPhoneVerifyStatus() {
    this.userCtrl.isPhoneVerified()
      .then(async (res) => {
          if (res.phone && res.verified && res.verified === true) {
              this.verificationPhoneNumber = res.phone;
              this.alreadyHasPhoneNumber = true;
          } else {
              const loggedUser = this.utils.getLoggedUser();
              const user = await this.userCtrl.getUser(loggedUser.id);
              this.verificationPhoneNumber = user.unverified_phone;
              if (this.verificationPhoneNumber) {
                this.alreadyHasPhoneNumber = true;
              }
          }
          this.matchCountryCodeByPhone();
          this.validatePhoneNumber();
      })
      .catch((err) => {
          console.log(err);
      })
  }

  handleSkipDOBchange(event) {
    this.skipDOB = event.target.checked;
  }

  // ==========================================================================
  // For profile picture uploading
  triggerUpload() {
    document.getElementById('uploader-input').click();
  }

  async fileChangeListener($event) {
    var image: any = new Image();
    var file: File = $event.target.files[0];
    var myReader: FileReader = new FileReader();
    this.isUploaded = true;

    let fileTypeSplit = file.type.toLowerCase().split('/');
    if (fileTypeSplit[0] !== 'image'){
      return;
    }

    this.utils.showOverlay();
    if (fileTypeSplit[1] == 'heif' || fileTypeSplit[1] == 'heic') {
      // Convert HEIC to JPEG
      try {
        const heic2any = require("heic2any");
        let newFile : any = await heic2any({
          blob: file,
          toType: "image/jpeg",
          quality: 1
        });
        file = newFile;
      } catch (e) {
        this.utils.hideOverlay();
        return;
      }
    }

    myReader.onloadend = function (loadEvent: any) {
      image.src = loadEvent.target.result;
      image.onerror = () => {
        // Show error if the image fails to load
        this.genCtrl.triggerAlertError(this.utils.getTranslator().instant('InvalidImgErrMsg'));
        this.onCloseCropImageModal();
        this.utils.hideOverlay();
        return;
      };
      image.onload = () => {
        var canvas = document.createElement("canvas");
        let canvasWidth = image.width;
        let canvasHeight = image.height;
        if (image.width > 1000 || image.height > 1000) {
          var max = Math.max(image.width, image.height);
          var scale = 1000 / max;
          canvasWidth = image.width * scale;
          canvasHeight = image.height * scale;
        }
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(image, 0, 0, canvasWidth, canvasHeight);
        var dataURL = canvas.toDataURL(file.type);
        image.src = dataURL;
        image.onload = undefined;
        this.cropper.setImage(image);
        canvas.remove();
        this.utils.hideOverlay();
      };
      image.src = loadEvent.target.result;
    }.bind(this);

    myReader.readAsDataURL(file);
    this.onOpenCropImageModal();
  }

  onCloseCropImageModal() {
    this.genCtrl.hideModal(this.renderer, this.cropModal);
  }

  onOpenCropImageModal() {
    this.cropModal = this.genCtrl.getModal(
      this.el,
      this.renderer,
      "div.cropImage",
      true
    );
  }

  onCancelCrop() {
    this.isUploaded = false;
    this.onCloseCropImageModal();
  }

  onApplyCrop() {
    this.isUploaded = false;
    this.profilePictureUploaded = true;
    this.newPrfilePicture = true;
    this.onCloseCropImageModal();
  }

  async saveProfilePicture() {
    let loggedUser = this.utils.getLoggedUser();
    let user = await this.userCtrl.getUser(loggedUser.id);
    user.customImage = this.imageUploaded.image;
    this.user.customImage = this.imageUploaded.image;
    this.utils.setLoggedUserImage(this.imageUploaded.image);
    this.userCtrl.getUpdateUserService().updateNotifyOther();
    this.userCtrl.updateUser(loggedUser.id, JSON.parse(JSON.stringify(user)));
  }

  updateStep(step) {
    this.step = step;

    // Update the URL query
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { step: step },
      queryParamsHandling: 'merge'
    });
    this.utils.goToTop();
  }

  handlePhoneNumberInputChange() {
    this.verificationPhoneNumber = this.verificationPhoneNumber.trim();
    this.validatePhoneNumber();
    this.alreadyHasPhoneNumber = false
  }
}
