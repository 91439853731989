import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxStripeModule} from 'ngx-stripe';
import {PaymentComponent} from './payment/payment.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ConnectionModule} from '../connection/connection.module';
import {RentRequestModule} from '../rent-request/rent-request.module';
import {NgxMaskModule} from 'ngx-mask';
import {TextMaskModule} from 'angular2-text-mask';

export {
    PaymentComponent
};

@NgModule({
    declarations: [PaymentComponent],
    imports: [
        CommonModule,
        NgxStripeModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        ConnectionModule,
        RentRequestModule,
        NgxMaskModule,
        TextMaskModule
    ],
    exports: [
        PaymentComponent
    ]
})
export class PaymentModule {
}
