import {NgModule} from '@angular/core';
import {ConnectionModule} from '../connection/connection.module';
import {CommonModule} from '@angular/common';
import {RentRequestDetailsComponent} from './rent-request-details/rent-request-details.component';
import {ApplicationSummaryComponent} from './application-summary/application-summary.component';
import {FormsModule} from '@angular/forms';
import {FileUploadModule} from 'ng2-file-upload';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {AccordionModule} from 'ngx-bootstrap/accordion';
import {TextMaskModule} from 'angular2-text-mask';
import {SelectDropDownModule} from 'ngx-select-dropdown';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {AvatarModule} from 'ngx-avatar';
import { GeneralModule } from '../general/general.module';

export {
    RentRequestDetailsComponent,
    ApplicationSummaryComponent,
};

@NgModule({
    declarations: [RentRequestDetailsComponent, ApplicationSummaryComponent],
    imports: [
        CommonModule,
        FileUploadModule,
        RouterModule,
        TranslateModule,
        ConnectionModule,
        AccordionModule.forRoot(),
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        TextMaskModule,
        ConnectionModule,
        SelectDropDownModule,
        GeneralModule,
        BsDatepickerModule.forRoot(),
        AvatarModule
    ],
    exports: []
})
export class RentRequestModule {
}
