import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { Cookie } from 'ng2-cookies';

@Injectable()
export class LangRouteGuard implements CanActivate {
  constructor(private _router: Router) {}

  canActivate() {
    let lang = Cookie.check('lang') ? Cookie.get('lang') : 'fr';
    this._router.navigate(['/' + lang + '/']);
    return false;
  }
}
