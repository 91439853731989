import { Component, OnInit, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Utils } from "../../../utils/utils";
import { GeneralController, UserController, CityController } from '../../../controllers/controllers.module';
import { GeneralUtils } from '../../../utils/general';
import { Router } from "@angular/router";
import { Cookie } from "ng2-cookies";
import { DropdownComponent, MyOptionComponent } from '../../general/dropdown/Dropdown.component';

@Component({
  selector: 'app-pricing',
  templateUrl: './pricing.component.html',
  styleUrls: ['./pricing.component.css']
})
export class PricingComponent implements OnInit {

  constructor(
    private utils: Utils,
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer2,
    private genCtrl: GeneralController,
    private userCtrl: UserController,
    public cityCtrl: CityController,
    private genUtils: GeneralUtils,
  ) {
    (async () => {
      this.cities = await this.cityCtrl.getCities();
    })();
  }

  @ViewChild('cityDropdown') cityDropdown: DropdownComponent;

  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-sm',
    animated: false,
  };
  isCalendarOpen: boolean = false;
  lang: string = 'en';
  autocomplete: any = {};
  renewAutocomplete: boolean = false;
  cityToSearchFrom: string;
  cities: any = [];
  phoneMask = ['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  // Plan A form
  address: string = '';
  numberOfRooms: string = '';
  numberOfBathrooms: string = '';
  furnished: boolean = false;

  // Plan B form
  city: string = '';
  numberOfUnits: string = '';

  // Info for all plans
  name: string = '';
  email: string = '';
  phone: string = '';
  availableStartDate: Date = new Date();
  additionalDetails: string = '';

  ngAfterViewInit() {
    this.utils.executeBrowserOnly(() => {
      // Load Google Place Autocomplete
      let that = this;
      window['initMap'] = function () {
        setInterval(() => {
          let input = document.getElementById('addressGoogleCompletion');
          if (input) {
              if (that.renewAutocomplete) {
                let cityBounds = { "north" : 46.944288, "south" : 43.692446, "east" : -72.98018, "west" : -80.427204 };

                that.autocomplete = new window['google'].maps.places.Autocomplete(input, {
                  bounds: cityBounds,
                  componentRestrictions: {country: 'ca'},
                  strictBounds: true,
                  fields: ['address_components', 'geometry'],
                  types: ['address']
                });
                that.renewAutocomplete = false;
              }
          } else {
            that.renewAutocomplete = true;
          }
        }, 500);
      };

      let s = document.createElement('script');
      s.type = 'text/javascript';
      s.src = 'https://maps.googleapis.com/maps/api/js?key=' + this.utils.getEnvironmentValue('googleLocationAPIKey') + '&libraries=places&callback=initMap';
      this.el.nativeElement.appendChild(s);
    });
  }

  cityChanged(city) {
      this.setCityBound(city)
      if (this.cityToSearchFrom !== city) {
          this.cityToSearchFrom = city
      }
      this.updateDropdown();
  }

  updateDropdown() {
    if (!this.cityDropdown)
      return;
    let allOptionsArr: Array<MyOptionComponent> = [];
    for (const city of this.cities) {
      const newElem = document.createElement('div');
      newElem.innerHTML = this.genUtils.titlecase(city.name);
      let option = new MyOptionComponent(new ElementRef(newElem));
      option.value = city.name;
      if (this.cityToSearchFrom && (
        this.cityToSearchFrom.toLowerCase() === city.name.toLowerCase() || this.cityToSearchFrom.toLowerCase() === city.name_fr.toLowerCase() || this.cityToSearchFrom.toLowerCase() === city.value.toLowerCase()
      )) {
        option.selected = true;
      }
      allOptionsArr.push(option);
    }
    this.cityDropdown.updateItems(allOptionsArr);
  }

  setCityBound(city) {
    let cityBounds = this.getCityBounds(city);
    if (this.autocomplete.setBounds)
      this.autocomplete.setBounds(cityBounds)
  }

  getCityBounds(city) {
    return this.cities.find(c => c.name === city).cityBounds;
  }

  ngOnInit() {
    this.utils.hideOverlay();
    this.lang = Cookie.get("lang") == "en" ? "en" : "fr";
    setInterval(() => {
      this.updateDropdown();

      // Set the size of city dropdown
      const parent = document.getElementById('cityDropdown');
      const element = parent.getElementsByClassName('chosen-container')[0];
      if (element) {
        element.setAttribute('style', 'width: 30%');
      }
    }, 1000);
    setInterval(() => {
      let elem = document.getElementById('addressGoogleCompletion');
      if (!elem) return;
      let currAddr = elem['value'];
      this.address = currAddr;
    }, 200);
  }

  goBack() {
    this.router.navigate([`/${this.lang}/host`]);
  }

  getToday() {
    return new Date();
  }

  openForm(modal: any) {
    modal.show();
    this.renewAutocomplete = true;
  }

  emailValid(email) {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }

  async phoneValid(phone) {
    if (!phone) {
      return false;
    }

    try {
      const phoneToCheck = phone.startsWith('+') ? phone : `+1${phone}`;
      const res = await this.userCtrl.checkPhoneNumber('CA', phoneToCheck);
      if (res.success) {
          this.phone = res.phone;
      }
      else {
          return false;
      }
    } catch (err) {
      return false;
    }

    return true;
  }

  async validatePlanAform() {
    if (!this.address) {
      this.genCtrl.triggerAlertError(this.utils.getTranslator().instant('Please fill in all fields'));
      return false;
    }

    // Check email format
    if (!this.emailValid(this.email)) {
      this.genCtrl.triggerAlertError(this.utils.getTranslator().instant('You must provide a valid email address'));
      return false;
    }

    // Validate phone number
    const phoneValid = await this.phoneValid(this.phone);
    if (!phoneValid) {
      this.genCtrl.triggerAlertError(this.utils.getTranslator().instant('Please provide a valid Canadian phone number.'));
      return false;
    }

    return true;
  }

  async validatePlanBform() {
    if (!this.city || !this.numberOfUnits) {
      this.genCtrl.triggerAlertError(this.utils.getTranslator().instant('Please fill in all fields'));
      return false;
    }

    // Check email format
    if (!this.emailValid(this.email)) {
      this.genCtrl.triggerAlertError(this.utils.getTranslator().instant('You must provide a valid email address'));
      return false;
    }

    // Validate phone number
    const phoneValid = await this.phoneValid(this.phone);
    if (!phoneValid) {
      this.genCtrl.triggerAlertError(this.utils.getTranslator().instant('Please provide a valid Canadian phone number.'));
      return false;
    }

    return true;
  }

  async submitPlanAform(modal: any) {
    const formValid = await this.validatePlanAform();
    if (!formValid) {
      return;
    }

    const dateStr = this.availableStartDate.toISOString().split('T')[0];
    const data = {
      plan: 'A',
      name: this.name,
      email: this.email,
      phone: this.phone,
      address: this.address,
      numberOfRooms: this.numberOfRooms,
      numberOfBathrooms: this.numberOfBathrooms,
      furnished: this.furnished,
      availableStart: dateStr,
      additionalDetails: this.additionalDetails,
    };
    try {
      await this.userCtrl.submitPricingInquiry(data);
      const successModal = this.el.nativeElement.querySelector('div.modal-success');
      this.genCtrl.showModal(this.renderer, successModal);
      modal.hide();
    } catch (err) {
      this.genCtrl.triggerAlertError(this.utils.getTranslator().instant('Could not submit, please retry'));
    }
  }

  async submitPlanBform(modal: any) {
    const formValid = await this.validatePlanBform();
    if (!formValid) {
      return;
    }

    const dateStr = this.availableStartDate.toISOString().split('T')[0];
    const data = {
      plan: 'B',
      name: this.name,
      email: this.email,
      phone: this.phone,
      city: this.city,
      numberOfUnits: this.numberOfUnits,
      availableStart: dateStr,
      additionalDetails: this.additionalDetails,
    };
    try {
      await this.userCtrl.submitPricingInquiry(data);
      const successModal = this.el.nativeElement.querySelector('div.modal-success');
      this.genCtrl.showModal(this.renderer, successModal);
      modal.hide();
    } catch (err) {
      this.genCtrl.triggerAlertError(this.utils.getTranslator().instant('Could not submit, please retry'));
    }
  }

  onMsgboxClose() {
    
  }
}
