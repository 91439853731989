import { Injectable } from "@angular/core";
import { Subject } from "rxjs/Subject";
@Injectable()
export class AlertService {
  private alertSuccessNotify = new Subject<any>();
  alertSuccessNotifyObservable$ = this.alertSuccessNotify.asObservable();

  constructor() {}

  public alertSuccessNotifyOther(message) {
    this.alertSuccessNotify.next(message);
  }

  private alertErrorNotify = new Subject<any>();
  alertErrorNotifyObservable$ = this.alertErrorNotify.asObservable();

  public alertErrorNotifyOther(message) {
    this.alertErrorNotify.next(message);
  }
}
