import { Injectable } from '@angular/core'
import { Utils } from '../utils/utils'
import { Headers, Http, RequestOptions } from '@angular/http'
import {
  Answer,
  Answers,
  PendingFeedbackApiResponse,
  Question,
} from 'app/modules/general/feedback/feedback.component.type'

@Injectable()
export class FeedbackController {
  constructor(private http: Http, private utils: Utils) {}

  prepareHeaders(authRequest = true) {
    const headers = new Headers()
    headers.append('Content-Type', 'application/json')
    if (authRequest) {
      headers.append('authentication', this.utils.getLoggedUser().id)
    }
    return new RequestOptions({ headers: headers })
  }

  async fetchFeedbackQuestions(userStatus: string) {
    return this.http
      .get(
        `${this.utils.getEnvironmentValue(
          'apiUrl',
        )}feedback/form/${userStatus}`,
      )
      .toPromise()
  }

  async fetchPendingFeedback() {
    const options = this.prepareHeaders(true)
    return this.http
      .get(
        `${this.utils.getEnvironmentValue('apiUrl')}feedback/pending`,
        options,
      )
      .toPromise()
  }

  async fetchPendingFeedbackById(id: string) {
    const options = this.prepareHeaders(true)
    return this.http
      .get(
        `${this.utils.getEnvironmentValue('apiUrl')}feedback/pending/${id}`,
        options,
      )
      .toPromise()
  }

  async postFeedbackAnswers(
    data,
    questions: Question[],
    formId: string,
    pendingFeedbackResponse: PendingFeedbackApiResponse,
  ) {
    const options = this.prepareHeaders(true)
    const answers: Answer[] = []

    // the admin answer object has always the question title in english
    questions.forEach((question) => {
      answers.push({
        questionId: question._id,
        questionTitle: question.title['en'],
        answer: data[question._id],
      })
    })

    const body: Answers = {
      userId: pendingFeedbackResponse.userId,
      formId,
      apartmentId: pendingFeedbackResponse.apartmentId,
      rentRequestId: pendingFeedbackResponse.rentRequestId,
      userStatus: pendingFeedbackResponse.userStatus,
      answers: answers,
    }

    return this.http
      .post(
        `${this.utils.getEnvironmentValue('apiUrl')}feedback/submit`,
        body,
        options,
      )
      .toPromise()
  }
}
