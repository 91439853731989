import { Injectable, PLATFORM_ID, Inject } from "@angular/core";
import { isPlatformBrowser } from "@angular/common";
import { Http } from "@angular/http";
import { FacebookService, LoginResponse } from "ngx-facebook";
import { GeneralUtils } from "./general";
import { Cookie } from "ng2-cookies";

@Injectable()
export class UserUtils {
  constructor(
    private http: Http,
    private fb: FacebookService,
    private _general: GeneralUtils,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    if (isPlatformBrowser(this.platformId)) {
      this.fb.init({
        appId: this._general.getEnvironmentValue("fbAppId"),
        xfbml: true,
        version: "v2.9"
      });
    }
  }

  user: any;
  loggedUser: any;
  loggedUserImage: string;
  loggedUserFirstName: string;
  loggedUserLastName: string;

  setLoggedUserImage(image) {
    this.loggedUserImage = image;
  }

  getLoggedUserImage() {
    return this.loggedUserImage;
  }

  setLoggedUserFirstName(firstName) {
    this.loggedUserFirstName = firstName;
  }

  getLoggedUserFirstName() {
    if (this.loggedUserFirstName === undefined) {
      this.loggedUserFirstName = this.getLoggedUser().first_name
    }
    return this.loggedUserFirstName;
  }

  setLoggedUserLastName(lastName) {
    this.loggedUserLastName = lastName;
  }

  getLoggedUserLastName() {
    if (this.loggedUserLastName === undefined) {
      this.loggedUserLastName = this.getLoggedUser().last_name
    }
    return this.loggedUserLastName;
  }

  setLoggedUser(loggedUser) {
    this.loggedUser = loggedUser;
  }

  getLoggedUser() {
    const cookieUser = Cookie.get("user")
    if (!cookieUser) {
      this.loggedUser = null;
      this.resetUser();
    }
    if (!this.loggedUser) {
      if (cookieUser) {
        let user = JSON.parse(cookieUser)
        user.id = atob(user.id)
        this.setLoggedUser(user)
      }
    }
    return this.loggedUser;
  }

  logout(callback) {
    this.resetUser();
    this.setLoggedUser(null);
    if (callback !== null) {
      callback();
    }
  }

  getUser() {
    return Cookie.get("user");
  }

  resetUser() {
    Cookie.delete("user", "/");
    Cookie.delete("token", "/");
  }

  getUserHistory() {
    return Cookie.get("user_history");
  }

  setUserLoginCookies(user) {
    Cookie.set("user", JSON.stringify({ id: btoa(user._id) }), 365, "/");

    let userHistory = this.getUserHistory();
    if (userHistory) {
      let history = JSON.parse(userHistory);
      // check if the user has already in the list
      if (!history.id.includes(user._id.toString())) {
        history.id.push(user._id);
        Cookie.set("user_history", JSON.stringify(history), 365, '/'); 
      }
    } else {
      Cookie.set("user_history", JSON.stringify({
        id: [user._id]
      }), 365, '/');
    }
  }

  isFirstLogin(user) {
    return !(JSON.parse(this.getUserHistory()).id.includes(user.id));
  }
}
