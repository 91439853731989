import { Component, ElementRef, Renderer2, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Location } from "@angular/common";
import { Cookie } from "ng2-cookies";
import { Utils } from "app/utils/utils";
import { FilterObject } from "../../../models/filterobject";
import { ConditionsObject } from "../../../models/conditionsobject";

import {
  ProfileController,
  GeneralController,
  UserController,
  CityController
} from "../../../controllers/controllers.module";

@Component({
  selector: "searchProfile",
  templateUrl: "./searchProfile.component.html",
  styleUrls: ["./searchProfile.component.css"]
})
export class SearchProfileComponent {
  constructor(
    private utils: Utils,
    private profCtrl: ProfileController,
    private genCtrl: GeneralController,
    private userCtrl: UserController,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private el: ElementRef,
    private renderer: Renderer2,
    public cityCtrl: CityController
  ) {}

  filter = new FilterObject();
  filtersCount: number = 0;

  loading = false;
  modalFilters: any;
  profiles: any = [];

  currentPage: number = 1;

  lang: string;
  today: Date = new Date();
  imageHost: string = this.utils.getEnvironmentValue("amazonS3Url");
  showFilters: boolean = false;
  conditions: ConditionsObject = this.genCtrl.getNewConditionsObject();

  loginShowed: boolean = false;

  @ViewChild("wrapperContainer") wrapperContainer: ElementRef;

  async ngOnInit() {
    this.lang = this.utils.getCurrentLang();
    this.utils.setSeoMeta();

    const page = this.activatedRoute.snapshot.queryParams.page;
    if (page) {
      this.utils.executeServerOnly(() => {
        this.utils.createCanonicalURL();
      });

      const defaultLimit = this.filter.limit;
      this.filter.limit = page * defaultLimit;
      this.currentPage = page;

      await this.filterData(true, false);

      this.filter.skip = this.filter.limit - defaultLimit;
      this.filter.limit = defaultLimit;
    } else {
      await this.filterData(true, false);
    }

    this.utils.executeBrowserOnly(() => {
      window.scrollTo(0, 0);
      this.conditions.isBrowser = true;
    });
  }

  ngAfterViewInit() {
    this.utils.executeBrowserOnly(() => {
      setInterval(() => {
        if (!this.utils.getLoggedUser()) {
          if (!this.loginShowed) {
            this.loginShowed = true;
            this.genCtrl.showModal(this.renderer, this.el.nativeElement.querySelector("div.modal-login"));
          }
        }
        else {
          if (this.loginShowed) {
            this.loginShowed = false;
            this.genCtrl.hideModal(this.renderer, this.el.nativeElement.querySelector("div.modal-login"));
          }
        }
      }, 50);
      this.userCtrl
        .getSubscribeLogout(true)
        .subscribe((res) => {
          let lang = Cookie.check('lang') ? Cookie.get('lang') : 'fr';
          this.router.navigate(["/" + lang + "/apartments" ]);
        });
    });
  }

  initUtils() {
    this.utils.setListLayout();
    this.utils.hideOverlay();
  }

  onResetFilters = async (isMobile) => {
    if (isMobile) {
      this.onCloseModal();
    }

    this.filter = new FilterObject();
    await this.filterData(true, true);
    this.showFilters = false;
  };

  toggleFilters() {
    this.showFilters = !this.showFilters;
  }

  async onSort(event) {
    if (event == "price-asc") {
      this.filter.sort = "minprice";
      this.filter.sortorder = "asc";
    } else if (event == "price-desc") {
      this.filter.sort = "maxprice";
      this.filter.sortorder = "desc";
    } else if (event == "listeddate-asc") {
      this.filter.sort = "listedDate";
      this.filter.sortorder = "asc";
    } else if (event == "listeddate-desc") {
      this.filter.sort = "listedDate";
      this.filter.sortorder = "desc";
    }
    this.filter.skip = 0;
    await this.filterData(true, true);
  }

  updateUrl() {
    const url = this.router
      .createUrlTree([], {
        relativeTo: this.activatedRoute,
        queryParams: { page: ++this.currentPage }
      })
      .toString();
    this.location.go(url);
  }

  async onScroll() {
    if (
      this.profiles &&
      this.profiles.length >= this.filter.limit + this.filter.skip
    ) {
      this.updateUrl();

      this.loading = true;
      this.filter.skip += this.filter.limit;

      await this.filterData(false, false);

      this.loading = false;
      this.utils.setListLayout();
    }
  }

  onSearch = async (isMobile) => {
    if (isMobile) {
      this.onCloseModal();
    }

    this.filter.skip = 0;
    await this.filterData(true, true);

    this.showFilters = false;
  };

  async filterData(showOverlay, resetList) {
    if (showOverlay) {
      this.utils.showOverlay();
    }
    if (resetList) {
      this.profiles = [];
    }

    this.filtersCount = 0;
    const defaultFilters = new FilterObject();
    let filters = "";
    for (const key in this.filter) {
      const isFilterKey =
        key !== "sort" &&
        key !== "sortorder" &&
        key !== "limit" &&
        key !== "skip";

      if (this.filter[key] != null && this.filter[key] !== "") {
        filters += `&${key}=${this.filter[key]}`;
      }

      if (this.filter[key] !== defaultFilters[key] && isFilterKey) {
        this.filtersCount++;
      }
    }

    this.utils.executeBrowserOnly(() => {
      this.initUtils();
    });
  }

  compareDates(date) {
    let colocDate = new Date(date);
    let yesterday = new Date(this.today);
    yesterday.setDate(yesterday.getDate() - 2);
    return colocDate.setHours(0, 0, 0, 0) >= yesterday.setHours(0, 0, 0, 0);
  }

  async handleKeyDown(event: any) {
    if (event.keyCode == 13) {
      this.filter.skip = 0;
      await this.filterData(true, true);
    }
  }

  ngOnDestroy() {
    this.utils.executeBrowserOnly(() => {
      this.utils.showOverlay();
    });
  }

  onOpenModal() {
    this.toggleFilters();
    this.modalFilters = this.genCtrl.getModal(
      this.el,
      this.renderer,
      "div.searchProfileFiltersModal",
      true
    );
  }

  onCloseModal() {
    this.toggleFilters();
    this.genCtrl.hideModal(this.renderer, this.modalFilters);
  }

  scrollToContainer() {
    this.wrapperContainer.nativeElement.scrollIntoView({
      block: "start",
      behavior: "smooth"
    });
  }

  getUtils() {
    return this.utils;
  }

  getURL() {
    return this.router.url;
  }
}
