import { Injectable } from "@angular/core";
import { Headers, Http } from "@angular/http";
import { Utils } from "../utils/utils";
import { KeywordCategory } from "./matching.types";

@Injectable()
export class MatchingController {
  imageHost = this.utils.getEnvironmentValue("amazonS3Url");

  constructor(
    private http: Http,
    private utils: Utils
  ) {}


  async getKeywords(): Promise<KeywordCategory[]> {
    const headers = new Headers();
    const user = this.utils.getLoggedUser();
    if (user && user.id) {
      headers.append('Authentication', user.id);
    }
    return this.http
      .get(`${this.utils.getEnvironmentValue("apiUrl")}keywords/`, { headers: headers })
      .toPromise()
      .then((response) => response.json() as KeywordCategory[]);
  }


}
