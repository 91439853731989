import { Component } from "@angular/core";
import { Utils } from "../../../utils/utils";

@Component({
  selector: "shareProfile",
  templateUrl: "./shareProfile.component.html"
})
export class ShareProfileComponent {
  constructor(private utils: Utils) {}

  ngOnInit() {
    this.utils.setSeoMeta();
  }
}
