import { Component, ElementRef, Input, Renderer2 } from "@angular/core";
import { Utils } from "../../../utils/utils";
import {
  UserController,
  GeneralController
} from "../../../controllers/controllers.module";

import { Router, ActivatedRoute } from "@angular/router";
import { Cookie } from "ng2-cookies";

@Component({
  selector: "loginDialog",
  templateUrl: "./loginDialog.component.html"
})
export class LoginDialogComponent {
  @Input() public isRequired: boolean;
  @Input() public allowClose: boolean;
  @Input() public backURL: string;

  constructor(
    private utils: Utils,
    private el: ElementRef,
    private userCtrl: UserController,
    private genCtrl: GeneralController,
    private renderer: Renderer2,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {}

  modalEl = null;
  lang = "fr";

  ngOnInit() {
    this.utils.executeBrowserOnly(() => {
      this.lang = this.utils.getCurrentLang();
    });
  }

  onCloseModal() {
    this.genCtrl.hideModal(this.renderer, this.modalEl);
  }

  onGoBack() {
    this.router.navigate([this.backURL]);
  }

  ngAfterViewInit() {
    this.modalEl = this.genCtrl.getModal(
      this.el,
      this.renderer,
      "div.modal-login",
      false
    );
  }

  onLoginRequired() {
    this.onCloseModal();
    this.userCtrl.getLoginService().loginModalNotifyOther();
  }
  
  onLogin() {
    this.onCloseModal();
    this.userCtrl.getLoginService().loginNotifyOther();
  }

  setCookie(data) {
    this.utils.setUserLoginCookies(data);
    // Cookie.set("user", JSON.stringify({ id: btoa(data._id) }), 365, "/");
  }

  goToLogin() {
    this.router.navigate([this.lang + "/login"], { queryParams: {
      redirect: this.router.url,
      redirectParam: JSON.stringify(this.activatedRoute.queryParams)
    } });
  }
}
