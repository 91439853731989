import { Component, EventEmitter, Input } from "@angular/core";
import { Options, LabelType } from "ng5-slider";
import { Utils } from "../../../../utils/utils";
import { ConditionsObject } from "../../../../models/conditionsobject";

import { GeneralController } from "../../../../controllers/controllers.module";

@Component({
  selector: "searchProfileFilters",
  templateUrl: "./searchProfileFilters.component.html"
})
export class SearchProfileFiltersComponent {
  @Input() public filter: any;
  @Input() public onSearch: any;
  @Input() public onReset: any;
  @Input() public isMobile: boolean;

  constructor(private utils: Utils, private genCtrl: GeneralController) {}

  minValue: number = 0;
  maxValue: number = 1500;
  options: Options = {
    floor: 0,
    ceil: 2000,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return "$" + value;
        case LabelType.High:
          return this.filter.maxprice == 2000 ? `$2000+` : `$${value}`;
        default:
          return "$" + value;
      }
    }
  };
  manualRefresh: EventEmitter<void> = new EventEmitter<void>();
  conditions: ConditionsObject = this.genCtrl.getNewConditionsObject();

  ngOnInit() {
    this.utils.executeBrowserOnly(() => {
      this.setSliderValues();
      this.conditions.isBrowser = true;
    });
  }

  setSliderValues() {
    this.minValue = this.filter.minprice;
    this.maxValue = this.filter.maxprice;
    this.manualRefresh.emit();
  }

  onChangeSlider(event) {
    this.filter.minprice = event.value ? event.value : this.filter.minprice;
    this.filter.maxprice = event.highValue > 2000 ? 10000 : event.highValue;

    if (event.highValue == 2000) {
      this.manualRefresh.emit();
    }
  }

  async onHandleSearch() {
    await this.onSearch(this.isMobile);
  }

  async onHandleReset() {
    await this.onReset(this.isMobile);
    this.setSliderValues();
  }
}
