export class Profile {

  constructor() {
    this.name = "";
    this.slug = "";
    this.description = "";
    this.creationDate = new Date;
    this.isListed = true;
    this.listedDate = new Date;
    this.rentaltype = "longterm";
    this.durationFrom = null;
    this.durationTo = null;
    this.isFurnished = true;
    this.contact = new Contact();
    this.owner = new Owner();
    this.photos = new Array<Photo>();
    this.roomsCount = 1;
    this.minprice = 0;
    this.maxprice = 2000;
    this.roommates = new Array<Roommate>();
    this.currency = "";
    this.nearestMetroLine = "";
    this.nearestMetroStation = "";
    this.accomodations = new Array<Accomodation>();
    this.facilities = new Array<Facility>();
	}


  addRoomate(user){
    let roommate = new Roommate();
    roommate.userId = user._id;
    roommate.userName = user.first_name + " " + user.last_name;
    roommate.userPhoto = user.image;
    this.roommates.push(roommate);
  }

  addNewPhoto(source:string) {
    let photo = new Photo();
    photo.source = source;
    this.photos.push(photo);
  }

  addNewAccomodation(newAccomodation:any) {
    let accomodation = new Accomodation();
    accomodation.id = newAccomodation.id;
    this.accomodations.push(accomodation);
  }

  addNewFacility(newFacility:any) {
    let facility = new Facility();
    facility.id = newFacility.id;
    this.facilities.push(facility);
  }

	name: string;
  slug: string;
	description:	string;
  creationDate:	Date;
  isListed:	boolean;
  listedDate: Date;
  rentaltype: string;
  durationFrom: Date;
  durationTo: Date;
  isFurnished: boolean;
  contact: Contact;
  owner: Owner;
  photos: Array<Photo>;
  roomsCount: number;
  minprice: number;
  maxprice: number;
  roommates : Array<Roommate>;
  currency: string;
  nearestMetroLine: string;
  nearestMetroStation: string;
  accomodations : Array<Accomodation>;
  facilities : Array<Facility>;
}

export class Roommate{
  constructor(){
    this.userId = null;
    this.userName = "";
    this.userPhoto = "";
  }
  userId : string;
  userName : string;
  userPhoto: string;
}

class Contact{
  constructor(){
    this.name = "";
    this.email = "";
    this.phone = "";
  }
  name: string;
  email: string;
  phone: string;
}

class Owner{
  constructor(){
    this.userId = "";
    this.name = "";
    this.photo = "";
  }
  userId: any;
  name: string;
  photo: string;
}

class Photo{
  constructor(){
    this.source = "";
    this.description = "";
  }
  source:string;
  description:string;
}

class Accomodation{
  constructor(){}
  id:number;
}

class Facility{
  constructor(){}
  id:number;
}
