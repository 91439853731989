import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserController } from 'app/controllers/user.controller';
import { Utils } from 'app/utils/utils';
import { Cookie } from "ng2-cookies";

const INCENTIVE_INTERVAL = 60; // In minutes
const CHECK_INTERVAL = 60 // In seconds

@Component({
  selector: 'app-incentive',
  templateUrl: './incentive.component.html',
  styleUrls: ['./incentive.component.css']
})
export class IncentiveComponent implements OnInit {

  hidden: boolean = true;
  lastHide: Date = new Date()
  loggedUser;

  constructor(
    private utils: Utils,
    private userCtrl: UserController,
    private router: Router
  ) {}

  async ngOnInit() {
    await this.updateLoggedUser();
    this.userCtrl.getSubscribeLogout(true).subscribe(async () => {
      this.loggedUser = undefined;
    });
    this.userCtrl.getSubscribeLogin(true).subscribe(async () => {
      await this.updateLoggedUser();
    });
    setInterval(async () => {
      await this.updateLoggedUser()
    }, CHECK_INTERVAL * 1000)
  }

  getLastHide(): Date {
    const lastIncentiveHide =  window.localStorage.getItem('lastIncentiveHide');
    if (lastIncentiveHide) {
      try {
        this.setLastHide(new Date(Number(lastIncentiveHide)));
      } catch {
        this.setLastHide(new Date(1990, 1, 1));
      }
    } else {
      this.setLastHide(new Date(1990, 1, 1));
    }
    return this.lastHide;
  }

  setLastHide(lastHide: Date) {
    this.lastHide = lastHide;
    if (window) {
      window.localStorage.setItem('lastIncentiveHide', lastHide.getTime().toString());
    }
  }

  calculateHidden() {
    this.getLastHide();
    if (!Cookie.get("user") || !this.loggedUser) {
      this.hidden = true;
      return true;
    }
    if (this.loggedUser.aboutme && this.loggedUser.keywords && this.loggedUser.keywords.length > 0) {
      this.hidden = true;
      return true;
    }
    if ((new Date().getTime() - this.lastHide.getTime()) / (60 * 1000) < INCENTIVE_INTERVAL) {
      this.hidden = true
    } else {
      this.hidden = false
    }

    // Do not show on onboarding page
    if (this.router.url.includes('onboarding')) {
      this.hidden = true;
    }

    // Do not show on apartment details page, booking summary page, payment page
    const aptDetailsRegex = new RegExp('\/' + this.utils.getCurrentLang() + '\/apartments\/[a-zA-Z0-9]{24}');
    if (this.router.url.match(aptDetailsRegex) || this.router.url.includes('application-summary') || this.router.url.includes('payment')) {
      this.hidden = true;
    }

    return this.hidden;
  }

  hide(event?: Event) {
    if (event) event.stopPropagation();
    this.setLastHide(new Date());
    this.calculateHidden();
  }


  redirect() {
    this.hide();
    this.router.navigate([this.utils.getCurrentLang() + '/myprofile/aboutme'])
  }


  async updateLoggedUser() {
    if (Cookie.get("user")) {
      const storedUser = JSON.parse(Cookie.get("user"));
      try {
        const user = await this.userCtrl.getUser(this.utils.getLoggedUser().id);

        if (!user) {
          this.utils.resetUser();
        } else {
          this.loggedUser = user;
        }
      } catch (err) {
        this.utils.resetUser();
        this.loggedUser = undefined;
        this.calculateHidden();
      }
    } else {
      this.loggedUser = undefined;
    }
    this.calculateHidden();
  }
}
