import { Component, OnInit, Input, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import * as mapboxgl from 'mapbox-gl';
import { ApartmentService } from 'app/modules/services/apartmentService';
import { environment } from 'environments/environment';

interface Apartment {
  _id: string;
  name: string;
  adress: {
    lat: number;
    lng: number;
    neighborhood: string;
  };
  photos: Array<{source: string}>;
  price?: number;
  wholeApartment: boolean;
  rooms: Array<{price: number}>;
  minprice: number;
  isHovered: boolean;
}


@Component({
  selector: 'app-mapbox',
  template: `
    <div id="map" class="map-container"></div>
    <app-bottom-sheet
      [apartment]="selectedApartment"
      [imageHost]="imageHost"
      [isOpen]="isBottomSheetOpen"
      (close)="closeBottomSheet()"
    ></app-bottom-sheet>
  `,
  styles: [`
    .map-container {
      position: relative;
      width: 100%;
      height: 100%;
    }

    /* Custom Popup Styling */
    :host ::ng-deep .mapboxgl-popup {
      min-width: 300px !important;
      max-width: 300px !important;
      width: 300px !important;
      z-index: 3 !important;
    }

    :host ::ng-deep .mapboxgl-popup-content {
      padding: 0 !important;
      border-radius: 8px !important;
      box-shadow: 0 2px 10px rgba(0,0,0,0.1) !important;
      overflow: hidden !important;
      background: white !important;
      width: 300px !important; /* Force consistent width */
    }

    :host ::ng-deep .mapboxgl-popup-close-button {
      padding: 5px !important;
      right: 8px !important;
      top: 5px !important;
      color: #666 !important;
      font-size: 18px !important;
      z-index: 2 !important;
      background: white !important;
      border-radius: 50% !important;
      width: 30px !important;
      height: 30px !important;
      display: flex !important;
      align-items: center !important;
      justify-content: center !important;
      line-height: 1 !important;
      border: none !important;
      cursor: pointer !important;
      box-shadow: 0 2px 4px rgba(0,0,0,0.1) !important;
      outline: none !important;
    }

    :host ::ng-deep .mapboxgl-popup-close-button {
      content: '×' !important; 
      font-family: "Arial", sans-serif !important;  
      font-weight: 300 !important;
      font-size: 25px !important;
    }

    :host ::ng-deep .mapboxgl-popup-close-button:hover {
      background: #f5f5f5 !important;
      color: #333 !important;
    }

    :host ::ng-deep .mapboxgl-popup-tip {
      display: none !important;
    }

    /* Consistent popup content styling */
    :host ::ng-deep .popup-container {
      width: 300px !important;
      height: 250px !important; /* Fixed height for consistency */
      display: flex !important;
      flex-direction: column !important;
    }

    :host ::ng-deep .popup-image-container {
      width: 100% !important;
      height: 150px !important; /* Fixed height for images */
      position: relative !important;
      overflow: hidden !important;
    }

    :host ::ng-deep .popup-image {
      width: 100% !important;
      height: 100% !important;
      object-fit: cover !important;
      display: block !important;
    }

    :host ::ng-deep .popup-content {
      flex: 1 !important;
      padding: 16px !important;
      display: flex !important;
      flex-direction: column !important;
    }

    /* Price Marker Styling */
    .price-marker {
      cursor: pointer;
      background-color: white;
      border-radius: 25px;
      padding: 6px 12px;
      font-size: 13px;
      font-weight: 500;
      box-shadow: 0 2px 4px rgba(0,0,0,0.15);
      transition: all 0.2s ease;
    }

    .price-marker:hover,
    .price-marker.active {
      background-color: #ff7a59;
      color: white;
      transform: scale(1.05);
    }

    @media (max-width: 768px) {
      .map-container {
        width: 100vw !important;
        left: 50% !important;
        margin-left: -50vw !important;
      }
    }
  `]
})

export class SearchApartmentMap implements OnInit, OnChanges, OnDestroy {
  @Input() apartments: Apartment[] = [];
  @Input() imageHost: string = '';
  @Input() isVisible: boolean;
  
  map: mapboxgl.Map;
  markers: mapboxgl.Marker[] = [];
  markerMap: Map<string, { marker: mapboxgl.Marker, element: HTMLDivElement }> = new Map();
  selectedApartment: Apartment = null;
  isBottomSheetOpen: boolean = false;
  isMobile: boolean = false;
  showMap: boolean = false;
  private resizeTimeout: any;
  private currentPopup: mapboxgl.Popup = null;
  private activeMarkerId: string = null;
  
  constructor(private apartmentService: ApartmentService) {
    (mapboxgl as any).accessToken = environment.mapboxAccessToken;
    this.checkMobile();
  }

  private checkMobile(): void {
    this.isMobile = window.innerWidth <= 768;
  }

  closeBottomSheet(): void {
    this.isBottomSheetOpen = false;
    this.selectedApartment = null;
  }

  centerOnPosition(lng: number, lat: number) {
    if (this.map) {
      this.map.flyTo({
        center: [lng, lat],
        zoom: 14,
        essential: true
      });
  
      //if there's a marker at this position, highlight it
      this.apartments.forEach(apartment => {
        if (apartment.adress && 
            apartment.adress.lat === lat && 
            apartment.adress.lng === lng) {
          this.handleMarkerClick(apartment);
        }
      });
    }
  }

  public handleMarkerClick(apartment: Apartment): void {
    if (this.isMobile) {
      this.selectedApartment = apartment;
      this.isBottomSheetOpen = true;
     
      if (this.map) {
        this.map.setPadding({
          top: 50,
          bottom: 250,
          left: 50,
          right: 50
        });
       
        this.map.easeTo({
          center: [apartment.adress.lng, apartment.adress.lat],
          duration: 500
        });
      }
    } else {
      //remove previous popup if it exists
      if (this.currentPopup) {
        this.currentPopup.remove();
      }
      //reset previous active marker style if exists
      if (this.activeMarkerId) {
        const prevMarkerInfo = this.markerMap.get(this.activeMarkerId);
        if (prevMarkerInfo) {
          this.updateMarkerStyle(prevMarkerInfo.element, false);
        }
      }
      //create and show new popup
      this.currentPopup = new mapboxgl.Popup({
        offset: 25,
        closeButton: true,
        closeOnClick: false
      })
        .setLngLat([apartment.adress.lng, apartment.adress.lat])
        .setHTML(this.getPopupContent(apartment))
        .addTo(this.map);
      //update active marker
      this.activeMarkerId = apartment._id;
      const markerInfo = this.markerMap.get(apartment._id);
      if (markerInfo) {
        this.updateMarkerStyle(markerInfo.element, true);
      }
      // event listener for popup close
      this.currentPopup.on('close', () => {
        if (this.activeMarkerId) {
          const markerInfo = this.markerMap.get(this.activeMarkerId);
          if (markerInfo) {
            this.updateMarkerStyle(markerInfo.element, false);
          }
          this.activeMarkerId = null;
        }
      });
    }

    //save marker position in parent component
    if (apartment.adress && apartment.adress.lat && apartment.adress.lng) {
      const parentComponent = this.apartmentService.getSearchComponent();
      if (parentComponent) {
        parentComponent.saveMarkerPosition(apartment.adress.lat, apartment.adress.lng);
      }
    }
  }

  resizeIfMobile(forceResize: boolean = false): void {
    this.checkMobile();
    
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    
    this.resizeTimeout = setTimeout(() => {
      if (this.map && (forceResize || this.showMap)) {
        this.map.resize();
        
        if (this.markers.length > 0) {
          const bounds = new mapboxgl.LngLatBounds();
          this.markers.forEach(marker => {
            bounds.extend(marker.getLngLat());
          });
          
          this.map.fitBounds(bounds, {
            padding: { 
              top: 50, 
              bottom: this.isMobile && this.isBottomSheetOpen ? 250 : 50,
              left: 50, 
              right: 50 
            }
          });
        }
      }
    }, 100);
  }

  ngOnInit() {
    this.initializeMap();
    this.map.resize();
    
    this.apartmentService.apartmentHoverState$.subscribe(({ id, isHovered }) => {
      const markerInfo = this.markerMap.get(id);
      if (markerInfo) {
        this.updateMarkerStyle(markerInfo.element, isHovered);
      }
    });

    window.addEventListener('resize', () => this.resizeIfMobile());
  }

  
  ngOnChanges(changes: SimpleChanges) {
    if (changes['apartments'] && this.map) {
      this.updateMarkers();
    }
    
    if (changes['isVisible'] && this.map) {
      setTimeout(() => {
        this.map.resize();
        if (this.selectedApartment && this.isBottomSheetOpen) {
          this.map.setPadding({ 
            top: 50, 
            bottom: 250,
            left: 50, 
            right: 50 
          });
        }
      }, 100);
    }
  }


  private initializeMap() {
    this.map = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v12',
      center: [-73.6248, 45.5481],
      zoom: 12,
      attributionControl: false,
    });
  
    this.map.setPadding({
      top: 0,
      bottom: 1000, // shifting midpoint upwards
      left: 0,
      right: 0
    });  
    
    this.map.addControl(new mapboxgl.AttributionControl(), 'top-left');
    this.map.addControl(new mapboxgl.NavigationControl());
   
    //on load resize to markers
    this.map.on('load', () => {
      this.map.resize();
      if (this.apartments && this.apartments.length) {
        this.updateMarkers();
      }
    });

    //  click handler to close popup when clicking on the map
    this.map.on('click', () => {
      if (this.currentPopup) {
        this.currentPopup.remove();
        // reset active marker style
        if (this.activeMarkerId) {
          const markerInfo = this.markerMap.get(this.activeMarkerId);
          if (markerInfo) {
            this.updateMarkerStyle(markerInfo.element, false);
          }
          this.activeMarkerId = null;
        }
      }
    });
  }

  private getPopupContent(apartment: Apartment): string {
    const photoUrl = apartment.photos && apartment.photos.length > 0 
      ? this.imageHost + 'apartment/' + apartment.photos[0].source
      : 'assets/images/small-no-picture.png';
    
    const neighborhood = apartment.adress && apartment.adress.neighborhood 
      ? apartment.adress.neighborhood 
      : '';
  
    const price = `${apartment.minprice}+ CAD / Month`;
    const currentPath = window.location.pathname;
    const languagePrefix = !currentPath.startsWith('/fr') ? 'en' : 'fr';
    const apartmentUrl = `/${languagePrefix}/apartments/${apartment._id}`;
    const roomText = apartment.rooms.length === 1 ? 'Room' : 'Rooms';
  
    return `
      <div class="popup-container" onclick="window.open('${apartmentUrl}', '_blank')" style="cursor: pointer;">
        <div class="popup-image-container">
          <img 
            class="popup-image"
            src="${photoUrl}" 
            alt="${apartment.name}"
          >
          <div style="
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            background: linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0));
            color: white;
            padding: 12px 16px;
            font-size: 15px;
            font-weight: 500;
          ">
            ${price}
          </div>
        </div>
  
        <div class="popup-content">
          <div style="
            font-size: 15px;
            font-weight: 600;
            margin-bottom: 4px;
            color: #333;
            line-height: 1.3;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
          ">
            ${apartment.name}
          </div>
          
          <div style="
            font-size: 13px;
            color: #666;
            margin-bottom: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          ">
            <i class="fa fa-map-marker"></i> 
            ${neighborhood}
          </div>
          
          <div style="
            display: flex;
            gap: 12px;
            font-size: 13px;
            color: #666;
          ">
            <span>
              <i class="fa fa-bed"></i>
              ${apartment.rooms.length} ${roomText}
            </span>
            <span>
              ${apartment.wholeApartment ? 'Entire home' : 'Private room'}
            </span>
          </div>
        </div>
      </div>
    `;
  }


  private createPriceMarkerElement(apartment: Apartment): HTMLDivElement {
    const el = document.createElement('div');
    el.className = 'price-marker';

    const price = apartment.minprice;
    const currentPath = window.location.pathname;
    const isEnglish = !currentPath.startsWith('/fr');
    
    const formatNumber = (num: number, isEnglish: boolean): string => {
        if (isEnglish) {
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        }
    };
    
    const formattedPrice = formatNumber(price, isEnglish);
    const priceText = isEnglish ? 
        `$${formattedPrice} CAD` : 
        `${formattedPrice}$ CAD`;

    el.innerHTML = priceText;

    el.style.backgroundColor = 'white';
    el.style.borderRadius = '25px';
    el.style.color = 'black';
    el.style.padding = '4px 8px';
    el.style.fontSize = '12px';
    el.style.fontWeight = 'bold';
    el.style.boxShadow = '0 2px 4px rgba(0,0,0,0.15)';
    el.style.cursor = 'pointer';

    return el;
}

private updateMarkerStyle(element: HTMLDivElement, isActive: boolean) {
  if (!element) return;

  const styles = {
    backgroundColor: isActive ? '#ff7a59' : 'white',
    color: isActive ? 'white' : 'black',
    zIndex: isActive ? '2' : '1'
  };
  
  Object.assign(element.style, styles);
}

private updateMarkers(): void {
  if (this.currentPopup) {
    this.currentPopup.remove();
  }
  this.activeMarkerId = null;
  this.markers.forEach(marker => marker.remove());
  this.markers = [];
  this.markerMap.clear();

  if (!this.apartments) {
    return;
  }

  const bounds = new mapboxgl.LngLatBounds();
  let hasValidCoordinates = false;

  this.apartments.forEach(apartment => {
    if (apartment.adress && apartment.adress.lat && apartment.adress.lng) {
      const markerElement = this.createPriceMarkerElement(apartment);
      
      const marker = new mapboxgl.Marker({
        element: markerElement,
        anchor: 'center'
      })
        .setLngLat([apartment.adress.lng, apartment.adress.lat])
        .addTo(this.map);

      markerElement.addEventListener('click', (e) => {
        e.stopPropagation();
        this.handleMarkerClick(apartment);
      });

      markerElement.addEventListener('mouseenter', () => {
        if (this.activeMarkerId !== apartment._id) {
          this.apartmentService.notifyHoverState(apartment._id, true);
        }
      });
      
      markerElement.addEventListener('mouseleave', () => {
        if (this.activeMarkerId !== apartment._id) {
          this.apartmentService.notifyHoverState(apartment._id, false);
        }
      });

      this.markerMap.set(apartment._id, { marker, element: markerElement });
      this.markers.push(marker);
      bounds.extend([apartment.adress.lng, apartment.adress.lat]);
      hasValidCoordinates = true;
    }
  });

  if (hasValidCoordinates) {
    this.map.fitBounds(bounds, {
      padding: { 
        top: 50, 
        bottom: this.isMobile && this.isBottomSheetOpen ? 250 : 50,
        left: 50, 
        right: 50 
      }
    });
  }
}

ngOnDestroy() {
  if (this.resizeTimeout) {
    clearTimeout(this.resizeTimeout);
  }
  if (this.currentPopup) {
    this.currentPopup.remove();
  }
  this.markers.forEach(marker => marker.remove());
  this.markerMap.clear();
  window.removeEventListener('resize', () => this.resizeIfMobile());
  this.closeBottomSheet();
}

}