import { Injectable } from "@angular/core";
import { Headers, Http, RequestOptions } from "@angular/http";
import { Utils } from "../utils/utils";

import { GeneralController } from "./general.controller";

@Injectable()
export class ApartmentController {
  imageHost = this.utils.getEnvironmentValue("amazonS3Url");

  constructor(
    private http: Http,
    private utils: Utils,
    private genCtrl: GeneralController
  ) {}

  prepareHeaders() {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("Authorization", this.utils.getLoginToken());
    return new RequestOptions({ headers: headers });
  }

  async getApartment(id) {
    const headers = new Headers();
    const token = this.utils.getLoginToken();
    if (token) {
      headers.append("Authorization", token);
    }
    return this.http
      .get(`${this.utils.getEnvironmentValue("apiUrl")}apartments/${id}`, {
        headers: headers,
      })
      .toPromise()
      .then((response) => response.json());
  }

  async getApartments(filters) {
    const headers = new Headers();
    const token = this.utils.getLoginToken();
    if (token) {
      headers.append("Authorization", token);
    }
    return this.http
      .get(
        `${this.utils.getEnvironmentValue(
          "apiUrl"
        )}apartments?onlyActive=true${filters}`,
        { headers: headers }
      )
      .toPromise()
      .then((response) => response.json());
  }

  async getBookmarkedApartments(id) {
    return this.http
      .get(
        `${this.utils.getEnvironmentValue("apiUrl")}bookmarks/${id}/apartments`,
        this.prepareHeaders()
      )
      .toPromise()
      .then((response) => response.json());
  }

  async getOwnedApartments(id) {
    return this.http
      .get(
        `${this.utils.getEnvironmentValue("apiUrl")}apartments?owner=${id}`,
        this.prepareHeaders()
      )
      .toPromise()
      .then((response) => response.json());
  }

  async postApartment(apartment, user) {
    return this.http
      .post(
        `${this.utils.getEnvironmentValue("apiUrl")}apartments`,
        apartment,
        this.prepareHeaders()
      )
      .toPromise()
      .then((response) => response.json());
  }

  async updateApartment(id, apartment) {
    return this.http
      .put(
        `${this.utils.getEnvironmentValue("apiUrl")}apartments/${id}`,
        apartment,
        this.prepareHeaders()
      )
      .toPromise()
      .then((response) => response.json());
  }

  async deleteApartment(id, apartment) {
    return this.http
      .delete(
        `${this.utils.getEnvironmentValue("apiUrl")}apartments/${id}`,
        this.prepareHeaders()
      )
      .toPromise()
      .then((response) => response.json());
  }

  async getAdress(postalCode) {
    return this.http
      .get(
        `https://maps.googleapis.com/maps/api/geocode/json?key=AIzaSyCCyG3_3J__P-wpE5WYLiZ9A_-76dMnyz0&address=${postalCode}`
      )
      .toPromise()
      .then((response) => response.json());
  }

  async removePhoto(source) {
    return this.http
      .delete(
        `${this.utils.getEnvironmentValue(
          "apiUrl"
        )}remove-image/apartment/${source}`,
        this.prepareHeaders()
      )
      .toPromise();
  }

  initExtras(apartment) {
    return {
      facilities: this.genCtrl.initExtra(
        this.utils.getFacilities(),
        apartment.facilities
      ),
      accomodations: this.genCtrl.initExtra(
        this.utils.getAccomodations(),
        apartment.accomodations
      ),
      accomodationsCriterias: this.genCtrl.initExtra(
        this.utils.getAccomodationsCriterias(),
        apartment.accomodations
      ),
    };
  }

  getFurnishedBedroomsCount(apartment) {
    let furnishedBedrooms = 0;
    for (let room of apartment.rooms) {
      if (room.isFurnished && !room.userId) {
        furnishedBedrooms++;
      }
    }

    return furnishedBedrooms;
  }

  async getBookedDates(apartmentId) {
    return this.http
      .get(
        `${this.utils.getEnvironmentValue(
          "apiUrl"
        )}apartments/get-booked-dates/${apartmentId}`
      )
      .toPromise()
      .then((response) => response.json());
  }

  isUserRoommate(apartment, user): Promise<boolean> {
    return new Promise(function (resolve, reject) {
      const room = apartment.rooms.find((room) => {
        return user._id == room.userId;
      });

      room ? resolve(true) : resolve(false);
    });
  }

  setSEO(apartment) {
    this.utils.setSeoMetaParams(apartment.name, apartment.description);
    if (apartment.photos && apartment.photos.length > 0) {
      this.utils.setOGImage(
        this.imageHost + "apartment/" + apartment.photos[0].source
      );
    }
  }

  getApartmentsNeihghborhoodPerCity(city) {
    return this.http
      .get(
        `${this.utils.getEnvironmentValue("apiUrl")}apartments/neighborhoods/${
          city.charAt(0).toUpperCase() + city.slice(1)
        }`
      )
      .toPromise()
      .then((response) => response.json());
  }

  getApartmentRent(moveInDate, moveOutDate, rooms, rentType) {
    return this.http
      .post(
        `${this.utils.getEnvironmentValue("apiUrl")}apartments/calculate-rent`,
        { moveInDate, moveOutDate, rooms, rentType }
      )
      .toPromise()
      .then((response) => response.json());
  }

  getApartmentAvailability(apartmentId, moveInDate = undefined, moveOutDate = undefined) {
    return this.http
      .post(
        `${this.utils.getEnvironmentValue(
          "apiUrl"
        )}apartments/availability`,
        { apartmentId, moveInDate, moveOutDate }
      )
      .toPromise()
      .then((response) => response.json());
  }
}
