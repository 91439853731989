import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {TranslateModule} from '@ngx-translate/core';

import {ConnectionModule} from '../connection/connection.module';

import {ApartmentDetailsComponent} from './apartmentDetails/apartmentDetails.component';
import {ProfileDetailsComponent} from './profileDetails/profileDetails.component';
import {UserDetailsComponent} from './userDetails/userDetails.component';
import {SendMessageComponent} from './sendMessage/sendMessage.component';
import {SendMessageModalComponent} from './sendMessageModal/sendMessageModal.component';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import { SelectDropDownModule } from 'ngx-select-dropdown'
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { AvatarModule } from 'ngx-avatar';

export {
    ApartmentDetailsComponent,
    ProfileDetailsComponent,
    UserDetailsComponent
};

import {TextMaskModule} from 'angular2-text-mask';
import {AgmCoreModule} from '@agm/core';
import {RentCalculatorComponent} from './rent-calculator/rent-calculator.component';
import { GeneralModule } from '../general/general.module';

const googleMapsCore = AgmCoreModule.forRoot({
    apiKey: 'AIzaSyCZStE1RjCN1UqHvxMo47WYQ7ftwyqtevE'
});

@NgModule({
    imports: [
        AccordionModule.forRoot(),
        CommonModule,
        FormsModule,
        RouterModule,
        TranslateModule,
        googleMapsCore,
        TextMaskModule,
        ConnectionModule,
        SelectDropDownModule,
        GeneralModule,
        BsDatepickerModule.forRoot(),
        AvatarModule,
    ],
    declarations: [
        ApartmentDetailsComponent,
        ProfileDetailsComponent,
        UserDetailsComponent,
        SendMessageComponent,
        SendMessageModalComponent,
        RentCalculatorComponent
    ],
    exports: [
        ApartmentDetailsComponent,
        ProfileDetailsComponent,
        UserDetailsComponent
    ]
})
export class DetailsModule {
}
