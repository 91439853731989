import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { AlertService } from "./alertService";
import { LogoutService } from "./logoutService";
import { LoginService } from "./loginService";
import { UpdateUserService } from "./updateUserService";
import { SetLangService } from "./setLangService";
import { OverlayService } from "./overlayService";
import { ApartmentService } from "./apartmentService";

@NgModule({
  imports: [CommonModule],
  providers: [
    AlertService,
    LogoutService,
    LoginService,
    UpdateUserService,
    SetLangService,
    OverlayService,
    ApartmentService
  ]
})
export class ServicesModule {}
