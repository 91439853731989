import { Subject } from 'rxjs/Subject';
import { Component, EventEmitter, Input } from "@angular/core";
import { Options, LabelType } from "ng5-slider";
import { Utils } from "../../../../utils/utils";
import { ConditionsObject } from "../../../../models/conditionsobject";

import { CityController, GeneralController } from "../../../../controllers/controllers.module";
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: "searchApartmentFilters",
  templateUrl: "./searchApartmentFilters.component.html",
  styleUrls: ["./searchApartmentFilters.component.css"],
})
export class SearchApartmentFiltersComponent {
  @Input() public filter: any;
  @Input() public onSearch: any;
  @Input() public onReset: any;
  @Input() public isMobile: boolean;
  constructor(private utils: Utils, private genCtrl: GeneralController, public cityCtrl: CityController) {}

  distroyResources: Subject<boolean> = new Subject();
  minValue: number = 0;
  minValueVisible: number = 0;
  maxValue: number = 2000;
  maxValueVisible: number = 2000;
  wholeApartment: string = '';   // empty string or 'entire' or 'room'
  roomsCount: number = 0;
  accommodationsSelected: Array<string> = [];
  facilitiesSelected: Array<string> = [];
  neighborhoodSelected: Array<string> = [];
  selectedIndex: number = -1;
  highlightedIndex: number = -1;
  options: Options = {
    floor: 0,
    ceil: 2000,
    step: 10,
    translate: (value: number, label: LabelType): string => {
      switch (label) {
        case LabelType.Low:
          return this.formatPrice(value);
        case LabelType.High:
          return this.formatPrice(this.filter.maxprice >= 2000 ? '2000+' : value);
        default:
          return this.formatPrice(value);
      }
    }
  };
  manualRefresh: EventEmitter<void> = new EventEmitter<void>();
  conditions: ConditionsObject = this.genCtrl.getNewConditionsObject();
  config = {
    backdrop: true,
    ignoreBackdropClick: true,
    class: 'modal-sm',
    animated: false,
  };
  facilities = this.utils.getFacilitiesFilter();
  accommodations = this.utils.getAccommodationsFilter();
  neighborhoods = this.cityCtrl.neighborhoods;

  ngOnInit() {
    this.utils.executeBrowserOnly(() => {
      this.setSliderValues();
      this.conditions.isBrowser = true;
    });

    this.cityCtrl.selectedCity
      .pipe(takeUntil(this.distroyResources))
      .subscribe(async (city: string) => {
        this.minValue = 0;
        this.minValueVisible = 0;
        this.maxValue = 2000;
        this.maxValueVisible = 2000;
        this.manualRefresh.emit();
      });
  }

  toggleBodyClass(value: boolean) {
    if (value) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }

  onOpenPrice(data: boolean): void {
    this.toggleBodyClass(data);

    if (!data) {
      this.minValueVisible = this.minValue;
      this.maxValueVisible = this.maxValue;
    }

    setTimeout(() => {
      this.manualRefresh.emit();
    }, 200);
  }

  setSliderValues() {
    this.minValue = this.filter.minprice;
    this.maxValue = this.filter.maxprice;
    this.manualRefresh.emit();
  }

  applyPriceRange() {
    this.toggleBodyClass(false);
    this.minValue = this.minValueVisible;
    this.maxValue = this.maxValueVisible;
    this.onHandleSearch();
  }

  resetSliderValues() {
    this.toggleBodyClass(false);
    this.minValue = 0;
    this.minValueVisible = 0;
    this.maxValue = 2000;
    this.maxValueVisible = 2000;
    this.filter.minprice = undefined;
    this.filter.maxprice = undefined;
    this.manualRefresh.emit();
    this.onHandleSearch();
  }

  onChangeSlider(event) {
    this.filter.minprice = event.value;
    this.filter.maxprice = event.highValue >= 2000 ? 10000 : event.highValue;

    if (event.highValue === 2000) {
      this.manualRefresh.emit();
    }
  }

  applyRooms() {
    this.toggleBodyClass(false);
    this.filter.roomsCount = this.roomsCount || '';
    this.onHandleSearch();
  }

  resetRooms() {
    this.toggleBodyClass(false);
    this.roomsCount = 0;
    this.filter.roomsCount = '';
    this.onHandleSearch();
  }

  async onHandleSearch() {
    await this.onSearch(this.isMobile);
  }

  checked(filter, value) {
    return filter.includes(value);
  }

  onOpenAptType(data: boolean): void {
    this.toggleBodyClass(data);

    if (data && this.filter.wholeApartment) {
      this.wholeApartment = this.filter.wholeApartment;
    } else {
      this.wholeApartment = '';
    }
  }

  resetAptType() {
    this.toggleBodyClass(false);
    this.wholeApartment = '';
    this.filter.wholeApartment = '';
    this.onHandleSearch();
  }

  applyAptType() {
    this.toggleBodyClass(false);
    this.filter.wholeApartment = this.wholeApartment;
    this.onHandleSearch();
  }

  onOpenRooms(data: boolean): void {
    this.toggleBodyClass(data);

    if (data && this.filter.roomsCount > 0) {
      this.roomsCount = 0;
      this.roomsCount = this.filter.roomsCount;
    } else {
      this.roomsCount = 0;
    }
  }

  minusRoom() {
    if (this.roomsCount > 0) {
      this.roomsCount--;
    }
  }

  plusRoom() {
    if (this.roomsCount < 9) {
      this.roomsCount++;
    }
  }

  setSelectedIndex(newIndex: number) {
    const value = this.cityCtrl.neighborhoods[newIndex].value;

    // @ts-ignore
    if (!this.neighborhoodSelected.includes(value)) {
      this.neighborhoodSelected.push(this.cityCtrl.neighborhoods[newIndex].value);
    } else {
      const index = this.neighborhoodSelected.indexOf(value);
      if (index !== -1) {
        this.neighborhoodSelected.splice(index, 1);
      }
    }
  }

  setSelectedAccommodationsIndex(newIndex: number) {
    const value = this.accommodations[newIndex].id;

    // @ts-ignore
    if (!this.accommodationsSelected.includes(value)) {
      this.accommodationsSelected.push(this.accommodations[newIndex].id);
    } else {
      const index = this.accommodationsSelected.indexOf(value);
      if (index !== -1) {
        this.accommodationsSelected.splice(index, 1);
      }
    }
  }

  setSelectedFacilitiesIndex(newIndex: number) {
    const value = this.facilities[newIndex].id;

    // @ts-ignore
    if (!this.facilitiesSelected.includes(value)) {
      this.facilitiesSelected.push(this.facilities[newIndex].id);
    } else {
      const index = this.facilitiesSelected.indexOf(value);
      if (index !== -1) {
        this.facilitiesSelected.splice(index, 1);
      }
    }
  }

  onOpenAccommodation(data: boolean): void {
    this.toggleBodyClass(data);

    if (data && (this.filter.accommodations.length || this.filter.facilities.length)) {
      this.accommodationsSelected = [];
      this.facilitiesSelected = [];
      this.accommodationsSelected = [...this.filter.accommodations];
      this.facilitiesSelected = [...this.filter.facilities];
    } else {
      this.accommodationsSelected = [];
      this.facilitiesSelected = [];
    }
  }

  applyAccommodations() {
    this.toggleBodyClass(false);
    this.filter.accommodations = [...this.accommodationsSelected];
    this.filter.facilities = [...this.facilitiesSelected];
    this.onHandleSearch();
  }

  resetAccommodations() {
    this.toggleBodyClass(false);
    this.filter.accommodations = [];
    this.filter.facilities = [];
    this.accommodationsSelected = [];
    this.facilitiesSelected = [];
    this.onHandleSearch();
  }

  onOpenNeighborhoods(data: boolean): void {
    this.toggleBodyClass(data);

    if (data && this.filter.neighborhoods.length) {
      this.neighborhoodSelected = [];
      this.neighborhoodSelected = [...this.filter.neighborhoods];
    } else {
      this.neighborhoodSelected = [];
    }
  }

  applyNeighborhoods() {
    this.toggleBodyClass(false);
    this.filter.neighborhoods = [...this.neighborhoodSelected];
    this.onHandleSearch();
  }

  resetNeighborhoods() {
    this.toggleBodyClass(false);
    this.filter.neighborhoods = [];
    this.neighborhoodSelected = [];
    this.onHandleSearch();
  }

  formatPrice(value) {
    const lang = this.utils.getCurrentLang();
    return typeof value === 'number' ? this.utils.formatCurrency(value, lang) : value;
  }

  get2000PlusText() {
    const lang = this.utils.getCurrentLang();
    if (lang === 'en') {
      return '$2000+ CAD';
    } else {
      return '2000+ $CAD';
    }
  }
}
