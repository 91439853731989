import { CityController } from "./../../../controllers/city.controller";
import { FeedbackController, RentRequestController } from "../../../controllers/controllers.module";
import { Location, LocationStrategy } from "@angular/common";
import {
  Component,
  ElementRef,
  Renderer2,
  HostBinding,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Utils } from "../../../utils/utils";
import { Cookie } from "ng2-cookies";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import {
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faChevronDown,
  faBars,
  faTimes,
  faList,
  faAddressBook,
  faCalendarWeek,
  faPaperPlane,
  faUserCircle,
  faSearch,
  faStar,
  faTasks,
  faQuestionCircle,
  faFileSignature,
  faPowerOff,
  faPhone,
  faHome,
  faCreditCard,
  faLock,
  faGlobe,
  faIdBadge,
  faInfoCircle,
  faAlignCenter,
  faBell,
} from "@fortawesome/free-solid-svg-icons";
import { BsLocaleService } from "ngx-bootstrap/datepicker";
import { defineLocale, frLocale } from "ngx-bootstrap/chronos";

import {
  UserController,
  GeneralController,
  SystemController,
} from "../../../controllers/controllers.module";

import { ApartmentService } from "../../services/apartmentService";
import {DropdownComponent, MyOptionComponent} from '../dropdown/Dropdown.component';
import { PendingFeedbackApiResponse } from 'app/modules/general/feedback/feedback.component.type'
import {GeneralUtils} from '../../../utils/general';

@Component({
  selector: "header-container",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent {
  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private utils: Utils,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private userCtrl: UserController,
    private genCtrl: GeneralController,
    public cityCtrl: CityController,
    private rentReqCtrl: RentRequestController,
    private feedbackCtrl: FeedbackController,
    public systemCtrl: SystemController,
    private localeService: BsLocaleService,
    private apartmentService: ApartmentService,
    private _url: LocationStrategy,
    private genUtils: GeneralUtils,
  ) {
    this.utils.executeBrowserOnly(async () => {
      this.subscriptions.loginCompleted = this.userCtrl
        .getSubscribeLogin(true)
        .subscribe(async (res) => {
          this.loggedUser = this.utils.getLoggedUser();
          this.conditions.isLogged = this.loggedUser ? true : false;
        });

      this.subscriptions.login = userCtrl
        .getSubscribeLogin()
        .subscribe(async (res) => {
          const { user, newUser }: any = await userCtrl.signIn();
          if (user) {
            console.log("user:", user);
            if (newUser) {
              this.setCookie(user);

              let url;
              if (this.currentUrl) {
                url = this.currentUrl;
              } else {
                url = `${utils.getCurrentLang()}/apartments`;
              }

              if (url.indexOf("?") > -1) {
                url += "&new_signup=true";
              } else {
                url += "?new_signup=true";
              }

              location.go(url);
              window.location.reload();
            } else {
              user.id = user._id;
              this.setUser(user, false);
            }
          }
        });

      this.subscriptions.modal = this.userCtrl
        .getSubscribeLoginModal()
        .subscribe(async (res) => {
          const { user }: any = await this.userCtrl.signIn();
          if (user) {
            user.id = user._id;
            this.setUser(user, true);
          }
        });

      this.subscriptions.update = this.userCtrl
        .getSubscribeUpdateUser()
        .subscribe((res) => {
          this.loggedUser.customImage = this.utils.getLoggedUserImage();
          this.loggedUser.first_name = this.utils.getLoggedUserFirstName();
          this.loggedUser.last_name = this.utils.getLoggedUserLastName();
        });

      router.events.subscribe((val) => {
        if (val instanceof NavigationEnd && val.url) {
          this.currentUrl = val.urlAfterRedirects;

          const type = val.urlAfterRedirects.substring(4).split("?")[0];
          this.setType(type);
        }
      });

      this.cities = await this.cityCtrl.getCities();
    });
  }
  conditions: any = this.genCtrl.getNewConditionsObject();
  loggedUser: any;
  modals: any = {};
  search: any;
  searchType: string = "apartment";
  subscriptions: any = {};
  lang: string;
  type: string = null;
  menuStatus: any;
  isApartmentDetailsPage: boolean = false;
  showCitySearch = true;
  currentUrl: string;
  imageHost: string = this.utils.getEnvironmentValue("amazonS3Url");
  headerClass: string;
  isProdEnv: boolean = this.utils.getEnvironmentValue("production");
  devBranchName: string = this.utils.getEnvironmentValue("branchName");
  apiBranchName: string;
  resizeObserver;
  pendingFeedbacks: PendingFeedbackApiResponse[] = [];
  notifToggled = false;

  @HostBinding('class') subheaderClass = 'noSubheader';
  isChoiceLang = false;

  cities: any = [];
  @ViewChild('cityDropdown') cityDropdown: DropdownComponent;

  // icons
  faChevronLeft = faChevronLeft;
  faChevronRight = faChevronRight;
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faBars = faBars;
  faTimes = faTimes;
  faList = faList;
  faAddressBook = faAddressBook;
  faCalendarWeek = faCalendarWeek;
  faPaperPlane = faPaperPlane;
  faUserCircle = faUserCircle;
  faIdBadge = faIdBadge;
  faSearch = faSearch;
  faStar = faStar;
  faTasks = faTasks;
  faQuestionCircle = faQuestionCircle;
  faFileSignature = faFileSignature;
  faPowerOff = faPowerOff;
  faPhone = faPhone;
  faHome = faHome;
  faCreditCard = faCreditCard;
  faLock = faLock;
  faGlobe = faGlobe;
  faInfoCircle = faInfoCircle;
  faAlignCenter = faAlignCenter;
  faBell = faBell

  ngOnInit() {
    this.utils.executeBrowserOnly(async () => {
      this.lang = this.utils.getCurrentLang();
      if (!this.lang) {
        if (this._url.path().length >= 3) {
          if (this._url.path().substring(0, 3) === '/fr') {
            this.lang = 'fr';
          } else {
            this.lang = 'en';
          }
          this.utils.setCurrentLang(this.lang);
        } else {
          this.lang = 'en';
          this.setLanguage(this.lang); 
        }
      } else {
        this.lang = 'en';
        this.setLanguage(this.lang);
      }

      if (this.lang !== "en") {
        defineLocale(this.utils.getCurrentLang(), frLocale);
        this.localeService.use(this.lang);
      }

      const url = window.location.pathname.substring(4);
      this.setType(url);

      this.genCtrl.langNotifyOther();

      this.activatedRoute.params.subscribe(async (params) => {
        if (!('admin' in params)) {
          if (Cookie.get("user")) {
        const storedUser = JSON.parse(Cookie.get("user"));
        try {
          const user = await this.userCtrl.verifyUser(storedUser.id);

          if (!user) {
            this.utils.resetUser();
          } else {
            this.setLoggedUser(user);
            this.userCtrl.getLoginService().loginCompletedNotifyOther();
          }
        } catch (err) {
          this.utils.resetUser();
        }

        try {
          this.menuStatus = await this.rentReqCtrl.getMenuStatus();
        } catch (err) {
          this.menuStatus = {
            host: true,
            guest: true,
          };
        }
        this.pendingFeedbacks = (
            await this.feedbackCtrl.fetchPendingFeedback()
        ).json();
      }
        }
      })
      
    });
    this.isRoute();
    this.systemCtrl.getBranchName().then((data) => {
      this.apiBranchName = data.branchName;
    });
  }

  async cityChanged(city) {
    await this.cityCtrl.cityChanged(city);
    let isFrench = window.location.toString().includes("/fr/");
    this.router.navigate([isFrench ? "fr" : "en", city, "apartments"]);
    this.showCitySearch = false;
    setTimeout(() => {
      this.showCitySearch = true;
    }, 250);
  }

  setType(url) {
    delete this.type;
    this.isApartmentDetailsPage = false;
    this.subheaderClass = "noSubheader";
    this.headerClass = "";
    let splittedUrl = url.split("/");
    let splittedUrlLength = splittedUrl.length;

    if (url === "" || url === "host") {
      this.headerClass = "isHomepage";
    }
    if (splittedUrl[splittedUrlLength - 2] === "apartments") {
      this.isApartmentDetailsPage = true;
    }
    if (splittedUrl[splittedUrlLength - 1] === "apartments") {
      this.type = "apartments";
      this.subheaderClass = "noSubheader";
    } else if (url.indexOf("roommates") > -1) {
      this.type = "roommates";
      this.subheaderClass = "hasSubheader";
    }
  }

  toggleNotif() {
    this.notifToggled = !this.notifToggled
  }

  onClickLogin() {
    this.router.navigate([`/${this.lang}/login`], {
      queryParams: {
        redirect: this.router.url,
        redirectParam: JSON.stringify(this.activatedRoute.queryParams),
      },
    });
  }

  onSearch() {
    if (this.search) {
      if (this.searchType === "roommate") {
        this.router.navigate([this.utils.getCurrentLang() + "/roommates"], {
          queryParams: { search: this.search },
        });
      } else {
        this.router.navigate([this.utils.getCurrentLang() + "/apartments"], {
          queryParams: { search: this.search },
        });
      }
    }
  }

  goToFeedback(pendingFeedbackId: string) {
    this.router.navigate([
      this.utils.getCurrentLang() + '/feedback/' + pendingFeedbackId,
    ])
  }

  handleKeyDown(event: any) {
    if (event.keyCode == 13) {
      this.onSearch();
    }
  }

  setLanguage(lang, refresh = false) {
    Cookie.set('lang', lang == 'fr' ? 'fr' : 'en', 365, '/');
    this.lang = lang;
    if (refresh) {
      window.location.href = `/${
        lang + (window.location.pathname + window.location.search).substring(3)
      }`;
    }
  }

  setCookie(user) {
    // Cookie.set("user", JSON.stringify({ id: btoa(user._id) }), 365, "/");
    this.utils.setUserLoginCookies(user);
  }

  setUser(user, triggerModal) {
    this.setCookie(user);
    this.setLoggedUser(user);

    if (triggerModal) {
      this.userCtrl.getLoginService().loginModalCompletedNotifyOther();
    } else {
      this.userCtrl.getLoginService().loginCompletedNotifyOther();
    }
  }

  setLoggedUser(user) {
    this.loggedUser = user;
    this.utils.setLoggedUser(user);
    this.conditions.isLogged = user != null ? true : false;
  }

  signOut() {
    this.userCtrl.signOut();
    this.utils.logout(() => {
      this.loggedUser = null;
      this.conditions.isLogged = false;
      this.userCtrl.getLogoutService().logoutCompletedNotifyOther();
    });
  }

  ngOnDestroy() {
    this.utils.executeBrowserOnly(() => {
      if (this.resizeObserver !== undefined)
        this.resizeObserver.unobserve(document.body);
      [
        this.subscriptions.login,
        this.subscriptions.modal,
        this.subscriptions.update,
      ].map((obs) => this.utils.unsubscribeObservable(obs));
    });
  }
  isRoute() {
    return this.router.url == "/" + this.lang;
  }

  onNavigate(location) {
    if (location == "apartment") {
      this.router.navigate([`/${this.lang}/share/newapartment`]);
    } else if (location == "phone") {
      this.router.navigate([`/${this.lang}/myprofile/phonenumber`]);
    } else if (location == "host") {
      this.router.navigate([`/${this.lang}/host`]);
    } else if (location == "payout") {
      this.router.navigate([`/${this.lang}/myprofile/payoutmethod`]);
    } else if (location == "changepsw") {
      this.router.navigate([`/${this.lang}/myprofile/changepassword`]);
    } else {
      this.router.navigate([`/${this.lang}/share/roommate`]);
    }
  }

  choiceLang() {
    this.isChoiceLang = !this.isChoiceLang;
  }

  getPath() {
    return this.router.url.split("?")[0];
  }

  goToTop() {
    this.utils.executeBrowserOnly(() => {
      window.scrollTo(0, 0);
    });
  }

  onClickSignUp() {
    const queryParams = {
      queryParams: {
        redirect: this.router.url,
        redirectParam: JSON.stringify(this.activatedRoute.snapshot.queryParams),
        action: "signUp",
      },
    };

    this.router.navigate([`/${this.lang}/login`], queryParams);
  }

  showLoginButton() {
    return (
      this.currentUrl &&
      (!this.currentUrl.includes("/login") ||
        this.currentUrl.includes("action=signUp")) &&
      !this.conditions.isLogged
    );
  }

  showSignUpButton() {
    return (
      this.currentUrl &&
      !this.currentUrl.includes("action=signUp") &&
      !this.conditions.isLogged
    );
  }

  isHome() {
    return this.currentUrl && (this.currentUrl === `/${this.lang}` || this.currentUrl.includes("/host"));
  }
}
