import {Component, ElementRef, HostListener, Renderer2, ViewChild} from '@angular/core';
import {Location} from '@angular/common';
import {AgmMap} from '@agm/core';
import {Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import {Apartment} from '../../../models/apartment';
import {Utils} from '../../../utils/utils';
import {FacebookService} from 'ngx-facebook';
import {TranslateService} from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as _ from 'lodash'
import * as moment from "moment";

import {
    ApartmentController,
    UserController,
    GeneralController
} from '../../../controllers/controllers.module';

import '../../../../assets/scripts/external.js';
import {environment} from 'environments/environment';

declare var myExtObject: any;

@Component({
    selector: 'apartmentDetails',
    templateUrl: './apartmentDetails.component.html',
    styleUrls: ['./apartmentDetails.component.css']
})
export class ApartmentDetailsComponent {
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private el: ElementRef,
        private renderer: Renderer2,
        private utils: Utils,
        private location: Location,
        private aptCtrl: ApartmentController,
        private userCtrl: UserController,
        private genCtrl: GeneralController,
        private fb: FacebookService,
        public sanitizer: DomSanitizer,
        private translate: TranslateService,
    ) {
        this.apartment = new Apartment();

        this.subscriptions.urlChange = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.isInternal = event.id > 1;
            }
        });

        this.subscriptions.login = this.userCtrl
            .getSubscribeLogin(true)
            .subscribe(async () => {
                if (this.utils.getLoggedUser()) {
                    this.loggedUser = await this.userCtrl.getUser(this.utils.getLoggedUser().id);
                }
                else {
                    this.loggedUser = null;
                }

                this.setIsOwner();
                await this.setIsRoommate();
                await this.setIsBookmarked();
            });

        this.subscriptions.logout = this.userCtrl
            .getSubscribeLogout(true)
            .subscribe(async () => {
                if (this.utils.getLoggedUser()) {
                    this.loggedUser = await this.userCtrl.getUser(this.utils.getLoggedUser().id);
                }
                else {
                    this.loggedUser = null;
                }
                this.conditions = Object.assign(this.conditions, {isOwner: false});
            });
    }

    @ViewChild('agmMap') agmMap: AgmMap;

    apartment: Apartment;
    properties = {
        accomodations: [],
        facilities: [],
        accomodationsCriterias: [],
        furnishedBedrooms: 0
    };

    sizeLabel: string = '';
    subscriptions: any = {};
    modalEl = null;
    id: any;
    conditions: any = this.genCtrl.getNewConditionsObject();
    loggedUser: any;

    imageHost: string = this.utils.getEnvironmentValue('amazonS3Url');
    center = {lat: 45.516136, lng: -73.65683};
    isInternal: boolean = true;
    minimumStayVar: string;
    showMinimumStay: boolean = true;

    usingRentCalculator2: boolean = false;

    defaultContentHeight: number;
    safeVideoTourUrl: SafeResourceUrl = null;

    ngOnInit() {
        this.utils.executeBrowserOnly(async () => {
            window.scrollTo(0, 0);
            this.conditions.isBrowser = true;
        });

        this.subscriptions.url = this.route.params.subscribe(async (params) => {
            this.id = params['id'];
            
            this.utils.showOverlay();
            this.apartment = await this.aptCtrl.getApartment(this.id).catch(() => {
                this.utils.hideOverlay();
                return this.router.navigate(['404']);
            });

            try {
                if (!this.apartment || !this.apartment.owner.userId) {
                    this.utils.hideOverlay();
                    return this.router.navigate(['404']);
                }
                if (this.apartment && this.apartment.photos) {
                    let isFrench = window.location.toString().includes('/fr/');
                    this.apartment.photos = this.apartment.photos.map(photo => {
                        if (photo.tag !== undefined) {
                            photo.tag = isFrench ? photo.tag.replace('Bedroom', 'Chambre') : photo.tag;
                        }
                        return photo;
                    })
                }
                this.properties.furnishedBedrooms = this.aptCtrl.getFurnishedBedroomsCount(
                    this.apartment
                );
                this.sizeLabel = this.utils.getApartmentSize(this.apartment.size);
                if (this.utils.getLoggedUser()) {
                    this.loggedUser = await this.userCtrl.getUser(this.utils.getLoggedUser().id);
                }
                else {
                    this.loggedUser = null;
                }

                if (this.apartment.adress.lat && this.apartment.adress.lng) {
                    this.center = {
                        lat: this.apartment.adress.lat,
                        lng: this.apartment.adress.lng
                    };
                }

                this.utils.executeBrowserOnly(async () => {
                    this.agmMap.triggerResize();
                });

                this.aptCtrl.setSEO(this.apartment);
                if (this.apartment.videoTour) {
                    this.safeVideoTourUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.getEmbedUrl(this.apartment.videoTour));
                }

                // Find if rooms have different minimum stays
                let minimumStay = this.apartment.rooms[0].minimumStay;
                for (const room of this.apartment.rooms) {
                    if (room.minimumStay !== minimumStay) {
                        this.showMinimumStay = false;
                        break;
                    }
                }

                this.getMinimumStayVar();

                this.setIsOwner();
                await this.setIsRoommate();
                await this.setIsBookmarked();

                this.properties = Object.assign(
                    this.properties,
                    this.aptCtrl.initExtras(this.apartment)
                );
            } catch (e) {
                console.error(e);
            }

            this.utils.hideOverlay();
        });
    }

    ngAfterViewInit() {
        this.onResize({});
    
        let timer = setInterval(() => {
            try {
                this.defaultContentHeight = document.getElementsByClassName("readmore-link")[0].parentElement.clientHeight;
                clearInterval(timer);
                console.log(this.defaultContentHeight)
            }
            catch (e) { }
        }, 100);
    }

    showMore() {
        var readMore = document.getElementsByClassName("readmore-link");

        readMore[0].classList.toggle('expand');
        readMore[0].parentElement.classList.toggle('expand');
    }

    showHideBedroom(item){
        var showDiv = document.getElementsByClassName('bedroom-menu');

        showDiv[item].classList.toggle('active');
        var arrow = showDiv[item].querySelector('i.fa');
        arrow.classList.toggle('rotate');

        var checkboxes = showDiv[item].querySelector('.myMenu');

        if(checkboxes["style"].display === "block"){checkboxes["style"].display = "none";}
        else{checkboxes["style"].display = "block";}
    }

    setIsOwner() {
        if (this.loggedUser) {
            let ownerId;
            if (this.apartment.owner.userId) {
                if (this.apartment.owner.userId._id) {
                    ownerId = this.apartment.owner.userId._id;
                } else {
                    ownerId = this.apartment.owner.userId;
                }
            } else {
                ownerId = this.apartment.owner;
            }
            this.conditions.isOwner = (this.loggedUser._id == ownerId);
        }
    }

    async setIsRoommate() {
        if (this.loggedUser) {
            this.conditions.isRoommate = await this.aptCtrl.isUserRoommate(
                this.apartment,
                this.loggedUser
            );
        }
    }

    async setIsBookmarked() {
        if (this.loggedUser) {
            this.conditions.isBookmarked = await this.userCtrl.isObjectBookmarked(
                this.loggedUser,
                this.id
            );
        }
    }

    onShare() {
        let imageHost = this.utils.getEnvironmentValue('amazonS3Url');

        this.fb.ui({
            method: 'feed',
            href: document.URL,
            app_id: environment.fbAppId,
            redirect_uri: document.URL,
            quote: document.URL,
            action_properties: JSON.stringify({
                'share': {
                    'og:type': 'website',
                    'og:url': document.URL,
                    'href': document.URL,
                    'og:title': this.apartment.name,
                    'og:description': this.apartment.description,
                    'og:image': imageHost + 'apartment/' + this.apartment.photos[0].source,
                    'og:image:secure_url': imageHost + 'apartment/' + this.apartment.photos[0].source,
                    'og:image:alt': document.URL,
                    'og:site_name': 'Hivenue'
                }
            }),
            picture: imageHost + 'apartment/' + this.apartment.photos[0].source,
            caption: this.apartment.name,
            description: this.apartment.description,
            name: this.apartment.name
        });
    }

    onEdit(aptId) {
        this.router.navigate([this.utils.getCurrentLang() + "/share/apartment"], { queryParams: { edit: aptId } });
    }

    openMfp() {
        myExtObject.openMfp();
    }

    ngOnDestroy() {
        this.utils.executeBrowserOnly(() => {
            this.utils.showOverlay();
            [
                this.subscriptions.url,
                this.subscriptions.logout,
                this.subscriptions.login,
                this.subscriptions.urlChange
            ].map((obs) => this.utils.unsubscribeObservable(obs));
        });
    }

    onOpenModal() {
        let modalEl = this.genCtrl.getModal(
            this.el,
            this.renderer,
            'div.sendMessageModal',
            true
        );
    }

    scrollToBook() {
        window.scrollTo({
            top: document.getElementById('rent-calculator2').offsetTop + 450,
            left: 0,
            behavior: 'smooth'
        });
    }

    async bookmark() {
        const success = await this.userCtrl.setBookmark(
            this.id,
            true,
            this.conditions.isBookmarked
        );
        if (success) {
            this.conditions.isBookmarked = !this.conditions.isBookmarked;
        }
    }

    @HostListener('window:scroll', ['$event']) // for window scroll events
    onScroll(event) {
        let prefix = 40
        if (this.apartment.newFlow) {
            prefix = 400
        }

        // Fix the book button at bottom
        if (!document.getElementById('rent-calculator2'))
            return;
        let maxTop = document.getElementById('rent-calculator2').offsetTop;
        if (window.pageYOffset < maxTop) {
            document.getElementById('book-button').style.visibility = 'unset';
            document.getElementById('book-button').style.position = 'fixed';
            document.getElementById('book-button').style.top = (window.innerHeight - 55) + 'px';
            document.getElementById('book-button').style.height = '50px';
        } else {
            document.getElementById('book-button').style.visibility = 'hidden';
        }
    }

    // for window resize events
    @HostListener('window:resize', ['$event'])
    onResize(event) {
        // Check window size
        this.usingRentCalculator2 = (window.innerWidth < 992);

        this.onScroll({});
    }

    getMinimumStay() {
        if (this.apartment.minimumStay === '1') {
            return 'No minimum stay';
        } else if (this.apartment.minimumStay === '13') {
            return '1 year';
        } else if (this.apartment.minimumStay === '14') {
            return '1+ year';
        } else {
            return (parseInt(this.apartment.minimumStay.toString(), 10) - 1).toString() + ' months';
        }
    }

    getMinimumStayVar() {
        this.apartment.minimumStay = '1';
        for (const room of this.apartment.rooms) {
            if (room.minimumStay && parseInt(room.minimumStay, 10) > parseInt(this.apartment.minimumStay.toString(), 10)) {
                this.apartment.minimumStay = room.minimumStay;
            }
        }
        this.minimumStayVar = this.getMinimumStay();
        return this.minimumStayVar;
    }
    formatAmount(number) {
        return this.utils.formatCurrency(_.round(number, 0), this.utils.getCurrentLang())
    }

    getRentText(rentType) {
        switch (rentType.toLowerCase()) {
            case 'monthly':
                return this.utils.getTranslator().instant('Month');

            case 'weekly':
                return this.utils.getTranslator().instant('Week_short');

            case 'daily':
                return this.utils.getTranslator().instant('Day');
        }
    }

    getEmbedUrl(url: string): string {
        return this.utils.transformYoutubeURL2EmbedURL(url);
    }

    isValidYtbUrl(url: string): boolean {
        if (url) {
            return this.utils.matchYoutubeUrl(url);
        }
        return false;
    }

    onMatchClick() {
        let modalEl = this.genCtrl.getModal(
            this.el,
            this.renderer,
            'div.matchDetailsModal',
            true
        );
    }

    isMatch() {
        return (this.loggedUser && this.loggedUser.aboutme && this.loggedUser.keywords && this.loggedUser.keywords.length > 0) &&
            (this.apartment.matchLevel || this.apartment.neighborhoodMatchLevel) &&
            (this.apartment.matchLevel !== 'neutral' || this.apartment.neighborhoodMatchLevel !== 'neutral')
    }

    isMobileUA() {
        // Credits: http://detectmobilebrowsers.com/
        let check = false;
        (function (a) {
        if (
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
            a
            ) ||
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
            )
        )
            check = true;
        })(navigator.userAgent || navigator.vendor || (window as any).opera);
        return check;
    }

    goBackToListings() {
        const selectedCity = localStorage.getItem('selectedCity');
        if (selectedCity) {
            this.router.navigate([`${this.utils.getCurrentLang()}/${selectedCity}/apartments`]);
        } else {
            this.router.navigate([`${this.utils.getCurrentLang()}/apartments`]);
        }
    }

    convertDate(date) {
        const localDate = date.toString().split('T')[0];
        const locale = this.utils.getCurrentLang() === 'fr' ? 'fr' : 'en';
        return this.utils.formatLocaleDateString(localDate, locale);
    }

    getMinimumStayString(id) {
        let result = '';
        if (id === '1') {
            result = 'No minimum stay';
        } else if (id === '13') {
            result = '1 year';
        } else if (id === '14') {
            result = '1+ year';
        } else {
            result = (parseInt(id, 10) - 1).toString() + ' months';
        }
        return this.utils.getTranslator().instant(result);
    }

    formatAvailableFromDate(availableFromDate) {
        if (!availableFromDate) {
            availableFromDate = new Date();
        }
        if (typeof availableFromDate === 'string') {
            availableFromDate = availableFromDate.split('T')[0];
        }
  
        if (typeof(availableFromDate) === 'number') {
            availableFromDate = new Date(availableFromDate);
        }
  
        let cultureLang = this.translate.getBrowserCultureLang();
        if (!cultureLang.includes(this.translate.currentLang)) {
            cultureLang = this.translate.currentLang == "en" ? "en-US" : "fr-FR";
        }
  
        // Display year if the year is not the current year
        if (new Date(availableFromDate).getFullYear() !== new Date().getFullYear()) {
            return moment(availableFromDate).locale(cultureLang).format('MMM DD, YYYY');
        }
        else {
            return moment(availableFromDate).locale(cultureLang).format('MMM DD');
        }
    }
}
