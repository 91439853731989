import { Location } from "@angular/common";
import { Component, ElementRef, Renderer2, Input } from "@angular/core";
import { Utils } from "../../../utils/utils";
import { Cookie } from "ng2-cookies";
import { Router, ActivatedRoute, RouterEvent } from "@angular/router";

import { UserController, GeneralController } from "../../../controllers/controllers.module";

@Component({
  selector: "login",
  templateUrl: "./login.component.html",
    styleUrls: ["./login.component.css"]
})
export class LoginComponent {
  constructor(
    private utils: Utils,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private location: Location,
    private el: ElementRef,
    private renderer: Renderer2,
    private userCtrl: UserController,
    private genCtrl: GeneralController
  ) {}

  body: any;
  lang: string;

  state: number = 0;    // 0: Default sceren; 1: personal info; 2: forgot password; 3: reset password
  email: string = '';
  password: string = '';
  invalidCredentials: boolean = false;
  errMsg: string;

  resetPasswordEmail: string;

  resettingEmail: string;
  resettingToken: string;
  resettingPassword: string;
  resettingConfirmPassword: string;

  registerEmail: string;
  registerPassword: string;
  registerConfirmPassword: string;
  firstName: string;
  lastName: string;
  invalidRegister: boolean = false;
  errMsgRegister: string;
  showNewPassword: boolean = false;
  showPassword: boolean = false;
  showResetNewPassword: boolean = false;
  showResetConfirmNewPassword: boolean = false;
  showConfirmNewPassword: boolean = false;
  redirect: string = '';
  redirectParam: string = '';
  messageBoxText: string = '';
  googleClientId: string = this.utils.getEnvironmentValue("googleClientId");

  ngOnInit() {
    this.utils.executeBrowserOnly(async () => {
      // Initialize Google login
      let scriptElem = document.createElement('script'),
          scriptElemParent = document.getElementsByTagName('script')[0];
      let that = this;
      scriptElem['src'] = 'https://accounts.google.com/gsi/client';
      scriptElem.addEventListener('load', function (e) {
        window.onload = () => {
          window['google'].accounts.id.initialize({
            client_id: that.utils.getEnvironmentValue("googleClientId"),
            ux_mode: 'popup',
            callback: function (response) {
              alert(response);
            }
          });
        };
      }, false);
      scriptElemParent.parentNode.insertBefore(scriptElem, scriptElemParent);

      // Create Google login callback function
      window['googleLoginCallback'] = (response) => {
        this.onLoginGoogle(response.credential);
      };

      this.lang = Cookie.get("lang") == "en" ? "en" : "fr";
      window.scrollTo(0, 0);

      this.router.events.subscribe(event => {
        this.routerChange(event)
      })

      this.body = this.el.nativeElement.closest("body");
      this.renderer.addClass(this.body, "login");
      
      const { token, email, action, redirect, redirectParam } = this.activatedRoute.snapshot.queryParams;
      this.redirect = redirect;

      this.redirectParam = '';
      if (redirectParam) {
        for (let item of JSON.parse(redirectParam)) {
          this.redirectParam += item.key + '=' + item.value + '&';
        }
        this.redirectParam = this.redirectParam.slice(0, -1);
      }

      if (token && email) {
        if (action === "confirm") {
          let { success, error } = await this.userCtrl.confirmUserEmail({
            email: email,
            token: token,
          });
          this.goToState(0);
          if (success) {
            const message = this.utils
              .getTranslator()
              .instant("Email confirmed! You can now login.");
            this.showMessage(message);
          } else {
            if (error.toString() === 'TOKEN_EXPIRED') {
              const message = this.utils
                .getTranslator()
                .instant('The link you are using has expired. A new link has been generated and sent to your email, please confirm.');
              return error.json();
            }
            const message = this.utils
              .getTranslator()
              .instant(error.toString());
            this.showMessage(message);
          }
          
          // Clean the URL
          this.router.navigate([], {
            queryParams: {}
          });
        } else if (action === "reset") {
          this.resettingEmail = email;
          this.resettingToken = token;
          this.goToState(3);
        }
      } else {
        if (action === "signUp") {
          this.showRegisterForm()
        }
      }
      this.utils.hideOverlay();
    });

    this.utils.setSeoMeta();
  }

  goBack() {
    this.router.navigate([`/${this.lang}/login`], {
      queryParams: {}
    }).then(res => {
      this.showLoginForm()
    });
  }

  showLoginForm() {
    this.password = "";
    this.goToState(0);
    setTimeout(() => {
      this.el.nativeElement.querySelector("#email").focus();
    }, 200);
  }

  goToState(newState) {
    this.state = newState;
    window.scrollTo(0, 0);
  }

  emailValid(email) {
    const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(email);
  }

  passwordValid(password) {
    // Requirements: at least 8 characters, at least 1 uppercase, at least 1 lowercase, at least 1 number, at least 1 special character
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[-\._!"`'#%&,:;<>=@{}~\$\(\)\*\+\/\\\?\[\]\^\|]).{8,}$/;
    return regex.test(password);
  }

  isEmailValid() {
    return this.emailValid(this.email);
  }
  
  isResetEmailValid() {
    return this.emailValid(this.resetPasswordEmail);
  }

  isPasswordValid() {
    return this.passwordValid(this.password);
  }

  isRegisterInfoValid() {
    return (
      this.emailValid(this.registerEmail) &&
      this.registerPassword &&
      this.passwordValid(this.registerPassword) &&
      this.registerPassword === this.registerConfirmPassword &&
      this.firstName &&
      this.lastName
    );
  }

  isResettingFormValid() {
    return (
      this.resettingPassword &&
      this.resettingConfirmPassword &&
      this.passwordValid(this.resettingPassword) &&
      this.resettingPassword === this.resettingConfirmPassword
    );
  }

  handleEmailEnter(event) {
    if (event.keyCode === 13) {
      if (this.isEmailValid()) {
        this.el.nativeElement.querySelector("#password").focus();
        this.el.nativeElement.querySelector("#password").select();
      }
    }
  }

  handlePasswordEnter(event) {
    if (event.keyCode === 13) {
      if (this.isEmailValid() && this.isPasswordValid()) {
        this.onLoginEmail();
      }
    }
  }
  
  handleConfirmPasswordEnter(event) {
    if (event.keyCode === 13) {
      if (this.isRegisterInfoValid()) {
        this.onRegister();
      }
    }
  }

  handleResetEnter(event) {
    if (event.keyCode === 13) {
      if (this.isResetEmailValid()) {
        this.resetPasswordRequest();
      }
    }
  }

  handleResettingEnter(event) {
    if (event.keyCode === 13) {
      if (this.isResettingFormValid()) {
        this.resetPasswordConfirm();
      }
    }
  }

  redirectUser(user, newUser) {
    if (user) {
      if (user.confirmEmail) {
        throw {_body: JSON.stringify({error: user.confirmEmail})};
      }
      this.setCookie(user);

      const url = `${this.utils.getCurrentLang()}/apartments`;
      if ((user.onboarding_completed === undefined || user.onboarding_completed === false) &&
        (user.onboarding_skipped === undefined || user.onboarding_skipped === false)) {
        this.location.go(`${this.utils.getCurrentLang()}/onboarding`);
        window.location.reload();
      } else if (newUser) {
        if (this.redirect) {
          this.location.go(this.redirect);
        } else {
          this.location.go(`${url}?new_signup=true`);
        }
        window.location.reload();
      } else {
        user.id = user._id;
        this.utils.setLoggedUser(user);
        this.userCtrl.getLoginService().loginCompletedNotifyOther();
        if (this.redirect) {
          this.location.go(this.redirect);
          window.location.reload();
        } else {
          this.router.navigate([url]);
        }
      }
    }
  }

  async onLoginFB() {
    try {
      this.utils.showOverlay();
      const { user, newUser }: any = await this.userCtrl.signIn();
      this.utils.hideOverlay();
      this.redirectUser(user, newUser);
    } catch (error) {
      this.utils.hideOverlay();
      this.showMessage(
        this.utils.getTranslator().instant(
          JSON.parse((error as any)._body).error
        )
      );
    }
  }

  async onLoginGoogle(credential: string) {
    try {
      this.utils.showOverlay();
      const { user, newUser }: any = await this.userCtrl.signInGoogle(credential);
      this.utils.hideOverlay();
      this.redirectUser(user, newUser);
    } catch (error) {
      this.utils.hideOverlay();
      this.showMessage(
        this.utils.getTranslator().instant(
          JSON.parse((error as any)._body).error
        )
      );
    }
  }

  async onLoginEmail() {
    // display spinner
    this.utils.showOverlay();
    const { error, user }: any = await this.userCtrl.signInEmail(this.email, this.password);

    
    if (user && user._id) {
      this.invalidCredentials = false;
      this.setCookie(user);

      user.id = user._id;
      this.utils.setLoggedUser(user);
      this.userCtrl.getLoginService().loginCompletedNotifyOther();

      if ((user.onboarding_completed === undefined || user.onboarding_completed === false) &&
          (user.onboarding_skipped === undefined || user.onboarding_skipped === false)) {
        this.location.go(`${this.utils.getCurrentLang()}/onboarding`);
      } else if (this.redirect) {
        this.location.go(this.redirect);
      } else {
        const url = `${this.utils.getCurrentLang()}/apartments`;
        this.location.go(`${url}`);
      }
      window.location.reload();
    } else {
      this.utils.hideOverlay();
      this.invalidCredentials = true;
      if (error) {
        const message = this.utils
          .getTranslator()
          .instant(error.toString());
        this.showMessage(message);
      } else {
        this.errMsg = "Unable to verify email/password combination";
      }
      this.el.nativeElement.querySelector("#password").select();
    }
  }

  async onRegister() {
    this.utils.showOverlay();

    const { error, success, confirmEmail }: any = await this.userCtrl.registerEmail({
      email: this.registerEmail,
      password: this.registerPassword,
      first_name: this.firstName,
      last_name: this.lastName
    });

    if (success || confirmEmail) {
      this.invalidRegister = false;
      this.goToState(0);
      this.registerEmail = "";
      this.registerPassword = "";
      this.registerConfirmPassword = "";
      this.firstName = "";
      this.lastName = "";
      
      const message = this.utils
        .getTranslator()
        .instant("Please check your email to confirm your account.");
      
      this.utils.hideOverlay();
      this.showMessage(message);
    }
    else {
      this.invalidRegister = true;
      this.utils.hideOverlay();
      if (error) {
        this.errMsgRegister = error.toString();
      } else {
        this.errMsgRegister = "Failed to register the user, please confirm your information is correct";
      }
    }
  }

  async resetPasswordRequest() {
    await this.userCtrl.resetPasswordReq(this.resetPasswordEmail);
    this.resetPasswordEmail = "";

    const message = this.utils
      .getTranslator()
      .instant("A reset email has been sent");
    this.showMessage(message);
    this.goToState(0);
    setTimeout(() => {
      this.el.nativeElement.querySelector("#email").focus();
    }, 200);
  }

  async resetPasswordConfirm() {
    const { error }: any = await this.userCtrl.resetPasswordConfirm(this.resettingEmail, this.resettingToken, this.resettingPassword);
    if (error) {
      const message = this.utils
        .getTranslator()
        .instant(error.toString());
      this.showMessage(message);
    } else {
      const message = this.utils
        .getTranslator()
        .instant("Password has been reset!");
      this.showMessage(message);
      this.goToState(0);
      const url = this.router
        .createUrlTree([], {
            relativeTo: this.activatedRoute,
            queryParams: {}
        })
        .toString();
      if (this.redirect) {
        this.location.go(this.redirect);
      } else {
        this.location.go(url);
      }
      window.location.reload();
      setTimeout(() => {
        this.el.nativeElement.querySelector("#email").focus();
      }, 200);
    }
  }

  setCookie(data) {
    this.utils.setUserLoginCookies(data);
    // Cookie.set("user", JSON.stringify({ id: btoa(data._id) }), 365, "/");
  }

  ngOnDestroy() {
    this.utils.executeBrowserOnly(() => {
      this.utils.showOverlay();
      this.renderer.removeClass(this.body, "login");
    });
  }

  goToPage(url) {
    const newUrl = this.router.serializeUrl(
      this.router.createUrlTree([`/${this.lang}/${url}`])
    );
    window.open(newUrl, '_blank');
  }

  switchToSignUp() {
    const queryParams = { queryParams: { action: "signUp" }}
    this.router.navigate([`/${this.lang}/login`], queryParams)
  }

  showRegisterForm() {
    this.goToState(1)
    this.registerEmail = this.email;
    setTimeout(() => {
      this.el.nativeElement.querySelector("#first_name").focus();
    }, 200);
  }

  showMessage(msg) {
    this.messageBoxText = msg;
    const successModal = this.el.nativeElement.querySelector(
        'div.modal-success'
    );
    this.genCtrl.showModal(this.renderer, successModal);
  }

  routerChange(event) {
    const action = this.router.routerState.snapshot.root.queryParams.action
    if (action == "signUp") {
      this.showRegisterForm()
    } else if (action == undefined || action == "") {
      this.showLoginForm()
    }
  }

}
