import { Component, ElementRef, OnInit, Renderer2 } from '@angular/core'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { Utils } from '../../../utils/utils'
import { Options } from 'ng5-slider'
import * as _ from 'lodash'
import {
  UserController,
  FeedbackController,
  ApartmentController,
  GeneralController,
} from '../../../controllers/controllers.module'
import { Subject } from 'rxjs'
import { FormArray } from '@angular/forms'
import {
  FeedbackForm,
  FORM_INITIAL_VALUES,
  InputType,
  PendingFeedbackApiResponse,
  Question,
} from './feedback.component.type'

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css'],
})
export class FeedbackComponent implements OnInit {
  // html var ref
  public currentLang = this.utils.getCurrentLang()
  InputType = InputType

  apartments: any
  subscriptions: any = {}
  loggedUser: any

  // local var
  questions: Question[]
  answer: any = {}
  feedbackFormId: string
  pendingFeedbackResponse: PendingFeedbackApiResponse
  submitting = false
  errorModal: any
  errorMessage: string

  // form to submit
  form: FormGroup

  // slider params
  public manualRefresh = new Subject<void>()
  sliderOptions: { [key: string]: Options }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private utils: Utils,
    private userCtrl: UserController,
    private feedbackCtrl: FeedbackController,
    private aptCtrl: ApartmentController,
    private fb: FormBuilder,
    private genCtrl: GeneralController,
  ) {
    this.utils.executeBrowserOnly(() => {
      this.subscriptions.logout = this.userCtrl
        .getSubscribeLogout(true)
        .subscribe((res) => {
          this.router.navigate([this.utils.getCurrentLang() + '/login'], {
            queryParams: {
              redirect: this.router.url,
              redirectParam: JSON.stringify(this.route.snapshot.queryParams),
            },
          })
        })
    })
    this.questions = []
    this.sliderOptions = {}
  }

  ngOnInit() {
    this.route.params.subscribe(async (params) => {
      this.utils.executeBrowserOnly(async () => {
        try {
          this.pendingFeedbackResponse = (
            await this.feedbackCtrl.fetchPendingFeedbackById(params.feedbackId)
          ).json()
          if (this.pendingFeedbackResponse) {
            this.feedbackCtrl
              .fetchFeedbackQuestions(this.pendingFeedbackResponse.userStatus)
              .then((res) => {
                const feedbackForm = res.json() as FeedbackForm
                this.questions = feedbackForm.questions
                this.feedbackFormId = feedbackForm._id
                this.form = this.createFormGroup()
                this.questions.forEach((question) => {
                  if (question.inputType !== InputType.RANGE) return
                  // set sliderOptions
                  this.sliderOptions[question._id] = {
                    floor: question.options[question.inputType].min,
                    ceil: question.options[question.inputType].max,
                    step: 1,
                    showTicks: true,
                  }
                })
              })
          }
        } catch (e) {
          const error = e.json().error
          this.errorMessage =
            error === 'alreadyAnswered' || error === 'wrongUser'
              ? error
              : 'feedbackFormError'
        }
      })
    })

    this.utils.setSeoMeta()
    this.utils.executeBrowserOnly(async () => {
      window.scrollTo(0, 0)

      this.loggedUser = this.utils.getLoggedUser()
      if (this.loggedUser) {
        try {
          this.apartments = await this.aptCtrl.getBookmarkedApartments(
            this.loggedUser.id,
          )
        } catch (e) {
          this.router.navigate(['404'])
        }
        this.utils.hideOverlay()
      } else {
        this.router.navigate(['404'])
      }
    })
  }

  onErrorOk() {
    this.router.navigate([this.currentLang])
  }

  createFormGroup = () => {
    const group: any = {}
    this.questions.forEach((question) => {
      if (question.inputType === InputType.CHECKBOX) {
        group[question._id] = new FormArray([], {
          validators: Validators.minLength(1),
        })
        return
      }

      group[question._id] = question.required
        ? new FormControl(
            FORM_INITIAL_VALUES[question.inputType],
            Validators.required,
          )
        : new FormControl(FORM_INITIAL_VALUES[question.inputType])
    })
    return this.fb.group(group)
  }

  onChangeSlider(event, questionId) {
    this.form.controls[questionId].setValue(event.value)
    this.manualRefresh.next()
  }

  onCheckboxCheck(event, questionId) {
    const checkArray: FormArray = this.form.get(`${questionId}`) as FormArray
    if (event.target.checked) {
      checkArray.push(new FormControl(event.target.value))
    } else {
      checkArray.controls.forEach((item: FormControl, idx) => {
        if (item.value === event.target.value) {
          checkArray.removeAt(idx)
          return
        }
      })
    }
  }

  checkboxRequiredFilled() {
    let valid = true
    this.questions
      .filter((q) => q.inputType === InputType.CHECKBOX && q.required)
      .forEach((requiredCheckboxQuestion) => {
        if (this.form.value[requiredCheckboxQuestion._id].length < 1)
          valid = false
      })
    return valid
  }

  onSubmit = () => {
    this.router.navigate([this.currentLang])
    this.submitting = true
    this.feedbackCtrl
      .postFeedbackAnswers(
        this.form.value,
        this.questions,
        this.feedbackFormId,
        this.pendingFeedbackResponse,
      )
      .then(() => {
        this.submitting = false
        this.genCtrl.triggerAlertSuccess(
          this.utils.getTranslator().instant('Feedback submitted'),
        )
      })
  }
}
