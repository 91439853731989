import {Injectable} from '@angular/core';
import {Utils} from '../utils/utils';
import {Headers, Http, RequestOptions} from '@angular/http';

@Injectable()
export class PaymentController {
    constructor(
        private http: Http,
        private utils: Utils,
    ) {
    }

    prepareHeaders(authRequest = true) {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (authRequest) {
            headers.append('authentication', this.utils.getLoggedUser().id);
            headers.append('Authorization', this.utils.getLoginToken());
        }
        return new RequestOptions({headers: headers});
    }

    async getCountries() {
        return this.http.get(`${this.utils.getEnvironmentValue('apiUrl')}countries-list`).toPromise().then(response => {
            return response.json().content
        })
    }


    async saveBooking(data) {
        const options = this.prepareHeaders(true)
        return this.http
            .post(
                `${this.utils.getEnvironmentValue('apiUrl')}apartments/charge-and-book`,
                data,
                options
            )
            .toPromise()
            .then((response) => response.json());
    }

    async cauculateRent(requestId: string) {
        return this.http
            .get(
                `${this.utils.getEnvironmentValue('apiUrl')}rent-requests/${requestId}/calculate-rent`,
            )
            .toPromise()
            .then((response) => response.json());
    }
}
