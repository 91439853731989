import { Location } from "@angular/common";
import { Component, ElementRef, Renderer2 } from "@angular/core";
import { Router, ActivatedRoute, NavigationEnd } from "@angular/router";
import { Profile } from "../../../models/profile";
import { Utils } from "../../../utils/utils";
import { FacebookService } from "ngx-facebook";

import {
  ProfileController,
  UserController,
  GeneralController
} from "../../../controllers/controllers.module";

@Component({
  selector: "profileDetails",
  templateUrl: "./profileDetails.component.html"
})
export class ProfileDetailsComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private utils: Utils,
    private el: ElementRef,
    private location: Location,
    private renderer: Renderer2,
    private userCtrl: UserController,
    private genCtrl: GeneralController,
    private fb: FacebookService
  ) {
    this.profile = new Profile();

    this.subscriptions.urlChange = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.isInternal = event.id > 1;
      }
    });

    this.subscriptions.login = this.userCtrl
      .getSubscribeLogin(true)
      .subscribe(async () => {
        this.loggedUser = this.utils.getLoggedUser();

        this.setIsOwner();
        await this.setIsBookmarked();
      });

    this.subscriptions.logout = this.userCtrl
      .getSubscribeLogout(true)
      .subscribe(async () => {
        this.loggedUser = this.utils.getLoggedUser();
        this.conditions = Object.assign(this.conditions, { isOwner: false });
      });
  }

  profile: Profile;
  properties: any = {
    gender: "male",
    status: "",
    accomodations: [],
    accomodationsCriterias: [],
    facilities: []
  };
  subscriptions: any = {};
  modalEl = null;
  id: any;
  conditions = this.genCtrl.getNewConditionsObject();
  loggedUser: any;
  isInternal: boolean = true;

  sendMessageCallback: any = function() {
    this.setMailSent();
  };
  imageHost: string = this.utils.getEnvironmentValue("amazonS3Url");

  ngOnInit() {
    this.utils.executeBrowserOnly(() => {
      window.scrollTo(0, 0);
      this.conditions.isBrowser = true;
    });

    this.subscriptions.url = this.route.params.subscribe(async (params) => {
      this.id = params["id"];

      if (!this.profile || !this.profile.owner || !this.profile.owner.userId) {
        this.router.navigate(["404"]);
        return;
      }

      this.properties = {
        gender: this.profile.owner.userId.gender,
        status: this.profile.owner.userId.status
      };

      this.loggedUser = this.utils.getLoggedUser();

      this.setIsOwner();
      await this.setIsBookmarked();

      this.properties = Object.assign(
        this.properties,
      );

      this.utils.hideOverlay();
    });
  }

  ngOnDestroy() {
    this.utils.executeBrowserOnly(() => {
      this.utils.showOverlay();
      [
        this.subscriptions.login,
        this.subscriptions.logout,
        this.subscriptions.url,
        this.subscriptions.urlChange
      ].map((obs) => this.utils.unsubscribeObservable(obs));
    });
  }

  setIsOwner() {
    if (this.loggedUser) {
      this.conditions.isOwner =
        this.loggedUser.id == this.profile.owner.userId._id;
    }
  }

  async setIsBookmarked() {
    if (this.loggedUser) {
      this.conditions.isBookmarked = await this.userCtrl.isObjectBookmarked(
        this.loggedUser,
        this.id
      );
    }
  }

  onShare() {
    let imageHost = this.utils.getEnvironmentValue("amazonS3Url");
    
    this.fb.ui({
      method: 'feed',
      href: document.URL,
      app_id: this.utils.getEnvironmentValue("fbAppId"),
      redirect_uri: document.URL,
      quote: document.URL,
      action_properties: JSON.stringify({
        "share": {
          "og:type": "website",
          "og:url": document.URL,
          "href": document.URL,
          "og:title": this.profile.name,
          "og:description": this.profile.description,
          "og:image": imageHost + "user/" + this.profile.owner.photo,
          "og:image:secure_url": imageHost + "user/" + this.profile.owner.photo,
          "og:image:alt": document.URL,
          "og:site_name": "Hivenue"
        }
      }),
      picture: imageHost + "user/" + this.profile.owner.photo,
      caption: this.profile.name,
      description: this.profile.description,
      name: this.profile.name
    });
  }

  onOpenModal() {
    let modalEl = this.genCtrl.getModal(
      this.el,
      this.renderer,
      "div.sendMessageModal",
      true
    );
  }

  async bookmark() {
    const success = await this.userCtrl.setBookmark(
      this.id,
      false,
      this.conditions.isBookmarked
    );
    if (success) {
      this.conditions.isBookmarked = !this.conditions.isBookmarked;
    }
  }

  navBack() {
    if (this.isInternal) {
      this.location.back();
    } else {
      this.router.navigate([`${this.utils.getCurrentLang()}/roommates`]);
    }
  }
}
