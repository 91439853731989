import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "switch",
  templateUrl: "./switch.component.html",
  styleUrls: ["./switch.component.css"]
})
export class SwitchComponent {
  @Input()  checked: boolean;
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter();
  @Input() text: string = '';
  @Input() disabled: boolean = false;
  @Input() disabledReason: string = "";

  onChange(event) {
    const checked = event.target.checked;
    this.checked = checked;
    this.checkedChange.emit(checked);
  }
}
