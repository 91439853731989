import { Injectable, Inject } from "@angular/core";
import { Subject } from "rxjs/Subject";
@Injectable()
export class ApartmentService {
  constructor() {}

  private moveInDateNotify = new Subject<Date>();
  moveInDateNotifyObservable$ = this.moveInDateNotify.asObservable();

  public moveInDateNotifyOther(moveInDate: Date) {
    this.moveInDateNotify.next(moveInDate);
  }
}
