import {Injectable, PLATFORM_ID, Inject} from '@angular/core';
import {isPlatformBrowser, isPlatformServer} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {Constants} from './constants';
import {environment} from '../../environments/environment';
import {OverlayService} from '../modules/services/overlayService';
import * as moment from "moment";
import '../../assets/scripts/external.js';

declare var myExtObject: any;

require('aws-sdk/dist/aws-sdk');

@Injectable()
export class GeneralUtils {
    constructor(
        private _translate: TranslateService,
        private _constants: Constants,
        private overlayService: OverlayService,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
    }

    apartmentSize: any;
    minimumStayList: any;
    bedTypeList: any;
    propertyAccessList: any;
    hostWaitGuestTimeList: any;
    appSizes: any;
    accomodations: any;
    accomodationsFilter: any;
    accomodationsCriterias: any;
    facilitiesFilter: any;
    facilities: any;
    neighborhoods: any = {};
    _lang: string;
    purposesOfStay: any

    executeBrowserOnly(callback) {
        if (isPlatformBrowser(this.platformId)) {
            callback();
        }
    }

    executeServerOnly(callback) {
        if (isPlatformServer(this.platformId)) {
            callback();
        }
    }

    getEnvironmentValue(value: string) {
        if (typeof environment[value] !== 'undefined') {
            return environment[value];
        }
        return value;
    }

    getApartmentSize(id: string) {
        let label = '';
        for (let apartmentSize of this.getApartmentSizes()) {
            if (apartmentSize.id == id) {
                label = apartmentSize.label;
            }
        }

        return label;
    }

    getApartmentSizes(): any {
        if (!this.appSizes) {
            this.appSizes = this._constants.getApartmentSize();
        }
        return this.appSizes;
    }

    getMinimumStayList(): any {
        if (!this.minimumStayList) {
            this.minimumStayList = this._constants.getMinimumStayList();
        }
        return this.minimumStayList;
    }

    getBedTypeList(): any {
        if (!this.bedTypeList) {
            this.bedTypeList = this._constants.getBedTypeList();
        }
        return this.bedTypeList;
    }

    getPurposesOfStay(): any {
        if (!this.purposesOfStay) {
            this.purposesOfStay = this._constants.getPurposesOfStay();
        }
        return this.purposesOfStay;
    }

    getPropertyAccessList(): any {
        if (!this.propertyAccessList) {
            this.propertyAccessList = this._constants.getPropertyAccessList();
        }
        return this.propertyAccessList;
    }

    getHostWaitGuestTimeList(): any {
        if (!this.hostWaitGuestTimeList) {
            this.hostWaitGuestTimeList = this._constants.getHostWaitGuestTimeList();
        }
        return this.hostWaitGuestTimeList;
    }
    getAccomodations(): any {
        if (!this.accomodations) {
            this.accomodations = this._constants.getAccomodations();
        }
        return this.accomodations;
    }

    getAccomodationsCriterias(): any {
        if (!this.accomodationsCriterias) {
            this.accomodationsCriterias = this._constants.getAccomodationsCriterias();
        }
        return this.accomodationsCriterias;
    }

    getAccommodationsFilter(): any {
        if (!this.accomodationsFilter) {
            this.accomodationsFilter = this._constants.getAccommodationsFilter();
        }
        return this.accomodationsFilter;
    }

    getFacilities(): any {
        if (!this.facilities) {
            this.facilities = this._constants.getFacilities();
        }
        return this.facilities;
    }

    getFacilitiesFilter(): any {
        if (!this.facilitiesFilter) {
            this.facilitiesFilter = this._constants.getFacilitiesFilter();
        }
        return this.facilitiesFilter;
    }

    getTranslator() {
        return this._translate;
    }

    getCurrentLang() {
        return this._lang;
    }

    setCurrentLang(lang: string) {
        this._lang = lang;
        this._translate.use(lang);
    }

    getPagination(first, last) {
        let current = first,
            delta = 2,
            left = current - delta,
            right = current + delta + 1,
            range = [],
            rangeWithDots = [],
            l;

        for (let i = 1; i <= last; i++) {
            if (i == 1 || i == last || (i >= left && i < right)) {
                range.push(i);
            }
        }

        for (let i of range) {
            if (l) {
                if (i - l === 2) {
                    rangeWithDots.push(l + 1);
                } else if (i - l !== 1) {
                    rangeWithDots.push('...');
                }
            }
            rangeWithDots.push(i);
            l = i;
        }

        return rangeWithDots;
    }

    setTooltips() {
        myExtObject.tooltip();
    }

    setToggle() {
        myExtObject.toggle();
    }

    setListLayout() {
        myExtObject.listLayout();
    }

    showOverlay() {
        this.overlayService.showOverlayNotifyOther();
    }

    hideOverlay() {
        this.overlayService.hideOverlayNotifyOther();
    }

    unsubscribeObservable(observable) {
        if (observable) {
            observable.unsubscribe();
        }
    }

    formatCurrency(number, locale = 'en') {
        if (locale === 'en') {
            return '$' + Number(number).toLocaleString('en-GB') + ' CAD';
        } else {
            return Number(number).toLocaleString('fr-CA') + ' $ CAD';
        }
    }

    getApartmentPricingTitle(apartment) {
        let minPrice = 9e99,
        maxPrice = 0;
        let periodText;

        if (apartment.rentType === "Monthly") {
            periodText = this.getTranslator().instant("Month");
        } else {
            periodText = this.getTranslator().instant(apartment.rentType);
        }

        apartment.rooms.forEach((room) => {
            if (room.isHidden) return;
            if (room.price < minPrice) minPrice = room.price;
            if (room.price > maxPrice) maxPrice = room.price;
        });

        if (minPrice === maxPrice) return this.formatCurrency(minPrice, this.getCurrentLang()) + " / " + periodText;
        else return this.formatCurrency(minPrice, this.getCurrentLang()) + "+ / " + periodText;
    }

    formatLocaleDateString(date, lang) {
        return moment(date).locale(lang).format('MMM DD, YYYY');
    }

    titlecase(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
}
