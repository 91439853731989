import {Component, ElementRef} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Utils} from '../utils/utils';
import {LocationStrategy} from '@angular/common';
import {Cookie} from 'ng2-cookies';
import {NotifierService} from 'angular-notifier';

import {GeneralController} from '../controllers/controllers.module';
import { UserController } from '../controllers/controllers.module';
import { Router } from '@angular/router';

@Component({
    selector: 'app',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    subscriptions: any = {};
    oldBrowser: boolean = false;
    lang: string;
    browserDefaultLanguage: string;

    showSuccessAlert: boolean = false;
    successAlertMessage: string;

    showErrorAlert: boolean = false;
    errorAlertMessage: string;
    hideHeader = false
    hideFooter = false

    constructor(
        private el: ElementRef,
        private translate: TranslateService,
        private utils: Utils,
        private _url: LocationStrategy,
        private notifier: NotifierService,
        private genCtrl: GeneralController,
        private userCtrl: UserController,
        private router: Router
    ) {
        this.utils.executeBrowserOnly(() => {
            this.subscriptions.showOverlay = this.genCtrl
                .getSubscribeShowOverlay()
                .subscribe((res) => {
                    this.utils.executeBrowserOnly(() => {
                        document.body.classList.add('hasOverlay');
                    });
                });

            this.subscriptions.hideOverlay = this.genCtrl
                .getSubscribeHideOverlay()
                .subscribe((res) => {
                    this.utils.executeBrowserOnly(() => {
                        document.body.classList.remove('hasOverlay');
                    });
                });

            this.subscriptions.lang = this.genCtrl
                .getSubscribeLang()
                .subscribe(async () => {
                    const lang = document.createAttribute('lang');
                    lang.value = this.utils.getCurrentLang() == 'fr' ? 'fr' : 'en';
                    this.el.nativeElement.parentElement.parentElement.attributes.setNamedItem(
                        lang
                    );
                    
                    let currentPath = this._url.path();
                    if (currentPath.length >= 3) {
                        if (currentPath.substring(0, 3).toLowerCase() == '/fr') {
                            this.setLanguage('fr');
                        } else if (currentPath.substring(0, 3).toLowerCase() == '/en') {
                            this.setLanguage('en');
                        } 
                    } else {
                        this.lang = 'en'
                        this.utils.setCurrentLang(this.lang)
                        this.translate.use(this.lang);
                        Cookie.set('lang', this.lang, 365, '/'); 
                    }
                });

            this.subscriptions.alertSuccess = this.genCtrl
                .getAlertSuccessObservable()
                .subscribe((message) => {
                    this.notifier.notify('success', message);
                });

            this.subscriptions.alertError = this.genCtrl
                .getAlertErrorObservable()
                .subscribe((message) => {
                    this.notifier.notify('error', message);
                });
        });
    }

    getBrowserInfo() {
        var ua = navigator.userAgent,
            tem,
            M =
                ua.match(
                    /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
                ) || [];
        if (/trident/i.test(M[1])) {
            tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE ' + (tem[1] || '');
        }
        if (M[1] === 'Chrome') {
            tem = ua.match(/\b(OPR|Edge)\/(\d+)/);
            if (tem != null) {
                return tem
                    .slice(1)
                    .join(' ')
                    .replace('OPR', 'Opera');
            }
        }
        M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i)) != null) {
            M.splice(1, 1, tem[1]);
        }

        return M;
    }

    setLanguage(lang) {
        lang = (lang == 'fr') ? 'fr' : 'en';
        this.lang = lang
        this.utils.setCurrentLang(this.lang)
        this.translate.use(this.lang);
        Cookie.set('lang', this.lang, 365, '/');
        
        let currentPath = this._url.path();
        if (currentPath.substring(1, 3) != this.lang) {
            window.location.href = `/${
                lang + (window.location.pathname + window.location.search).substring(3)
              }`;
        }
    }

    setBrowserStatus() {
        var browser = this.getBrowserInfo();
        if (
            (browser[0] === 'MSIE' && parseInt(browser[1]) <= 9) ||
            (browser[0] === 'Safari' && parseInt(browser[1]) <= 9)
        ) {
            this.oldBrowser = true;
        }
    }
    
    ngOnInit() {
        this.utils.executeBrowserOnly(() => {
            // this.setAppLanguage();
            this.setBrowserStatus();
        });
    }

    setLang() {
        let lang = Cookie.check('lang') ? Cookie.get('lang') : 'fr';
        let currentPath = this._url.path();

        if (currentPath == '/') {
            return true;
        } else if (currentPath.substring(1, 3) != lang) {
            let oldCookie = Cookie.get('lang');
            if (!oldCookie) {
                Cookie.set('lang', currentPath.substring(1, 3), 365, '/');
            } else {
                Cookie.delete('lang', '/');
                Cookie.set(
                    'lang',
                    oldCookie && oldCookie == 'fr' ? 'en' : 'fr',
                    365,
                    '/'
                );
            }

            return true;
        } else {
            return true;
        }
    }


    ngOnDestroy() {
        [
            this.subscriptions.showOverlay,
            this.subscriptions.hideOverlay,
            this.subscriptions.lang,
            this.subscriptions.alertSuccess,
            this.subscriptions.alertError
        ].map((obs) => this.utils.unsubscribeObservable(obs));
    }

    isNotListing() {
        return this.utils.getPath().slice(3) != '/share/newapartment';
    }

    showHeader() {
        return !this.hideHeader && this.isNotListing()    
    }

    showFooter() {
        if (String(this._url.path()).includes('/rent-request-details') ||
            String(this._url.path()).includes('/application-summary') ||
            String(this._url.path()).includes('/payment') ||
            String(this._url.path()).includes('/share/apartment')
        ) {
            this.hideFooter = true;
        }
        return !this.hideFooter
    }
}
