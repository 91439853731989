import { Component } from '@angular/core';
import { Subscription } from 'rxjs/Subscription';
import { OverlayService } from '../../services/overlayService';

@Component({
  selector: 'overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.css']
})
export class OverlayComponent {
  	constructor(private overlayService: OverlayService) {}

    ngAfterViewInit(){
    	//this.overlayService.hideOverlayNotifyOther();
    }
}