import {Component, Input, OnInit, Renderer2, ElementRef, ChangeDetectorRef} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Location } from '@angular/common';
import {BsLocaleService, BsDatepickerConfig} from 'ngx-bootstrap/datepicker';
import {GeneralController, RentRequestController, ApartmentController} from '../../../controllers/controllers.module';
import {Utils} from '../../../utils/utils';
import {defineLocale, frLocale} from 'ngx-bootstrap/chronos';
import * as moment from 'moment';
import * as _ from 'lodash'
import {TranslateModule} from '@ngx-translate/core'
import {MAX_SHORT_STAY_DAYS} from '../../../utils/constants';

import {ConditionsObject} from '../../../models/conditionsobject';

@Component({
    selector: 'apartment-rent-calculator',
    templateUrl: './rent-calculator.component.html',
    styleUrls: ['./rent-calculator.component.css']
})
export class RentCalculatorComponent implements OnInit {
    @Input() apartment: any;
    @Input() isOwner: boolean;

    conditions: ConditionsObject = this.genCtrl.getNewConditionsObject();
    accordionMainClass = 'rooms-accordion bigger-input filter-input custom-input'
    moveInDate: Date
    moveOutDate: Date
    stayInDays = 0
    stayInMonths = 0
    utilityFee = 0
    roomRent = 0;
    roomRentPerMonth = 0;
    loginModal: any;
    tax = 0;
    totalRent = 0;
    totalAmountPayable = 0
    utilityRate = 0
    isDropDownOpen = false
    grossRoomRent = 0
    availableRooms = []
    rooms = []
    roomsChecked: any = {}
    heading = 'Please select a room'
    dropDownDisabled = false
    currentLang: string
    message: string
    error: string
    translate: any
    conflictError: boolean
    rentType: string
    rentTypeHeading: string

    setRoomEventSent: boolean = false;
    allSetEventSent: boolean = false;
    firstAvailableMoveInDate: Date;
    firstAvailableMoveOutDate: Date;
    disabledMoveInDates: Date[];
    disabledMoveOutDates: Date[];
    maxMoveOutDate: Date;

    bookingInfo: [any];
    MAX_SHORT_STAY_DAYS = MAX_SHORT_STAY_DAYS;

    constructor(
        private utils: Utils,
        private genCtrl: GeneralController,
        private localeService: BsLocaleService,
        private rentRequestController: RentRequestController,
        private aptCtrl: ApartmentController,
        private rentRequestCtrl: RentRequestController,
        private renderer: Renderer2,
        private el: ElementRef,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private location: Location,
        private translator: TranslateModule,
        private cdr: ChangeDetectorRef,
    ) {
        this.translate = translator
    }

    async ngOnInit() {
        this.utils.executeBrowserOnly(() => {
            if (this.utils.getCurrentLang() !== 'en') {
                defineLocale(this.utils.getCurrentLang(), frLocale);
                this.localeService.use(this.utils.getCurrentLang());
            }
            window['gtag']('event', 'General_Enter_details_page', {
                'event_category': 'General_Enter_details_page',
                'event_label': 'General_Enter_details_page'
            }); 
        })
        this.currentLang = this.utils.getCurrentLang()
        this.setRentType()
        if (this.apartment.rooms.length === 1 || this.apartment.wholeApartment) {
            this.setRoom(this.apartment.rooms[0])
            this.dropDownDisabled = true
        }

        // Handle whole apartment situation
        if (this.apartment.wholeApartment) {
            this.apartment.rooms[0].description = this.utils.getTranslator().instant('Entire place');
        }

        await this.updateDisabledMoveInDates();
        const { moveInDate, moveOutDate, rooms } = this.activatedRoute.snapshot.queryParams;
        this.moveInDate = moveInDate ? new Date(parseInt(moveInDate)) : undefined;
        this.moveOutDate = moveOutDate ? new Date(parseInt(moveOutDate)) : undefined;
        if (moveInDate && moveOutDate && rooms) {
            this.rooms = JSON.parse(rooms).map(room => this.apartment.rooms.find(r => r._id === room));
            this.rooms.forEach(room => this.roomsChecked[this.getRoomId(room)] = true);
            this.setGrossRoomRent();
            this.setHeading();
            this.calculateRent();
        }
    }

    translateRoomDesc(desc: string) {
        const tmp = desc.split(' ');
        return this.utils.getTranslator().instant('Room') + ' ' + this.utils.getTranslator().instant(tmp[1]);
    }

    translateRoomsDesc(rooms) {
        let res = '';
        for (let i = 0; i < rooms.length; ++i) {
            res += this.translateRoomDesc(rooms[i].description);
            if (i < rooms.length - 1) {
                res += ', '
            }
        }
        return res;
    }

    getDisabledMoveInDates() {
        return this.disabledMoveInDates;
    }

    getFirstAvailableMoveInDate() {
        return this.firstAvailableMoveInDate;
    }

    getFirstAvailableMoveOutDate() {
        return this.firstAvailableMoveOutDate;
    }

    getMaxMoveOutDate() {
        return this.maxMoveOutDate;
    }

    getDisabledMoveOutDates() {
        return this.disabledMoveOutDates;
    }

    

    getMinimumStayString(id) {
        let result = '';
        if (id === '1') {
            result = 'No minimum stay';
        } else if (id === '13') {
            result = '1 year';
        } else if (id === '14') {
            result = '1+ year';
        } else {
            result = (parseInt(id, 10) - 1).toString() + ' months';
        }
        return this.utils.getTranslator().instant(result);
    }

    formatAmount(number) {
        return this.utils.formatCurrency(_.round(number, 0), this.currentLang)
    }

    getRentText(rentType) {
        switch (rentType.toLowerCase()) {
            case 'monthly':
                return this.utils.getTranslator().instant('Month');

            case 'weekly':
                return this.utils.getTranslator().instant('Week_short');

            case 'daily':
                return this.utils.getTranslator().instant('Day');
        }
    }

    keepDatePart(date) {
        return moment(date.toISOString().split('T')[0]).toDate();
    }

    keepDate(date) {
        return date.toLocaleDateString('en-ca');
    }

    async updateDisabledMoveInDates() {
        const {
            requests,
            maxDate,
            disabledDates,
            firstAvailableMoveInDate
        } = await this.aptCtrl.getApartmentAvailability(this.apartment._id);

        this.disabledMoveInDates = disabledDates.map(date => this.keepDatePart(new Date(date)));
        if (firstAvailableMoveInDate) {
            this.firstAvailableMoveInDate = this.keepDatePart(new Date(firstAvailableMoveInDate));
        } else {
            this.firstAvailableMoveInDate = undefined;
        }
        this.maxMoveOutDate = new Date(maxDate);
        this.bookingInfo = requests;
        this.cdr.detectChanges();
    }

    async updateDisabledMoveOutDates() {
        // Remove the time part of the date
        if (this.moveInDate) {
            this.moveInDate.setHours(0, 0, 0, 0);
        } else {
            return;
        }

        const {
            requests,
            maxDate,
            disabledDates,
            firstAvailableMoveOutDate
        } = await this.aptCtrl.getApartmentAvailability(this.apartment._id, this.keepDate(this.moveInDate));

        this.disabledMoveOutDates = disabledDates.map(date => this.keepDatePart(new Date(date)));
        this.firstAvailableMoveOutDate = this.keepDatePart(new Date(firstAvailableMoveOutDate));
        this.maxMoveOutDate = new Date(maxDate);
        this.bookingInfo = requests;
        this.cdr.detectChanges();
    }

    async updateAvailableRooms() {
        // Make sure moveInDate and moveOutDate are set and valid
        if (!this.moveInDate || !this.moveOutDate) {
            return;
        }
        if (this.moveInDate > this.moveOutDate) {
            this.moveOutDate = undefined;
        }

        // Ignore hours, minutes, seconds, milliseconds
        this.moveInDate.setHours(0, 0, 0, 0);
        this.moveOutDate.setHours(0, 0, 0, 0);

        this.availableRooms = await this.aptCtrl.getApartmentAvailability(this.apartment._id, this.keepDate(this.moveInDate), this.keepDate(this.moveOutDate));

        // Only one room, force select that room
        if (this.apartment.wholeApartment || this.apartment.rooms.length === 1) {
            this.rooms = [this.apartment.rooms[0]];
            this.roomsChecked[this.apartment.rooms[0]._id] = true;
        }

        for (const room of this.apartment.rooms) {
            const roomAvailable = this.availableRooms.indexOf(room._id) !== -1;
            if (roomAvailable) {
                if (this.apartment.wholeApartment || this.apartment.rooms.length === 1) {
                    // If this is a whole apartment or single room listing, this is the only room available so add it to the selected rooms
                    this.roomsChecked[room._id] = true;
                    this.setGrossRoomRent();
                    this.setHeading();
                    this.calculateRent();
                }
            }
            else {
                // If the room is not available, remove it from the selected rooms
                // Except... This is the only room available, so force select it
                if (!(this.apartment.wholeApartment || this.apartment.rooms.length === 1)) {
                    let existingRoomIndex = -1;
                    for (let i = 0; i < this.rooms.length; i++) {
                        if (this.rooms[i]._id === room._id) {
                            existingRoomIndex = i;
                            break;
                        }
                    }
                    if (existingRoomIndex !== -1) {
                        this.rooms.splice(existingRoomIndex, 1);
                        this.roomsChecked[room._id] = false;
                        this.setGrossRoomRent();
                        this.setHeading();
                        this.calculateRent();
                    }
                }
            }
        }

        this.cdr.detectChanges();
    }

    checkRoomAvailable(room) {
        return this.availableRooms.indexOf(this.getRoomId(room)) !== -1;
    }

    calculateRent() {
        this.updateUrl();

        if (this.allSet()) {
            // Ignore hours, minutes, seconds, milliseconds
            this.moveInDate.setHours(0, 0, 0, 0);
            this.moveOutDate.setHours(0, 0, 0, 0);

            this.aptCtrl
                .getApartmentRent(
                this.moveInDate,
                this.moveOutDate,
                this.rooms,
                this.rentType
                )
                .then((res) => {
                    this.tax = res.tax;
                    this.roomRent = res.roomRent;
                    this.utilityFee = res.utilityFee;
                    this.totalRent = res.totalRent;
                    this.stayInDays = res.stay.days;
                    this.stayInMonths = res.stay.months;
                    this.totalAmountPayable = res.totalAmountPayable;
                    this.utilityRate = res.utilityRate;
                    this.roomRentPerMonth = res.roomRentPerMonth;
                });

            if (!this.allSetEventSent) {
                this.allSetEventSent = true
                window['gtag']('event', 'Booking_After_dates_selection', {
                    'event_category': 'Booking_After_dates_selection',
                    'event_label': 'Booking_After_dates_selection'
                });
            }
        }
    }

    dateOverlapped() {
        // Check if move-in date and move-out date does not have overlap with all items in bookingInfo
        if (this.bookingInfo && this.bookingInfo.length > 0) {
            for (const booking of this.bookingInfo) {
                for (let room of this.rooms) {
                    if (booking.roomIds.includes(room._id) &&
                        this.moveInDate < new Date(booking.moveOutDate) &&
                        this.moveOutDate > new Date(booking.moveInDate)
                       ) {
                        return true;
                    }
                }
            }
        }
        return false;
    }

    moveInDateIsBefore() {
        if (this.moveInDate) {
            return moment(this.moveInDate).isBefore(moment(), 'day');
        }
        return false;
    }

    allSet() {
        return this.rooms.length > 0 && this.moveInDate && this.moveOutDate &&
            !isNaN(this.moveInDate.getDate()) && !isNaN(this.moveOutDate.getDate()) &&
            (this.moveInDate < this.moveOutDate) && !this.dateOverlapped();
    }

    setRoom(event) {
        this.roomsChecked[this.getRoomId(event)] = !this.roomsChecked[this.getRoomId(event)];
        if (!_.isEmpty(this.rooms)) {
            let existingRoomIndex = -1
            this.rooms.forEach((room, key) => {
                if (this.getRoomId(room) === this.getRoomId(event)) {
                    existingRoomIndex = key
                }
            })
            if (existingRoomIndex > -1) {
                this.rooms.splice(existingRoomIndex, 1)
                this.setGrossRoomRent()
                this.setHeading()
                this.calculateRent()
            } else {
                this.rooms.push(event)
                this.setGrossRoomRent()
                this.setHeading()
                this.calculateRent()
            }
        } else {
            this.rooms.push(event)
            this.setGrossRoomRent()
            this.setHeading()
            this.calculateRent()
            if (!this.setRoomEventSent) {
                this.setRoomEventSent = true
                window['gtag']('event', 'Booking_After_room_selection', {
                    'event_category': 'Booking_After_room_selection',
                    'event_label': 'Booking_After_room_selection'
                });
            }
        }
    }

    setGrossRoomRent() {
        this.grossRoomRent = this.utils.totalRoomsRent(this.rooms)
    }

    setHeading() {
        const roomsLength = this.rooms.length
        if (this.rooms.length > 1) {
            this.heading = `${roomsLength} ${this.utils.getTranslator().instant('rooms')} - ${this.formatAmount(this.grossRoomRent)} / ` + this.utils.getTranslator().instant("Month")
        } else if (roomsLength === 1) {
            this.heading = `${!this.apartment.wholeApartment ? this.translateRoomDesc(this.rooms[0].description) : this.rooms[0].description} - ${this.formatAmount(this.grossRoomRent)} / ` + this.utils.getTranslator().instant("Month")
        } else {
            this.heading = 'Please select a room'
        }
    }

    preparePrice(apartment) {
        if (apartment.rooms.length > 1) {
            let minPrice = 9e99, maxPrice = 0;
            apartment.rooms.forEach(room => {
                if (room.price < minPrice) {
                    minPrice = room.price;
                }
                if (room.price > maxPrice) {
                    maxPrice = room.price;
                }
            });
            if (minPrice === maxPrice) {
                return `${this.formatAmount(minPrice)}`;
            } else {
                return `${this.formatAmount(minPrice)} - ${this.formatAmount(maxPrice)}`;
            }
        } else {
            return `${this.formatAmount(apartment.rooms[0].price)}`;
        }
    }

    expandDropDown(event) {
        if (this.dropDownDisabled === false) {
            this.isDropDownOpen = !this.isDropDownOpen;
        }
    }

    getRoomId(room) {
        if (!_.isEmpty(room._id)) {
            return String(room._id).trim()
        } else {
            return String(room.description).trim()
        }
    }

    showModal(modal) {
        this.genCtrl.showModal(this.renderer, modal);
    }

    userHasLoggedIn() {
        return !_.isEmpty(this.utils.getLoggedUser())
    }

    async submit(apartment) {
        if (!this.userHasLoggedIn()) {
            this.showModal(this.loginModal)
            return {}
        }
        const data = await this.rentRequestController.isStayValid(apartment._id, this.extractRoomIds(this.rooms))
        if (!data.validStay) {
            this.conflictError = true
        } else {
            await this.router.navigate([`/${this.utils.getCurrentLang()}/application-summary`])
            this.storeDataForNextComponent(apartment)
        }
    }

    storeDataForNextComponent(apartment) {
        const data = {
            apartment,
            totalRent: this.totalRent,
            roomRent: this.roomRent,
            tax: this.tax,
            utilityFee: this.utilityFee,
            utilityRate: this.utilityRate,
            totalAmountPayable: this.totalAmountPayable,
            moveInDate: this.moveInDate,
            moveOutDate: this.moveOutDate,
            stay: {
                days: this.stayInDays,
                months: this.stayInMonths
            },
            roomIds: this.extractRoomIds(this.rooms),
            user: this.utils.getLoggedUser(),
            roomRentPerMonth: this.roomRentPerMonth,
        }
        localStorage.setItem('bookingData', JSON.stringify(data))
    }

    extractRoomIds(rooms) {
        const roomIds = []
        rooms.forEach((room) => {
            roomIds.push(room._id)
        })
        return roomIds
    }

    ngAfterViewInit() {
        this.utils.executeBrowserOnly(() => {
            this.loginModal = this.el.nativeElement.querySelector('div.modal-login');
        });
    }


    setRentType() {
        switch (this.apartment.rentType) {
            case 'Daily':
                this.rentType = 'Daily'
                this.rentTypeHeading = 'Daily price'
                break;
            case 'Weekly':
                this.rentType = 'Weekly'
                this.rentTypeHeading = 'Weekly price'
                break;
            case 'Monthly':
                this.rentType = 'Monthly'
                this.rentTypeHeading = 'Monthly price'
                break;
            default:
                this.rentType = 'Monthly'
                this.rentTypeHeading = 'Monthly price'
                break;
        }
    }

    showContactHostWindow() {
        this.genCtrl.getModal(
            this.el,
            this.renderer,
            'div.sendMessageModal',
            true
        );
    }

    convertDate(date) {
        const localDate = date.toString().split('T')[0];
        const locale = this.currentLang === 'fr' ? 'fr' : 'en';
        return this.utils.formatLocaleDateString(localDate, locale);
    }
    
    getRoomsRentInfo() {
        let res = []
        let isDateSame = (date1: Date, date2: Date) => {
            return date1.getDate() === date2.getDate() &&
                    date1.getMonth() === date2.getMonth() &&
                    date1.getFullYear() === date2.getFullYear();
        }
        let findIndex = (room, rooms) => {
            for (let i = 0; i < rooms.length; ++i) {
                for (let j = 0; rooms[i].rooms && j < rooms[i].rooms.length; ++j) {
                    if (isDateSame(new Date(rooms[i].rooms[j].moveOutDate), new Date(room.moveOutDate)) &&
                        rooms[i].rooms[j].minimumStay === room.minimumStay) {
                            return i;
                    } 
                }
            }
            return -1;
        }
        for (let room of this.apartment.rooms) {
            let index = findIndex(room, res);
            if (index === -1) {
                let obj = {
                    rooms: []
                }
                obj.rooms.push(room);
                res.push(obj)
            } else {
                res[index].rooms.push(room)
            }
        }
        return res; 
    }

    generateDepositHelp() {
        const isMoveInDateNotOnFirst = moment(this.moveInDate).date() !== 1;
        const firstMonth = moment(this.moveInDate).locale(this.currentLang).format('MMMM, YYYY');
        if (isMoveInDateNotOnFirst) {
            const secondMonth = moment(this.moveInDate).add(1, 'month').locale(this.currentLang).format('MMMM, YYYY');
            return this.utils.getTranslator().instant('The deposit corresponds to the price for your stay during the month of') + ' ' + firstMonth + ' ' +
              this.utils.getTranslator().instant('and the price of the month') + ' ' + secondMonth;
        }
        else {
            return this.utils.getTranslator().instant('The deposit corresponds to the price for your stay during the month of') + ' ' + firstMonth
        }
    }


    updateUrl() {
        const url = this.router.createUrlTree([], {relativeTo: this.activatedRoute, queryParams: {
            moveInDate: this.moveInDate ? this.moveInDate.getTime() : undefined,
            moveOutDate: this.moveOutDate ? this.moveOutDate.getTime() : undefined,
            rooms: this.rooms ? JSON.stringify(this.rooms.map(room => room._id)) : undefined,
        }}).toString();
        this.location.go(url);
    }

    contactByWhatsapp() {
        const message = this.currentLang === 'fr' ? `Je suis intéressé par ce logement ${window.location.href} et j'aimerais être contacté.` : `I'm interested in ${window.location.href} apartment and I'd like to be contacted.`
        const baseUrl = `https://wa.me/15146791814?text=`;
        const encodedMessage = encodeURIComponent(message);
        window.open(`${baseUrl}${encodedMessage}`,"_blank");
    }

    removeHiddenRooms(rooms) {
        let res = [];
        for (const room of rooms) {
            if (!room.isHidden) {
                res.push(room)
            }
        }
        return res;
    }

}
