import {
    Component,
    ElementRef,
    Input,
    ViewChild,
    Renderer2
} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Apartment} from '../../../models/apartment';
import {Router, ActivatedRoute} from '@angular/router';
import {DomSanitizer} from '@angular/platform-browser';
import {Utils} from '../../../utils/utils';
import {FileItem, FileUploader} from 'ng2-file-upload';

import {BsLocaleService} from 'ngx-bootstrap/datepicker';
import {defineLocale, frLocale} from 'ngx-bootstrap/chronos';

import {ConditionsObject} from '../../../models/conditionsobject';

import {
    ApartmentController,
    UserController,
    GeneralController
} from '../../../controllers/controllers.module';
import * as moment from 'moment';
import { UserUtils } from 'app/utils/user';

@Component({
    selector: 'editShareApartmentForm',
    templateUrl: './editShareApartmentForm.component.html',
    styleUrls: ['./editShareApartmentForm.component.css']
})
export class EditShareApartmentFormComponent {
    moveInDate: any
    moveOutDate: any
    apartment: Apartment;
    accomodations: any = [];
    accomodationsCriterias: any = [];
    facilities: any = [];
    selectedRoom: any = {};
    apartmentSizes: any = [];
    sectors: any = [];
    loggedUser: any;
    id: any;
    mask = [
        '(',
        /[1-9]/,
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/
    ];
    postalCodeMask = [
        /[a-zA-Z]/,
        /[0-9]/,
        /[a-zA-Z]/,
        ' ',
        /[0-9]/,
        /[a-zA-Z]/,
        /[0-9]/
    ];
    limitPhoto: boolean = false;
    imageHost: string = this.utils.getEnvironmentValue('amazonS3Url');
    uploader: FileUploader = new FileUploader({
        url: this.utils.getEnvironmentValue('apiUrl') + 'upload-image/apartment',
        removeAfterUpload: true,
        headers: [
            {name: 'authorization', value: this.utils.getLoginToken()},
            {name: 'Authentication', value: this.userUtil.getLoggedUser().id}
        ],
    });
    uploadingStatus: any = {};
    objectKeys = Object.keys;
    hasSaved: boolean = false;
    duration: any = {};
    modals: any = {};
    conditions: ConditionsObject = this.genCtrl.getNewConditionsObject();
    subscriptions: any = {};
    minimumStayList: any
    minimumStay: any

    @ViewChild('apartmentForm') public apartmentForm: NgForm;

    @Input() public verifyUser: boolean;
    @Input() public isEdit: boolean;

    constructor(
        private router: Router,
        private utils: Utils,
        private route: ActivatedRoute,
        private el: ElementRef,
        private renderer: Renderer2,
        private localeService: BsLocaleService,
        private genCtrl: GeneralController,
        private userCtrl: UserController,
        private aptCtrl: ApartmentController,
        private domSanitizer: DomSanitizer,
        private userUtil: UserUtils
    ) {
        this.apartmentSizes = utils.getApartmentSizes();
        this.accomodations = utils.getAccomodations();
        this.accomodationsCriterias = utils.getAccomodationsCriterias();
        this.facilities = utils.getFacilities();
        this.apartment = new Apartment();
        this.minimumStayList = utils.getMinimumStayList()

        this.subscriptions.login = this.userCtrl
            .getSubscribeLogin(true)
            .subscribe((res) => {
                this.validateUser();
            });

        this.subscriptions.modal = this.userCtrl
            .getSubscribeLoginModal(true)
            .subscribe((res) => {
                this.validateUser();
                this.onSubmitForm(this.apartmentForm);
            });

        this.subscriptions.logout = this.userCtrl
            .getSubscribeLogout(true)
            .subscribe((res) => {
                this.conditions.isLogged = false;
                this.loggedUser = null;
            });

        this.uploader.onBeforeUploadItem = (
            item: FileItem
        ) => {
            this.uploadingStatus[item.index] = this.domSanitizer.bypassSecurityTrustUrl(URL.createObjectURL(item.file.rawFile));
            let tmpIndex = item.index;
            item.onComplete = () => {
                delete this.uploadingStatus[tmpIndex]
            };
        };

        this.uploader.onCompleteItem = (
            item: any,
            response: any,
            status: any,
            headers: any
        ) => {
            var responsePath = JSON.parse(response);
            this.apartment.addNewPhoto(responsePath.filename);
        };
    }

    ngOnInit() {
        this.utils.executeBrowserOnly(() => {
            if (this.utils.getCurrentLang() !== 'en') {
                defineLocale(this.utils.getCurrentLang(), frLocale);
                this.localeService.use(this.utils.getCurrentLang());
            }

            window.scrollTo(0, 0);
            this.conditions.isBrowser = true;

            this.resetAccomodations();
            this.resetFacilities();
            this.validateUser();

            this.subscriptions.id = this.route.params.subscribe(async (params) => {
                this.id = params['id'];
                if (!this.id) {
                    this.router.navigate([this.utils.getCurrentLang() + '/notfound']);
                }
                Object.assign(this.apartment, await this.aptCtrl.getApartment(this.id));

                if (this.verifyUser) {
                    let owner = this.apartment.owner.userId;
                    if (this.loggedUser.id !== owner._id) {
                        this.router.navigate([this.utils.getCurrentLang() + '/notfound']);
                    }
                }

                this.initAccomodations();
                this.initFacilities();
                this.minimumStay = this.apartment.minimumStay
            });
            this.utils.hideOverlay();
        });
    }

    onDateChange() {
        this.setRentType()
    }

    setRentType() {
        const availabilityInDays = moment(this.moveOutDate).diff(moment(this.moveInDate), 'days')
        if (availabilityInDays >= 30) {
            this.apartment.rentType = 'Monthly'
        } else if (availabilityInDays < 30 && availabilityInDays >= 7) {
            this.apartment.rentType = 'Weekly'
        } else {
            this.apartment.rentType = 'Daily'
        }
    }

    getCurrentDate() {
        return new Date()
    }

    validateUser() {
        let loggedUser = this.utils.getLoggedUser();
        if (loggedUser) {
            this.conditions.isLogged = true;
            this.loggedUser = loggedUser;
            if (loggedUser.isBlocked) {
                this.router.navigate([this.utils.getCurrentLang() + '/notfound']);
            }

            if (loggedUser.first_name && loggedUser.last_name) {
                this.apartment.contact.name =
                    loggedUser.first_name + ' ' + loggedUser.last_name;
            }
            if (loggedUser.email) {
                this.apartment.contact.email = loggedUser.email;
            }
        }
    }

    uploadAll() {
        if (this.uploader.queue.length + this.apartment.photos.length > 8) {
            this.uploader.queue.splice(8 - this.apartment.photos.length);
        }

        this.uploader.uploadAll();
    }

    onlyNumberKey(event) {
        return event.charCode == 8 || event.charCode == 0
            ? null
            : event.charCode >= 48 && event.charCode <= 57;
    }

    ngAfterViewInit() {
        this.utils.executeBrowserOnly(() => {
            this.utils.setTooltips();
            this.utils.setToggle();
        });
    }

    onOpenAssignUserModal(room: any) {
        this.selectedRoom = room;
        this.genCtrl.getModal(
            this.el,
            this.renderer,
            'div.modal-assign-user',
            true
        );
    }

    onOpenLoginModal() {
        this.genCtrl.getModal(this.el, this.renderer, 'div.modal-login', true);
    }

    addRoom() {
        if (this.apartment.rooms.length < 10) {
            this.apartment.addEmptyRoom(
                this.utils.getTranslator().instant('Room') +
                ' ' +
                (this.apartment.rooms.length + 1)
            );
            this.utils.setTooltips();
        }
    }

    async removePhoto(photo: any) {
        await this.aptCtrl.removePhoto(photo.source).catch((e) => {
            let error = JSON.parse(e.text());
            console.log(error.error);
            window.scrollTo(0, 0);
        });

        this.apartment.photos.splice(this.apartment.photos.indexOf(photo), 1);
        await this.aptCtrl.updateApartment(this.id, {
            photos: this.apartment.photos
        });
    }

    async onSave() {
        this.setAccomodations();
        this.setFacilities();
        this.apartment.roomsCount = this.apartment.rooms.length;
        this.apartment.minprice = 0;
        this.apartment.maxprice = 0;

        for (let room of this.apartment.rooms) {
            if (!room.userId) {
                if (
                    this.apartment.minprice == 0 ||
                    Number(room.price) < Number(this.apartment.minprice)
                ) {
                    this.apartment.minprice = room.price;
                }

                if (Number(room.price) > Number(this.apartment.maxprice)) {
                    this.apartment.maxprice = room.price;
                }
            }
        }

        let user = this.utils.getLoggedUser();
        let postData = JSON.parse(JSON.stringify(this.apartment));
        postData.userId = user.id;
        let response = await this.aptCtrl.updateApartment(this.id, postData);

        this.hasSaved = true;

        if (response !== null && response.slug != '' && response._id != '') {
            this.router.navigate([
                `${this.utils.getCurrentLang()}/apartments/${response._id}/${
                    response.slug
                }`
            ]);
        } else {
            this.router.navigate([
                `${this.utils.getCurrentLang()}/apartments/${response._id}/${
                    response.slug
                }`
            ]);
        }
    }

    async getAddress() {
        if (
            this.apartment.adress.postalCode &&
            this.apartmentForm.form.controls['postalcode'].valid &&
            this.apartment.adress.postalCode.length == 7
        ) {
            let data: any = {};
            try {
                data = await this.aptCtrl.getAdress(this.apartment.adress.postalCode);
            } catch (e) {
                this.setAddress('', null, null);
            }
            if (data.status == 'OK' && data.results && data.results.length > 0) {
                this.setAddress(
                    data.results[0].address_components[1].long_name.replace('—', '-'),
                    data.results[0].geometry.location.lat,
                    data.results[0].geometry.location.lng
                );
                return true;
            } else {
                this.setAddress('', null, null);
            }
        } else {
            this.setAddress('', null, null);
        }

        return false;
    }

    setAddress(city, lat, lng) {
        if (city) {
            this.apartmentForm.form.controls['postalcode'].setErrors({
                incorrect: true
            });
        } else {
            this.apartmentForm.form.controls['postalcode'].setErrors(null);
        }
        this.apartment.adress.city = city;
        this.apartment.adress.lat = lat;
        this.apartment.adress.lng = lng;
    }

    initAccomodations() {
        for (let accomodation of this.apartment.accomodations) {
            let acc = this.accomodations.find(function (oAcc) {
                return oAcc.id === accomodation.id;
            });
            if (acc) {
                acc.isSelected = true;
            } else {
                let accCriteria = this.accomodationsCriterias.find(function (oAcc) {
                    return oAcc.id == accomodation.id;
                });

                if (accCriteria) {
                    accCriteria.isSelected = true;
                }
            }
        }
    }

    resetAccomodations() {
        for (let accomodation of this.accomodations) {
            accomodation.isSelected = false;
        }

        for (let accomodationCriteria of this.accomodationsCriterias) {
            accomodationCriteria.isSelected = false;
        }
    }

    setAccomodations() {
        this.apartment.accomodations = [];
        for (let accomodation of this.accomodations) {
            if (accomodation.isSelected) {
                this.apartment.accomodations.push({id: accomodation.id});
            }
        }

        for (let accomodationCriteria of this.accomodationsCriterias) {
            if (accomodationCriteria.isSelected) {
                this.apartment.accomodations.push({id: accomodationCriteria.id});
            }
        }
    }

    initFacilities() {
        for (let facility of this.apartment.facilities) {
            let fac = this.facilities.find(function (oFac) {
                return oFac.id === facility.id;
            });
            if (fac) {
                fac.isSelected = true;
            }
        }
    }

    resetFacilities() {
        for (let facility of this.facilities) {
            facility.isSelected = false;
        }
    }

    setFacilities() {
        this.apartment.facilities = [];
        for (let facility of this.facilities) {
            if (facility.isSelected) {
                this.apartment.facilities.push({id: facility.id});
            }
        }
    }

    myListFormatter(data: any): String {
        return data.value;
    }

    ngOnDestroy() {
        this.utils.executeBrowserOnly(() => {
            this.utils.showOverlay();
            [
                this.subscriptions.logout,
                this.subscriptions.login,
                this.subscriptions.modal,
                this.subscriptions.id
            ].map((obs) => this.utils.unsubscribeObservable(obs));
        });
    }

    async onSubmitForm(form) {
        if (form.valid && this.apartment.photos.length >= 3) {
            if (!this.apartment.adress.city) {
                let isValid = await this.getAddress();
                if (isValid) {
                    this.onSave();
                } else {
                    window.scrollTo(0, 0);
                }
            } else {
                this.onSave();
            }
        } else {
            this.conditions.hasError = true;
            for (var i in form.controls) {
                form.controls[i].markAsTouched();
            }
            window.scrollTo(0, 0);
        }
    }

    removeUser(room) {
        room.userId = undefined;
        room.userName = '';
        room.userPhoto = '';
    }

    removeRoom(room) {
        let index: number = this.apartment.rooms.indexOf(room);
        if (index !== -1) {
            this.apartment.rooms.splice(index, 1);
        }
    }

    deleteListing() {
        this.genCtrl.showModal(this.renderer, this.el.nativeElement.querySelector('div.modal-delete-confirm'));
    }

    async confirmDelete(confirmed) {
        if (confirmed === true) {
            await this.aptCtrl.deleteApartment(this.id, this.apartment);
            this.router.navigate([this.utils.getCurrentLang() + '/myprofile/sharings']);
        }
    }

    getRentTypeHeading(rentType) {
        console.log(this.apartment)
        switch (rentType) {
            case 'Daily':
                return 'Daily price'
            case 'Weekly':
                return 'Weekly price'
            case 'Monthly':
                return 'Monthly price'
        }
    }
}
