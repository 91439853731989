import { Injectable, Inject } from '@angular/core';
import { Subject }    from 'rxjs/Subject';
@Injectable()
export class OverlayService {
    constructor(){}

  	private hideOverlayNotify = new Subject<any>();
  	hideOverlayNotifyObservable$ = this.hideOverlayNotify.asObservable();

  	public hideOverlayNotifyOther() {
		  this.hideOverlayNotify.next();
  	}

    private showOverlayNotify = new Subject<any>();
    showOverlayNotifyObservable$ = this.showOverlayNotify.asObservable();

    public showOverlayNotifyOther() {
      this.showOverlayNotify.next();
    }
}