import { Injectable, Inject } from '@angular/core';
import { Subject }    from 'rxjs/Subject';
@Injectable()
export class LogoutService {
  	private logoutNotify = new Subject<any>();
  	logoutNotifyObservable$ = this.logoutNotify.asObservable();

  	constructor(){}

  	public logoutNotifyOther() {
		  this.logoutNotify.next();
  	}

  	private logoutCompletedNotify = new Subject<any>();
  	logoutCompletedNotifyObservable$ = this.logoutCompletedNotify.asObservable();

  	public logoutCompletedNotifyOther() {
		  this.logoutCompletedNotify.next();
  	}
}