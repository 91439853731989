import { Utils } from '../../../utils/utils';
import { GeneralUtils } from '../../../utils/general';
import { Component, ElementRef, Renderer2, ViewChild } from '@angular/core';
import {
  ApartmentController,
  ProfileController
} from '../../../controllers/controllers.module';
import { Route, Router } from '@angular/router';
import { Cookie } from 'ng2-cookies';
import { CityController } from 'app/controllers/city.controller';
import {DropdownComponent, MyOptionComponent} from '../dropdown/Dropdown.component';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent {
  constructor(
    private utils: Utils,
    private aptCtrl: ApartmentController,
    private profCtrl: ProfileController,
    public cityCtrl: CityController,
    private route: Router,
    private genUtils: GeneralUtils
  ) {}

  apartments: any = [];
  pinnedApartmentsCities: any = {};
  imageHost: string = this.utils.getEnvironmentValue('amazonS3Url');
  lang: string;
  cities: any = [];
  @ViewChild('cityDropdown') cityDropdown: DropdownComponent;
  @ViewChild('cityDropdown2') cityDropdown2: DropdownComponent;

  ngOnInit() {
    this.lang = Cookie.get("lang") == "en" ? "en" : "fr";
    this.utils.setSeoMeta();
    this.utils.executeBrowserOnly(async () => {
      this.cities = await this.cityCtrl.getCities();
      this.apartments = await this.aptCtrl.getApartments(
        `&limit=3&hasPhotos=true`
      );
      this.cityCtrl.selectedCityName = ""
      this.pinnedApartmentsCities = {
        'Toronto': await this.aptCtrl.getApartments(
          `&city=Toronto&format=count`
        ),
        'Montreal': await this.aptCtrl.getApartments(
          `&city=Montreal&format=count`
        ),
        'Ottawa': await this.aptCtrl.getApartments(
          `&city=Ottawa&format=count`
        ),
        'Kingston': await this.aptCtrl.getApartments(
          `&city=Kingston&format=count`
        ),
        'Quebec City': await this.aptCtrl.getApartments(
          `&city=Quebec City&format=count`
        ),
      };

      this.updateDropdown();
      this.utils.hideOverlay();
    });
  }

  updateDropdown() {
    let allOptionsArr: Array<MyOptionComponent> = [];
    for (const city of this.cities) {
        const newElem = document.createElement('div');
        newElem.innerHTML = this.genUtils.titlecase(city.name);
        let option = new MyOptionComponent(new ElementRef(newElem));
        option.value = city.value;
        allOptionsArr.push(option);
    }
    this.cityDropdown.updateItems(allOptionsArr);
    this.cityDropdown2.updateItems(allOptionsArr);
  }

  goToTop() {
    this.utils.executeBrowserOnly(() => {
      window.scrollTo(0, 0);
    });
  }
}
