import { Utils } from 'app/utils/utils';
import { Injectable } from "@angular/core";
import { Subject } from 'rxjs';
import { Constants } from '../utils/constants';

import {
  ApartmentController
} from './apartment.controller';

interface ICityModel {
  name: string;
  name_fr: string;
  value: string;
}

@Injectable()
export class CityController {
  availableCities: ICityModel[];
  selectedCityName: string;
  selectedCity: Subject<string> = new Subject();
  neighborhoods: any[];
  constants: Constants;

  constructor(private utils: Utils, private aptCtrl: ApartmentController) {
    this.utils.executeBrowserOnly(async () => {
      this.availableCities = await this.utils.getCities();
      if (this.utils.getCurrentLang() === 'fr') {
        this.availableCities = this.availableCities.map(city => {
          city.name = city.name_fr;
          return city;
        });
      }
      this.selectedCity.next(this.availableCities[0].name);

      const urlPath = window.location.pathname;
      if (this.availableCities.find(city => urlPath.indexOf(city.value + "/apartments") > 0) !== undefined) {
        let city = this.availableCities.find(city => urlPath.indexOf(city.value) > 0);
        this.selectedCityName = this.titlecase(city.value)
      } else {
        this.selectedCityName = "Montreal"
      }

      this.getNeighborhoods(this.selectedCityName);
    });
  }

  public getCities = async () => {
    return await this.utils.getCities();
  }

  private getNeighborhoods = async (city) => {
    this.neighborhoods = await this.aptCtrl.getApartmentsNeihghborhoodPerCity(city);
  }

  private titlecase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  async cityChanged(city: string) {
    this.selectedCityName = this.titlecase(city);
    this.getNeighborhoods(city);
    this.selectedCity.next(city);
    this.utils.router.navigate(["/" + this.utils.getCurrentLang() + "/" + this.selectedCityName.toLowerCase() + "/apartments"])
  }
}
