import {NgModule} from '@angular/core';
import {APP_BASE_HREF} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';

import {LangRouteGuard} from './misc/guards/lang-route-guard.service';
import {LoginRouteGuard} from './misc/guards/login-route-guard.service';
import { FeedbackRouteGuard } from './misc/guards/feedback-route-guard.service'
import {IsLoggedRouteGuard} from './misc/guards/islogged-route-guard.service';

import {
    ApartmentDetailsComponent,
    ProfileDetailsComponent,
    UserDetailsComponent
} from './modules/details/details.module';

import {
    PaymentComponent
} from './modules/payment/payment.module'

import {
    RentRequestDetailsComponent,
    ApplicationSummaryComponent
} from './modules/rent-request/rent-request.module'

import {
    SearchApartmentComponent,
} from './modules/search/search.module';
import { HomeComponent, HostHomeComponent, OnboardingComponent, } from './modules/general/general.module';

import { PricingComponent } from './modules/general/pricing/pricing.component';

const childRoutes: Routes = [
    {
        path: 'share',
        loadChildren: './modules/share/share.module#ShareModule',
        // canActivate: [LoginRouteGuard],
    },
    {
        path: 'myprofile',
        loadChildren: './modules/profile/profile.module#ProfileModule',
        canActivate: [LoginRouteGuard]
    },
    {
        path: 'edit',
        loadChildren: './modules/edit/edit.module#EditModule',
        canActivate: [LoginRouteGuard]
    },
    {
        path: 'notfound',
        loadChildren: './modules/general/notfound/notfound.module#NotFoundModule'
    },
    {
        path: 'apartments',
        component: SearchApartmentComponent
    },
    {
        path: ':city/apartments',
        component: SearchApartmentComponent
    },
    {
        path: 'privacy-policy',
        loadChildren: './modules/general/policy/policy.module#PolicyModule'
    },
    {
        path: 'terms-condition',
        loadChildren: './modules/general/terms/terms.module#TermsModule'
    },
    {
        path: 'help-center',
        loadChildren: './modules/general/faq/faq.module#FaqModule'
    },
    {
        path: 'login',
        loadChildren: './modules/connection/connection.module#ConnectionModule',
        canActivate: [IsLoggedRouteGuard]
    },
    {
        path: 'apartments/:id/:slug',
        redirectTo: 'apartments/:id'
    },
    {
        path: ':cityName/apartments/:id/:slug',
        redirectTo: 'apartments/:id'
    },
    {
        path: 'roommates/:id/:slug',
        redirectTo: 'roommates/:id'
    },
    {
        path: 'apartments/:id',
        component: ApartmentDetailsComponent
    },
    {
        path: ':city/apartments/:id',
        component: ApartmentDetailsComponent
    },
    {
        path: 'rent-request-details/:id',
        component: RentRequestDetailsComponent
    },
    {
        path: 'application-summary',
        component: ApplicationSummaryComponent
    },
    {
        path: 'payment',
        component: PaymentComponent
    },
    {
        path: 'roommates/:id',
        component: ProfileDetailsComponent
    },
    {
        path: 'user/:id',
        component: UserDetailsComponent
    },
    {
        path: '',
        component: HomeComponent
    },
    {
        path: 'host',
        component: HostHomeComponent
    },
    {
        path: 'plans',
        component: PricingComponent
    },
    {
        path: 'onboarding',
        component: OnboardingComponent
    },
    {
        path: 'about',
        loadChildren: './modules/general/about/about.module#AboutModule'
    },
];

const routes: Routes = [
    {
        path: 'fr',
        children: childRoutes
    },
    {
        path: 'en',
        children: childRoutes
    },
    {
        path: '',
        component: HomeComponent,
        canActivate: [LangRouteGuard]
    },
    {
        path: '**',
        redirectTo: '/fr/notfound',
        pathMatch: 'full'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [LangRouteGuard, LoginRouteGuard, IsLoggedRouteGuard]
})
export class RoutingModule {
}
