import { Component, ElementRef, Input, Renderer2 } from "@angular/core";
import { Utils } from "../../../utils/utils";

import { UserController } from "../../../controllers/controllers.module";

@Component({
  selector: "addUser",
  templateUrl: "./addUser.component.html",
  providers: [Utils]
})
export class AddUserComponent {
  @Input() public obj: any;
  @Input() public parent: any;
  @Input() public type: string;
  errors: any = {};
  secretCode: string;

  constructor(
    private utils: Utils,
    private el: ElementRef,
    private renderer: Renderer2,
    private userCtrl: UserController
  ) {}

  modalEl = null;

  onCloseModal() {
    this.renderer.setStyle(this.modalEl, "display", "none");
    this.renderer.removeClass(this.modalEl, "opened");
  }

  ngOnInit() {
    this.reInit();
  }

  ngAfterViewInit() {
    if (this.el.nativeElement.querySelector) {
      this.modalEl = this.el.nativeElement.querySelector("div.modal");
    }
  }

  reInit() {
    this.secretCode = "";
    this.errors = {};
  }

  async onConfirm() {
    this.errors = {};
    if (this.secretCode) {
      let user = await this.userCtrl.getUserByCode(this.secretCode);
      if (!user) {
        this.errors.notfound = {
          error: this.utils.getTranslator().instant("This user does not exist.")
        };
        return;
      }

      let newUser = {
        userId: user[0]._id,
        userName: `${user[0].first_name} ${user[0].last_name}`,
        userPhoto: user[0].customImage ? user[0].customImage : user[0].image
      };

      if (this.type == "room") {
        let room = this.parent.rooms.find(function(objRoom) {
          return objRoom.userId == newUser.userId;
        });
        if (!room) {
          this.obj.userName = newUser.userName;
          this.obj.userPhoto = newUser.userPhoto;
          this.obj.userId = newUser.userId;
          this.onCloseModal();
        } else {
          this.errors.alreadyexists = {
            error: this.utils
              .getTranslator()
              .instant("This person is already a roommate.")
          };
        }
      } else if (this.type == "profile") {
        let roommate = this.obj.roommates.find(function(objRoommate) {
          return objRoommate.userId == newUser.userId;
        });

        if (!roommate) {
          this.obj.roommates.push(newUser);
          this.onCloseModal();
        } else {
          this.errors.alreadyexists = {
            error: this.utils
              .getTranslator()
              .instant("This person is already a roommate.")
          };
        }
      }
    } else {
      this.errors.required = {
        error: this.utils.getTranslator().instant("Please enter a code.")
      };
    }
  }
}
