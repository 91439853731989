import { Utils } from 'app/utils/utils';
import { Injectable } from "@angular/core";
import { Http } from '@angular/http';

@Injectable()
export class TemplateController {

  constructor(
    private http: Http,
    private utils: Utils
  ) {
  }

  async getTemplate(templateTitle: string) {
    return this.http
      .get(
          `${this.utils.getEnvironmentValue("apiUrl")}template/${templateTitle}`
      )
      .toPromise()
      .then((response) => response.json());
  }


}
