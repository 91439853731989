import { Component, Input, Output, EventEmitter } from "@angular/core";
import { Constants } from '../../../utils/constants';

@Component({
  selector: "AboutMeTraits",
  templateUrl: "./AboutMeTraits.component.html",
  styleUrls: ["./AboutMeTraits.component.css"]
})
export class AboutMeTraits {
  @Input() selected_traits_per_category: any;
  @Output() emit_matching_info = new EventEmitter<any>();

  // These are constant values for the list of items
  personalityList = [];
  lifestyleList = [];
  interestsList = [];
  musicList = [];
  sportsList = [];
  moviesGenreList = [];

  constructor(
    private constants: Constants,
  ) {}

  ngOnInit() {
    this.initTraitLists();
    setTimeout(() => {
      this.toggleAccordion(0);
    }, 400);
  }

  initTraitLists() {
    this.personalityList = this.constants.getAboutMePersonalityList();
    this.lifestyleList = this.constants.getAboutMeLifestyleList();
    this.interestsList = this.constants.getAboutMeInterestsList();
    this.musicList = this.constants.getAboutMeMusicList();
    this.sportsList = this.constants.getAboutMeSportsList();
    this.moviesGenreList = this.constants.getAboutMeMoviesGenreList();
  }

  toggleAccordion(number){
    var triggers = document.getElementsByClassName('trigger');

    triggers[number].classList.toggle('active');

    var content = triggers[number].nextElementSibling;
    if (content['style'].maxHeight){
      content['style'].maxHeight = null;
    } else {
      content['style'].maxHeight = content.scrollHeight + "px";
    }
  }

  updateTraitsCategory(traits_category, list) {
    this.emit_matching_info.emit({
      traits_category: traits_category,
      list: list.map(item => {return {id: item.id, name: item.name }})
    });
  }
}
