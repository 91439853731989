import { Component, Input } from '@angular/core';

@Component({
  selector: 'searchBanner',
  templateUrl: './searchBanner.component.html',
  styleUrls: ['./searchBanner.component.css']
})
export class SearchBannerComponent {
  @Input() public type: string;
  constructor() {}
}
