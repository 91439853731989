import {Component, ElementRef, EventEmitter, Input, OnInit, Output, Renderer2} from '@angular/core';
import {ConditionsObject} from '../../../models/conditionsobject';
import {Utils} from '../../../utils/utils';
import {GeneralController} from '../../../controllers/general.controller';
import { Router } from "@angular/router";

@Component({
    selector: 'app-success-modal',
    templateUrl: './success-modal.component.html',
    styleUrls: ['./success-modal.component.css']
})
export class SuccessModalComponent implements OnInit {

    @Output() emitter = new EventEmitter<String>();
    @Input() status: string
    conditions: ConditionsObject = this.genCtrl.getNewConditionsObject();
    modalEl = null
    message: string
    topHeading: string
    altText: string = ''

    constructor(
        private utils: Utils,
        private el: ElementRef,
        private renderer: Renderer2,
        private genCtrl: GeneralController,
        private router: Router
    ) {
    }

    onCloseModal() {
        this.renderer.setStyle(this.modalEl, 'display', 'none');
        this.renderer.removeClass(this.modalEl, 'opened');
    }

    ngOnInit() {
        this.utils.executeBrowserOnly(() => {
            this.conditions.isBrowser = true;
        });
    }

    ngOnChanges() {
        this.setData()
    }

    setData() {
        if (this.status === 'approve') {
            this.message = 'Rent request approved successfully.'
            this.topHeading = 'Rent Request Approved'
        }
        else if (this.status === 'decline') {
            this.message = 'Rent request declined successfully.'
            this.topHeading = 'Rent Request Declined'
        }
        else if (this.status === 'created') {
            this.message = 'Your listing has been successfully created.'
            this.topHeading = '🎉'
            this.altText = 'Your space has been listed!'
        }
        else if (this.status === 'updated') {
            this.message = 'Your listing has been successfully updated.'
            this.topHeading = 'Your space has been updated!'
        }
        else if (this.status === 'pending') {
            this.message = "payment_process_failed"
            this.topHeading = 'Pending Payment'
        }
        else if (this.status === 'pricing-form-submitted') {
            this.message = "We've received all your information. Our team will review it shortly. If we need anything else, we’ll be in touch. Welcome to Hivenue!"
            this.topHeading = 'Thank You! 🎉'
        }
        else {
            this.message = this.status;
            this.topHeading = 'Message';
        }
    }

    sayYes() {
        this.onCloseModal()
        if (this.status === 'approve') {
            this.router.navigateByUrl(`/${(this.utils.getCurrentLang())}/myprofile/reservations`);
        } else if (this.status === 'decline') {
            this.router.navigateByUrl(`/${this.utils.getCurrentLang()}/myprofile/bookingrequests`);
        } else {
            this.emitter.emit('true')
        }
    }

    ngAfterViewInit() {
        if (this.el.nativeElement.querySelector) {
            this.modalEl = this.el.nativeElement.querySelector('div.modal');
        }
    }

}
