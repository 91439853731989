import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule }   from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { LoginDialogComponent } from './loginDialog/loginDialog.component';
import { PhoneVerifyCodeComponent } from "../profile/phoneverifycode/phoneVerifyCode.component";
import { LoginComponent } from './login/login.component';
import { DeleteConfirmComponent } from '../profile/mysharings/DeleteConfirm.component';
import { DropdownComponent, MyOptionComponent } from '../general/dropdown/Dropdown.component';
import { SelectPanel } from '../general/selectpanel/SelectPanel.component';
import { AboutMeTraits } from '../profile/aboutmetraits/AboutMeTraits.component';
import { ConfirmationModalComponent } from '../rent-request/confirmation-modal/confirmation-modal.component';
import { SuccessModalComponent } from '../rent-request/success-modal/success-modal.component';
import { TranslateModule } from '@ngx-translate/core';

export {
  ConfirmationModalComponent,
  SuccessModalComponent
}

const routes: Routes = [
  { path: '', component: LoginComponent }
];


@NgModule({
  imports: [
  	CommonModule, 
  	TranslateModule,
    FormsModule,
  	RouterModule.forChild(routes)
  ],
  declarations: [
    LoginDialogComponent,
    LoginComponent,
    PhoneVerifyCodeComponent,
    DeleteConfirmComponent,
    DropdownComponent,
    MyOptionComponent,
    SelectPanel,
    AboutMeTraits,
    ConfirmationModalComponent,
    ConfirmationModalComponent,
    SuccessModalComponent
  ],
  exports: [
    LoginDialogComponent,
    PhoneVerifyCodeComponent,
    DeleteConfirmComponent,
    DropdownComponent,
    MyOptionComponent,
    SelectPanel,
    AboutMeTraits,
    ConfirmationModalComponent,
    SuccessModalComponent
  ]
})
export class ConnectionModule {}
