import {
  Component,
  ElementRef,
  Renderer2,
  Input,
  Output,
  EventEmitter
} from "@angular/core";
import { Utils } from "../../../utils/utils";

import { ConditionsObject } from "../../../models/conditionsobject";
import { GeneralController } from "../../../controllers/controllers.module";

@Component({
  selector: "deleteConfirm",
  templateUrl: "./DeleteConfirm.component.html",
  providers: [Utils]
})
export class DeleteConfirmComponent {
  @Input() public timeout: number;
  @Output() confirmEvent = new EventEmitter<Boolean>();

  conditions: ConditionsObject = this.genCtrl.getNewConditionsObject();

  modalEl = null;

  constructor(
    private utils: Utils,
    private el: ElementRef,
    private renderer: Renderer2,
    private genCtrl: GeneralController
  ) {}

  onCloseModal() {
    this.renderer.setStyle(this.modalEl, "display", "none");
    this.renderer.removeClass(this.modalEl, "opened");
  }

  ngOnInit() {
    this.utils.executeBrowserOnly(() => {
      this.conditions.isBrowser = true;
    });
  }

  onConfirm() {
    this.confirmEvent.emit(true);
    this.onCloseModal();
  }

  ngAfterViewInit() {
    if (this.el.nativeElement.querySelector) {
      this.modalEl = this.el.nativeElement.querySelector("div.modal");
    }
  }
}
