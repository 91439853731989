import { Injectable } from "@angular/core";
import { Headers, Http, RequestOptions } from "@angular/http";
import { Utils } from "../utils/utils";

import { GeneralController } from "./general.controller";

@Injectable()
export class ProfileController {
  imageHost = this.utils.getEnvironmentValue("amazonS3Url");

  constructor(
    private http: Http,
    private utils: Utils,
    private genCtrl: GeneralController
  ) {}

  initExtras(profile) {
    return {
      facilities: this.genCtrl.initExtra(
        this.utils.getFacilities(),
        profile.facilities
      ),
      accomodations: this.genCtrl.initExtra(
        this.utils.getAccomodations(),
        profile.accomodations
      ),
      accomodationsCriterias: this.genCtrl.initExtra(
        this.utils.getAccomodationsCriterias(),
        profile.accomodations
      )
    };
  }

  setSEO(profile) {
    this.utils.setSeoMetaParams(profile.name, profile.description);
    if (profile.owner && profile.owner.photo) {
      this.utils.setOGImage(
        profile.owner.photo.indexOf("http") == 0
          ? profile.owner.photo
          : this.imageHost + "user/" + profile.owner.photo
      );
    }
  }
}
