import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import {NotifierModule} from 'angular-notifier';

import {AppComponent} from './app.component';
import {OverlayComponent} from './general/overlay/overlay.component';
import {DetailsModule} from './details/details.module';
import {SearchModule} from './search/search.module';
import {GeneralModule} from './general/general.module';
import {PaymentModule} from './payment/payment.module';
import { RentRequestModule } from './rent-request/rent-request.module';

@NgModule({
    declarations: [AppComponent, OverlayComponent],
    imports: [
        SearchModule,
        DetailsModule,
        GeneralModule,
        RouterModule,
        CommonModule,
        TranslateModule,
        NotifierModule.withConfig({
            position: {
                vertical: {
                    position: 'bottom',
                    distance: 50
                }
            },
        }),
        PaymentModule,
        RentRequestModule
    ]
})
export class AppModules {
}
