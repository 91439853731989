import { Component, ElementRef, Renderer2 } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { User } from "../../../models/user";
import { Utils } from "../../../utils/utils";
import { Location } from "@angular/common";
import {
  UserController,
  GeneralController,
} from "../../../controllers/controllers.module";

@Component({
  selector: "userDetails",
  templateUrl: "./userDetails.component.html",
  styleUrls: ["./userDetails.component.css"],
})
export class UserDetailsComponent {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private utils: Utils,
    private el: ElementRef,
    private renderer: Renderer2,
    private userCtrl: UserController,
    private genCtrl: GeneralController,
    private location: Location
  ) {
    this.user = new User();

    this.subscriptions.login = this.userCtrl
      .getSubscribeLogin(true)
      .subscribe(async () => {
        this.loggedUser = this.utils.getLoggedUser();
        this.setIsOwner();
      });

    this.subscriptions.logout = this.userCtrl
      .getSubscribeLogout(true)
      .subscribe(async () => {
        this.conditions.isOwner = false;
      });
  }

  user: any;
  userMessage = { contact: {}, owner: {} };
  subscriptions: any = {};
  id: any;
  conditions = this.genCtrl.getNewConditionsObject();
  imageHost: string = this.utils.getEnvironmentValue("amazonS3Url");
  loggedUser: any;

  ngOnInit(): void {
    this.utils.executeBrowserOnly(() => {
      window.scrollTo(0, 0);

      // Redirect to login if not logged in
      if (!this.utils.getLoggedUser()) {
        this.router.navigate([this.utils.getCurrentLang() + "/login"], {
          queryParams: { redirect: this.router.url },
        });
        return;
      }

      this.subscriptions.url = this.route.params.subscribe(async (params) => {
        this.id = params["id"];
        this.user = await this.userCtrl.getUserProfile(this.id);

        if (!this.user) {
          this.router.navigate(["404"]);
        }

        let userMessage = {
          contact: {
            email: this.user.email,
            name: `${this.user.first_name} ${this.user.last_name}`,
          },
          owner: {
            userId: { _id: this.user._id },
            photo: this.user.customImage
              ? this.user.customImage
              : this.user.image,
          },
        };
        if (this.user.phone) {
          userMessage.contact = Object.assign(userMessage.contact, {
            phone: this.user.phone,
          });
        }

        this.userMessage = Object.assign(this.userMessage, userMessage);

        this.userCtrl.setSEO(this.user);

        this.loggedUser = this.utils.getLoggedUser();
        this.setIsOwner();

        this.utils.hideOverlay();
      });
    });
  }

  ngOnDestroy() {
    this.utils.executeBrowserOnly(() => {
      this.utils.showOverlay();
      [
        this.subscriptions.login,
        this.subscriptions.logout,
        this.subscriptions.url,
      ].map((obs) => this.utils.unsubscribeObservable(obs));
    });
  }

  onOpenModal() {
    this.genCtrl.getModal(this.el, this.renderer, "div.sendMessageModal", true);
  }

  setIsOwner() {
    if (this.loggedUser) {
      this.conditions.isOwner = this.loggedUser.id == this.id;
    }
  }
  navBack() {
    this.location.back();
  }

  calculateAge(dob) {
    const diff_ms = Date.now() - (new Date(dob)).getTime();
    const age_dt = new Date(diff_ms);

    return Math.abs(age_dt.getUTCFullYear() - 1970);
  }

  hasMatchingCategoriesFill() {
    if (Object.keys(this.user.matching_info).length === 6 && this.user.matching_info['personality'].length > 0) {
      for(const matching_category of this.user.matching_info) {
        if(this.user.matching_info[matching_category].length  === 0) {
          return false;
        }
      }
      return true;
    } else {
      return false;
    }
  }

  filterEmptyMatchingInfo(matching_info) {
    if (matching_info) {
      let result = {};
      for (const matching_category of Object.keys(matching_info)) {
        if (matching_info[matching_category].length > 0) {
          result[matching_category] = matching_info[matching_category];
        }
      }
      return result;
    }
    else {
      return [];
    }
  }

  capitalize(text) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
}
