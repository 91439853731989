import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { Utils } from "../../../utils/utils";

@Component({
  selector: "shareToggle",
  templateUrl: "./shareToggle.component.html"
})
export class ShareToggleComponent {
  @Input() public containerClass: string;
  @Input() public type: string;

  constructor(private utils: Utils, private router: Router) {}

  triggerToggle(type) {
    this.type = type;
    this.router.navigate([`${this.utils.getCurrentLang()}/${type}`]);
  }


}
