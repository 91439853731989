import {Injectable, OnDestroy} from '@angular/core';
import * as moment from 'moment'
import * as _ from 'lodash'

import {SeoUtils} from './seo';
import {UserUtils} from './user';
import {GeneralUtils} from './general';

import {TAX_PER_DAY, SERVICE_FEE_RATE, MAX_SHORT_STAY_DAYS, MINIMUM_RENT_FOR_PERCENTAGE_TAX, PERCENT_TAX_RATE, MINIMUM_TAX} from '../utils/constants';
import { CdkDropList } from '@angular/cdk/drag-drop';

import { Router } from "@angular/router";
import { Http } from "@angular/http";
import {Cookie} from 'ng2-cookies';

@Injectable()
export class Utils {
    cities: any;
    citiesReqInProgress: boolean = false;

    constructor(
        private _seo: SeoUtils,
        private _user: UserUtils,
        private _general: GeneralUtils,
        public router: Router,
        private http: Http
    ) {
    }

    setLoggedUserImage(image) {
        this._user.setLoggedUserImage(image);
    }

    getLoggedUserImage() {
        return this._user.getLoggedUserImage();
    }

    getLoggedUserFirstName() {
        return this._user.getLoggedUserFirstName();
    }

    setLoggedUserFirstName(firstName) {
        this._user.setLoggedUserFirstName(firstName);
    }

    getLoggedUserLastName() {
        return this._user.getLoggedUserLastName();
    }

    setLoggedUserLastName(lastName) {
        this._user.setLoggedUserLastName(lastName);
    }

    setLoggedUser(loggedUser) {
        this._user.setLoggedUser(loggedUser);
    }

    getLoggedUser() {
        return this._user.getLoggedUser();
    }

    getUser() {
        return this._user.getUser();
    }

    storeLoginToken(token: string) {
        Cookie.set('token', token, 1, '/');
    }

    getLoginToken() {
        return Cookie.get('token');
    }

    removeLoginToken() {
        Cookie.delete('token');
    }

    resetUser() {
        this._user.resetUser();
    }

    setUserLoginCookies(user) {
        this._user.setUserLoginCookies(user);
    }

    isFirstLogin(user) {
        return this._user.isFirstLogin(user);
    }

    logout(callback) {
        this.removeLoginToken();
        this._user.logout(callback);
    }

    executeBrowserOnly(callback) {
        this._general.executeBrowserOnly(callback);
    }

    executeServerOnly(callback) {
        this._general.executeServerOnly(callback);
    }

    getMinimumStayList(): any {
        return this._general.getMinimumStayList();
    }

    getBedTypeList(): any {
        return this._general.getBedTypeList();
    }

    getPurposesOfStay(): any {
        return this._general.getPurposesOfStay();
    }

    getApartmentSizes(): any {
        return this._general.getApartmentSizes();
    }

    getApartmentSize(id: string): any {
        return this._general.getApartmentSize(id);
    }

    getAccomodations(): any {
        return this._general.getAccomodations();
    }

    getAccomodationsCriterias(): any {
        return this._general.getAccomodationsCriterias();
    }

    getFacilitiesFilter(): any {
        return this._general.getFacilitiesFilter();
    }

    getAccommodationsFilter(): any {
        return this._general.getAccommodationsFilter();
    }

    getFacilities(): any {
        return this._general.getFacilities();
    }

    async sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async getCities(): Promise<any> {
        if (this.cities) {
            return this.cities;
        }
        else {
            if (!this.citiesReqInProgress) {
                this.citiesReqInProgress = true;
                return await this.http
                    .get(`${this.getEnvironmentValue("apiUrl")}cities`)
                    .toPromise()
                    .then((response) => {
                        this.cities = response.json();
                        return this.cities;
                    })
                    .catch((err) => {
                        console.log(err);
                        // Fallback plan
                        this.cities = [
                            {name: 'montreal', value: 'montreal'},
                            {name: 'toronto', value: 'toronto'},
                            {name: 'ottawa', value: 'ottawa'},
                            {name: 'kingston', value: 'kingston'}
                        ];
                        return this.cities;
                    });
            } else {
                while (!this.cities) {
                    await this.sleep(100);
                }
                return this.cities;
            }
        }
    }
    
    getPropertyAccessList(): any {
        return this._general.getPropertyAccessList();
    }

    getHostWaitGuestTimeList(): any {
        return this._general.getHostWaitGuestTimeList();
    }

    getTranslator() {
        return this._general.getTranslator();
    }

    getCurrentLang() {
        return this._general.getCurrentLang();
    }

    setCurrentLang(lang: string) {
        return this._general.setCurrentLang(lang);
    }

    getPagination(first, last) {
        return this._general.getPagination(first, last);
    }

    getEnvironmentValue(value: string) {
        return this._general.getEnvironmentValue(value);
    }

    createCanonicalURL() {
        this._seo.createCanonicalURL();
    }

    setSeoMeta() {
        this._seo.setSeoMeta();
    }

    setSeoMetaParams(title, description) {
        this._seo.setSeoMetaParams(title, description);
    }

    setOGImage(image) {
        this._seo.setOGImage(image);
    }

    setTooltips() {
        this._general.setTooltips();
    }

    setToggle() {
        this._general.setToggle();
    }

    setListLayout() {
        this._general.setListLayout();
    }

    showOverlay() {
        this._general.showOverlay();
    }

    hideOverlay() {
        this._general.hideOverlay();
    }

    unsubscribeObservable(observable) {
        this._general.unsubscribeObservable(observable);
    }

    suitableUnit(number, unit) {
        if (number <= 1) {
            return unit
        } else {
            return `${unit}s`
        }
    }

    totalRoomsRent(rooms) {
        let totalRent = 0
        rooms.forEach((room) => {
            switch (room.rentType.toLowerCase()) {
                case 'daily':
                    totalRent += Number(room.price) * 30;
                    break;

                case 'weekly':
                    totalRent += Number(room.price) / 7 * 30;
                    break;

                case 'monthly':
                    totalRent += Number(room.price);
                    break;
            }
        })
        return totalRent
    }

    formatDate(date) {
        return moment(date).format('MMMM DD, YYYY')
    }

    formatCurrency(number, locale = 'en') {
        return this._general.formatCurrency(number, locale)
    }

    formatDuration(days, months) {
        if (months < 1) {
            return `${days} ${this.suitableUnit(days, 'day')}`
        } else {
            return `${months} ${this.suitableUnit(months, 'month')}`
        }
    }

    getTimezoneStr(date, lang) {
        // Get offset
        let offset = parseInt(date.split('T')[1].split('.')[0].split(':')[0]);
        if (offset != 4 && offset != 5) {
            offset = 4;
        }
        return `UTC-${offset}`;
    }

    getFullName(firstName, lastName) {
        return `${firstName} ${lastName}`
    }

    indexOf(collection, node) {
        return Array.prototype.indexOf.call(collection, node);
    }

    isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
        return event.type.startsWith('touch');
    }
    
    isInsideDropListClientRect(dropList: CdkDropList, x: number, y: number) {
        const {top, bottom, left, right} = dropList.element.nativeElement.getBoundingClientRect();
        return y >= top && y <= bottom && x >= left && x <= right; 
    }

    getPath() {
        return this.router.url.split("?")[0];
    }

    formatLocaleDateString(date, lang) {
        return this._general.formatLocaleDateString(date, lang);
    }

    goToTop() {
        this.executeBrowserOnly(() => {
            window.scrollTo(0, 0);
        });
    }

    async getLanguages() {
        return this.http
            .get("assets/i18n/languages.json")
            .toPromise()
            .then((response) => response.json())
            .catch((err) => err);
    }
    
    dateToEpoch(date) {
        let time = date.getTime();
        return time - (time % 86400000) + 86400000;
    }

    getPathLanguage() {
        return this.router.url.split('/')[1];
    }

    titlecase(str: string) {
        return this._general.titlecase(str);
    }

    matchYoutubeUrl(url): boolean {
        let regex = /^(https?:\/\/)?(www.)?(youtube.com|youtu.be)\/(?:watch\?v=|embed\/|v\/|u\/\w\/|shorts\/)?([a-zA-Z0-9-_]+)$/;
        regex = new RegExp(regex.source + '-?$', regex.flags);
        return regex.test(url);
    }

    getYoutubeVideoId(url: string): string {
        const regex = /^(https?:\/\/)?(www.)?(youtube.com|youtu.be)\/(?:watch\?v=|embed\/|v\/|u\/\w\/|shorts\/)?([a-zA-Z0-9-_]+)$/;
        const match = regex.exec(url);
        return match ? match[4] : null;
    };

    transformYoutubeURL2EmbedURL(url: string): string {
        return `https://youtube.com/embed/${this.getYoutubeVideoId(url)}`;
    }
}
